<template>
  <div class="antd-layout-container">
    <div class="login-panel">
      <div>
        <h1 class="title">登录</h1>
        <a-form :form="form" class="login-form" @submit="login">
          <p class="des">账号/邮箱</p>
          <a-form-item>
            <a-input
              size="large"
              style="width: 500px"
              v-decorator="[
                'userName',
                {
                  rules: [{ required: true, message: '请输入账号或邮箱地址!' }],
                },
              ]"
              placeholder="请输入账号或邮箱地址"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-item>
          <p class="passWord">密码</p>
          <a-form-item>
            <a-input
              size="large"
              v-decorator="[
                'password',
                { rules: [{ required: true, message: '请输入您的密码!' }] },
              ]"
              type="password"
              placeholder="请输入您的密码"
              autocomplete
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-checkbox
              v-decorator="[
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]"
              >记住我</a-checkbox
            >
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="login-form-button"
              :loading="loading"
              >登录</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="bg"></div>
    <div class="copyright">
      <p>建议使用<img  class="googleIcon" src="@assets/images/google.png" alt="">Chrome(谷歌)浏览器访问本系统</p>
      <p>Copyright ©2019 OnePower Corporation, All Rights Reserved</p>
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >豫ICP备2021025725-1号</a
        >
      </p>
      <!-- <p>湖北爱特云信息技术有限公司</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      company: "",
      form: this.$form.createForm(this),
    };
  },
  created() {
    // this.getCompany();
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (
          !err &&
          values.userName != undefined &&
          values.password != undefined
        ) {
          this.loading = true;
          this.$http
            .post(this.$api.LOGIN, {
              username: values.userName,
              password: values.password,
            })
            .then((res) => {
              if (res.code == 1) {
                this.$store.dispatch("Login", res.data);
                this.$store.dispatch("initRoutes");
                var path = this.$route.query.redirect;
                this.$router.replace({
                  path:
                    path == "/" || typeof path == "undefined"
                      ? "/dashboard"
                      : path,
                });
                // window.location.reload();
              }
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
      return false;
    },
    getCompany() {
      this.$http
        .get("/common/company")
        .then((res) => {
          if (res.code == 1) {
            this.company = res.data.name;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.antd-layout-container {
  width: 100%;
  background-image: url("../assets/images/loginbackground.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  flex: 1;
}
.login-panel {
  border-radius: 4px;
  position: absolute;
  right: calc(30% - 80px);
  top: 50%;
  transform: translateY(-60%);
  width: 400px;
  z-index: 2;
  height: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .title {
    font-size: 38px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    padding: 0 30px;
    // letter-spacing: 30px;
  }
  .brand {
    text-align: center;

    flex: 1;
  }
  h2 {
    // color: #fff;
    text-shadow: 1px 2px 5px #616161;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  p.des {
    margin-top: 12px;
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: left;
    line-height: 20px;
  }
}
.login-form {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 30px;
  // box-shadow: 10px 15px 0px rgba(0, 0, 0, 0.2);
  p.passWord {
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-top: 24px;
  }
  .ant-form-item {
    margin-bottom: 0;
    margin-top: 0px;
    .ant-input-affix-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .login-form-button {
      margin: 6px 0;
      width: 100%;
      height: 50px;
      background: #197cff;
      border-radius: 6px 6px 6px 6px;
    }
  }
}
.copyright {
  position: absolute;
  bottom: 50px;
  left:calc(70% - 42px) ;
  transform: translateX(-50%);
  text-align: center;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
  p {
    margin: 0;
    .googleIcon{
      margin: 0 4px;
    }
    a{
       color: #999999;
    }
  }
}
.ant-btn-primary {
  background-color: #3b63e6;
  border-color: #3b63e6;
  &:hover,
  &:focus {
    background-color: #416dfd;
    border-color: #416dfd;
  }
}
</style>
