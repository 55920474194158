import http from "@/api/Http";
const Region = {
  // namespaced: true,
  state: {
    regions: [
      {
        id: 110000,
        name: "北京",
        shortName: "北京",
        children: [
          {
            id: 110100,
            name: "北京市",
            shortName: "北京",
            children: [
              {
                id: 110101,
                name: "东城区",
                shortName: "东城",
                children: [],
              },
              {
                id: 110102,
                name: "西城区",
                shortName: "西城",
                children: [],
              },
              {
                id: 110105,
                name: "朝阳区",
                shortName: "朝阳",
                children: [],
              },
              {
                id: 110106,
                name: "丰台区",
                shortName: "丰台",
                children: [],
              },
              {
                id: 110107,
                name: "石景山区",
                shortName: "石景山",
                children: [],
              },
              {
                id: 110108,
                name: "海淀区",
                shortName: "海淀",
                children: [],
              },
              {
                id: 110109,
                name: "门头沟区",
                shortName: "门头沟",
                children: [],
              },
              {
                id: 110111,
                name: "房山区",
                shortName: "房山",
                children: [],
              },
              {
                id: 110112,
                name: "通州区",
                shortName: "通州",
                children: [],
              },
              {
                id: 110113,
                name: "顺义区",
                shortName: "顺义",
                children: [],
              },
              {
                id: 110114,
                name: "昌平区",
                shortName: "昌平",
                children: [],
              },
              {
                id: 110115,
                name: "大兴区",
                shortName: "大兴",
                children: [],
              },
              {
                id: 110116,
                name: "怀柔区",
                shortName: "怀柔",
                children: [],
              },
              {
                id: 110117,
                name: "平谷区",
                shortName: "平谷",
                children: [],
              },
              {
                id: 110118,
                name: "密云区",
                shortName: "密云",
                children: [],
              },
              {
                id: 110119,
                name: "延庆区",
                shortName: "延庆",
                children: [],
              },
              {
                id: 110120,
                name: "中关村科技园区",
                shortName: "中关村",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 120000,
        name: "天津",
        shortName: "天津",
        children: [
          {
            id: 120100,
            name: "天津市",
            shortName: "天津",
            children: [
              {
                id: 120101,
                name: "和平区",
                shortName: "和平",
                children: [],
              },
              {
                id: 120102,
                name: "河东区",
                shortName: "河东",
                children: [],
              },
              {
                id: 120103,
                name: "河西区",
                shortName: "河西",
                children: [],
              },
              {
                id: 120104,
                name: "南开区",
                shortName: "南开",
                children: [],
              },
              {
                id: 120105,
                name: "河北区",
                shortName: "河北",
                children: [],
              },
              {
                id: 120106,
                name: "红桥区",
                shortName: "红桥",
                children: [],
              },
              {
                id: 120110,
                name: "东丽区",
                shortName: "东丽",
                children: [],
              },
              {
                id: 120111,
                name: "西青区",
                shortName: "西青",
                children: [],
              },
              {
                id: 120112,
                name: "津南区",
                shortName: "津南",
                children: [],
              },
              {
                id: 120113,
                name: "北辰区",
                shortName: "北辰",
                children: [],
              },
              {
                id: 120114,
                name: "武清区",
                shortName: "武清",
                children: [],
              },
              {
                id: 120115,
                name: "宝坻区",
                shortName: "宝坻",
                children: [],
              },
              {
                id: 120116,
                name: "滨海新区",
                shortName: "滨海",
                children: [],
              },
              {
                id: 120117,
                name: "宁河区",
                shortName: "宁河",
                children: [],
              },
              {
                id: 120118,
                name: "静海区",
                shortName: "静海",
                children: [],
              },
              {
                id: 120119,
                name: "蓟州区",
                shortName: "蓟州",
                children: [],
              },
              {
                id: 120120,
                name: "滨海高新区",
                shortName: "滨海高新区",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 130000,
        name: "河北省",
        shortName: "河北",
        children: [
          {
            id: 130100,
            name: "石家庄市",
            shortName: "石家庄",
            children: [
              {
                id: 130102,
                name: "长安区",
                shortName: "长安",
                children: [],
              },
              {
                id: 130104,
                name: "桥西区",
                shortName: "桥西",
                children: [],
              },
              {
                id: 130105,
                name: "新华区",
                shortName: "新华",
                children: [],
              },
              {
                id: 130107,
                name: "井陉矿区",
                shortName: "井陉矿区",
                children: [],
              },
              {
                id: 130108,
                name: "裕华区",
                shortName: "裕华",
                children: [],
              },
              {
                id: 130109,
                name: "藁城区",
                shortName: "藁城",
                children: [],
              },
              {
                id: 130110,
                name: "鹿泉区",
                shortName: "鹿泉",
                children: [],
              },
              {
                id: 130111,
                name: "栾城区",
                shortName: "栾城",
                children: [],
              },
              {
                id: 130121,
                name: "井陉县",
                shortName: "井陉",
                children: [],
              },
              {
                id: 130123,
                name: "正定县",
                shortName: "正定",
                children: [],
              },
              {
                id: 130125,
                name: "行唐县",
                shortName: "行唐",
                children: [],
              },
              {
                id: 130126,
                name: "灵寿县",
                shortName: "灵寿",
                children: [],
              },
              {
                id: 130127,
                name: "高邑县",
                shortName: "高邑",
                children: [],
              },
              {
                id: 130128,
                name: "深泽县",
                shortName: "深泽",
                children: [],
              },
              {
                id: 130129,
                name: "赞皇县",
                shortName: "赞皇",
                children: [],
              },
              {
                id: 130130,
                name: "无极县",
                shortName: "无极",
                children: [],
              },
              {
                id: 130131,
                name: "平山县",
                shortName: "平山",
                children: [],
              },
              {
                id: 130132,
                name: "元氏县",
                shortName: "元氏",
                children: [],
              },
              {
                id: 130133,
                name: "赵县",
                shortName: "赵县",
                children: [],
              },
              {
                id: 130181,
                name: "辛集市",
                shortName: "辛集",
                children: [],
              },
              {
                id: 130183,
                name: "晋州市",
                shortName: "晋州",
                children: [],
              },
              {
                id: 130184,
                name: "新乐市",
                shortName: "新乐",
                children: [],
              },
              {
                id: 130185,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 130186,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 130200,
            name: "唐山市",
            shortName: "唐山",
            children: [
              {
                id: 130202,
                name: "路南区",
                shortName: "路南",
                children: [],
              },
              {
                id: 130203,
                name: "路北区",
                shortName: "路北",
                children: [],
              },
              {
                id: 130204,
                name: "古冶区",
                shortName: "古冶",
                children: [],
              },
              {
                id: 130205,
                name: "开平区",
                shortName: "开平",
                children: [],
              },
              {
                id: 130207,
                name: "丰南区",
                shortName: "丰南",
                children: [],
              },
              {
                id: 130208,
                name: "丰润区",
                shortName: "丰润",
                children: [],
              },
              {
                id: 130209,
                name: "曹妃甸区",
                shortName: "曹妃甸",
                children: [],
              },
              {
                id: 130223,
                name: "滦县",
                shortName: "滦县",
                children: [],
              },
              {
                id: 130224,
                name: "滦南县",
                shortName: "滦南",
                children: [],
              },
              {
                id: 130225,
                name: "乐亭县",
                shortName: "乐亭",
                children: [],
              },
              {
                id: 130227,
                name: "迁西县",
                shortName: "迁西",
                children: [],
              },
              {
                id: 130229,
                name: "玉田县",
                shortName: "玉田",
                children: [],
              },
              {
                id: 130281,
                name: "遵化市",
                shortName: "遵化",
                children: [],
              },
              {
                id: 130283,
                name: "迁安市",
                shortName: "迁安",
                children: [],
              },
            ],
          },
          {
            id: 130300,
            name: "秦皇岛市",
            shortName: "秦皇岛",
            children: [
              {
                id: 130302,
                name: "海港区",
                shortName: "海港",
                children: [],
              },
              {
                id: 130303,
                name: "山海关区",
                shortName: "山海关",
                children: [],
              },
              {
                id: 130304,
                name: "北戴河区",
                shortName: "北戴河",
                children: [],
              },
              {
                id: 130306,
                name: "抚宁区",
                shortName: "抚宁",
                children: [],
              },
              {
                id: 130321,
                name: "青龙满族自治县",
                shortName: "青龙",
                children: [],
              },
              {
                id: 130322,
                name: "昌黎县",
                shortName: "昌黎",
                children: [],
              },
              {
                id: 130324,
                name: "卢龙县",
                shortName: "卢龙",
                children: [],
              },
              {
                id: 130325,
                name: "北戴河新区",
                shortName: "北戴河新区",
                children: [],
              },
            ],
          },
          {
            id: 130400,
            name: "邯郸市",
            shortName: "邯郸",
            children: [
              {
                id: 130402,
                name: "邯山区",
                shortName: "邯山",
                children: [],
              },
              {
                id: 130403,
                name: "丛台区",
                shortName: "丛台",
                children: [],
              },
              {
                id: 130404,
                name: "复兴区",
                shortName: "复兴",
                children: [],
              },
              {
                id: 130406,
                name: "峰峰矿区",
                shortName: "峰峰矿区",
                children: [],
              },
              {
                id: 130407,
                name: "肥乡区",
                shortName: "肥乡",
                children: [],
              },
              {
                id: 130408,
                name: "永年区",
                shortName: "永年",
                children: [],
              },
              {
                id: 130423,
                name: "临漳县",
                shortName: "临漳",
                children: [],
              },
              {
                id: 130424,
                name: "成安县",
                shortName: "成安",
                children: [],
              },
              {
                id: 130425,
                name: "大名县",
                shortName: "大名",
                children: [],
              },
              {
                id: 130426,
                name: "涉县",
                shortName: "涉县",
                children: [],
              },
              {
                id: 130427,
                name: "磁县",
                shortName: "磁县",
                children: [],
              },
              {
                id: 130430,
                name: "邱县",
                shortName: "邱县",
                children: [],
              },
              {
                id: 130431,
                name: "鸡泽县",
                shortName: "鸡泽",
                children: [],
              },
              {
                id: 130432,
                name: "广平县",
                shortName: "广平",
                children: [],
              },
              {
                id: 130433,
                name: "馆陶县",
                shortName: "馆陶",
                children: [],
              },
              {
                id: 130434,
                name: "魏县",
                shortName: "魏县",
                children: [],
              },
              {
                id: 130435,
                name: "曲周县",
                shortName: "曲周",
                children: [],
              },
              {
                id: 130481,
                name: "武安市",
                shortName: "武安",
                children: [],
              },
              {
                id: 130482,
                name: "高新技术产业开发区",
                shortName: "高新技术产业开发区",
                children: [],
              },
            ],
          },
          {
            id: 130500,
            name: "邢台市",
            shortName: "邢台",
            children: [
              {
                id: 130502,
                name: "桥东区",
                shortName: "桥东",
                children: [],
              },
              {
                id: 130503,
                name: "桥西区",
                shortName: "桥西",
                children: [],
              },
              {
                id: 130521,
                name: "邢台县",
                shortName: "邢台",
                children: [],
              },
              {
                id: 130522,
                name: "临城县",
                shortName: "临城",
                children: [],
              },
              {
                id: 130523,
                name: "内丘县",
                shortName: "内丘",
                children: [],
              },
              {
                id: 130524,
                name: "柏乡县",
                shortName: "柏乡",
                children: [],
              },
              {
                id: 130525,
                name: "隆尧县",
                shortName: "隆尧",
                children: [],
              },
              {
                id: 130526,
                name: "任县",
                shortName: "任县",
                children: [],
              },
              {
                id: 130527,
                name: "南和县",
                shortName: "南和",
                children: [],
              },
              {
                id: 130528,
                name: "宁晋县",
                shortName: "宁晋",
                children: [],
              },
              {
                id: 130529,
                name: "巨鹿县",
                shortName: "巨鹿",
                children: [],
              },
              {
                id: 130530,
                name: "新河县",
                shortName: "新河",
                children: [],
              },
              {
                id: 130531,
                name: "广宗县",
                shortName: "广宗",
                children: [],
              },
              {
                id: 130532,
                name: "平乡县",
                shortName: "平乡",
                children: [],
              },
              {
                id: 130533,
                name: "威县",
                shortName: "威县",
                children: [],
              },
              {
                id: 130534,
                name: "清河县",
                shortName: "清河",
                children: [],
              },
              {
                id: 130535,
                name: "临西县",
                shortName: "临西",
                children: [],
              },
              {
                id: 130581,
                name: "南宫市",
                shortName: "南宫",
                children: [],
              },
              {
                id: 130582,
                name: "沙河市",
                shortName: "沙河",
                children: [],
              },
            ],
          },
          {
            id: 130600,
            name: "保定市",
            shortName: "保定",
            children: [
              {
                id: 130602,
                name: "竞秀区",
                shortName: "竞秀",
                children: [],
              },
              {
                id: 130606,
                name: "莲池区",
                shortName: "莲池",
                children: [],
              },
              {
                id: 130607,
                name: "满城区",
                shortName: "满城",
                children: [],
              },
              {
                id: 130608,
                name: "清苑区",
                shortName: "清苑",
                children: [],
              },
              {
                id: 130609,
                name: "徐水区",
                shortName: "徐水",
                children: [],
              },
              {
                id: 130623,
                name: "涞水县",
                shortName: "涞水",
                children: [],
              },
              {
                id: 130624,
                name: "阜平县",
                shortName: "阜平",
                children: [],
              },
              {
                id: 130626,
                name: "定兴县",
                shortName: "定兴",
                children: [],
              },
              {
                id: 130627,
                name: "唐县",
                shortName: "唐县",
                children: [],
              },
              {
                id: 130628,
                name: "高阳县",
                shortName: "高阳",
                children: [],
              },
              {
                id: 130629,
                name: "容城县",
                shortName: "容城",
                children: [],
              },
              {
                id: 130630,
                name: "涞源县",
                shortName: "涞源",
                children: [],
              },
              {
                id: 130631,
                name: "望都县",
                shortName: "望都",
                children: [],
              },
              {
                id: 130632,
                name: "安新县",
                shortName: "安新",
                children: [],
              },
              {
                id: 130633,
                name: "易县",
                shortName: "易县",
                children: [],
              },
              {
                id: 130634,
                name: "曲阳县",
                shortName: "曲阳",
                children: [],
              },
              {
                id: 130635,
                name: "蠡县",
                shortName: "蠡县",
                children: [],
              },
              {
                id: 130636,
                name: "顺平县",
                shortName: "顺平",
                children: [],
              },
              {
                id: 130637,
                name: "博野县",
                shortName: "博野",
                children: [],
              },
              {
                id: 130638,
                name: "雄县",
                shortName: "雄县",
                children: [],
              },
              {
                id: 130681,
                name: "涿州市",
                shortName: "涿州",
                children: [],
              },
              {
                id: 130682,
                name: "定州市",
                shortName: "定州",
                children: [],
              },
              {
                id: 130683,
                name: "安国市",
                shortName: "安国",
                children: [],
              },
              {
                id: 130684,
                name: "高碑店市",
                shortName: "高碑店",
                children: [],
              },
              {
                id: 130685,
                name: "雄安新区",
                shortName: "雄安新区",
                children: [],
              },
              {
                id: 130686,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 130700,
            name: "张家口市",
            shortName: "张家口",
            children: [
              {
                id: 130702,
                name: "桥东区",
                shortName: "桥东",
                children: [],
              },
              {
                id: 130703,
                name: "桥西区",
                shortName: "桥西",
                children: [],
              },
              {
                id: 130705,
                name: "宣化区",
                shortName: "宣化",
                children: [],
              },
              {
                id: 130706,
                name: "下花园区",
                shortName: "下花园",
                children: [],
              },
              {
                id: 130708,
                name: "万全区",
                shortName: "万全",
                children: [],
              },
              {
                id: 130709,
                name: "崇礼区",
                shortName: "崇礼",
                children: [],
              },
              {
                id: 130722,
                name: "张北县",
                shortName: "张北",
                children: [],
              },
              {
                id: 130723,
                name: "康保县",
                shortName: "康保",
                children: [],
              },
              {
                id: 130724,
                name: "沽源县",
                shortName: "沽源",
                children: [],
              },
              {
                id: 130725,
                name: "尚义县",
                shortName: "尚义",
                children: [],
              },
              {
                id: 130726,
                name: "蔚县",
                shortName: "蔚县",
                children: [],
              },
              {
                id: 130727,
                name: "阳原县",
                shortName: "阳原",
                children: [],
              },
              {
                id: 130728,
                name: "怀安县",
                shortName: "怀安",
                children: [],
              },
              {
                id: 130730,
                name: "怀来县",
                shortName: "怀来",
                children: [],
              },
              {
                id: 130731,
                name: "涿鹿县",
                shortName: "涿鹿",
                children: [],
              },
              {
                id: 130732,
                name: "赤城县",
                shortName: "赤城",
                children: [],
              },
            ],
          },
          {
            id: 130800,
            name: "承德市",
            shortName: "承德",
            children: [
              {
                id: 130802,
                name: "双桥区",
                shortName: "双桥",
                children: [],
              },
              {
                id: 130803,
                name: "双滦区",
                shortName: "双滦",
                children: [],
              },
              {
                id: 130804,
                name: "鹰手营子矿区",
                shortName: "鹰手营子矿区",
                children: [],
              },
              {
                id: 130821,
                name: "承德县",
                shortName: "承德",
                children: [],
              },
              {
                id: 130822,
                name: "兴隆县",
                shortName: "兴隆",
                children: [],
              },
              {
                id: 130824,
                name: "滦平县",
                shortName: "滦平",
                children: [],
              },
              {
                id: 130825,
                name: "隆化县",
                shortName: "隆化",
                children: [],
              },
              {
                id: 130826,
                name: "丰宁满族自治县",
                shortName: "丰宁",
                children: [],
              },
              {
                id: 130827,
                name: "宽城满族自治县",
                shortName: "宽城",
                children: [],
              },
              {
                id: 130828,
                name: "围场满族蒙古族自治县",
                shortName: "围场",
                children: [],
              },
              {
                id: 130881,
                name: "平泉市",
                shortName: "平泉",
                children: [],
              },
            ],
          },
          {
            id: 130900,
            name: "沧州市",
            shortName: "沧州",
            children: [
              {
                id: 130902,
                name: "新华区",
                shortName: "新华",
                children: [],
              },
              {
                id: 130903,
                name: "运河区",
                shortName: "运河",
                children: [],
              },
              {
                id: 130921,
                name: "沧县",
                shortName: "沧县",
                children: [],
              },
              {
                id: 130922,
                name: "青县",
                shortName: "青县",
                children: [],
              },
              {
                id: 130923,
                name: "东光县",
                shortName: "东光",
                children: [],
              },
              {
                id: 130924,
                name: "海兴县",
                shortName: "海兴",
                children: [],
              },
              {
                id: 130925,
                name: "盐山县",
                shortName: "盐山",
                children: [],
              },
              {
                id: 130926,
                name: "肃宁县",
                shortName: "肃宁",
                children: [],
              },
              {
                id: 130927,
                name: "南皮县",
                shortName: "南皮",
                children: [],
              },
              {
                id: 130928,
                name: "吴桥县",
                shortName: "吴桥",
                children: [],
              },
              {
                id: 130929,
                name: "献县",
                shortName: "献县",
                children: [],
              },
              {
                id: 130930,
                name: "孟村回族自治县",
                shortName: "孟村",
                children: [],
              },
              {
                id: 130981,
                name: "泊头市",
                shortName: "泊头",
                children: [],
              },
              {
                id: 130982,
                name: "任丘市",
                shortName: "任丘",
                children: [],
              },
              {
                id: 130983,
                name: "黄骅市",
                shortName: "黄骅",
                children: [],
              },
              {
                id: 130984,
                name: "河间市",
                shortName: "河间",
                children: [],
              },
              {
                id: 130985,
                name: "渤海新区",
                shortName: "渤海新区",
                children: [],
              },
              {
                id: 130986,
                name: "临港经济技术开发区",
                shortName: "临港经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 131000,
            name: "廊坊市",
            shortName: "廊坊",
            children: [
              {
                id: 131002,
                name: "安次区",
                shortName: "安次",
                children: [],
              },
              {
                id: 131003,
                name: "广阳区",
                shortName: "广阳",
                children: [],
              },
              {
                id: 131022,
                name: "固安县",
                shortName: "固安",
                children: [],
              },
              {
                id: 131023,
                name: "永清县",
                shortName: "永清",
                children: [],
              },
              {
                id: 131024,
                name: "香河县",
                shortName: "香河",
                children: [],
              },
              {
                id: 131025,
                name: "大城县",
                shortName: "大城",
                children: [],
              },
              {
                id: 131026,
                name: "文安县",
                shortName: "文安",
                children: [],
              },
              {
                id: 131028,
                name: "大厂回族自治县",
                shortName: "大厂",
                children: [],
              },
              {
                id: 131081,
                name: "霸州市",
                shortName: "霸州",
                children: [],
              },
              {
                id: 131082,
                name: "三河市",
                shortName: "三河",
                children: [],
              },
              {
                id: 131083,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 131100,
            name: "衡水市",
            shortName: "衡水",
            children: [
              {
                id: 131102,
                name: "桃城区",
                shortName: "桃城",
                children: [],
              },
              {
                id: 131103,
                name: "冀州区",
                shortName: "冀州",
                children: [],
              },
              {
                id: 131121,
                name: "枣强县",
                shortName: "枣强",
                children: [],
              },
              {
                id: 131122,
                name: "武邑县",
                shortName: "武邑",
                children: [],
              },
              {
                id: 131123,
                name: "武强县",
                shortName: "武强",
                children: [],
              },
              {
                id: 131124,
                name: "饶阳县",
                shortName: "饶阳",
                children: [],
              },
              {
                id: 131125,
                name: "安平县",
                shortName: "安平",
                children: [],
              },
              {
                id: 131126,
                name: "故城县",
                shortName: "故城",
                children: [],
              },
              {
                id: 131127,
                name: "景县",
                shortName: "景县",
                children: [],
              },
              {
                id: 131128,
                name: "阜城县",
                shortName: "阜城",
                children: [],
              },
              {
                id: 131182,
                name: "深州市",
                shortName: "深州",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 140000,
        name: "山西省",
        shortName: "山西",
        children: [
          {
            id: 140100,
            name: "太原市",
            shortName: "太原",
            children: [
              {
                id: 140105,
                name: "小店区",
                shortName: "小店",
                children: [],
              },
              {
                id: 140106,
                name: "迎泽区",
                shortName: "迎泽",
                children: [],
              },
              {
                id: 140107,
                name: "杏花岭区",
                shortName: "杏花岭",
                children: [],
              },
              {
                id: 140108,
                name: "尖草坪区",
                shortName: "尖草坪",
                children: [],
              },
              {
                id: 140109,
                name: "万柏林区",
                shortName: "万柏林",
                children: [],
              },
              {
                id: 140110,
                name: "晋源区",
                shortName: "晋源",
                children: [],
              },
              {
                id: 140121,
                name: "清徐县",
                shortName: "清徐",
                children: [],
              },
              {
                id: 140122,
                name: "阳曲县",
                shortName: "阳曲",
                children: [],
              },
              {
                id: 140123,
                name: "娄烦县",
                shortName: "娄烦",
                children: [],
              },
              {
                id: 140181,
                name: "古交市",
                shortName: "古交",
                children: [],
              },
              {
                id: 140182,
                name: "高新阳曲园区",
                shortName: "阳曲园区",
                children: [],
              },
              {
                id: 140183,
                name: "高新汾东园区",
                shortName: "汾东园区",
                children: [],
              },
              {
                id: 140184,
                name: "高新姚村园区",
                shortName: "姚村园区",
                children: [],
              },
            ],
          },
          {
            id: 140200,
            name: "大同市",
            shortName: "大同",
            children: [
              {
                id: 140202,
                name: "城区",
                shortName: "城区",
                children: [],
              },
              {
                id: 140203,
                name: "矿区",
                shortName: "矿区",
                children: [],
              },
              {
                id: 140211,
                name: "南郊区",
                shortName: "南郊",
                children: [],
              },
              {
                id: 140212,
                name: "新荣区",
                shortName: "新荣",
                children: [],
              },
              {
                id: 140221,
                name: "阳高县",
                shortName: "阳高",
                children: [],
              },
              {
                id: 140222,
                name: "天镇县",
                shortName: "天镇",
                children: [],
              },
              {
                id: 140223,
                name: "广灵县",
                shortName: "广灵",
                children: [],
              },
              {
                id: 140224,
                name: "灵丘县",
                shortName: "灵丘",
                children: [],
              },
              {
                id: 140225,
                name: "浑源县",
                shortName: "浑源",
                children: [],
              },
              {
                id: 140226,
                name: "左云县",
                shortName: "左云",
                children: [],
              },
              {
                id: 140227,
                name: "大同县",
                shortName: "大同",
                children: [],
              },
              {
                id: 140228,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 140300,
            name: "阳泉市",
            shortName: "阳泉",
            children: [
              {
                id: 140302,
                name: "城区",
                shortName: "城区",
                children: [],
              },
              {
                id: 140303,
                name: "矿区",
                shortName: "矿区",
                children: [],
              },
              {
                id: 140311,
                name: "郊区",
                shortName: "郊区",
                children: [],
              },
              {
                id: 140321,
                name: "平定县",
                shortName: "平定",
                children: [],
              },
              {
                id: 140322,
                name: "盂县",
                shortName: "盂县",
                children: [],
              },
            ],
          },
          {
            id: 140400,
            name: "长治市",
            shortName: "长治",
            children: [
              {
                id: 140402,
                name: "城区",
                shortName: "城区",
                children: [],
              },
              {
                id: 140411,
                name: "郊区",
                shortName: "郊区",
                children: [],
              },
              {
                id: 140421,
                name: "长治县",
                shortName: "长治",
                children: [],
              },
              {
                id: 140423,
                name: "襄垣县",
                shortName: "襄垣",
                children: [],
              },
              {
                id: 140424,
                name: "屯留县",
                shortName: "屯留",
                children: [],
              },
              {
                id: 140425,
                name: "平顺县",
                shortName: "平顺",
                children: [],
              },
              {
                id: 140426,
                name: "黎城县",
                shortName: "黎城",
                children: [],
              },
              {
                id: 140427,
                name: "壶关县",
                shortName: "壶关",
                children: [],
              },
              {
                id: 140428,
                name: "长子县",
                shortName: "长子",
                children: [],
              },
              {
                id: 140429,
                name: "武乡县",
                shortName: "武乡",
                children: [],
              },
              {
                id: 140430,
                name: "沁县",
                shortName: "沁县",
                children: [],
              },
              {
                id: 140431,
                name: "沁源县",
                shortName: "沁源",
                children: [],
              },
              {
                id: 140481,
                name: "潞城市",
                shortName: "潞城",
                children: [],
              },
            ],
          },
          {
            id: 140500,
            name: "晋城市",
            shortName: "晋城",
            children: [
              {
                id: 140502,
                name: "城区",
                shortName: "城区",
                children: [],
              },
              {
                id: 140521,
                name: "沁水县",
                shortName: "沁水",
                children: [],
              },
              {
                id: 140522,
                name: "阳城县",
                shortName: "阳城",
                children: [],
              },
              {
                id: 140524,
                name: "陵川县",
                shortName: "陵川",
                children: [],
              },
              {
                id: 140525,
                name: "泽州县",
                shortName: "泽州",
                children: [],
              },
              {
                id: 140581,
                name: "高平市",
                shortName: "高平",
                children: [],
              },
              {
                id: 140582,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 140600,
            name: "朔州市",
            shortName: "朔州",
            children: [
              {
                id: 140602,
                name: "朔城区",
                shortName: "朔城",
                children: [],
              },
              {
                id: 140603,
                name: "平鲁区",
                shortName: "平鲁",
                children: [],
              },
              {
                id: 140621,
                name: "山阴县",
                shortName: "山阴",
                children: [],
              },
              {
                id: 140622,
                name: "应县",
                shortName: "应县",
                children: [],
              },
              {
                id: 140623,
                name: "右玉县",
                shortName: "右玉",
                children: [],
              },
              {
                id: 140624,
                name: "怀仁县",
                shortName: "怀仁",
                children: [],
              },
            ],
          },
          {
            id: 140700,
            name: "晋中市",
            shortName: "晋中",
            children: [
              {
                id: 140702,
                name: "榆次区",
                shortName: "榆次",
                children: [],
              },
              {
                id: 140721,
                name: "榆社县",
                shortName: "榆社",
                children: [],
              },
              {
                id: 140722,
                name: "左权县",
                shortName: "左权",
                children: [],
              },
              {
                id: 140723,
                name: "和顺县",
                shortName: "和顺",
                children: [],
              },
              {
                id: 140724,
                name: "昔阳县",
                shortName: "昔阳",
                children: [],
              },
              {
                id: 140725,
                name: "寿阳县",
                shortName: "寿阳",
                children: [],
              },
              {
                id: 140726,
                name: "太谷县",
                shortName: "太谷",
                children: [],
              },
              {
                id: 140727,
                name: "祁县",
                shortName: "祁县",
                children: [],
              },
              {
                id: 140728,
                name: "平遥县",
                shortName: "平遥",
                children: [],
              },
              {
                id: 140729,
                name: "灵石县",
                shortName: "灵石",
                children: [],
              },
              {
                id: 140781,
                name: "介休市",
                shortName: "介休",
                children: [],
              },
            ],
          },
          {
            id: 140800,
            name: "运城市",
            shortName: "运城",
            children: [
              {
                id: 140802,
                name: "盐湖区",
                shortName: "盐湖",
                children: [],
              },
              {
                id: 140821,
                name: "临猗县",
                shortName: "临猗",
                children: [],
              },
              {
                id: 140822,
                name: "万荣县",
                shortName: "万荣",
                children: [],
              },
              {
                id: 140823,
                name: "闻喜县",
                shortName: "闻喜",
                children: [],
              },
              {
                id: 140824,
                name: "稷山县",
                shortName: "稷山",
                children: [],
              },
              {
                id: 140825,
                name: "新绛县",
                shortName: "新绛",
                children: [],
              },
              {
                id: 140826,
                name: "绛县",
                shortName: "绛县",
                children: [],
              },
              {
                id: 140827,
                name: "垣曲县",
                shortName: "垣曲",
                children: [],
              },
              {
                id: 140828,
                name: "夏县",
                shortName: "夏县",
                children: [],
              },
              {
                id: 140829,
                name: "平陆县",
                shortName: "平陆",
                children: [],
              },
              {
                id: 140830,
                name: "芮城县",
                shortName: "芮城",
                children: [],
              },
              {
                id: 140881,
                name: "永济市",
                shortName: "永济",
                children: [],
              },
              {
                id: 140882,
                name: "河津市",
                shortName: "河津",
                children: [],
              },
            ],
          },
          {
            id: 140900,
            name: "忻州市",
            shortName: "忻州",
            children: [
              {
                id: 140902,
                name: "忻府区",
                shortName: "忻府",
                children: [],
              },
              {
                id: 140921,
                name: "定襄县",
                shortName: "定襄",
                children: [],
              },
              {
                id: 140922,
                name: "五台县",
                shortName: "五台",
                children: [],
              },
              {
                id: 140923,
                name: "代县",
                shortName: "代县",
                children: [],
              },
              {
                id: 140924,
                name: "繁峙县",
                shortName: "繁峙",
                children: [],
              },
              {
                id: 140925,
                name: "宁武县",
                shortName: "宁武",
                children: [],
              },
              {
                id: 140926,
                name: "静乐县",
                shortName: "静乐",
                children: [],
              },
              {
                id: 140927,
                name: "神池县",
                shortName: "神池",
                children: [],
              },
              {
                id: 140928,
                name: "五寨县",
                shortName: "五寨",
                children: [],
              },
              {
                id: 140929,
                name: "岢岚县",
                shortName: "岢岚",
                children: [],
              },
              {
                id: 140930,
                name: "河曲县",
                shortName: "河曲",
                children: [],
              },
              {
                id: 140931,
                name: "保德县",
                shortName: "保德",
                children: [],
              },
              {
                id: 140932,
                name: "偏关县",
                shortName: "偏关",
                children: [],
              },
              {
                id: 140981,
                name: "原平市",
                shortName: "原平",
                children: [],
              },
            ],
          },
          {
            id: 141000,
            name: "临汾市",
            shortName: "临汾",
            children: [
              {
                id: 141002,
                name: "尧都区",
                shortName: "尧都",
                children: [],
              },
              {
                id: 141021,
                name: "曲沃县",
                shortName: "曲沃",
                children: [],
              },
              {
                id: 141022,
                name: "翼城县",
                shortName: "翼城",
                children: [],
              },
              {
                id: 141023,
                name: "襄汾县",
                shortName: "襄汾",
                children: [],
              },
              {
                id: 141024,
                name: "洪洞县",
                shortName: "洪洞",
                children: [],
              },
              {
                id: 141025,
                name: "古县",
                shortName: "古县",
                children: [],
              },
              {
                id: 141026,
                name: "安泽县",
                shortName: "安泽",
                children: [],
              },
              {
                id: 141027,
                name: "浮山县",
                shortName: "浮山",
                children: [],
              },
              {
                id: 141028,
                name: "吉县",
                shortName: "吉县",
                children: [],
              },
              {
                id: 141029,
                name: "乡宁县",
                shortName: "乡宁",
                children: [],
              },
              {
                id: 141030,
                name: "大宁县",
                shortName: "大宁",
                children: [],
              },
              {
                id: 141031,
                name: "隰县",
                shortName: "隰县",
                children: [],
              },
              {
                id: 141032,
                name: "永和县",
                shortName: "永和",
                children: [],
              },
              {
                id: 141033,
                name: "蒲县",
                shortName: "蒲县",
                children: [],
              },
              {
                id: 141034,
                name: "汾西县",
                shortName: "汾西",
                children: [],
              },
              {
                id: 141081,
                name: "侯马市",
                shortName: "侯马",
                children: [],
              },
              {
                id: 141082,
                name: "霍州市",
                shortName: "霍州",
                children: [],
              },
            ],
          },
          {
            id: 141100,
            name: "吕梁市",
            shortName: "吕梁",
            children: [
              {
                id: 141102,
                name: "离石区",
                shortName: "离石",
                children: [],
              },
              {
                id: 141121,
                name: "文水县",
                shortName: "文水",
                children: [],
              },
              {
                id: 141122,
                name: "交城县",
                shortName: "交城",
                children: [],
              },
              {
                id: 141123,
                name: "兴县",
                shortName: "兴县",
                children: [],
              },
              {
                id: 141124,
                name: "临县",
                shortName: "临县",
                children: [],
              },
              {
                id: 141125,
                name: "柳林县",
                shortName: "柳林",
                children: [],
              },
              {
                id: 141126,
                name: "石楼县",
                shortName: "石楼",
                children: [],
              },
              {
                id: 141127,
                name: "岚县",
                shortName: "岚县",
                children: [],
              },
              {
                id: 141128,
                name: "方山县",
                shortName: "方山",
                children: [],
              },
              {
                id: 141129,
                name: "中阳县",
                shortName: "中阳",
                children: [],
              },
              {
                id: 141130,
                name: "交口县",
                shortName: "交口",
                children: [],
              },
              {
                id: 141181,
                name: "孝义市",
                shortName: "孝义",
                children: [],
              },
              {
                id: 141182,
                name: "汾阳市",
                shortName: "汾阳",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 150000,
        name: "内蒙古自治区",
        shortName: "内蒙古",
        children: [
          {
            id: 150100,
            name: "呼和浩特市",
            shortName: "呼和浩特",
            children: [
              {
                id: 150102,
                name: "新城区",
                shortName: "新城",
                children: [],
              },
              {
                id: 150103,
                name: "回民区",
                shortName: "回民",
                children: [],
              },
              {
                id: 150104,
                name: "玉泉区",
                shortName: "玉泉",
                children: [],
              },
              {
                id: 150105,
                name: "赛罕区",
                shortName: "赛罕",
                children: [],
              },
              {
                id: 150121,
                name: "土默特左旗",
                shortName: "土左旗",
                children: [],
              },
              {
                id: 150122,
                name: "托克托县",
                shortName: "托克托",
                children: [],
              },
              {
                id: 150123,
                name: "和林格尔县",
                shortName: "和林格尔",
                children: [],
              },
              {
                id: 150124,
                name: "清水河县",
                shortName: "清水河",
                children: [],
              },
              {
                id: 150125,
                name: "武川县",
                shortName: "武川",
                children: [],
              },
            ],
          },
          {
            id: 150200,
            name: "包头市",
            shortName: "包头",
            children: [
              {
                id: 150202,
                name: "东河区",
                shortName: "东河",
                children: [],
              },
              {
                id: 150203,
                name: "昆都仑区",
                shortName: "昆都仑",
                children: [],
              },
              {
                id: 150204,
                name: "青山区",
                shortName: "青山",
                children: [],
              },
              {
                id: 150205,
                name: "石拐区",
                shortName: "石拐",
                children: [],
              },
              {
                id: 150206,
                name: "白云鄂博矿区",
                shortName: "白云矿区",
                children: [],
              },
              {
                id: 150207,
                name: "九原区",
                shortName: "九原",
                children: [],
              },
              {
                id: 150221,
                name: "土默特右旗",
                shortName: "土右旗",
                children: [],
              },
              {
                id: 150222,
                name: "固阳县",
                shortName: "固阳",
                children: [],
              },
              {
                id: 150223,
                name: "达尔罕茂明安联合旗",
                shortName: "达茂旗",
                children: [],
              },
            ],
          },
          {
            id: 150300,
            name: "乌海市",
            shortName: "乌海",
            children: [
              {
                id: 150302,
                name: "海勃湾区",
                shortName: "海勃湾",
                children: [],
              },
              {
                id: 150303,
                name: "海南区",
                shortName: "海南",
                children: [],
              },
              {
                id: 150304,
                name: "乌达区",
                shortName: "乌达",
                children: [],
              },
            ],
          },
          {
            id: 150400,
            name: "赤峰市",
            shortName: "赤峰",
            children: [
              {
                id: 150402,
                name: "红山区",
                shortName: "红山",
                children: [],
              },
              {
                id: 150403,
                name: "元宝山区",
                shortName: "元宝山",
                children: [],
              },
              {
                id: 150404,
                name: "松山区",
                shortName: "松山",
                children: [],
              },
              {
                id: 150421,
                name: "阿鲁科尔沁旗",
                shortName: "阿旗",
                children: [],
              },
              {
                id: 150422,
                name: "巴林左旗",
                shortName: "左旗",
                children: [],
              },
              {
                id: 150423,
                name: "巴林右旗",
                shortName: "右旗",
                children: [],
              },
              {
                id: 150424,
                name: "林西县",
                shortName: "林西",
                children: [],
              },
              {
                id: 150425,
                name: "克什克腾旗",
                shortName: "克旗",
                children: [],
              },
              {
                id: 150426,
                name: "翁牛特旗",
                shortName: "翁旗",
                children: [],
              },
              {
                id: 150428,
                name: "喀喇沁旗",
                shortName: "喀旗",
                children: [],
              },
              {
                id: 150429,
                name: "宁城县",
                shortName: "宁城",
                children: [],
              },
              {
                id: 150430,
                name: "敖汉旗",
                shortName: "敖汉",
                children: [],
              },
            ],
          },
          {
            id: 150500,
            name: "通辽市",
            shortName: "通辽",
            children: [
              {
                id: 150502,
                name: "科尔沁区",
                shortName: "科尔沁",
                children: [],
              },
              {
                id: 150521,
                name: "科尔沁左翼中旗",
                shortName: "科左中旗",
                children: [],
              },
              {
                id: 150522,
                name: "科尔沁左翼后旗",
                shortName: "科左后旗",
                children: [],
              },
              {
                id: 150523,
                name: "开鲁县",
                shortName: "开鲁",
                children: [],
              },
              {
                id: 150524,
                name: "库伦旗",
                shortName: "库伦",
                children: [],
              },
              {
                id: 150525,
                name: "奈曼旗",
                shortName: "奈曼",
                children: [],
              },
              {
                id: 150526,
                name: "扎鲁特旗",
                shortName: "扎鲁特",
                children: [],
              },
              {
                id: 150581,
                name: "霍林郭勒市",
                shortName: "霍林郭勒",
                children: [],
              },
            ],
          },
          {
            id: 150600,
            name: "鄂尔多斯市",
            shortName: "鄂尔多斯",
            children: [
              {
                id: 150602,
                name: "东胜区",
                shortName: "东胜",
                children: [],
              },
              {
                id: 150603,
                name: "康巴什区",
                shortName: "康巴什",
                children: [],
              },
              {
                id: 150621,
                name: "达拉特旗",
                shortName: "达拉特",
                children: [],
              },
              {
                id: 150622,
                name: "准格尔旗",
                shortName: "准格尔",
                children: [],
              },
              {
                id: 150623,
                name: "鄂托克前旗",
                shortName: "鄂前旗",
                children: [],
              },
              {
                id: 150624,
                name: "鄂托克旗",
                shortName: "鄂托克",
                children: [],
              },
              {
                id: 150625,
                name: "杭锦旗",
                shortName: "杭锦旗",
                children: [],
              },
              {
                id: 150626,
                name: "乌审旗",
                shortName: "乌审旗",
                children: [],
              },
              {
                id: 150627,
                name: "伊金霍洛旗",
                shortName: "伊金霍洛",
                children: [],
              },
            ],
          },
          {
            id: 150700,
            name: "呼伦贝尔市",
            shortName: "呼伦贝尔",
            children: [
              {
                id: 150702,
                name: "海拉尔区",
                shortName: "海拉尔",
                children: [],
              },
              {
                id: 150703,
                name: "扎赉诺尔区",
                shortName: "扎赉诺尔",
                children: [],
              },
              {
                id: 150721,
                name: "阿荣旗",
                shortName: "阿荣旗",
                children: [],
              },
              {
                id: 150722,
                name: "莫力达瓦达斡尔族自治旗",
                shortName: "莫旗",
                children: [],
              },
              {
                id: 150723,
                name: "鄂伦春自治旗",
                shortName: "鄂伦春",
                children: [],
              },
              {
                id: 150724,
                name: "鄂温克族自治旗",
                shortName: "鄂温克旗",
                children: [],
              },
              {
                id: 150725,
                name: "陈巴尔虎旗",
                shortName: "陈旗",
                children: [],
              },
              {
                id: 150726,
                name: "新巴尔虎左旗",
                shortName: "新左旗",
                children: [],
              },
              {
                id: 150727,
                name: "新巴尔虎右旗",
                shortName: "新右旗",
                children: [],
              },
              {
                id: 150781,
                name: "满洲里市",
                shortName: "满洲里",
                children: [],
              },
              {
                id: 150782,
                name: "牙克石市",
                shortName: "牙克石",
                children: [],
              },
              {
                id: 150783,
                name: "扎兰屯市",
                shortName: "扎兰屯",
                children: [],
              },
              {
                id: 150784,
                name: "额尔古纳市",
                shortName: "额尔古纳",
                children: [],
              },
              {
                id: 150785,
                name: "根河市",
                shortName: "根河",
                children: [],
              },
            ],
          },
          {
            id: 150800,
            name: "巴彦淖尔市",
            shortName: "巴彦淖尔",
            children: [
              {
                id: 150802,
                name: "临河区",
                shortName: "临河",
                children: [],
              },
              {
                id: 150821,
                name: "五原县",
                shortName: "五原",
                children: [],
              },
              {
                id: 150822,
                name: "磴口县",
                shortName: "磴口",
                children: [],
              },
              {
                id: 150823,
                name: "乌拉特前旗",
                shortName: "乌前旗",
                children: [],
              },
              {
                id: 150824,
                name: "乌拉特中旗",
                shortName: "乌中旗",
                children: [],
              },
              {
                id: 150825,
                name: "乌拉特后旗",
                shortName: "乌后旗",
                children: [],
              },
              {
                id: 150826,
                name: "杭锦后旗",
                shortName: "杭锦后旗",
                children: [],
              },
            ],
          },
          {
            id: 150900,
            name: "乌兰察布市",
            shortName: "乌兰察布",
            children: [
              {
                id: 150902,
                name: "集宁区",
                shortName: "集宁",
                children: [],
              },
              {
                id: 150921,
                name: "卓资县",
                shortName: "卓资",
                children: [],
              },
              {
                id: 150922,
                name: "化德县",
                shortName: "化德",
                children: [],
              },
              {
                id: 150923,
                name: "商都县",
                shortName: "商都",
                children: [],
              },
              {
                id: 150924,
                name: "兴和县",
                shortName: "兴和",
                children: [],
              },
              {
                id: 150925,
                name: "凉城县",
                shortName: "凉城",
                children: [],
              },
              {
                id: 150926,
                name: "察哈尔右翼前旗",
                shortName: "察右前旗",
                children: [],
              },
              {
                id: 150927,
                name: "察哈尔右翼中旗",
                shortName: "察右中旗",
                children: [],
              },
              {
                id: 150928,
                name: "察哈尔右翼后旗",
                shortName: "察右后旗",
                children: [],
              },
              {
                id: 150929,
                name: "四子王旗",
                shortName: "四子王旗",
                children: [],
              },
              {
                id: 150981,
                name: "丰镇市",
                shortName: "丰镇",
                children: [],
              },
            ],
          },
          {
            id: 152200,
            name: "兴安盟",
            shortName: "兴安盟",
            children: [
              {
                id: 152201,
                name: "乌兰浩特市",
                shortName: "乌兰浩特",
                children: [],
              },
              {
                id: 152202,
                name: "阿尔山市",
                shortName: "阿尔山",
                children: [],
              },
              {
                id: 152221,
                name: "科尔沁右翼前旗",
                shortName: "科右前旗",
                children: [],
              },
              {
                id: 152222,
                name: "科尔沁右翼中旗",
                shortName: "科右中旗",
                children: [],
              },
              {
                id: 152223,
                name: "扎赉特旗",
                shortName: "扎赉特旗",
                children: [],
              },
              {
                id: 152224,
                name: "突泉县",
                shortName: "突泉",
                children: [],
              },
            ],
          },
          {
            id: 152500,
            name: "锡林郭勒盟",
            shortName: "锡林郭勒盟",
            children: [
              {
                id: 152501,
                name: "二连浩特市",
                shortName: "二连浩特",
                children: [],
              },
              {
                id: 152502,
                name: "锡林浩特市",
                shortName: "锡林浩特",
                children: [],
              },
              {
                id: 152522,
                name: "阿巴嘎旗",
                shortName: "阿巴嘎旗",
                children: [],
              },
              {
                id: 152523,
                name: "苏尼特左旗",
                shortName: "东苏旗",
                children: [],
              },
              {
                id: 152524,
                name: "苏尼特右旗",
                shortName: "西苏旗",
                children: [],
              },
              {
                id: 152525,
                name: "东乌珠穆沁旗",
                shortName: "东乌旗",
                children: [],
              },
              {
                id: 152526,
                name: "西乌珠穆沁旗",
                shortName: "西乌旗",
                children: [],
              },
              {
                id: 152527,
                name: "太仆寺旗",
                shortName: "太旗",
                children: [],
              },
              {
                id: 152528,
                name: "镶黄旗",
                shortName: "镶黄旗",
                children: [],
              },
              {
                id: 152529,
                name: "正镶白旗",
                shortName: "正镶白旗",
                children: [],
              },
              {
                id: 152530,
                name: "正蓝旗",
                shortName: "正蓝旗",
                children: [],
              },
              {
                id: 152531,
                name: "多伦县",
                shortName: "多伦",
                children: [],
              },
            ],
          },
          {
            id: 152900,
            name: "阿拉善盟",
            shortName: "阿拉善盟",
            children: [
              {
                id: 152921,
                name: "阿拉善左旗",
                shortName: "阿左旗",
                children: [],
              },
              {
                id: 152922,
                name: "阿拉善右旗",
                shortName: "阿右旗",
                children: [],
              },
              {
                id: 152923,
                name: "额济纳旗",
                shortName: "额济纳",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 210000,
        name: "辽宁省",
        shortName: "辽宁",
        children: [
          {
            id: 210100,
            name: "沈阳市",
            shortName: "沈阳",
            children: [
              {
                id: 210102,
                name: "和平区",
                shortName: "和平",
                children: [],
              },
              {
                id: 210103,
                name: "沈河区",
                shortName: "沈河",
                children: [],
              },
              {
                id: 210104,
                name: "大东区",
                shortName: "大东",
                children: [],
              },
              {
                id: 210105,
                name: "皇姑区",
                shortName: "皇姑",
                children: [],
              },
              {
                id: 210106,
                name: "铁西区",
                shortName: "铁西",
                children: [],
              },
              {
                id: 210111,
                name: "苏家屯区",
                shortName: "苏家屯",
                children: [],
              },
              {
                id: 210112,
                name: "浑南区",
                shortName: "浑南区",
                children: [],
              },
              {
                id: 210113,
                name: "沈北新区",
                shortName: "沈北",
                children: [],
              },
              {
                id: 210114,
                name: "于洪区",
                shortName: "于洪",
                children: [],
              },
              {
                id: 210115,
                name: "辽中区",
                shortName: "辽中",
                children: [],
              },
              {
                id: 210123,
                name: "康平县",
                shortName: "康平",
                children: [],
              },
              {
                id: 210124,
                name: "法库县",
                shortName: "法库",
                children: [],
              },
              {
                id: 210181,
                name: "新民市",
                shortName: "新民",
                children: [],
              },
              {
                id: 210182,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 210200,
            name: "大连市",
            shortName: "大连",
            children: [
              {
                id: 210202,
                name: "中山区",
                shortName: "中山",
                children: [],
              },
              {
                id: 210203,
                name: "西岗区",
                shortName: "西岗",
                children: [],
              },
              {
                id: 210204,
                name: "沙河口区",
                shortName: "沙河口",
                children: [],
              },
              {
                id: 210211,
                name: "甘井子区",
                shortName: "甘井子",
                children: [],
              },
              {
                id: 210212,
                name: "旅顺口区",
                shortName: "旅顺口",
                children: [],
              },
              {
                id: 210213,
                name: "金州区",
                shortName: "金州",
                children: [],
              },
              {
                id: 210214,
                name: "普兰店区",
                shortName: "普兰店",
                children: [],
              },
              {
                id: 210224,
                name: "长海县",
                shortName: "长海",
                children: [],
              },
              {
                id: 210281,
                name: "瓦房店市",
                shortName: "瓦房店",
                children: [],
              },
              {
                id: 210283,
                name: "庄河市",
                shortName: "庄河",
                children: [],
              },
              {
                id: 210284,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 210285,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
              {
                id: 210286,
                name: "金普新区",
                shortName: "金普新区",
                children: [],
              },
            ],
          },
          {
            id: 210300,
            name: "鞍山市",
            shortName: "鞍山",
            children: [
              {
                id: 210302,
                name: "铁东区",
                shortName: "铁东",
                children: [],
              },
              {
                id: 210303,
                name: "铁西区",
                shortName: "铁西",
                children: [],
              },
              {
                id: 210304,
                name: "立山区",
                shortName: "立山",
                children: [],
              },
              {
                id: 210311,
                name: "千山区",
                shortName: "千山",
                children: [],
              },
              {
                id: 210321,
                name: "台安县",
                shortName: "台安",
                children: [],
              },
              {
                id: 210323,
                name: "岫岩满族自治县",
                shortName: "岫岩",
                children: [],
              },
              {
                id: 210381,
                name: "海城市",
                shortName: "海城",
                children: [],
              },
              {
                id: 210382,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 210400,
            name: "抚顺市",
            shortName: "抚顺",
            children: [
              {
                id: 210402,
                name: "新抚区",
                shortName: "新抚",
                children: [],
              },
              {
                id: 210403,
                name: "东洲区",
                shortName: "东洲",
                children: [],
              },
              {
                id: 210404,
                name: "望花区",
                shortName: "望花",
                children: [],
              },
              {
                id: 210411,
                name: "顺城区",
                shortName: "顺城",
                children: [],
              },
              {
                id: 210421,
                name: "抚顺县",
                shortName: "抚顺",
                children: [],
              },
              {
                id: 210422,
                name: "新宾满族自治县",
                shortName: "新宾",
                children: [],
              },
              {
                id: 210423,
                name: "清原满族自治县",
                shortName: "清原",
                children: [],
              },
            ],
          },
          {
            id: 210500,
            name: "本溪市",
            shortName: "本溪",
            children: [
              {
                id: 210502,
                name: "平山区",
                shortName: "平山",
                children: [],
              },
              {
                id: 210503,
                name: "溪湖区",
                shortName: "溪湖",
                children: [],
              },
              {
                id: 210504,
                name: "明山区",
                shortName: "明山",
                children: [],
              },
              {
                id: 210505,
                name: "南芬区",
                shortName: "南芬",
                children: [],
              },
              {
                id: 210521,
                name: "本溪满族自治县",
                shortName: "本溪",
                children: [],
              },
              {
                id: 210522,
                name: "桓仁满族自治县",
                shortName: "桓仁",
                children: [],
              },
            ],
          },
          {
            id: 210600,
            name: "丹东市",
            shortName: "丹东",
            children: [
              {
                id: 210602,
                name: "元宝区",
                shortName: "元宝",
                children: [],
              },
              {
                id: 210603,
                name: "振兴区",
                shortName: "振兴",
                children: [],
              },
              {
                id: 210604,
                name: "振安区",
                shortName: "振安",
                children: [],
              },
              {
                id: 210624,
                name: "宽甸满族自治县",
                shortName: "宽甸",
                children: [],
              },
              {
                id: 210681,
                name: "东港市",
                shortName: "东港",
                children: [],
              },
              {
                id: 210682,
                name: "凤城市",
                shortName: "凤城",
                children: [],
              },
            ],
          },
          {
            id: 210700,
            name: "锦州市",
            shortName: "锦州",
            children: [
              {
                id: 210702,
                name: "古塔区",
                shortName: "古塔",
                children: [],
              },
              {
                id: 210703,
                name: "凌河区",
                shortName: "凌河",
                children: [],
              },
              {
                id: 210711,
                name: "太和区",
                shortName: "太和",
                children: [],
              },
              {
                id: 210726,
                name: "黑山县",
                shortName: "黑山",
                children: [],
              },
              {
                id: 210727,
                name: "义县",
                shortName: "义县",
                children: [],
              },
              {
                id: 210781,
                name: "凌海市",
                shortName: "凌海",
                children: [],
              },
              {
                id: 210782,
                name: "北镇市",
                shortName: "北镇",
                children: [],
              },
              {
                id: 210783,
                name: "松山新区",
                shortName: "松山新区",
                children: [],
              },
              {
                id: 210784,
                name: "龙栖湾新区",
                shortName: "龙栖湾新区",
                children: [],
              },
              {
                id: 210785,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 210800,
            name: "营口市",
            shortName: "营口",
            children: [
              {
                id: 210802,
                name: "站前区",
                shortName: "站前",
                children: [],
              },
              {
                id: 210803,
                name: "西市区",
                shortName: "西市",
                children: [],
              },
              {
                id: 210804,
                name: "鲅鱼圈区",
                shortName: "鲅鱼圈",
                children: [],
              },
              {
                id: 210811,
                name: "老边区",
                shortName: "老边",
                children: [],
              },
              {
                id: 210881,
                name: "盖州市",
                shortName: "盖州",
                children: [],
              },
              {
                id: 210882,
                name: "大石桥市",
                shortName: "大石桥",
                children: [],
              },
            ],
          },
          {
            id: 210900,
            name: "阜新市",
            shortName: "阜新",
            children: [
              {
                id: 210902,
                name: "海州区",
                shortName: "海州",
                children: [],
              },
              {
                id: 210903,
                name: "新邱区",
                shortName: "新邱",
                children: [],
              },
              {
                id: 210904,
                name: "太平区",
                shortName: "太平",
                children: [],
              },
              {
                id: 210905,
                name: "清河门区",
                shortName: "清河门",
                children: [],
              },
              {
                id: 210911,
                name: "细河区",
                shortName: "细河",
                children: [],
              },
              {
                id: 210921,
                name: "阜新蒙古族自治县",
                shortName: "阜新",
                children: [],
              },
              {
                id: 210922,
                name: "彰武县",
                shortName: "彰武",
                children: [],
              },
            ],
          },
          {
            id: 211000,
            name: "辽阳市",
            shortName: "辽阳",
            children: [
              {
                id: 211002,
                name: "白塔区",
                shortName: "白塔",
                children: [],
              },
              {
                id: 211003,
                name: "文圣区",
                shortName: "文圣",
                children: [],
              },
              {
                id: 211004,
                name: "宏伟区",
                shortName: "宏伟",
                children: [],
              },
              {
                id: 211005,
                name: "弓长岭区",
                shortName: "弓长岭",
                children: [],
              },
              {
                id: 211011,
                name: "太子河区",
                shortName: "太子河",
                children: [],
              },
              {
                id: 211021,
                name: "辽阳县",
                shortName: "辽阳",
                children: [],
              },
              {
                id: 211081,
                name: "灯塔市",
                shortName: "灯塔",
                children: [],
              },
            ],
          },
          {
            id: 211100,
            name: "盘锦市",
            shortName: "盘锦",
            children: [
              {
                id: 211102,
                name: "双台子区",
                shortName: "双台子",
                children: [],
              },
              {
                id: 211103,
                name: "兴隆台区",
                shortName: "兴隆台",
                children: [],
              },
              {
                id: 211104,
                name: "大洼区",
                shortName: "大洼",
                children: [],
              },
              {
                id: 211122,
                name: "盘山县",
                shortName: "盘山",
                children: [],
              },
            ],
          },
          {
            id: 211200,
            name: "铁岭市",
            shortName: "铁岭",
            children: [
              {
                id: 211202,
                name: "银州区",
                shortName: "银州",
                children: [],
              },
              {
                id: 211204,
                name: "清河区",
                shortName: "清河",
                children: [],
              },
              {
                id: 211221,
                name: "铁岭县",
                shortName: "铁岭",
                children: [],
              },
              {
                id: 211223,
                name: "西丰县",
                shortName: "西丰",
                children: [],
              },
              {
                id: 211224,
                name: "昌图县",
                shortName: "昌图",
                children: [],
              },
              {
                id: 211281,
                name: "调兵山市",
                shortName: "调兵山",
                children: [],
              },
              {
                id: 211282,
                name: "开原市",
                shortName: "开原",
                children: [],
              },
            ],
          },
          {
            id: 211300,
            name: "朝阳市",
            shortName: "朝阳",
            children: [
              {
                id: 211302,
                name: "双塔区",
                shortName: "双塔",
                children: [],
              },
              {
                id: 211303,
                name: "龙城区",
                shortName: "龙城",
                children: [],
              },
              {
                id: 211321,
                name: "朝阳县",
                shortName: "朝阳",
                children: [],
              },
              {
                id: 211322,
                name: "建平县",
                shortName: "建平",
                children: [],
              },
              {
                id: 211324,
                name: "喀喇沁左翼蒙古族自治县",
                shortName: "喀喇沁左翼",
                children: [],
              },
              {
                id: 211381,
                name: "北票市",
                shortName: "北票",
                children: [],
              },
              {
                id: 211382,
                name: "凌源市",
                shortName: "凌源",
                children: [],
              },
            ],
          },
          {
            id: 211400,
            name: "葫芦岛市",
            shortName: "葫芦岛",
            children: [
              {
                id: 211402,
                name: "连山区",
                shortName: "连山",
                children: [],
              },
              {
                id: 211403,
                name: "龙港区",
                shortName: "龙港",
                children: [],
              },
              {
                id: 211404,
                name: "南票区",
                shortName: "南票",
                children: [],
              },
              {
                id: 211421,
                name: "绥中县",
                shortName: "绥中",
                children: [],
              },
              {
                id: 211422,
                name: "建昌县",
                shortName: "建昌",
                children: [],
              },
              {
                id: 211481,
                name: "兴城市",
                shortName: "兴城",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 220000,
        name: "吉林省",
        shortName: "吉林",
        children: [
          {
            id: 220100,
            name: "长春市",
            shortName: "长春",
            children: [
              {
                id: 220102,
                name: "南关区",
                shortName: "南关",
                children: [],
              },
              {
                id: 220103,
                name: "宽城区",
                shortName: "宽城",
                children: [],
              },
              {
                id: 220104,
                name: "朝阳区",
                shortName: "朝阳",
                children: [],
              },
              {
                id: 220105,
                name: "二道区",
                shortName: "二道",
                children: [],
              },
              {
                id: 220106,
                name: "绿园区",
                shortName: "绿园",
                children: [],
              },
              {
                id: 220112,
                name: "双阳区",
                shortName: "双阳",
                children: [],
              },
              {
                id: 220113,
                name: "九台区",
                shortName: "九台",
                children: [],
              },
              {
                id: 220122,
                name: "农安县",
                shortName: "农安",
                children: [],
              },
              {
                id: 220182,
                name: "榆树市",
                shortName: "榆树",
                children: [],
              },
              {
                id: 220183,
                name: "德惠市",
                shortName: "德惠",
                children: [],
              },
              {
                id: 220184,
                name: "长春新区",
                shortName: "长春新区",
                children: [],
              },
              {
                id: 220185,
                name: "高新技术产业开发区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 220186,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
              {
                id: 220187,
                name: "汽车产业开发区",
                shortName: "汽车产业开发区",
                children: [],
              },
              {
                id: 220188,
                name: "兴隆综合保税区",
                shortName: "兴隆综合保税区",
                children: [],
              },
            ],
          },
          {
            id: 220200,
            name: "吉林市",
            shortName: "吉林",
            children: [
              {
                id: 220202,
                name: "昌邑区",
                shortName: "昌邑",
                children: [],
              },
              {
                id: 220203,
                name: "龙潭区",
                shortName: "龙潭",
                children: [],
              },
              {
                id: 220204,
                name: "船营区",
                shortName: "船营",
                children: [],
              },
              {
                id: 220211,
                name: "丰满区",
                shortName: "丰满",
                children: [],
              },
              {
                id: 220221,
                name: "永吉县",
                shortName: "永吉",
                children: [],
              },
              {
                id: 220281,
                name: "蛟河市",
                shortName: "蛟河",
                children: [],
              },
              {
                id: 220282,
                name: "桦甸市",
                shortName: "桦甸",
                children: [],
              },
              {
                id: 220283,
                name: "舒兰市",
                shortName: "舒兰",
                children: [],
              },
              {
                id: 220284,
                name: "磐石市",
                shortName: "磐石",
                children: [],
              },
              {
                id: 220285,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 220300,
            name: "四平市",
            shortName: "四平",
            children: [
              {
                id: 220302,
                name: "铁西区",
                shortName: "铁西",
                children: [],
              },
              {
                id: 220303,
                name: "铁东区",
                shortName: "铁东",
                children: [],
              },
              {
                id: 220322,
                name: "梨树县",
                shortName: "梨树",
                children: [],
              },
              {
                id: 220323,
                name: "伊通满族自治县",
                shortName: "伊通",
                children: [],
              },
              {
                id: 220381,
                name: "公主岭市",
                shortName: "公主岭",
                children: [],
              },
              {
                id: 220382,
                name: "双辽市",
                shortName: "双辽",
                children: [],
              },
            ],
          },
          {
            id: 220400,
            name: "辽源市",
            shortName: "辽源",
            children: [
              {
                id: 220402,
                name: "龙山区",
                shortName: "龙山",
                children: [],
              },
              {
                id: 220403,
                name: "西安区",
                shortName: "西安",
                children: [],
              },
              {
                id: 220421,
                name: "东丰县",
                shortName: "东丰",
                children: [],
              },
              {
                id: 220422,
                name: "东辽县",
                shortName: "东辽",
                children: [],
              },
            ],
          },
          {
            id: 220500,
            name: "通化市",
            shortName: "通化",
            children: [
              {
                id: 220502,
                name: "东昌区",
                shortName: "东昌",
                children: [],
              },
              {
                id: 220503,
                name: "二道江区",
                shortName: "二道江",
                children: [],
              },
              {
                id: 220521,
                name: "通化县",
                shortName: "通化",
                children: [],
              },
              {
                id: 220523,
                name: "辉南县",
                shortName: "辉南",
                children: [],
              },
              {
                id: 220524,
                name: "柳河县",
                shortName: "柳河",
                children: [],
              },
              {
                id: 220581,
                name: "梅河口市",
                shortName: "梅河口",
                children: [],
              },
              {
                id: 220582,
                name: "集安市",
                shortName: "集安",
                children: [],
              },
            ],
          },
          {
            id: 220600,
            name: "白山市",
            shortName: "白山",
            children: [
              {
                id: 220602,
                name: "浑江区",
                shortName: "浑江",
                children: [],
              },
              {
                id: 220605,
                name: "江源区",
                shortName: "江源",
                children: [],
              },
              {
                id: 220621,
                name: "抚松县",
                shortName: "抚松",
                children: [],
              },
              {
                id: 220622,
                name: "靖宇县",
                shortName: "靖宇",
                children: [],
              },
              {
                id: 220623,
                name: "长白朝鲜族自治县",
                shortName: "长白",
                children: [],
              },
              {
                id: 220681,
                name: "临江市",
                shortName: "临江",
                children: [],
              },
            ],
          },
          {
            id: 220700,
            name: "松原市",
            shortName: "松原",
            children: [
              {
                id: 220702,
                name: "宁江区",
                shortName: "宁江",
                children: [],
              },
              {
                id: 220721,
                name: "前郭尔罗斯蒙古族自治县",
                shortName: "前郭尔罗斯",
                children: [],
              },
              {
                id: 220722,
                name: "长岭县",
                shortName: "长岭",
                children: [],
              },
              {
                id: 220723,
                name: "乾安县",
                shortName: "乾安",
                children: [],
              },
              {
                id: 220781,
                name: "扶余市",
                shortName: "扶余",
                children: [],
              },
            ],
          },
          {
            id: 220800,
            name: "白城市",
            shortName: "白城",
            children: [
              {
                id: 220802,
                name: "洮北区",
                shortName: "洮北",
                children: [],
              },
              {
                id: 220821,
                name: "镇赉县",
                shortName: "镇赉",
                children: [],
              },
              {
                id: 220822,
                name: "通榆县",
                shortName: "通榆",
                children: [],
              },
              {
                id: 220881,
                name: "洮南市",
                shortName: "洮南",
                children: [],
              },
              {
                id: 220882,
                name: "大安市",
                shortName: "大安",
                children: [],
              },
            ],
          },
          {
            id: 222400,
            name: "延边朝鲜族自治州",
            shortName: "延边",
            children: [
              {
                id: 222401,
                name: "延吉市",
                shortName: "延吉",
                children: [],
              },
              {
                id: 222402,
                name: "图们市",
                shortName: "图们",
                children: [],
              },
              {
                id: 222403,
                name: "敦化市",
                shortName: "敦化",
                children: [],
              },
              {
                id: 222404,
                name: "珲春市",
                shortName: "珲春",
                children: [],
              },
              {
                id: 222405,
                name: "龙井市",
                shortName: "龙井",
                children: [],
              },
              {
                id: 222406,
                name: "和龙市",
                shortName: "和龙",
                children: [],
              },
              {
                id: 222424,
                name: "汪清县",
                shortName: "汪清",
                children: [],
              },
              {
                id: 222426,
                name: "安图县",
                shortName: "安图",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 230000,
        name: "黑龙江省",
        shortName: "黑龙江",
        children: [
          {
            id: 230100,
            name: "哈尔滨市",
            shortName: "哈尔滨",
            children: [
              {
                id: 230102,
                name: "道里区",
                shortName: "道里",
                children: [],
              },
              {
                id: 230103,
                name: "南岗区",
                shortName: "南岗",
                children: [],
              },
              {
                id: 230104,
                name: "道外区",
                shortName: "道外",
                children: [],
              },
              {
                id: 230108,
                name: "平房区",
                shortName: "平房",
                children: [],
              },
              {
                id: 230109,
                name: "松北区",
                shortName: "松北",
                children: [],
              },
              {
                id: 230110,
                name: "香坊区",
                shortName: "香坊",
                children: [],
              },
              {
                id: 230111,
                name: "呼兰区",
                shortName: "呼兰",
                children: [],
              },
              {
                id: 230112,
                name: "阿城区",
                shortName: "阿城",
                children: [],
              },
              {
                id: 230113,
                name: "双城区",
                shortName: "双城",
                children: [],
              },
              {
                id: 230123,
                name: "依兰县",
                shortName: "依兰",
                children: [],
              },
              {
                id: 230124,
                name: "方正县",
                shortName: "方正",
                children: [],
              },
              {
                id: 230125,
                name: "宾县",
                shortName: "宾县",
                children: [],
              },
              {
                id: 230126,
                name: "巴彦县",
                shortName: "巴彦",
                children: [],
              },
              {
                id: 230127,
                name: "木兰县",
                shortName: "木兰",
                children: [],
              },
              {
                id: 230128,
                name: "通河县",
                shortName: "通河",
                children: [],
              },
              {
                id: 230129,
                name: "延寿县",
                shortName: "延寿",
                children: [],
              },
              {
                id: 230183,
                name: "尚志市",
                shortName: "尚志",
                children: [],
              },
              {
                id: 230184,
                name: "五常市",
                shortName: "五常",
                children: [],
              },
              {
                id: 230185,
                name: "哈尔滨新区",
                shortName: "哈尔滨新区",
                children: [],
              },
              {
                id: 230186,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 230200,
            name: "齐齐哈尔市",
            shortName: "齐齐哈尔",
            children: [
              {
                id: 230202,
                name: "龙沙区",
                shortName: "龙沙",
                children: [],
              },
              {
                id: 230203,
                name: "建华区",
                shortName: "建华",
                children: [],
              },
              {
                id: 230204,
                name: "铁锋区",
                shortName: "铁锋",
                children: [],
              },
              {
                id: 230205,
                name: "昂昂溪区",
                shortName: "昂昂溪",
                children: [],
              },
              {
                id: 230206,
                name: "富拉尔基区",
                shortName: "富拉尔基",
                children: [],
              },
              {
                id: 230207,
                name: "碾子山区",
                shortName: "碾子山",
                children: [],
              },
              {
                id: 230208,
                name: "梅里斯达斡尔族区",
                shortName: "梅里斯",
                children: [],
              },
              {
                id: 230221,
                name: "龙江县",
                shortName: "龙江",
                children: [],
              },
              {
                id: 230223,
                name: "依安县",
                shortName: "依安",
                children: [],
              },
              {
                id: 230224,
                name: "泰来县",
                shortName: "泰来",
                children: [],
              },
              {
                id: 230225,
                name: "甘南县",
                shortName: "甘南",
                children: [],
              },
              {
                id: 230227,
                name: "富裕县",
                shortName: "富裕",
                children: [],
              },
              {
                id: 230229,
                name: "克山县",
                shortName: "克山",
                children: [],
              },
              {
                id: 230230,
                name: "克东县",
                shortName: "克东",
                children: [],
              },
              {
                id: 230231,
                name: "拜泉县",
                shortName: "拜泉",
                children: [],
              },
              {
                id: 230281,
                name: "讷河市",
                shortName: "讷河",
                children: [],
              },
              {
                id: 230282,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 230300,
            name: "鸡西市",
            shortName: "鸡西",
            children: [
              {
                id: 230302,
                name: "鸡冠区",
                shortName: "鸡冠",
                children: [],
              },
              {
                id: 230303,
                name: "恒山区",
                shortName: "恒山",
                children: [],
              },
              {
                id: 230304,
                name: "滴道区",
                shortName: "滴道",
                children: [],
              },
              {
                id: 230305,
                name: "梨树区",
                shortName: "梨树",
                children: [],
              },
              {
                id: 230306,
                name: "城子河区",
                shortName: "城子河",
                children: [],
              },
              {
                id: 230307,
                name: "麻山区",
                shortName: "麻山",
                children: [],
              },
              {
                id: 230321,
                name: "鸡东县",
                shortName: "鸡东",
                children: [],
              },
              {
                id: 230381,
                name: "虎林市",
                shortName: "虎林",
                children: [],
              },
              {
                id: 230382,
                name: "密山市",
                shortName: "密山",
                children: [],
              },
            ],
          },
          {
            id: 230400,
            name: "鹤岗市",
            shortName: "鹤岗",
            children: [
              {
                id: 230402,
                name: "向阳区",
                shortName: "向阳",
                children: [],
              },
              {
                id: 230403,
                name: "工农区",
                shortName: "工农",
                children: [],
              },
              {
                id: 230404,
                name: "南山区",
                shortName: "南山",
                children: [],
              },
              {
                id: 230405,
                name: "兴安区",
                shortName: "兴安",
                children: [],
              },
              {
                id: 230406,
                name: "东山区",
                shortName: "东山",
                children: [],
              },
              {
                id: 230407,
                name: "兴山区",
                shortName: "兴山",
                children: [],
              },
              {
                id: 230421,
                name: "萝北县",
                shortName: "萝北",
                children: [],
              },
              {
                id: 230422,
                name: "绥滨县",
                shortName: "绥滨",
                children: [],
              },
            ],
          },
          {
            id: 230500,
            name: "双鸭山市",
            shortName: "双鸭山",
            children: [
              {
                id: 230502,
                name: "尖山区",
                shortName: "尖山",
                children: [],
              },
              {
                id: 230503,
                name: "岭东区",
                shortName: "岭东",
                children: [],
              },
              {
                id: 230505,
                name: "四方台区",
                shortName: "四方台",
                children: [],
              },
              {
                id: 230506,
                name: "宝山区",
                shortName: "宝山",
                children: [],
              },
              {
                id: 230521,
                name: "集贤县",
                shortName: "集贤",
                children: [],
              },
              {
                id: 230522,
                name: "友谊县",
                shortName: "友谊",
                children: [],
              },
              {
                id: 230523,
                name: "宝清县",
                shortName: "宝清",
                children: [],
              },
              {
                id: 230524,
                name: "饶河县",
                shortName: "饶河",
                children: [],
              },
            ],
          },
          {
            id: 230600,
            name: "大庆市",
            shortName: "大庆",
            children: [
              {
                id: 230602,
                name: "萨尔图区",
                shortName: "萨尔图",
                children: [],
              },
              {
                id: 230603,
                name: "龙凤区",
                shortName: "龙凤",
                children: [],
              },
              {
                id: 230604,
                name: "让胡路区",
                shortName: "让胡路",
                children: [],
              },
              {
                id: 230605,
                name: "红岗区",
                shortName: "红岗",
                children: [],
              },
              {
                id: 230606,
                name: "大同区",
                shortName: "大同",
                children: [],
              },
              {
                id: 230621,
                name: "肇州县",
                shortName: "肇州",
                children: [],
              },
              {
                id: 230622,
                name: "肇源县",
                shortName: "肇源",
                children: [],
              },
              {
                id: 230623,
                name: "林甸县",
                shortName: "林甸",
                children: [],
              },
              {
                id: 230624,
                name: "杜尔伯特蒙古族自治县",
                shortName: "杜尔伯特",
                children: [],
              },
              {
                id: 230625,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 230700,
            name: "伊春市",
            shortName: "伊春",
            children: [
              {
                id: 230702,
                name: "伊春区",
                shortName: "伊春",
                children: [],
              },
              {
                id: 230703,
                name: "南岔区",
                shortName: "南岔",
                children: [],
              },
              {
                id: 230704,
                name: "友好区",
                shortName: "友好",
                children: [],
              },
              {
                id: 230705,
                name: "西林区",
                shortName: "西林",
                children: [],
              },
              {
                id: 230706,
                name: "翠峦区",
                shortName: "翠峦",
                children: [],
              },
              {
                id: 230707,
                name: "新青区",
                shortName: "新青",
                children: [],
              },
              {
                id: 230708,
                name: "美溪区",
                shortName: "美溪",
                children: [],
              },
              {
                id: 230709,
                name: "金山屯区",
                shortName: "金山屯",
                children: [],
              },
              {
                id: 230710,
                name: "五营区",
                shortName: "五营",
                children: [],
              },
              {
                id: 230711,
                name: "乌马河区",
                shortName: "乌马河",
                children: [],
              },
              {
                id: 230712,
                name: "汤旺河区",
                shortName: "汤旺河",
                children: [],
              },
              {
                id: 230713,
                name: "带岭区",
                shortName: "带岭",
                children: [],
              },
              {
                id: 230714,
                name: "乌伊岭区",
                shortName: "乌伊岭",
                children: [],
              },
              {
                id: 230715,
                name: "红星区",
                shortName: "红星",
                children: [],
              },
              {
                id: 230716,
                name: "上甘岭区",
                shortName: "上甘岭",
                children: [],
              },
              {
                id: 230722,
                name: "嘉荫县",
                shortName: "嘉荫",
                children: [],
              },
              {
                id: 230781,
                name: "铁力市",
                shortName: "铁力",
                children: [],
              },
            ],
          },
          {
            id: 230800,
            name: "佳木斯市",
            shortName: "佳木斯",
            children: [
              {
                id: 230803,
                name: "向阳区",
                shortName: "向阳",
                children: [],
              },
              {
                id: 230804,
                name: "前进区",
                shortName: "前进",
                children: [],
              },
              {
                id: 230805,
                name: "东风区",
                shortName: "东风",
                children: [],
              },
              {
                id: 230811,
                name: "郊区",
                shortName: "郊区",
                children: [],
              },
              {
                id: 230822,
                name: "桦南县",
                shortName: "桦南",
                children: [],
              },
              {
                id: 230826,
                name: "桦川县",
                shortName: "桦川",
                children: [],
              },
              {
                id: 230828,
                name: "汤原县",
                shortName: "汤原",
                children: [],
              },
              {
                id: 230881,
                name: "同江市",
                shortName: "同江",
                children: [],
              },
              {
                id: 230882,
                name: "富锦市",
                shortName: "富锦",
                children: [],
              },
              {
                id: 230883,
                name: "抚远市",
                shortName: "抚远",
                children: [],
              },
            ],
          },
          {
            id: 230900,
            name: "七台河市",
            shortName: "七台河",
            children: [
              {
                id: 230902,
                name: "新兴区",
                shortName: "新兴",
                children: [],
              },
              {
                id: 230903,
                name: "桃山区",
                shortName: "桃山",
                children: [],
              },
              {
                id: 230904,
                name: "茄子河区",
                shortName: "茄子河",
                children: [],
              },
              {
                id: 230921,
                name: "勃利县",
                shortName: "勃利",
                children: [],
              },
            ],
          },
          {
            id: 231000,
            name: "牡丹江市",
            shortName: "牡丹江",
            children: [
              {
                id: 231002,
                name: "东安区",
                shortName: "东安",
                children: [],
              },
              {
                id: 231003,
                name: "阳明区",
                shortName: "阳明",
                children: [],
              },
              {
                id: 231004,
                name: "爱民区",
                shortName: "爱民",
                children: [],
              },
              {
                id: 231005,
                name: "西安区",
                shortName: "西安",
                children: [],
              },
              {
                id: 231025,
                name: "林口县",
                shortName: "林口",
                children: [],
              },
              {
                id: 231081,
                name: "绥芬河市",
                shortName: "绥芬河",
                children: [],
              },
              {
                id: 231083,
                name: "海林市",
                shortName: "海林",
                children: [],
              },
              {
                id: 231084,
                name: "宁安市",
                shortName: "宁安",
                children: [],
              },
              {
                id: 231085,
                name: "穆棱市",
                shortName: "穆棱",
                children: [],
              },
              {
                id: 231086,
                name: "东宁市",
                shortName: "东宁",
                children: [],
              },
            ],
          },
          {
            id: 231100,
            name: "黑河市",
            shortName: "黑河",
            children: [
              {
                id: 231102,
                name: "爱辉区",
                shortName: "爱辉",
                children: [],
              },
              {
                id: 231121,
                name: "嫩江县",
                shortName: "嫩江",
                children: [],
              },
              {
                id: 231123,
                name: "逊克县",
                shortName: "逊克",
                children: [],
              },
              {
                id: 231124,
                name: "孙吴县",
                shortName: "孙吴",
                children: [],
              },
              {
                id: 231181,
                name: "北安市",
                shortName: "北安",
                children: [],
              },
              {
                id: 231182,
                name: "五大连池市",
                shortName: "五大连池",
                children: [],
              },
            ],
          },
          {
            id: 231200,
            name: "绥化市",
            shortName: "绥化",
            children: [
              {
                id: 231202,
                name: "北林区",
                shortName: "北林",
                children: [],
              },
              {
                id: 231221,
                name: "望奎县",
                shortName: "望奎",
                children: [],
              },
              {
                id: 231222,
                name: "兰西县",
                shortName: "兰西",
                children: [],
              },
              {
                id: 231223,
                name: "青冈县",
                shortName: "青冈",
                children: [],
              },
              {
                id: 231224,
                name: "庆安县",
                shortName: "庆安",
                children: [],
              },
              {
                id: 231225,
                name: "明水县",
                shortName: "明水",
                children: [],
              },
              {
                id: 231226,
                name: "绥棱县",
                shortName: "绥棱",
                children: [],
              },
              {
                id: 231281,
                name: "安达市",
                shortName: "安达",
                children: [],
              },
              {
                id: 231282,
                name: "肇东市",
                shortName: "肇东",
                children: [],
              },
              {
                id: 231283,
                name: "海伦市",
                shortName: "海伦",
                children: [],
              },
            ],
          },
          {
            id: 232700,
            name: "大兴安岭地区",
            shortName: "大兴安岭",
            children: [
              {
                id: 232701,
                name: "加格达奇区",
                shortName: "加格达奇",
                children: [],
              },
              {
                id: 232702,
                name: "新林区",
                shortName: "新林",
                children: [],
              },
              {
                id: 232703,
                name: "松岭区",
                shortName: "松岭",
                children: [],
              },
              {
                id: 232704,
                name: "呼中区",
                shortName: "呼中",
                children: [],
              },
              {
                id: 232721,
                name: "呼玛县",
                shortName: "呼玛",
                children: [],
              },
              {
                id: 232722,
                name: "塔河县",
                shortName: "塔河",
                children: [],
              },
              {
                id: 232723,
                name: "漠河县",
                shortName: "漠河",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 310000,
        name: "上海",
        shortName: "上海",
        children: [
          {
            id: 310100,
            name: "上海市",
            shortName: "上海",
            children: [
              {
                id: 310101,
                name: "黄浦区",
                shortName: "黄浦",
                children: [],
              },
              {
                id: 310104,
                name: "徐汇区",
                shortName: "徐汇",
                children: [],
              },
              {
                id: 310105,
                name: "长宁区",
                shortName: "长宁",
                children: [],
              },
              {
                id: 310106,
                name: "静安区",
                shortName: "静安",
                children: [],
              },
              {
                id: 310107,
                name: "普陀区",
                shortName: "普陀",
                children: [],
              },
              {
                id: 310109,
                name: "虹口区",
                shortName: "虹口",
                children: [],
              },
              {
                id: 310110,
                name: "杨浦区",
                shortName: "杨浦",
                children: [],
              },
              {
                id: 310112,
                name: "闵行区",
                shortName: "闵行",
                children: [],
              },
              {
                id: 310113,
                name: "宝山区",
                shortName: "宝山",
                children: [],
              },
              {
                id: 310114,
                name: "嘉定区",
                shortName: "嘉定",
                children: [],
              },
              {
                id: 310115,
                name: "浦东新区",
                shortName: "浦东",
                children: [],
              },
              {
                id: 310116,
                name: "金山区",
                shortName: "金山",
                children: [],
              },
              {
                id: 310117,
                name: "松江区",
                shortName: "松江",
                children: [],
              },
              {
                id: 310118,
                name: "青浦区",
                shortName: "青浦",
                children: [],
              },
              {
                id: 310120,
                name: "奉贤区",
                shortName: "奉贤",
                children: [],
              },
              {
                id: 310151,
                name: "崇明区",
                shortName: "崇明",
                children: [],
              },
              {
                id: 310231,
                name: "张江高新区",
                shortName: "张江高新区",
                children: [],
              },
              {
                id: 310232,
                name: "紫竹高新区",
                shortName: "紫竹高新区",
                children: [],
              },
              {
                id: 310233,
                name: "漕河泾开发区",
                shortName: "漕河泾开发区",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 320000,
        name: "江苏省",
        shortName: "江苏",
        children: [
          {
            id: 320100,
            name: "南京市",
            shortName: "南京",
            children: [
              {
                id: 320102,
                name: "玄武区",
                shortName: "玄武",
                children: [],
              },
              {
                id: 320104,
                name: "秦淮区",
                shortName: "秦淮",
                children: [],
              },
              {
                id: 320105,
                name: "建邺区",
                shortName: "建邺",
                children: [],
              },
              {
                id: 320106,
                name: "鼓楼区",
                shortName: "鼓楼",
                children: [],
              },
              {
                id: 320111,
                name: "浦口区",
                shortName: "浦口",
                children: [],
              },
              {
                id: 320113,
                name: "栖霞区",
                shortName: "栖霞",
                children: [],
              },
              {
                id: 320114,
                name: "雨花台区",
                shortName: "雨花台",
                children: [],
              },
              {
                id: 320115,
                name: "江宁区",
                shortName: "江宁",
                children: [],
              },
              {
                id: 320116,
                name: "六合区",
                shortName: "六合",
                children: [],
              },
              {
                id: 320117,
                name: "溧水区",
                shortName: "溧水",
                children: [],
              },
              {
                id: 320118,
                name: "高淳区",
                shortName: "高淳",
                children: [],
              },
              {
                id: 320119,
                name: "江北新区",
                shortName: "江北新区",
                children: [],
              },
              {
                id: 320120,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 320200,
            name: "无锡市",
            shortName: "无锡",
            children: [
              {
                id: 320205,
                name: "锡山区",
                shortName: "锡山",
                children: [],
              },
              {
                id: 320206,
                name: "惠山区",
                shortName: "惠山",
                children: [],
              },
              {
                id: 320211,
                name: "滨湖区",
                shortName: "滨湖",
                children: [],
              },
              {
                id: 320213,
                name: "梁溪区",
                shortName: "梁溪",
                children: [],
              },
              {
                id: 320214,
                name: "新吴区",
                shortName: "新吴",
                children: [],
              },
              {
                id: 320281,
                name: "江阴市",
                shortName: "江阴",
                children: [],
              },
              {
                id: 320282,
                name: "宜兴市",
                shortName: "宜兴",
                children: [],
              },
            ],
          },
          {
            id: 320300,
            name: "徐州市",
            shortName: "徐州",
            children: [
              {
                id: 320302,
                name: "鼓楼区",
                shortName: "鼓楼",
                children: [],
              },
              {
                id: 320303,
                name: "云龙区",
                shortName: "云龙",
                children: [],
              },
              {
                id: 320305,
                name: "贾汪区",
                shortName: "贾汪",
                children: [],
              },
              {
                id: 320311,
                name: "泉山区",
                shortName: "泉山",
                children: [],
              },
              {
                id: 320312,
                name: "铜山区",
                shortName: "铜山",
                children: [],
              },
              {
                id: 320321,
                name: "丰县",
                shortName: "丰县",
                children: [],
              },
              {
                id: 320322,
                name: "沛县",
                shortName: "沛县",
                children: [],
              },
              {
                id: 320324,
                name: "睢宁县",
                shortName: "睢宁",
                children: [],
              },
              {
                id: 320381,
                name: "新沂市",
                shortName: "新沂",
                children: [],
              },
              {
                id: 320382,
                name: "邳州市",
                shortName: "邳州",
                children: [],
              },
              {
                id: 320383,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
              {
                id: 320384,
                name: "高新技术产业开发区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 320385,
                name: "软件园",
                shortName: "软件园",
                children: [],
              },
            ],
          },
          {
            id: 320400,
            name: "常州市",
            shortName: "常州",
            children: [
              {
                id: 320402,
                name: "天宁区",
                shortName: "天宁",
                children: [],
              },
              {
                id: 320404,
                name: "钟楼区",
                shortName: "钟楼",
                children: [],
              },
              {
                id: 320411,
                name: "新北区",
                shortName: "新北",
                children: [],
              },
              {
                id: 320412,
                name: "武进区",
                shortName: "武进",
                children: [],
              },
              {
                id: 320413,
                name: "金坛区",
                shortName: "金坛",
                children: [],
              },
              {
                id: 320481,
                name: "溧阳市",
                shortName: "溧阳",
                children: [],
              },
              {
                id: 320482,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 320500,
            name: "苏州市",
            shortName: "苏州",
            children: [
              {
                id: 320505,
                name: "虎丘区",
                shortName: "虎丘",
                children: [],
              },
              {
                id: 320506,
                name: "吴中区",
                shortName: "吴中",
                children: [],
              },
              {
                id: 320507,
                name: "相城区",
                shortName: "相城",
                children: [],
              },
              {
                id: 320508,
                name: "姑苏区",
                shortName: "姑苏",
                children: [],
              },
              {
                id: 320509,
                name: "吴江区",
                shortName: "吴江",
                children: [],
              },
              {
                id: 320581,
                name: "常熟市",
                shortName: "常熟",
                children: [],
              },
              {
                id: 320582,
                name: "张家港市",
                shortName: "张家港",
                children: [],
              },
              {
                id: 320583,
                name: "昆山市",
                shortName: "昆山",
                children: [],
              },
              {
                id: 320585,
                name: "太仓市",
                shortName: "太仓",
                children: [],
              },
              {
                id: 320586,
                name: "苏州新区",
                shortName: "苏州新区",
                children: [],
              },
              {
                id: 320587,
                name: "工业园区",
                shortName: "工业园区",
                children: [],
              },
              {
                id: 320588,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 320600,
            name: "南通市",
            shortName: "南通",
            children: [
              {
                id: 320602,
                name: "崇川区",
                shortName: "崇川",
                children: [],
              },
              {
                id: 320611,
                name: "港闸区",
                shortName: "港闸",
                children: [],
              },
              {
                id: 320612,
                name: "通州区",
                shortName: "通州",
                children: [],
              },
              {
                id: 320621,
                name: "海安县",
                shortName: "海安",
                children: [],
              },
              {
                id: 320623,
                name: "如东县",
                shortName: "如东",
                children: [],
              },
              {
                id: 320681,
                name: "启东市",
                shortName: "启东",
                children: [],
              },
              {
                id: 320682,
                name: "如皋市",
                shortName: "如皋",
                children: [],
              },
              {
                id: 320684,
                name: "海门市",
                shortName: "海门",
                children: [],
              },
              {
                id: 320685,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 320700,
            name: "连云港市",
            shortName: "连云港",
            children: [
              {
                id: 320703,
                name: "连云区",
                shortName: "连云",
                children: [],
              },
              {
                id: 320706,
                name: "海州区",
                shortName: "海州",
                children: [],
              },
              {
                id: 320707,
                name: "赣榆区",
                shortName: "赣榆",
                children: [],
              },
              {
                id: 320722,
                name: "东海县",
                shortName: "东海",
                children: [],
              },
              {
                id: 320723,
                name: "灌云县",
                shortName: "灌云",
                children: [],
              },
              {
                id: 320724,
                name: "灌南县",
                shortName: "灌南",
                children: [],
              },
              {
                id: 320725,
                name: "新海新区",
                shortName: "新海新区",
                children: [],
              },
              {
                id: 320726,
                name: "连云新城",
                shortName: "连云新城",
                children: [],
              },
              {
                id: 320727,
                name: "徐圩新区",
                shortName: "徐圩新区",
                children: [],
              },
              {
                id: 320728,
                name: "济技术开发区",
                shortName: "济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 320800,
            name: "淮安市",
            shortName: "淮安",
            children: [
              {
                id: 320803,
                name: "淮安区",
                shortName: "淮安",
                children: [],
              },
              {
                id: 320804,
                name: "淮阴区",
                shortName: "淮阴",
                children: [],
              },
              {
                id: 320812,
                name: "清江浦区",
                shortName: "清江浦",
                children: [],
              },
              {
                id: 320813,
                name: "洪泽区",
                shortName: "洪泽",
                children: [],
              },
              {
                id: 320826,
                name: "涟水县",
                shortName: "涟水",
                children: [],
              },
              {
                id: 320830,
                name: "盱眙县",
                shortName: "盱眙",
                children: [],
              },
              {
                id: 320831,
                name: "金湖县",
                shortName: "金湖",
                children: [],
              },
              {
                id: 320832,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 320900,
            name: "盐城市",
            shortName: "盐城",
            children: [
              {
                id: 320902,
                name: "亭湖区",
                shortName: "亭湖",
                children: [],
              },
              {
                id: 320903,
                name: "盐都区",
                shortName: "盐都",
                children: [],
              },
              {
                id: 320904,
                name: "大丰区",
                shortName: "大丰",
                children: [],
              },
              {
                id: 320921,
                name: "响水县",
                shortName: "响水",
                children: [],
              },
              {
                id: 320922,
                name: "滨海县",
                shortName: "滨海",
                children: [],
              },
              {
                id: 320923,
                name: "阜宁县",
                shortName: "阜宁",
                children: [],
              },
              {
                id: 320924,
                name: "射阳县",
                shortName: "射阳",
                children: [],
              },
              {
                id: 320925,
                name: "建湖县",
                shortName: "建湖",
                children: [],
              },
              {
                id: 320981,
                name: "东台市",
                shortName: "东台",
                children: [],
              },
            ],
          },
          {
            id: 321000,
            name: "扬州市",
            shortName: "扬州",
            children: [
              {
                id: 321002,
                name: "广陵区",
                shortName: "广陵",
                children: [],
              },
              {
                id: 321003,
                name: "邗江区",
                shortName: "邗江",
                children: [],
              },
              {
                id: 321012,
                name: "江都区",
                shortName: "江都",
                children: [],
              },
              {
                id: 321023,
                name: "宝应县",
                shortName: "宝应",
                children: [],
              },
              {
                id: 321081,
                name: "仪征市",
                shortName: "仪征",
                children: [],
              },
              {
                id: 321084,
                name: "高邮市",
                shortName: "高邮",
                children: [],
              },
            ],
          },
          {
            id: 321100,
            name: "镇江市",
            shortName: "镇江",
            children: [
              {
                id: 321102,
                name: "京口区",
                shortName: "京口",
                children: [],
              },
              {
                id: 321111,
                name: "润州区",
                shortName: "润州",
                children: [],
              },
              {
                id: 321112,
                name: "丹徒区",
                shortName: "丹徒",
                children: [],
              },
              {
                id: 321181,
                name: "丹阳市",
                shortName: "丹阳",
                children: [],
              },
              {
                id: 321182,
                name: "扬中市",
                shortName: "扬中",
                children: [],
              },
              {
                id: 321183,
                name: "句容市",
                shortName: "句容",
                children: [],
              },
              {
                id: 321184,
                name: "镇江新区",
                shortName: "镇江新区",
                children: [],
              },
              {
                id: 321185,
                name: "镇江新区",
                shortName: "丹徒新区",
                children: [],
              },
              {
                id: 321186,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 321200,
            name: "泰州市",
            shortName: "泰州",
            children: [
              {
                id: 321202,
                name: "海陵区",
                shortName: "海陵",
                children: [],
              },
              {
                id: 321203,
                name: "高港区",
                shortName: "高港",
                children: [],
              },
              {
                id: 321204,
                name: "姜堰区",
                shortName: "姜堰",
                children: [],
              },
              {
                id: 321281,
                name: "兴化市",
                shortName: "兴化",
                children: [],
              },
              {
                id: 321282,
                name: "靖江市",
                shortName: "靖江",
                children: [],
              },
              {
                id: 321283,
                name: "泰兴市",
                shortName: "泰兴",
                children: [],
              },
            ],
          },
          {
            id: 321300,
            name: "宿迁市",
            shortName: "宿迁",
            children: [
              {
                id: 321302,
                name: "宿城区",
                shortName: "宿城",
                children: [],
              },
              {
                id: 321311,
                name: "宿豫区",
                shortName: "宿豫",
                children: [],
              },
              {
                id: 321322,
                name: "沭阳县",
                shortName: "沭阳",
                children: [],
              },
              {
                id: 321323,
                name: "泗阳县",
                shortName: "泗阳",
                children: [],
              },
              {
                id: 321324,
                name: "泗洪县",
                shortName: "泗洪",
                children: [],
              },
              {
                id: 321325,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 330000,
        name: "浙江省",
        shortName: "浙江",
        children: [
          {
            id: 330100,
            name: "杭州市",
            shortName: "杭州",
            children: [
              {
                id: 330102,
                name: "上城区",
                shortName: "上城",
                children: [],
              },
              {
                id: 330103,
                name: "下城区",
                shortName: "下城",
                children: [],
              },
              {
                id: 330104,
                name: "江干区",
                shortName: "江干",
                children: [],
              },
              {
                id: 330105,
                name: "拱墅区",
                shortName: "拱墅",
                children: [],
              },
              {
                id: 330106,
                name: "西湖区",
                shortName: "西湖",
                children: [],
              },
              {
                id: 330108,
                name: "滨江区",
                shortName: "滨江",
                children: [],
              },
              {
                id: 330109,
                name: "萧山区",
                shortName: "萧山",
                children: [],
              },
              {
                id: 330110,
                name: "余杭区",
                shortName: "余杭",
                children: [],
              },
              {
                id: 330111,
                name: "富阳区",
                shortName: "富阳",
                children: [],
              },
              {
                id: 330122,
                name: "桐庐县",
                shortName: "桐庐",
                children: [],
              },
              {
                id: 330127,
                name: "淳安县",
                shortName: "淳安",
                children: [],
              },
              {
                id: 330182,
                name: "建德市",
                shortName: "建德",
                children: [],
              },
              {
                id: 330185,
                name: "临安市",
                shortName: "临安",
                children: [],
              },
              {
                id: 330186,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 330200,
            name: "宁波市",
            shortName: "宁波",
            children: [
              {
                id: 330203,
                name: "海曙区",
                shortName: "海曙",
                children: [],
              },
              {
                id: 330205,
                name: "江北区",
                shortName: "江北",
                children: [],
              },
              {
                id: 330206,
                name: "北仑区",
                shortName: "北仑",
                children: [],
              },
              {
                id: 330211,
                name: "镇海区",
                shortName: "镇海",
                children: [],
              },
              {
                id: 330212,
                name: "鄞州区",
                shortName: "鄞州",
                children: [],
              },
              {
                id: 330213,
                name: "奉化区",
                shortName: "奉化",
                children: [],
              },
              {
                id: 330225,
                name: "象山县",
                shortName: "象山",
                children: [],
              },
              {
                id: 330226,
                name: "宁海县",
                shortName: "宁海",
                children: [],
              },
              {
                id: 330281,
                name: "余姚市",
                shortName: "余姚",
                children: [],
              },
              {
                id: 330282,
                name: "慈溪市",
                shortName: "慈溪",
                children: [],
              },
              {
                id: 330284,
                name: "杭州湾新区",
                shortName: "杭州湾新区",
                children: [],
              },
              {
                id: 330285,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 330300,
            name: "温州市",
            shortName: "温州",
            children: [
              {
                id: 330302,
                name: "鹿城区",
                shortName: "鹿城",
                children: [],
              },
              {
                id: 330303,
                name: "龙湾区",
                shortName: "龙湾",
                children: [],
              },
              {
                id: 330304,
                name: "瓯海区",
                shortName: "瓯海",
                children: [],
              },
              {
                id: 330305,
                name: "洞头区",
                shortName: "洞头",
                children: [],
              },
              {
                id: 330324,
                name: "永嘉县",
                shortName: "永嘉",
                children: [],
              },
              {
                id: 330326,
                name: "平阳县",
                shortName: "平阳",
                children: [],
              },
              {
                id: 330327,
                name: "苍南县",
                shortName: "苍南",
                children: [],
              },
              {
                id: 330328,
                name: "文成县",
                shortName: "文成",
                children: [],
              },
              {
                id: 330329,
                name: "泰顺县",
                shortName: "泰顺",
                children: [],
              },
              {
                id: 330381,
                name: "瑞安市",
                shortName: "瑞安",
                children: [],
              },
              {
                id: 330382,
                name: "乐清市",
                shortName: "乐清",
                children: [],
              },
            ],
          },
          {
            id: 330400,
            name: "嘉兴市",
            shortName: "嘉兴",
            children: [
              {
                id: 330402,
                name: "南湖区",
                shortName: "南湖",
                children: [],
              },
              {
                id: 330411,
                name: "秀洲区",
                shortName: "秀洲",
                children: [],
              },
              {
                id: 330421,
                name: "嘉善县",
                shortName: "嘉善",
                children: [],
              },
              {
                id: 330424,
                name: "海盐县",
                shortName: "海盐",
                children: [],
              },
              {
                id: 330481,
                name: "海宁市",
                shortName: "海宁",
                children: [],
              },
              {
                id: 330482,
                name: "平湖市",
                shortName: "平湖",
                children: [],
              },
              {
                id: 330483,
                name: "桐乡市",
                shortName: "桐乡",
                children: [],
              },
            ],
          },
          {
            id: 330500,
            name: "湖州市",
            shortName: "湖州",
            children: [
              {
                id: 330502,
                name: "吴兴区",
                shortName: "吴兴",
                children: [],
              },
              {
                id: 330503,
                name: "南浔区",
                shortName: "南浔",
                children: [],
              },
              {
                id: 330521,
                name: "德清县",
                shortName: "德清",
                children: [],
              },
              {
                id: 330522,
                name: "长兴县",
                shortName: "长兴",
                children: [],
              },
              {
                id: 330523,
                name: "安吉县",
                shortName: "安吉",
                children: [],
              },
            ],
          },
          {
            id: 330600,
            name: "绍兴市",
            shortName: "绍兴",
            children: [
              {
                id: 330602,
                name: "越城区",
                shortName: "越城",
                children: [],
              },
              {
                id: 330603,
                name: "柯桥区",
                shortName: "柯桥",
                children: [],
              },
              {
                id: 330604,
                name: "上虞区",
                shortName: "上虞",
                children: [],
              },
              {
                id: 330624,
                name: "新昌县",
                shortName: "新昌",
                children: [],
              },
              {
                id: 330681,
                name: "诸暨市",
                shortName: "诸暨",
                children: [],
              },
              {
                id: 330683,
                name: "嵊州市",
                shortName: "嵊州",
                children: [],
              },
            ],
          },
          {
            id: 330700,
            name: "金华市",
            shortName: "金华",
            children: [
              {
                id: 330702,
                name: "婺城区",
                shortName: "婺城",
                children: [],
              },
              {
                id: 330703,
                name: "金东区",
                shortName: "金东",
                children: [],
              },
              {
                id: 330723,
                name: "武义县",
                shortName: "武义",
                children: [],
              },
              {
                id: 330726,
                name: "浦江县",
                shortName: "浦江",
                children: [],
              },
              {
                id: 330727,
                name: "磐安县",
                shortName: "磐安",
                children: [],
              },
              {
                id: 330781,
                name: "兰溪市",
                shortName: "兰溪",
                children: [],
              },
              {
                id: 330782,
                name: "义乌市",
                shortName: "义乌",
                children: [],
              },
              {
                id: 330783,
                name: "东阳市",
                shortName: "东阳",
                children: [],
              },
              {
                id: 330784,
                name: "永康市",
                shortName: "永康",
                children: [],
              },
            ],
          },
          {
            id: 330800,
            name: "衢州市",
            shortName: "衢州",
            children: [
              {
                id: 330802,
                name: "柯城区",
                shortName: "柯城",
                children: [],
              },
              {
                id: 330803,
                name: "衢江区",
                shortName: "衢江",
                children: [],
              },
              {
                id: 330822,
                name: "常山县",
                shortName: "常山",
                children: [],
              },
              {
                id: 330824,
                name: "开化县",
                shortName: "开化",
                children: [],
              },
              {
                id: 330825,
                name: "龙游县",
                shortName: "龙游",
                children: [],
              },
              {
                id: 330881,
                name: "江山市",
                shortName: "江山",
                children: [],
              },
            ],
          },
          {
            id: 330900,
            name: "舟山市",
            shortName: "舟山",
            children: [
              {
                id: 330902,
                name: "定海区",
                shortName: "定海",
                children: [],
              },
              {
                id: 330903,
                name: "普陀区",
                shortName: "普陀",
                children: [],
              },
              {
                id: 330921,
                name: "岱山县",
                shortName: "岱山",
                children: [],
              },
              {
                id: 330922,
                name: "嵊泗县",
                shortName: "嵊泗",
                children: [],
              },
            ],
          },
          {
            id: 331000,
            name: "台州市",
            shortName: "台州",
            children: [
              {
                id: 331002,
                name: "椒江区",
                shortName: "椒江",
                children: [],
              },
              {
                id: 331003,
                name: "黄岩区",
                shortName: "黄岩",
                children: [],
              },
              {
                id: 331004,
                name: "路桥区",
                shortName: "路桥",
                children: [],
              },
              {
                id: 331021,
                name: "玉环市",
                shortName: "玉环",
                children: [],
              },
              {
                id: 331022,
                name: "三门县",
                shortName: "三门",
                children: [],
              },
              {
                id: 331023,
                name: "天台县",
                shortName: "天台",
                children: [],
              },
              {
                id: 331024,
                name: "仙居县",
                shortName: "仙居",
                children: [],
              },
              {
                id: 331081,
                name: "温岭市",
                shortName: "温岭",
                children: [],
              },
              {
                id: 331082,
                name: "临海市",
                shortName: "临海",
                children: [],
              },
            ],
          },
          {
            id: 331100,
            name: "丽水市",
            shortName: "丽水",
            children: [
              {
                id: 331102,
                name: "莲都区",
                shortName: "莲都",
                children: [],
              },
              {
                id: 331121,
                name: "青田县",
                shortName: "青田",
                children: [],
              },
              {
                id: 331122,
                name: "缙云县",
                shortName: "缙云",
                children: [],
              },
              {
                id: 331123,
                name: "遂昌县",
                shortName: "遂昌",
                children: [],
              },
              {
                id: 331124,
                name: "松阳县",
                shortName: "松阳",
                children: [],
              },
              {
                id: 331125,
                name: "云和县",
                shortName: "云和",
                children: [],
              },
              {
                id: 331126,
                name: "庆元县",
                shortName: "庆元",
                children: [],
              },
              {
                id: 331127,
                name: "景宁畲族自治县",
                shortName: "景宁",
                children: [],
              },
              {
                id: 331181,
                name: "龙泉市",
                shortName: "龙泉",
                children: [],
              },
            ],
          },
          {
            id: 331200,
            name: "舟山群岛新区",
            shortName: "舟山新区",
            children: [
              {
                id: 331201,
                name: "金塘岛",
                shortName: "金塘",
                children: [],
              },
              {
                id: 331202,
                name: "六横岛",
                shortName: "六横",
                children: [],
              },
              {
                id: 331203,
                name: "衢山岛",
                shortName: "衢山",
                children: [],
              },
              {
                id: 331204,
                name: "舟山本岛西北部",
                shortName: "舟山",
                children: [],
              },
              {
                id: 331205,
                name: "岱山岛西南部",
                shortName: "岱山",
                children: [],
              },
              {
                id: 331206,
                name: "泗礁岛",
                shortName: "泗礁",
                children: [],
              },
              {
                id: 331207,
                name: "朱家尖岛",
                shortName: "朱家尖",
                children: [],
              },
              {
                id: 331208,
                name: "洋山岛",
                shortName: "洋山",
                children: [],
              },
              {
                id: 331209,
                name: "长涂岛",
                shortName: "长涂",
                children: [],
              },
              {
                id: 331210,
                name: "虾峙岛",
                shortName: "虾峙",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 340000,
        name: "安徽省",
        shortName: "安徽",
        children: [
          {
            id: 340100,
            name: "合肥市",
            shortName: "合肥",
            children: [
              {
                id: 340102,
                name: "瑶海区",
                shortName: "瑶海",
                children: [],
              },
              {
                id: 340103,
                name: "庐阳区",
                shortName: "庐阳",
                children: [],
              },
              {
                id: 340104,
                name: "蜀山区",
                shortName: "蜀山",
                children: [],
              },
              {
                id: 340111,
                name: "包河区",
                shortName: "包河",
                children: [],
              },
              {
                id: 340121,
                name: "长丰县",
                shortName: "长丰",
                children: [],
              },
              {
                id: 340122,
                name: "肥东县",
                shortName: "肥东",
                children: [],
              },
              {
                id: 340123,
                name: "肥西县",
                shortName: "肥西",
                children: [],
              },
              {
                id: 340124,
                name: "庐江县",
                shortName: "庐江",
                children: [],
              },
              {
                id: 340181,
                name: "巢湖市",
                shortName: "巢湖",
                children: [],
              },
              {
                id: 340184,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
              {
                id: 340185,
                name: "高新技术开发区",
                shortName: "高新技术开发区",
                children: [],
              },
              {
                id: 340186,
                name: "北城新区",
                shortName: "北城新区",
                children: [],
              },
              {
                id: 340187,
                name: "滨湖新区",
                shortName: "滨湖新区",
                children: [],
              },
              {
                id: 340188,
                name: "政务文化新区",
                shortName: "政务文化新区",
                children: [],
              },
              {
                id: 340189,
                name: "新站综合开发试验区",
                shortName: "新站综合开发试验区",
                children: [],
              },
            ],
          },
          {
            id: 340200,
            name: "芜湖市",
            shortName: "芜湖",
            children: [
              {
                id: 340202,
                name: "镜湖区",
                shortName: "镜湖",
                children: [],
              },
              {
                id: 340203,
                name: "弋江区",
                shortName: "弋江",
                children: [],
              },
              {
                id: 340207,
                name: "鸠江区",
                shortName: "鸠江",
                children: [],
              },
              {
                id: 340208,
                name: "三山区",
                shortName: "三山",
                children: [],
              },
              {
                id: 340221,
                name: "芜湖县",
                shortName: "芜湖",
                children: [],
              },
              {
                id: 340222,
                name: "繁昌县",
                shortName: "繁昌",
                children: [],
              },
              {
                id: 340223,
                name: "南陵县",
                shortName: "南陵",
                children: [],
              },
              {
                id: 340225,
                name: "无为县",
                shortName: "无为",
                children: [],
              },
              {
                id: 340226,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 340300,
            name: "蚌埠市",
            shortName: "蚌埠",
            children: [
              {
                id: 340302,
                name: "龙子湖区",
                shortName: "龙子湖",
                children: [],
              },
              {
                id: 340303,
                name: "蚌山区",
                shortName: "蚌山",
                children: [],
              },
              {
                id: 340304,
                name: "禹会区",
                shortName: "禹会",
                children: [],
              },
              {
                id: 340311,
                name: "淮上区",
                shortName: "淮上",
                children: [],
              },
              {
                id: 340321,
                name: "怀远县",
                shortName: "怀远",
                children: [],
              },
              {
                id: 340322,
                name: "五河县",
                shortName: "五河",
                children: [],
              },
              {
                id: 340323,
                name: "固镇县",
                shortName: "固镇",
                children: [],
              },
              {
                id: 340324,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 340400,
            name: "淮南市",
            shortName: "淮南",
            children: [
              {
                id: 340402,
                name: "大通区",
                shortName: "大通",
                children: [],
              },
              {
                id: 340403,
                name: "田家庵区",
                shortName: "田家庵",
                children: [],
              },
              {
                id: 340404,
                name: "谢家集区",
                shortName: "谢家集",
                children: [],
              },
              {
                id: 340405,
                name: "八公山区",
                shortName: "八公山",
                children: [],
              },
              {
                id: 340406,
                name: "潘集区",
                shortName: "潘集",
                children: [],
              },
              {
                id: 340421,
                name: "凤台县",
                shortName: "凤台",
                children: [],
              },
              {
                id: 340422,
                name: "寿县",
                shortName: "寿县",
                children: [],
              },
              {
                id: 340423,
                name: "山南新区",
                shortName: "山南新区",
                children: [],
              },
              {
                id: 340424,
                name: "毛集实验区",
                shortName: "毛集实验区",
                children: [],
              },
              {
                id: 340425,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 340500,
            name: "马鞍山市",
            shortName: "马鞍山",
            children: [
              {
                id: 340503,
                name: "花山区",
                shortName: "花山",
                children: [],
              },
              {
                id: 340504,
                name: "雨山区",
                shortName: "雨山",
                children: [],
              },
              {
                id: 340506,
                name: "博望区",
                shortName: "博望",
                children: [],
              },
              {
                id: 340521,
                name: "当涂县",
                shortName: "当涂",
                children: [],
              },
              {
                id: 340522,
                name: "含山县",
                shortName: "含山",
                children: [],
              },
              {
                id: 340523,
                name: "和县",
                shortName: "和县",
                children: [],
              },
            ],
          },
          {
            id: 340600,
            name: "淮北市",
            shortName: "淮北",
            children: [
              {
                id: 340602,
                name: "杜集区",
                shortName: "杜集",
                children: [],
              },
              {
                id: 340603,
                name: "相山区",
                shortName: "相山",
                children: [],
              },
              {
                id: 340604,
                name: "烈山区",
                shortName: "烈山",
                children: [],
              },
              {
                id: 340621,
                name: "濉溪县",
                shortName: "濉溪",
                children: [],
              },
            ],
          },
          {
            id: 340700,
            name: "铜陵市",
            shortName: "铜陵",
            children: [
              {
                id: 340705,
                name: "铜官区",
                shortName: "铜官",
                children: [],
              },
              {
                id: 340706,
                name: "义安区",
                shortName: "义安",
                children: [],
              },
              {
                id: 340711,
                name: "郊区",
                shortName: "郊区",
                children: [],
              },
              {
                id: 340722,
                name: "枞阳县",
                shortName: "枞阳",
                children: [],
              },
            ],
          },
          {
            id: 340800,
            name: "安庆市",
            shortName: "安庆",
            children: [
              {
                id: 340802,
                name: "迎江区",
                shortName: "迎江",
                children: [],
              },
              {
                id: 340803,
                name: "大观区",
                shortName: "大观",
                children: [],
              },
              {
                id: 340811,
                name: "宜秀区",
                shortName: "宜秀",
                children: [],
              },
              {
                id: 340822,
                name: "怀宁县",
                shortName: "怀宁",
                children: [],
              },
              {
                id: 340824,
                name: "潜山县",
                shortName: "潜山",
                children: [],
              },
              {
                id: 340825,
                name: "太湖县",
                shortName: "太湖",
                children: [],
              },
              {
                id: 340826,
                name: "宿松县",
                shortName: "宿松",
                children: [],
              },
              {
                id: 340827,
                name: "望江县",
                shortName: "望江",
                children: [],
              },
              {
                id: 340828,
                name: "岳西县",
                shortName: "岳西",
                children: [],
              },
              {
                id: 340881,
                name: "桐城市",
                shortName: "桐城",
                children: [],
              },
            ],
          },
          {
            id: 341000,
            name: "黄山市",
            shortName: "黄山",
            children: [
              {
                id: 341002,
                name: "屯溪区",
                shortName: "屯溪",
                children: [],
              },
              {
                id: 341003,
                name: "黄山区",
                shortName: "黄山",
                children: [],
              },
              {
                id: 341004,
                name: "徽州区",
                shortName: "徽州",
                children: [],
              },
              {
                id: 341021,
                name: "歙县",
                shortName: "歙县",
                children: [],
              },
              {
                id: 341022,
                name: "休宁县",
                shortName: "休宁",
                children: [],
              },
              {
                id: 341023,
                name: "黟县",
                shortName: "黟县",
                children: [],
              },
              {
                id: 341024,
                name: "祁门县",
                shortName: "祁门",
                children: [],
              },
            ],
          },
          {
            id: 341100,
            name: "滁州市",
            shortName: "滁州",
            children: [
              {
                id: 341102,
                name: "琅琊区",
                shortName: "琅琊",
                children: [],
              },
              {
                id: 341103,
                name: "南谯区",
                shortName: "南谯",
                children: [],
              },
              {
                id: 341122,
                name: "来安县",
                shortName: "来安",
                children: [],
              },
              {
                id: 341124,
                name: "全椒县",
                shortName: "全椒",
                children: [],
              },
              {
                id: 341125,
                name: "定远县",
                shortName: "定远",
                children: [],
              },
              {
                id: 341126,
                name: "凤阳县",
                shortName: "凤阳",
                children: [],
              },
              {
                id: 341181,
                name: "天长市",
                shortName: "天长",
                children: [],
              },
              {
                id: 341182,
                name: "明光市",
                shortName: "明光",
                children: [],
              },
            ],
          },
          {
            id: 341200,
            name: "阜阳市",
            shortName: "阜阳",
            children: [
              {
                id: 341202,
                name: "颍州区",
                shortName: "颍州",
                children: [],
              },
              {
                id: 341203,
                name: "颍东区",
                shortName: "颍东",
                children: [],
              },
              {
                id: 341204,
                name: "颍泉区",
                shortName: "颍泉",
                children: [],
              },
              {
                id: 341221,
                name: "临泉县",
                shortName: "临泉",
                children: [],
              },
              {
                id: 341222,
                name: "太和县",
                shortName: "太和",
                children: [],
              },
              {
                id: 341225,
                name: "阜南县",
                shortName: "阜南",
                children: [],
              },
              {
                id: 341226,
                name: "颍上县",
                shortName: "颍上",
                children: [],
              },
              {
                id: 341282,
                name: "界首市",
                shortName: "界首",
                children: [],
              },
              {
                id: 341283,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 341300,
            name: "宿州市",
            shortName: "宿州",
            children: [
              {
                id: 341302,
                name: "埇桥区",
                shortName: "埇桥",
                children: [],
              },
              {
                id: 341321,
                name: "砀山县",
                shortName: "砀山",
                children: [],
              },
              {
                id: 341322,
                name: "萧县",
                shortName: "萧县",
                children: [],
              },
              {
                id: 341323,
                name: "灵璧县",
                shortName: "灵璧",
                children: [],
              },
              {
                id: 341324,
                name: "泗县",
                shortName: "泗县",
                children: [],
              },
              {
                id: 341325,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 341500,
            name: "六安市",
            shortName: "六安",
            children: [
              {
                id: 341502,
                name: "金安区",
                shortName: "金安",
                children: [],
              },
              {
                id: 341503,
                name: "裕安区",
                shortName: "裕安",
                children: [],
              },
              {
                id: 341504,
                name: "叶集区",
                shortName: "叶集",
                children: [],
              },
              {
                id: 341522,
                name: "霍邱县",
                shortName: "霍邱",
                children: [],
              },
              {
                id: 341523,
                name: "舒城县",
                shortName: "舒城",
                children: [],
              },
              {
                id: 341524,
                name: "金寨县",
                shortName: "金寨",
                children: [],
              },
              {
                id: 341525,
                name: "霍山县",
                shortName: "霍山",
                children: [],
              },
            ],
          },
          {
            id: 341600,
            name: "亳州市",
            shortName: "亳州",
            children: [
              {
                id: 341602,
                name: "谯城区",
                shortName: "谯城",
                children: [],
              },
              {
                id: 341621,
                name: "涡阳县",
                shortName: "涡阳",
                children: [],
              },
              {
                id: 341622,
                name: "蒙城县",
                shortName: "蒙城",
                children: [],
              },
              {
                id: 341623,
                name: "利辛县",
                shortName: "利辛",
                children: [],
              },
            ],
          },
          {
            id: 341700,
            name: "池州市",
            shortName: "池州",
            children: [
              {
                id: 341702,
                name: "贵池区",
                shortName: "贵池",
                children: [],
              },
              {
                id: 341721,
                name: "东至县",
                shortName: "东至",
                children: [],
              },
              {
                id: 341722,
                name: "石台县",
                shortName: "石台",
                children: [],
              },
              {
                id: 341723,
                name: "青阳县",
                shortName: "青阳",
                children: [],
              },
            ],
          },
          {
            id: 341800,
            name: "宣城市",
            shortName: "宣城",
            children: [
              {
                id: 341802,
                name: "宣州区",
                shortName: "宣州",
                children: [],
              },
              {
                id: 341821,
                name: "郎溪县",
                shortName: "郎溪",
                children: [],
              },
              {
                id: 341822,
                name: "广德县",
                shortName: "广德",
                children: [],
              },
              {
                id: 341823,
                name: "泾县",
                shortName: "泾县",
                children: [],
              },
              {
                id: 341824,
                name: "绩溪县",
                shortName: "绩溪",
                children: [],
              },
              {
                id: 341825,
                name: "旌德县",
                shortName: "旌德",
                children: [],
              },
              {
                id: 341881,
                name: "宁国市",
                shortName: "宁国",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 350000,
        name: "福建省",
        shortName: "福建",
        children: [
          {
            id: 350100,
            name: "福州市",
            shortName: "福州",
            children: [
              {
                id: 350102,
                name: "鼓楼区",
                shortName: "鼓楼",
                children: [],
              },
              {
                id: 350103,
                name: "台江区",
                shortName: "台江",
                children: [],
              },
              {
                id: 350104,
                name: "仓山区",
                shortName: "仓山",
                children: [],
              },
              {
                id: 350105,
                name: "马尾区",
                shortName: "马尾",
                children: [],
              },
              {
                id: 350111,
                name: "晋安区",
                shortName: "晋安",
                children: [],
              },
              {
                id: 350121,
                name: "闽侯县",
                shortName: "闽侯",
                children: [],
              },
              {
                id: 350122,
                name: "连江县",
                shortName: "连江",
                children: [],
              },
              {
                id: 350123,
                name: "罗源县",
                shortName: "罗源",
                children: [],
              },
              {
                id: 350124,
                name: "闽清县",
                shortName: "闽清",
                children: [],
              },
              {
                id: 350125,
                name: "永泰县",
                shortName: "永泰",
                children: [],
              },
              {
                id: 350128,
                name: "平潭县",
                shortName: "平潭",
                children: [],
              },
              {
                id: 350181,
                name: "福清市",
                shortName: "福清",
                children: [],
              },
              {
                id: 350182,
                name: "长乐市",
                shortName: "长乐",
                children: [],
              },
              {
                id: 350183,
                name: "福州新区",
                shortName: "福州新区",
                children: [],
              },
            ],
          },
          {
            id: 350200,
            name: "厦门市",
            shortName: "厦门",
            children: [
              {
                id: 350203,
                name: "思明区",
                shortName: "思明",
                children: [],
              },
              {
                id: 350205,
                name: "海沧区",
                shortName: "海沧",
                children: [],
              },
              {
                id: 350206,
                name: "湖里区",
                shortName: "湖里",
                children: [],
              },
              {
                id: 350211,
                name: "集美区",
                shortName: "集美",
                children: [],
              },
              {
                id: 350212,
                name: "同安区",
                shortName: "同安",
                children: [],
              },
              {
                id: 350213,
                name: "翔安区",
                shortName: "翔安",
                children: [],
              },
            ],
          },
          {
            id: 350300,
            name: "莆田市",
            shortName: "莆田",
            children: [
              {
                id: 350302,
                name: "城厢区",
                shortName: "城厢",
                children: [],
              },
              {
                id: 350303,
                name: "涵江区",
                shortName: "涵江",
                children: [],
              },
              {
                id: 350304,
                name: "荔城区",
                shortName: "荔城",
                children: [],
              },
              {
                id: 350305,
                name: "秀屿区",
                shortName: "秀屿",
                children: [],
              },
              {
                id: 350322,
                name: "仙游县",
                shortName: "仙游",
                children: [],
              },
            ],
          },
          {
            id: 350400,
            name: "三明市",
            shortName: "三明",
            children: [
              {
                id: 350402,
                name: "梅列区",
                shortName: "梅列",
                children: [],
              },
              {
                id: 350403,
                name: "三元区",
                shortName: "三元",
                children: [],
              },
              {
                id: 350421,
                name: "明溪县",
                shortName: "明溪",
                children: [],
              },
              {
                id: 350423,
                name: "清流县",
                shortName: "清流",
                children: [],
              },
              {
                id: 350424,
                name: "宁化县",
                shortName: "宁化",
                children: [],
              },
              {
                id: 350425,
                name: "大田县",
                shortName: "大田",
                children: [],
              },
              {
                id: 350426,
                name: "尤溪县",
                shortName: "尤溪",
                children: [],
              },
              {
                id: 350427,
                name: "沙县",
                shortName: "沙县",
                children: [],
              },
              {
                id: 350428,
                name: "将乐县",
                shortName: "将乐",
                children: [],
              },
              {
                id: 350429,
                name: "泰宁县",
                shortName: "泰宁",
                children: [],
              },
              {
                id: 350430,
                name: "建宁县",
                shortName: "建宁",
                children: [],
              },
              {
                id: 350481,
                name: "永安市",
                shortName: "永安",
                children: [],
              },
            ],
          },
          {
            id: 350500,
            name: "泉州市",
            shortName: "泉州",
            children: [
              {
                id: 350502,
                name: "鲤城区",
                shortName: "鲤城",
                children: [],
              },
              {
                id: 350503,
                name: "丰泽区",
                shortName: "丰泽",
                children: [],
              },
              {
                id: 350504,
                name: "洛江区",
                shortName: "洛江",
                children: [],
              },
              {
                id: 350505,
                name: "泉港区",
                shortName: "泉港",
                children: [],
              },
              {
                id: 350521,
                name: "惠安县",
                shortName: "惠安",
                children: [],
              },
              {
                id: 350524,
                name: "安溪县",
                shortName: "安溪",
                children: [],
              },
              {
                id: 350525,
                name: "永春县",
                shortName: "永春",
                children: [],
              },
              {
                id: 350526,
                name: "德化县",
                shortName: "德化",
                children: [],
              },
              {
                id: 350527,
                name: "金门县",
                shortName: "金门",
                children: [],
              },
              {
                id: 350581,
                name: "石狮市",
                shortName: "石狮",
                children: [],
              },
              {
                id: 350582,
                name: "晋江市",
                shortName: "晋江",
                children: [],
              },
              {
                id: 350583,
                name: "南安市",
                shortName: "南安",
                children: [],
              },
              {
                id: 350584,
                name: "台商投资区",
                shortName: "台商投资区",
                children: [],
              },
              {
                id: 350585,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
              {
                id: 350586,
                name: "高新技术开发区",
                shortName: "高新技术开发区",
                children: [],
              },
              {
                id: 350587,
                name: "综合保税区",
                shortName: "综合保税区",
                children: [],
              },
            ],
          },
          {
            id: 350600,
            name: "漳州市",
            shortName: "漳州",
            children: [
              {
                id: 350602,
                name: "芗城区",
                shortName: "芗城",
                children: [],
              },
              {
                id: 350603,
                name: "龙文区",
                shortName: "龙文",
                children: [],
              },
              {
                id: 350622,
                name: "云霄县",
                shortName: "云霄",
                children: [],
              },
              {
                id: 350623,
                name: "漳浦县",
                shortName: "漳浦",
                children: [],
              },
              {
                id: 350624,
                name: "诏安县",
                shortName: "诏安",
                children: [],
              },
              {
                id: 350625,
                name: "长泰县",
                shortName: "长泰",
                children: [],
              },
              {
                id: 350626,
                name: "东山县",
                shortName: "东山",
                children: [],
              },
              {
                id: 350627,
                name: "南靖县",
                shortName: "南靖",
                children: [],
              },
              {
                id: 350628,
                name: "平和县",
                shortName: "平和",
                children: [],
              },
              {
                id: 350629,
                name: "华安县",
                shortName: "华安",
                children: [],
              },
              {
                id: 350681,
                name: "龙海市",
                shortName: "龙海",
                children: [],
              },
            ],
          },
          {
            id: 350700,
            name: "南平市",
            shortName: "南平",
            children: [
              {
                id: 350702,
                name: "延平区",
                shortName: "延平",
                children: [],
              },
              {
                id: 350703,
                name: "建阳区",
                shortName: "建阳",
                children: [],
              },
              {
                id: 350721,
                name: "顺昌县",
                shortName: "顺昌",
                children: [],
              },
              {
                id: 350722,
                name: "浦城县",
                shortName: "浦城",
                children: [],
              },
              {
                id: 350723,
                name: "光泽县",
                shortName: "光泽",
                children: [],
              },
              {
                id: 350724,
                name: "松溪县",
                shortName: "松溪",
                children: [],
              },
              {
                id: 350725,
                name: "政和县",
                shortName: "政和",
                children: [],
              },
              {
                id: 350781,
                name: "邵武市",
                shortName: "邵武",
                children: [],
              },
              {
                id: 350782,
                name: "武夷山市",
                shortName: "武夷山",
                children: [],
              },
              {
                id: 350783,
                name: "建瓯市",
                shortName: "建瓯",
                children: [],
              },
            ],
          },
          {
            id: 350800,
            name: "龙岩市",
            shortName: "龙岩",
            children: [
              {
                id: 350802,
                name: "新罗区",
                shortName: "新罗",
                children: [],
              },
              {
                id: 350803,
                name: "永定区",
                shortName: "永定",
                children: [],
              },
              {
                id: 350821,
                name: "长汀县",
                shortName: "长汀",
                children: [],
              },
              {
                id: 350823,
                name: "上杭县",
                shortName: "上杭",
                children: [],
              },
              {
                id: 350824,
                name: "武平县",
                shortName: "武平",
                children: [],
              },
              {
                id: 350825,
                name: "连城县",
                shortName: "连城",
                children: [],
              },
              {
                id: 350881,
                name: "漳平市",
                shortName: "漳平",
                children: [],
              },
            ],
          },
          {
            id: 350900,
            name: "宁德市",
            shortName: "宁德",
            children: [
              {
                id: 350902,
                name: "蕉城区",
                shortName: "蕉城",
                children: [],
              },
              {
                id: 350921,
                name: "霞浦县",
                shortName: "霞浦",
                children: [],
              },
              {
                id: 350922,
                name: "古田县",
                shortName: "古田",
                children: [],
              },
              {
                id: 350923,
                name: "屏南县",
                shortName: "屏南",
                children: [],
              },
              {
                id: 350924,
                name: "寿宁县",
                shortName: "寿宁",
                children: [],
              },
              {
                id: 350925,
                name: "周宁县",
                shortName: "周宁",
                children: [],
              },
              {
                id: 350926,
                name: "柘荣县",
                shortName: "柘荣",
                children: [],
              },
              {
                id: 350981,
                name: "福安市",
                shortName: "福安",
                children: [],
              },
              {
                id: 350982,
                name: "福鼎市",
                shortName: "福鼎",
                children: [],
              },
              {
                id: 350983,
                name: "东侨开发区",
                shortName: "东侨开发区",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 360000,
        name: "江西省",
        shortName: "江西",
        children: [
          {
            id: 360100,
            name: "南昌市",
            shortName: "南昌",
            children: [
              {
                id: 360102,
                name: "东湖区",
                shortName: "东湖",
                children: [],
              },
              {
                id: 360103,
                name: "西湖区",
                shortName: "西湖",
                children: [],
              },
              {
                id: 360104,
                name: "青云谱区",
                shortName: "青云谱",
                children: [],
              },
              {
                id: 360105,
                name: "湾里区",
                shortName: "湾里",
                children: [],
              },
              {
                id: 360111,
                name: "青山湖区",
                shortName: "青山湖",
                children: [],
              },
              {
                id: 360112,
                name: "新建区",
                shortName: "新建",
                children: [],
              },
              {
                id: 360121,
                name: "南昌县",
                shortName: "南昌",
                children: [],
              },
              {
                id: 360123,
                name: "安义县",
                shortName: "安义",
                children: [],
              },
              {
                id: 360124,
                name: "进贤县",
                shortName: "进贤",
                children: [],
              },
              {
                id: 360125,
                name: "红谷滩新区",
                shortName: "红谷滩新区",
                children: [],
              },
              {
                id: 360126,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 360127,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
              {
                id: 360128,
                name: "小蓝开发区",
                shortName: "小蓝开发区",
                children: [],
              },
              {
                id: 360129,
                name: "桑海开发区",
                shortName: "桑海开发区",
                children: [],
              },
              {
                id: 360130,
                name: "望城新区",
                shortName: "望城新区",
                children: [],
              },
              {
                id: 360131,
                name: "赣江新区",
                shortName: "赣江新区",
                children: [],
              },
            ],
          },
          {
            id: 360200,
            name: "景德镇市",
            shortName: "景德镇",
            children: [
              {
                id: 360202,
                name: "昌江区",
                shortName: "昌江",
                children: [],
              },
              {
                id: 360203,
                name: "珠山区",
                shortName: "珠山",
                children: [],
              },
              {
                id: 360222,
                name: "浮梁县",
                shortName: "浮梁",
                children: [],
              },
              {
                id: 360281,
                name: "乐平市",
                shortName: "乐平",
                children: [],
              },
            ],
          },
          {
            id: 360300,
            name: "萍乡市",
            shortName: "萍乡",
            children: [
              {
                id: 360302,
                name: "安源区",
                shortName: "安源",
                children: [],
              },
              {
                id: 360313,
                name: "湘东区",
                shortName: "湘东",
                children: [],
              },
              {
                id: 360321,
                name: "莲花县",
                shortName: "莲花",
                children: [],
              },
              {
                id: 360322,
                name: "上栗县",
                shortName: "上栗",
                children: [],
              },
              {
                id: 360323,
                name: "芦溪县",
                shortName: "芦溪",
                children: [],
              },
            ],
          },
          {
            id: 360400,
            name: "九江市",
            shortName: "九江",
            children: [
              {
                id: 360402,
                name: "濂溪区",
                shortName: "濂溪",
                children: [],
              },
              {
                id: 360403,
                name: "浔阳区",
                shortName: "浔阳",
                children: [],
              },
              {
                id: 360421,
                name: "九江县",
                shortName: "九江",
                children: [],
              },
              {
                id: 360423,
                name: "武宁县",
                shortName: "武宁",
                children: [],
              },
              {
                id: 360424,
                name: "修水县",
                shortName: "修水",
                children: [],
              },
              {
                id: 360425,
                name: "永修县",
                shortName: "永修",
                children: [],
              },
              {
                id: 360426,
                name: "德安县",
                shortName: "德安",
                children: [],
              },
              {
                id: 360428,
                name: "都昌县",
                shortName: "都昌",
                children: [],
              },
              {
                id: 360429,
                name: "湖口县",
                shortName: "湖口",
                children: [],
              },
              {
                id: 360430,
                name: "彭泽县",
                shortName: "彭泽",
                children: [],
              },
              {
                id: 360481,
                name: "瑞昌市",
                shortName: "瑞昌",
                children: [],
              },
              {
                id: 360482,
                name: "共青城市",
                shortName: "共青城",
                children: [],
              },
              {
                id: 360483,
                name: "庐山市",
                shortName: "庐山",
                children: [],
              },
              {
                id: 360484,
                name: "经济技术开发区",
                shortName: "经济开发区",
                children: [],
              },
              {
                id: 360485,
                name: "八里湖新区",
                shortName: "八里湖新区",
                children: [],
              },
              {
                id: 360486,
                name: "庐山风景名胜区",
                shortName: "庐山风景名胜区",
                children: [],
              },
            ],
          },
          {
            id: 360500,
            name: "新余市",
            shortName: "新余",
            children: [
              {
                id: 360502,
                name: "渝水区",
                shortName: "渝水",
                children: [],
              },
              {
                id: 360521,
                name: "分宜县",
                shortName: "分宜",
                children: [],
              },
            ],
          },
          {
            id: 360600,
            name: "鹰潭市",
            shortName: "鹰潭",
            children: [
              {
                id: 360602,
                name: "月湖区",
                shortName: "月湖",
                children: [],
              },
              {
                id: 360622,
                name: "余江县",
                shortName: "余江",
                children: [],
              },
              {
                id: 360681,
                name: "贵溪市",
                shortName: "贵溪",
                children: [],
              },
              {
                id: 360682,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 360700,
            name: "赣州市",
            shortName: "赣州",
            children: [
              {
                id: 360702,
                name: "章贡区",
                shortName: "章贡",
                children: [],
              },
              {
                id: 360703,
                name: "南康区",
                shortName: "南康",
                children: [],
              },
              {
                id: 360704,
                name: "赣县区",
                shortName: "赣县",
                children: [],
              },
              {
                id: 360722,
                name: "信丰县",
                shortName: "信丰",
                children: [],
              },
              {
                id: 360723,
                name: "大余县",
                shortName: "大余",
                children: [],
              },
              {
                id: 360724,
                name: "上犹县",
                shortName: "上犹",
                children: [],
              },
              {
                id: 360725,
                name: "崇义县",
                shortName: "崇义",
                children: [],
              },
              {
                id: 360726,
                name: "安远县",
                shortName: "安远",
                children: [],
              },
              {
                id: 360727,
                name: "龙南县",
                shortName: "龙南",
                children: [],
              },
              {
                id: 360728,
                name: "定南县",
                shortName: "定南",
                children: [],
              },
              {
                id: 360729,
                name: "全南县",
                shortName: "全南",
                children: [],
              },
              {
                id: 360730,
                name: "宁都县",
                shortName: "宁都",
                children: [],
              },
              {
                id: 360731,
                name: "于都县",
                shortName: "于都",
                children: [],
              },
              {
                id: 360732,
                name: "兴国县",
                shortName: "兴国",
                children: [],
              },
              {
                id: 360733,
                name: "会昌县",
                shortName: "会昌",
                children: [],
              },
              {
                id: 360734,
                name: "寻乌县",
                shortName: "寻乌",
                children: [],
              },
              {
                id: 360735,
                name: "石城县",
                shortName: "石城",
                children: [],
              },
              {
                id: 360781,
                name: "瑞金市",
                shortName: "瑞金",
                children: [],
              },
              {
                id: 360782,
                name: "章康新区",
                shortName: "章康新区",
                children: [],
              },
            ],
          },
          {
            id: 360800,
            name: "吉安市",
            shortName: "吉安",
            children: [
              {
                id: 360802,
                name: "吉州区",
                shortName: "吉州",
                children: [],
              },
              {
                id: 360803,
                name: "青原区",
                shortName: "青原",
                children: [],
              },
              {
                id: 360821,
                name: "吉安县",
                shortName: "吉安",
                children: [],
              },
              {
                id: 360822,
                name: "吉水县",
                shortName: "吉水",
                children: [],
              },
              {
                id: 360823,
                name: "峡江县",
                shortName: "峡江",
                children: [],
              },
              {
                id: 360824,
                name: "新干县",
                shortName: "新干",
                children: [],
              },
              {
                id: 360825,
                name: "永丰县",
                shortName: "永丰",
                children: [],
              },
              {
                id: 360826,
                name: "泰和县",
                shortName: "泰和",
                children: [],
              },
              {
                id: 360827,
                name: "遂川县",
                shortName: "遂川",
                children: [],
              },
              {
                id: 360828,
                name: "万安县",
                shortName: "万安",
                children: [],
              },
              {
                id: 360829,
                name: "安福县",
                shortName: "安福",
                children: [],
              },
              {
                id: 360830,
                name: "永新县",
                shortName: "永新",
                children: [],
              },
              {
                id: 360881,
                name: "井冈山市",
                shortName: "井冈山",
                children: [],
              },
            ],
          },
          {
            id: 360900,
            name: "宜春市",
            shortName: "宜春",
            children: [
              {
                id: 360902,
                name: "袁州区",
                shortName: "袁州",
                children: [],
              },
              {
                id: 360921,
                name: "奉新县",
                shortName: "奉新",
                children: [],
              },
              {
                id: 360922,
                name: "万载县",
                shortName: "万载",
                children: [],
              },
              {
                id: 360923,
                name: "上高县",
                shortName: "上高",
                children: [],
              },
              {
                id: 360924,
                name: "宜丰县",
                shortName: "宜丰",
                children: [],
              },
              {
                id: 360925,
                name: "靖安县",
                shortName: "靖安",
                children: [],
              },
              {
                id: 360926,
                name: "铜鼓县",
                shortName: "铜鼓",
                children: [],
              },
              {
                id: 360981,
                name: "丰城市",
                shortName: "丰城",
                children: [],
              },
              {
                id: 360982,
                name: "樟树市",
                shortName: "樟树",
                children: [],
              },
              {
                id: 360983,
                name: "高安市",
                shortName: "高安",
                children: [],
              },
            ],
          },
          {
            id: 361000,
            name: "抚州市",
            shortName: "抚州",
            children: [
              {
                id: 361002,
                name: "临川区",
                shortName: "临川",
                children: [],
              },
              {
                id: 361003,
                name: "东乡区",
                shortName: "东乡",
                children: [],
              },
              {
                id: 361021,
                name: "南城县",
                shortName: "南城",
                children: [],
              },
              {
                id: 361022,
                name: "黎川县",
                shortName: "黎川",
                children: [],
              },
              {
                id: 361023,
                name: "南丰县",
                shortName: "南丰",
                children: [],
              },
              {
                id: 361024,
                name: "崇仁县",
                shortName: "崇仁",
                children: [],
              },
              {
                id: 361025,
                name: "乐安县",
                shortName: "乐安",
                children: [],
              },
              {
                id: 361026,
                name: "宜黄县",
                shortName: "宜黄",
                children: [],
              },
              {
                id: 361027,
                name: "金溪县",
                shortName: "金溪",
                children: [],
              },
              {
                id: 361028,
                name: "资溪县",
                shortName: "资溪",
                children: [],
              },
              {
                id: 361030,
                name: "广昌县",
                shortName: "广昌",
                children: [],
              },
            ],
          },
          {
            id: 361100,
            name: "上饶市",
            shortName: "上饶",
            children: [
              {
                id: 361102,
                name: "信州区",
                shortName: "信州",
                children: [],
              },
              {
                id: 361103,
                name: "广丰区",
                shortName: "广丰",
                children: [],
              },
              {
                id: 361121,
                name: "上饶县",
                shortName: "上饶",
                children: [],
              },
              {
                id: 361123,
                name: "玉山县",
                shortName: "玉山",
                children: [],
              },
              {
                id: 361124,
                name: "铅山县",
                shortName: "铅山",
                children: [],
              },
              {
                id: 361125,
                name: "横峰县",
                shortName: "横峰",
                children: [],
              },
              {
                id: 361126,
                name: "弋阳县",
                shortName: "弋阳",
                children: [],
              },
              {
                id: 361127,
                name: "余干县",
                shortName: "余干",
                children: [],
              },
              {
                id: 361128,
                name: "鄱阳县",
                shortName: "鄱阳",
                children: [],
              },
              {
                id: 361129,
                name: "万年县",
                shortName: "万年",
                children: [],
              },
              {
                id: 361130,
                name: "婺源县",
                shortName: "婺源",
                children: [],
              },
              {
                id: 361181,
                name: "德兴市",
                shortName: "德兴",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 370000,
        name: "山东省",
        shortName: "山东",
        children: [
          {
            id: 370100,
            name: "济南市",
            shortName: "济南",
            children: [
              {
                id: 370102,
                name: "历下区",
                shortName: "历下",
                children: [],
              },
              {
                id: 370103,
                name: "市中区",
                shortName: "市中",
                children: [],
              },
              {
                id: 370104,
                name: "槐荫区",
                shortName: "槐荫",
                children: [],
              },
              {
                id: 370105,
                name: "天桥区",
                shortName: "天桥",
                children: [],
              },
              {
                id: 370112,
                name: "历城区",
                shortName: "历城",
                children: [],
              },
              {
                id: 370113,
                name: "长清区",
                shortName: "长清",
                children: [],
              },
              {
                id: 370114,
                name: "章丘区",
                shortName: "章丘",
                children: [],
              },
              {
                id: 370124,
                name: "平阴县",
                shortName: "平阴",
                children: [],
              },
              {
                id: 370125,
                name: "济阳县",
                shortName: "济阳",
                children: [],
              },
              {
                id: 370126,
                name: "商河县",
                shortName: "商河",
                children: [],
              },
              {
                id: 370182,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 370200,
            name: "青岛市",
            shortName: "青岛",
            children: [
              {
                id: 370202,
                name: "市南区",
                shortName: "市南",
                children: [],
              },
              {
                id: 370203,
                name: "市北区",
                shortName: "市北",
                children: [],
              },
              {
                id: 370211,
                name: "黄岛区",
                shortName: "黄岛",
                children: [],
              },
              {
                id: 370212,
                name: "崂山区",
                shortName: "崂山",
                children: [],
              },
              {
                id: 370213,
                name: "李沧区",
                shortName: "李沧",
                children: [],
              },
              {
                id: 370214,
                name: "城阳区",
                shortName: "城阳",
                children: [],
              },
              {
                id: 370281,
                name: "胶州市",
                shortName: "胶州",
                children: [],
              },
              {
                id: 370282,
                name: "即墨市",
                shortName: "即墨",
                children: [],
              },
              {
                id: 370283,
                name: "平度市",
                shortName: "平度",
                children: [],
              },
              {
                id: 370285,
                name: "莱西市",
                shortName: "莱西",
                children: [],
              },
              {
                id: 370286,
                name: "西海岸新区",
                shortName: "西海岸新区",
                children: [],
              },
              {
                id: 370287,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 370300,
            name: "淄博市",
            shortName: "淄博",
            children: [
              {
                id: 370302,
                name: "淄川区",
                shortName: "淄川",
                children: [],
              },
              {
                id: 370303,
                name: "张店区",
                shortName: "张店",
                children: [],
              },
              {
                id: 370304,
                name: "博山区",
                shortName: "博山",
                children: [],
              },
              {
                id: 370305,
                name: "临淄区",
                shortName: "临淄",
                children: [],
              },
              {
                id: 370306,
                name: "周村区",
                shortName: "周村",
                children: [],
              },
              {
                id: 370321,
                name: "桓台县",
                shortName: "桓台",
                children: [],
              },
              {
                id: 370322,
                name: "高青县",
                shortName: "高青",
                children: [],
              },
              {
                id: 370323,
                name: "沂源县",
                shortName: "沂源",
                children: [],
              },
              {
                id: 370324,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 370400,
            name: "枣庄市",
            shortName: "枣庄",
            children: [
              {
                id: 370402,
                name: "市中区",
                shortName: "市中",
                children: [],
              },
              {
                id: 370403,
                name: "薛城区",
                shortName: "薛城",
                children: [],
              },
              {
                id: 370404,
                name: "峄城区",
                shortName: "峄城",
                children: [],
              },
              {
                id: 370405,
                name: "台儿庄区",
                shortName: "台儿庄",
                children: [],
              },
              {
                id: 370406,
                name: "山亭区",
                shortName: "山亭",
                children: [],
              },
              {
                id: 370481,
                name: "滕州市",
                shortName: "滕州",
                children: [],
              },
              {
                id: 370482,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 370500,
            name: "东营市",
            shortName: "东营",
            children: [
              {
                id: 370502,
                name: "东营区",
                shortName: "东营",
                children: [],
              },
              {
                id: 370503,
                name: "河口区",
                shortName: "河口",
                children: [],
              },
              {
                id: 370505,
                name: "垦利区",
                shortName: "垦利",
                children: [],
              },
              {
                id: 370522,
                name: "利津县",
                shortName: "利津",
                children: [],
              },
              {
                id: 370523,
                name: "广饶县",
                shortName: "广饶",
                children: [],
              },
            ],
          },
          {
            id: 370600,
            name: "烟台市",
            shortName: "烟台",
            children: [
              {
                id: 370602,
                name: "芝罘区",
                shortName: "芝罘",
                children: [],
              },
              {
                id: 370611,
                name: "福山区",
                shortName: "福山",
                children: [],
              },
              {
                id: 370612,
                name: "牟平区",
                shortName: "牟平",
                children: [],
              },
              {
                id: 370613,
                name: "莱山区",
                shortName: "莱山",
                children: [],
              },
              {
                id: 370634,
                name: "长岛县",
                shortName: "长岛",
                children: [],
              },
              {
                id: 370681,
                name: "龙口市",
                shortName: "龙口",
                children: [],
              },
              {
                id: 370682,
                name: "莱阳市",
                shortName: "莱阳",
                children: [],
              },
              {
                id: 370683,
                name: "莱州市",
                shortName: "莱州",
                children: [],
              },
              {
                id: 370684,
                name: "蓬莱市",
                shortName: "蓬莱",
                children: [],
              },
              {
                id: 370685,
                name: "招远市",
                shortName: "招远",
                children: [],
              },
              {
                id: 370686,
                name: "栖霞市",
                shortName: "栖霞",
                children: [],
              },
              {
                id: 370687,
                name: "海阳市",
                shortName: "海阳",
                children: [],
              },
              {
                id: 370688,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 370689,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 370700,
            name: "潍坊市",
            shortName: "潍坊",
            children: [
              {
                id: 370702,
                name: "潍城区",
                shortName: "潍城",
                children: [],
              },
              {
                id: 370703,
                name: "寒亭区",
                shortName: "寒亭",
                children: [],
              },
              {
                id: 370704,
                name: "坊子区",
                shortName: "坊子",
                children: [],
              },
              {
                id: 370705,
                name: "奎文区",
                shortName: "奎文",
                children: [],
              },
              {
                id: 370724,
                name: "临朐县",
                shortName: "临朐",
                children: [],
              },
              {
                id: 370725,
                name: "昌乐县",
                shortName: "昌乐",
                children: [],
              },
              {
                id: 370781,
                name: "青州市",
                shortName: "青州",
                children: [],
              },
              {
                id: 370782,
                name: "诸城市",
                shortName: "诸城",
                children: [],
              },
              {
                id: 370783,
                name: "寿光市",
                shortName: "寿光",
                children: [],
              },
              {
                id: 370784,
                name: "安丘市",
                shortName: "安丘",
                children: [],
              },
              {
                id: 370785,
                name: "高密市",
                shortName: "高密",
                children: [],
              },
              {
                id: 370786,
                name: "昌邑市",
                shortName: "昌邑",
                children: [],
              },
              {
                id: 370787,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 370800,
            name: "济宁市",
            shortName: "济宁",
            children: [
              {
                id: 370811,
                name: "任城区",
                shortName: "任城",
                children: [],
              },
              {
                id: 370812,
                name: "兖州区",
                shortName: "兖州",
                children: [],
              },
              {
                id: 370826,
                name: "微山县",
                shortName: "微山",
                children: [],
              },
              {
                id: 370827,
                name: "鱼台县",
                shortName: "鱼台",
                children: [],
              },
              {
                id: 370828,
                name: "金乡县",
                shortName: "金乡",
                children: [],
              },
              {
                id: 370829,
                name: "嘉祥县",
                shortName: "嘉祥",
                children: [],
              },
              {
                id: 370830,
                name: "汶上县",
                shortName: "汶上",
                children: [],
              },
              {
                id: 370831,
                name: "泗水县",
                shortName: "泗水",
                children: [],
              },
              {
                id: 370832,
                name: "梁山县",
                shortName: "梁山",
                children: [],
              },
              {
                id: 370881,
                name: "曲阜市",
                shortName: "曲阜",
                children: [],
              },
              {
                id: 370883,
                name: "邹城市",
                shortName: "邹城",
                children: [],
              },
              {
                id: 370884,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 370900,
            name: "泰安市",
            shortName: "泰安",
            children: [
              {
                id: 370902,
                name: "泰山区",
                shortName: "泰山",
                children: [],
              },
              {
                id: 370911,
                name: "岱岳区",
                shortName: "岱岳",
                children: [],
              },
              {
                id: 370921,
                name: "宁阳县",
                shortName: "宁阳",
                children: [],
              },
              {
                id: 370923,
                name: "东平县",
                shortName: "东平",
                children: [],
              },
              {
                id: 370982,
                name: "新泰市",
                shortName: "新泰",
                children: [],
              },
              {
                id: 370983,
                name: "肥城市",
                shortName: "肥城",
                children: [],
              },
            ],
          },
          {
            id: 371000,
            name: "威海市",
            shortName: "威海",
            children: [
              {
                id: 371002,
                name: "环翠区",
                shortName: "环翠",
                children: [],
              },
              {
                id: 371003,
                name: "文登区",
                shortName: "文登",
                children: [],
              },
              {
                id: 371082,
                name: "荣成市",
                shortName: "荣成",
                children: [],
              },
              {
                id: 371083,
                name: "乳山市",
                shortName: "乳山",
                children: [],
              },
            ],
          },
          {
            id: 371100,
            name: "日照市",
            shortName: "日照",
            children: [
              {
                id: 371102,
                name: "东港区",
                shortName: "东港",
                children: [],
              },
              {
                id: 371103,
                name: "岚山区",
                shortName: "岚山",
                children: [],
              },
              {
                id: 371121,
                name: "五莲县",
                shortName: "五莲",
                children: [],
              },
              {
                id: 371122,
                name: "莒县",
                shortName: "莒县",
                children: [],
              },
            ],
          },
          {
            id: 371200,
            name: "莱芜市",
            shortName: "莱芜",
            children: [
              {
                id: 371202,
                name: "莱城区",
                shortName: "莱城",
                children: [],
              },
              {
                id: 371203,
                name: "钢城区",
                shortName: "钢城",
                children: [],
              },
            ],
          },
          {
            id: 371300,
            name: "临沂市",
            shortName: "临沂",
            children: [
              {
                id: 371302,
                name: "兰山区",
                shortName: "兰山",
                children: [],
              },
              {
                id: 371311,
                name: "罗庄区",
                shortName: "罗庄",
                children: [],
              },
              {
                id: 371312,
                name: "河东区",
                shortName: "河东",
                children: [],
              },
              {
                id: 371321,
                name: "沂南县",
                shortName: "沂南",
                children: [],
              },
              {
                id: 371322,
                name: "郯城县",
                shortName: "郯城",
                children: [],
              },
              {
                id: 371323,
                name: "沂水县",
                shortName: "沂水",
                children: [],
              },
              {
                id: 371324,
                name: "兰陵县",
                shortName: "兰陵",
                children: [],
              },
              {
                id: 371325,
                name: "费县",
                shortName: "费县",
                children: [],
              },
              {
                id: 371326,
                name: "平邑县",
                shortName: "平邑",
                children: [],
              },
              {
                id: 371327,
                name: "莒南县",
                shortName: "莒南",
                children: [],
              },
              {
                id: 371328,
                name: "蒙阴县",
                shortName: "蒙阴",
                children: [],
              },
              {
                id: 371329,
                name: "临沭县",
                shortName: "临沭",
                children: [],
              },
            ],
          },
          {
            id: 371400,
            name: "德州市",
            shortName: "德州",
            children: [
              {
                id: 371402,
                name: "德城区",
                shortName: "德城",
                children: [],
              },
              {
                id: 371403,
                name: "陵城区",
                shortName: "陵城",
                children: [],
              },
              {
                id: 371422,
                name: "宁津县",
                shortName: "宁津",
                children: [],
              },
              {
                id: 371423,
                name: "庆云县",
                shortName: "庆云",
                children: [],
              },
              {
                id: 371424,
                name: "临邑县",
                shortName: "临邑",
                children: [],
              },
              {
                id: 371425,
                name: "齐河县",
                shortName: "齐河",
                children: [],
              },
              {
                id: 371426,
                name: "平原县",
                shortName: "平原",
                children: [],
              },
              {
                id: 371427,
                name: "夏津县",
                shortName: "夏津",
                children: [],
              },
              {
                id: 371428,
                name: "武城县",
                shortName: "武城",
                children: [],
              },
              {
                id: 371481,
                name: "乐陵市",
                shortName: "乐陵",
                children: [],
              },
              {
                id: 371482,
                name: "禹城市",
                shortName: "禹城",
                children: [],
              },
            ],
          },
          {
            id: 371500,
            name: "聊城市",
            shortName: "聊城",
            children: [
              {
                id: 371502,
                name: "东昌府区",
                shortName: "东昌府",
                children: [],
              },
              {
                id: 371521,
                name: "阳谷县",
                shortName: "阳谷",
                children: [],
              },
              {
                id: 371522,
                name: "莘县",
                shortName: "莘县",
                children: [],
              },
              {
                id: 371523,
                name: "茌平县",
                shortName: "茌平",
                children: [],
              },
              {
                id: 371524,
                name: "东阿县",
                shortName: "东阿",
                children: [],
              },
              {
                id: 371525,
                name: "冠县",
                shortName: "冠县",
                children: [],
              },
              {
                id: 371526,
                name: "高唐县",
                shortName: "高唐",
                children: [],
              },
              {
                id: 371581,
                name: "临清市",
                shortName: "临清",
                children: [],
              },
            ],
          },
          {
            id: 371600,
            name: "滨州市",
            shortName: "滨州",
            children: [
              {
                id: 371602,
                name: "滨城区",
                shortName: "滨城",
                children: [],
              },
              {
                id: 371603,
                name: "沾化区",
                shortName: "沾化",
                children: [],
              },
              {
                id: 371621,
                name: "惠民县",
                shortName: "惠民",
                children: [],
              },
              {
                id: 371622,
                name: "阳信县",
                shortName: "阳信",
                children: [],
              },
              {
                id: 371623,
                name: "无棣县",
                shortName: "无棣",
                children: [],
              },
              {
                id: 371625,
                name: "博兴县",
                shortName: "博兴",
                children: [],
              },
              {
                id: 371626,
                name: "邹平县",
                shortName: "邹平",
                children: [],
              },
              {
                id: 371627,
                name: "北海新区",
                shortName: "北海新区",
                children: [],
              },
            ],
          },
          {
            id: 371700,
            name: "菏泽市",
            shortName: "菏泽",
            children: [
              {
                id: 371702,
                name: "牡丹区",
                shortName: "牡丹",
                children: [],
              },
              {
                id: 371703,
                name: "定陶区",
                shortName: "定陶",
                children: [],
              },
              {
                id: 371721,
                name: "曹县",
                shortName: "曹县",
                children: [],
              },
              {
                id: 371722,
                name: "单县",
                shortName: "单县",
                children: [],
              },
              {
                id: 371723,
                name: "成武县",
                shortName: "成武",
                children: [],
              },
              {
                id: 371724,
                name: "巨野县",
                shortName: "巨野",
                children: [],
              },
              {
                id: 371725,
                name: "郓城县",
                shortName: "郓城",
                children: [],
              },
              {
                id: 371726,
                name: "鄄城县",
                shortName: "鄄城",
                children: [],
              },
              {
                id: 371728,
                name: "东明县",
                shortName: "东明",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 410000,
        name: "河南省",
        shortName: "河南",
        children: [
          {
            id: 410100,
            name: "郑州市",
            shortName: "郑州",
            children: [
              {
                id: 410102,
                name: "中原区",
                shortName: "中原",
                children: [],
              },
              {
                id: 410103,
                name: "二七区",
                shortName: "二七",
                children: [],
              },
              {
                id: 410104,
                name: "管城回族区",
                shortName: "管城",
                children: [],
              },
              {
                id: 410105,
                name: "金水区",
                shortName: "金水",
                children: [],
              },
              {
                id: 410106,
                name: "上街区",
                shortName: "上街",
                children: [],
              },
              {
                id: 410108,
                name: "惠济区",
                shortName: "惠济",
                children: [],
              },
              {
                id: 410122,
                name: "中牟县",
                shortName: "中牟",
                children: [],
              },
              {
                id: 410181,
                name: "巩义市",
                shortName: "巩义",
                children: [],
              },
              {
                id: 410182,
                name: "荥阳市",
                shortName: "荥阳",
                children: [],
              },
              {
                id: 410183,
                name: "新密市",
                shortName: "新密",
                children: [],
              },
              {
                id: 410184,
                name: "新郑市",
                shortName: "新郑",
                children: [],
              },
              {
                id: 410185,
                name: "登封市",
                shortName: "登封",
                children: [],
              },
              {
                id: 410186,
                name: "郑东新区",
                shortName: "郑东新区",
                children: [],
              },
              {
                id: 410187,
                name: "郑汴新区",
                shortName: "郑汴新区",
                children: [],
              },
              {
                id: 410188,
                name: "高新开发区",
                shortName: "高新开发区",
                children: [],
              },
              {
                id: 410189,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 410200,
            name: "开封市",
            shortName: "开封",
            children: [
              {
                id: 410202,
                name: "龙亭区",
                shortName: "龙亭",
                children: [],
              },
              {
                id: 410203,
                name: "顺河回族区",
                shortName: "顺河",
                children: [],
              },
              {
                id: 410204,
                name: "鼓楼区",
                shortName: "鼓楼",
                children: [],
              },
              {
                id: 410205,
                name: "禹王台区",
                shortName: "禹王台",
                children: [],
              },
              {
                id: 410212,
                name: "祥符区",
                shortName: "祥符",
                children: [],
              },
              {
                id: 410221,
                name: "杞县",
                shortName: "杞县",
                children: [],
              },
              {
                id: 410222,
                name: "通许县",
                shortName: "通许",
                children: [],
              },
              {
                id: 410223,
                name: "尉氏县",
                shortName: "尉氏",
                children: [],
              },
              {
                id: 410225,
                name: "兰考县",
                shortName: "兰考",
                children: [],
              },
              {
                id: 410226,
                name: "经济技术开发区",
                shortName: "兰考",
                children: [],
              },
            ],
          },
          {
            id: 410300,
            name: "洛阳市",
            shortName: "洛阳",
            children: [
              {
                id: 410302,
                name: "老城区",
                shortName: "老城",
                children: [],
              },
              {
                id: 410303,
                name: "西工区",
                shortName: "西工",
                children: [],
              },
              {
                id: 410304,
                name: "瀍河回族区",
                shortName: "瀍河",
                children: [],
              },
              {
                id: 410305,
                name: "涧西区",
                shortName: "涧西",
                children: [],
              },
              {
                id: 410306,
                name: "吉利区",
                shortName: "吉利",
                children: [],
              },
              {
                id: 410311,
                name: "洛龙区",
                shortName: "洛龙",
                children: [],
              },
              {
                id: 410322,
                name: "孟津县",
                shortName: "孟津",
                children: [],
              },
              {
                id: 410323,
                name: "新安县",
                shortName: "新安",
                children: [],
              },
              {
                id: 410324,
                name: "栾川县",
                shortName: "栾川",
                children: [],
              },
              {
                id: 410325,
                name: "嵩县",
                shortName: "嵩县",
                children: [],
              },
              {
                id: 410326,
                name: "汝阳县",
                shortName: "汝阳",
                children: [],
              },
              {
                id: 410327,
                name: "宜阳县",
                shortName: "宜阳",
                children: [],
              },
              {
                id: 410328,
                name: "洛宁县",
                shortName: "洛宁",
                children: [],
              },
              {
                id: 410329,
                name: "伊川县",
                shortName: "伊川",
                children: [],
              },
              {
                id: 410381,
                name: "偃师市",
                shortName: "偃师",
                children: [],
              },
              {
                id: 410382,
                name: "洛阳新区",
                shortName: "洛阳新区",
                children: [],
              },
              {
                id: 410383,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 410400,
            name: "平顶山市",
            shortName: "平顶山",
            children: [
              {
                id: 410402,
                name: "新华区",
                shortName: "新华",
                children: [],
              },
              {
                id: 410403,
                name: "卫东区",
                shortName: "卫东",
                children: [],
              },
              {
                id: 410404,
                name: "石龙区",
                shortName: "石龙",
                children: [],
              },
              {
                id: 410411,
                name: "湛河区",
                shortName: "湛河",
                children: [],
              },
              {
                id: 410421,
                name: "宝丰县",
                shortName: "宝丰",
                children: [],
              },
              {
                id: 410422,
                name: "叶县",
                shortName: "叶县",
                children: [],
              },
              {
                id: 410423,
                name: "鲁山县",
                shortName: "鲁山",
                children: [],
              },
              {
                id: 410425,
                name: "郏县",
                shortName: "郏县",
                children: [],
              },
              {
                id: 410481,
                name: "舞钢市",
                shortName: "舞钢",
                children: [],
              },
              {
                id: 410482,
                name: "汝州市",
                shortName: "汝州",
                children: [],
              },
            ],
          },
          {
            id: 410500,
            name: "安阳市",
            shortName: "安阳",
            children: [
              {
                id: 410502,
                name: "文峰区",
                shortName: "文峰",
                children: [],
              },
              {
                id: 410503,
                name: "北关区",
                shortName: "北关",
                children: [],
              },
              {
                id: 410505,
                name: "殷都区",
                shortName: "殷都",
                children: [],
              },
              {
                id: 410506,
                name: "龙安区",
                shortName: "龙安",
                children: [],
              },
              {
                id: 410522,
                name: "安阳县",
                shortName: "安阳",
                children: [],
              },
              {
                id: 410523,
                name: "汤阴县",
                shortName: "汤阴",
                children: [],
              },
              {
                id: 410526,
                name: "滑县",
                shortName: "滑县",
                children: [],
              },
              {
                id: 410527,
                name: "内黄县",
                shortName: "内黄",
                children: [],
              },
              {
                id: 410581,
                name: "林州市",
                shortName: "林州",
                children: [],
              },
              {
                id: 410582,
                name: "安阳新区",
                shortName: "安阳新区",
                children: [],
              },
            ],
          },
          {
            id: 410600,
            name: "鹤壁市",
            shortName: "鹤壁",
            children: [
              {
                id: 410602,
                name: "鹤山区",
                shortName: "鹤山",
                children: [],
              },
              {
                id: 410603,
                name: "山城区",
                shortName: "山城",
                children: [],
              },
              {
                id: 410611,
                name: "淇滨区",
                shortName: "淇滨",
                children: [],
              },
              {
                id: 410621,
                name: "浚县",
                shortName: "浚县",
                children: [],
              },
              {
                id: 410622,
                name: "淇县",
                shortName: "淇县",
                children: [],
              },
            ],
          },
          {
            id: 410700,
            name: "新乡市",
            shortName: "新乡",
            children: [
              {
                id: 410702,
                name: "红旗区",
                shortName: "红旗",
                children: [],
              },
              {
                id: 410703,
                name: "卫滨区",
                shortName: "卫滨",
                children: [],
              },
              {
                id: 410704,
                name: "凤泉区",
                shortName: "凤泉",
                children: [],
              },
              {
                id: 410711,
                name: "牧野区",
                shortName: "牧野",
                children: [],
              },
              {
                id: 410721,
                name: "新乡县",
                shortName: "新乡",
                children: [],
              },
              {
                id: 410724,
                name: "获嘉县",
                shortName: "获嘉",
                children: [],
              },
              {
                id: 410725,
                name: "原阳县",
                shortName: "原阳",
                children: [],
              },
              {
                id: 410726,
                name: "延津县",
                shortName: "延津",
                children: [],
              },
              {
                id: 410727,
                name: "封丘县",
                shortName: "封丘",
                children: [],
              },
              {
                id: 410728,
                name: "长垣县",
                shortName: "长垣",
                children: [],
              },
              {
                id: 410781,
                name: "卫辉市",
                shortName: "卫辉",
                children: [],
              },
              {
                id: 410782,
                name: "辉县市",
                shortName: "辉县",
                children: [],
              },
            ],
          },
          {
            id: 410800,
            name: "焦作市",
            shortName: "焦作",
            children: [
              {
                id: 410802,
                name: "解放区",
                shortName: "解放",
                children: [],
              },
              {
                id: 410803,
                name: "中站区",
                shortName: "中站",
                children: [],
              },
              {
                id: 410804,
                name: "马村区",
                shortName: "马村",
                children: [],
              },
              {
                id: 410811,
                name: "山阳区",
                shortName: "山阳",
                children: [],
              },
              {
                id: 410821,
                name: "修武县",
                shortName: "修武",
                children: [],
              },
              {
                id: 410822,
                name: "博爱县",
                shortName: "博爱",
                children: [],
              },
              {
                id: 410823,
                name: "武陟县",
                shortName: "武陟",
                children: [],
              },
              {
                id: 410825,
                name: "温县",
                shortName: "温县",
                children: [],
              },
              {
                id: 410882,
                name: "沁阳市",
                shortName: "沁阳",
                children: [],
              },
              {
                id: 410883,
                name: "孟州市",
                shortName: "孟州",
                children: [],
              },
            ],
          },
          {
            id: 410900,
            name: "濮阳市",
            shortName: "濮阳",
            children: [
              {
                id: 410902,
                name: "华龙区",
                shortName: "华龙",
                children: [],
              },
              {
                id: 410922,
                name: "清丰县",
                shortName: "清丰",
                children: [],
              },
              {
                id: 410923,
                name: "南乐县",
                shortName: "南乐",
                children: [],
              },
              {
                id: 410926,
                name: "范县",
                shortName: "范县",
                children: [],
              },
              {
                id: 410927,
                name: "台前县",
                shortName: "台前",
                children: [],
              },
              {
                id: 410928,
                name: "濮阳县",
                shortName: "濮阳",
                children: [],
              },
            ],
          },
          {
            id: 411000,
            name: "许昌市",
            shortName: "许昌",
            children: [
              {
                id: 411002,
                name: "魏都区",
                shortName: "魏都",
                children: [],
              },
              {
                id: 411003,
                name: "建安区",
                shortName: "建安",
                children: [],
              },
              {
                id: 411024,
                name: "鄢陵县",
                shortName: "鄢陵",
                children: [],
              },
              {
                id: 411025,
                name: "襄城县",
                shortName: "襄城",
                children: [],
              },
              {
                id: 411081,
                name: "禹州市",
                shortName: "禹州",
                children: [],
              },
              {
                id: 411082,
                name: "长葛市",
                shortName: "长葛",
                children: [],
              },
            ],
          },
          {
            id: 411100,
            name: "漯河市",
            shortName: "漯河",
            children: [
              {
                id: 411102,
                name: "源汇区",
                shortName: "源汇",
                children: [],
              },
              {
                id: 411103,
                name: "郾城区",
                shortName: "郾城",
                children: [],
              },
              {
                id: 411104,
                name: "召陵区",
                shortName: "召陵",
                children: [],
              },
              {
                id: 411121,
                name: "舞阳县",
                shortName: "舞阳",
                children: [],
              },
              {
                id: 411122,
                name: "临颍县",
                shortName: "临颍",
                children: [],
              },
            ],
          },
          {
            id: 411200,
            name: "三门峡市",
            shortName: "三门峡",
            children: [
              {
                id: 411202,
                name: "湖滨区",
                shortName: "湖滨",
                children: [],
              },
              {
                id: 411203,
                name: "陕州区",
                shortName: "陕州",
                children: [],
              },
              {
                id: 411221,
                name: "渑池县",
                shortName: "渑池",
                children: [],
              },
              {
                id: 411224,
                name: "卢氏县",
                shortName: "卢氏",
                children: [],
              },
              {
                id: 411281,
                name: "义马市",
                shortName: "义马",
                children: [],
              },
              {
                id: 411282,
                name: "灵宝市",
                shortName: "灵宝",
                children: [],
              },
            ],
          },
          {
            id: 411300,
            name: "南阳市",
            shortName: "南阳",
            children: [
              {
                id: 411302,
                name: "宛城区",
                shortName: "宛城",
                children: [],
              },
              {
                id: 411303,
                name: "卧龙区",
                shortName: "卧龙",
                children: [],
              },
              {
                id: 411321,
                name: "南召县",
                shortName: "南召",
                children: [],
              },
              {
                id: 411322,
                name: "方城县",
                shortName: "方城",
                children: [],
              },
              {
                id: 411323,
                name: "西峡县",
                shortName: "西峡",
                children: [],
              },
              {
                id: 411324,
                name: "镇平县",
                shortName: "镇平",
                children: [],
              },
              {
                id: 411325,
                name: "内乡县",
                shortName: "内乡",
                children: [],
              },
              {
                id: 411326,
                name: "淅川县",
                shortName: "淅川",
                children: [],
              },
              {
                id: 411327,
                name: "社旗县",
                shortName: "社旗",
                children: [],
              },
              {
                id: 411328,
                name: "唐河县",
                shortName: "唐河",
                children: [],
              },
              {
                id: 411329,
                name: "新野县",
                shortName: "新野",
                children: [],
              },
              {
                id: 411330,
                name: "桐柏县",
                shortName: "桐柏",
                children: [],
              },
              {
                id: 411381,
                name: "邓州市",
                shortName: "邓州",
                children: [],
              },
            ],
          },
          {
            id: 411400,
            name: "商丘市",
            shortName: "商丘",
            children: [
              {
                id: 411402,
                name: "梁园区",
                shortName: "梁园",
                children: [],
              },
              {
                id: 411403,
                name: "睢阳区",
                shortName: "睢阳",
                children: [],
              },
              {
                id: 411421,
                name: "民权县",
                shortName: "民权",
                children: [],
              },
              {
                id: 411422,
                name: "睢县",
                shortName: "睢县",
                children: [],
              },
              {
                id: 411423,
                name: "宁陵县",
                shortName: "宁陵",
                children: [],
              },
              {
                id: 411424,
                name: "柘城县",
                shortName: "柘城",
                children: [],
              },
              {
                id: 411425,
                name: "虞城县",
                shortName: "虞城",
                children: [],
              },
              {
                id: 411426,
                name: "夏邑县",
                shortName: "夏邑",
                children: [],
              },
              {
                id: 411481,
                name: "永城市",
                shortName: "永城",
                children: [],
              },
            ],
          },
          {
            id: 411500,
            name: "信阳市",
            shortName: "信阳",
            children: [
              {
                id: 411502,
                name: "浉河区",
                shortName: "浉河",
                children: [],
              },
              {
                id: 411503,
                name: "平桥区",
                shortName: "平桥",
                children: [],
              },
              {
                id: 411521,
                name: "罗山县",
                shortName: "罗山",
                children: [],
              },
              {
                id: 411522,
                name: "光山县",
                shortName: "光山",
                children: [],
              },
              {
                id: 411523,
                name: "新县",
                shortName: "新县",
                children: [],
              },
              {
                id: 411524,
                name: "商城县",
                shortName: "商城",
                children: [],
              },
              {
                id: 411525,
                name: "固始县",
                shortName: "固始",
                children: [],
              },
              {
                id: 411526,
                name: "潢川县",
                shortName: "潢川",
                children: [],
              },
              {
                id: 411527,
                name: "淮滨县",
                shortName: "淮滨",
                children: [],
              },
              {
                id: 411528,
                name: "息县",
                shortName: "息县",
                children: [],
              },
            ],
          },
          {
            id: 411600,
            name: "周口市",
            shortName: "周口",
            children: [
              {
                id: 411602,
                name: "川汇区",
                shortName: "川汇",
                children: [],
              },
              {
                id: 411621,
                name: "扶沟县",
                shortName: "扶沟",
                children: [],
              },
              {
                id: 411622,
                name: "西华县",
                shortName: "西华",
                children: [],
              },
              {
                id: 411623,
                name: "商水县",
                shortName: "商水",
                children: [],
              },
              {
                id: 411624,
                name: "沈丘县",
                shortName: "沈丘",
                children: [],
              },
              {
                id: 411625,
                name: "郸城县",
                shortName: "郸城",
                children: [],
              },
              {
                id: 411626,
                name: "淮阳县",
                shortName: "淮阳",
                children: [],
              },
              {
                id: 411627,
                name: "太康县",
                shortName: "太康",
                children: [],
              },
              {
                id: 411628,
                name: "鹿邑县",
                shortName: "鹿邑",
                children: [],
              },
              {
                id: 411681,
                name: "项城市",
                shortName: "项城",
                children: [],
              },
              {
                id: 411682,
                name: "东新区",
                shortName: "东新区",
                children: [],
              },
              {
                id: 411683,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 411700,
            name: "驻马店市",
            shortName: "驻马店",
            children: [
              {
                id: 411702,
                name: "驿城区",
                shortName: "驿城",
                children: [],
              },
              {
                id: 411721,
                name: "西平县",
                shortName: "西平",
                children: [],
              },
              {
                id: 411722,
                name: "上蔡县",
                shortName: "上蔡",
                children: [],
              },
              {
                id: 411723,
                name: "平舆县",
                shortName: "平舆",
                children: [],
              },
              {
                id: 411724,
                name: "正阳县",
                shortName: "正阳",
                children: [],
              },
              {
                id: 411725,
                name: "确山县",
                shortName: "确山",
                children: [],
              },
              {
                id: 411726,
                name: "泌阳县",
                shortName: "泌阳",
                children: [],
              },
              {
                id: 411727,
                name: "汝南县",
                shortName: "汝南",
                children: [],
              },
              {
                id: 411728,
                name: "遂平县",
                shortName: "遂平",
                children: [],
              },
              {
                id: 411729,
                name: "新蔡县",
                shortName: "新蔡",
                children: [],
              },
            ],
          },
          {
            id: 419001,
            name: "济源市",
            shortName: "济源",
            children: [
              {
                id: 419011,
                name: "沁园街道",
                shortName: "沁园街道",
                children: [],
              },
              {
                id: 419012,
                name: "济水街道",
                shortName: "济水街道",
                children: [],
              },
              {
                id: 419013,
                name: "北海街道",
                shortName: "北海街道",
                children: [],
              },
              {
                id: 419014,
                name: "天坛街道",
                shortName: "天坛街道",
                children: [],
              },
              {
                id: 419015,
                name: "玉泉街道",
                shortName: "玉泉街道",
                children: [],
              },
              {
                id: 419016,
                name: "克井镇",
                shortName: "克井镇",
                children: [],
              },
              {
                id: 419017,
                name: "五龙口镇",
                shortName: "五龙口镇",
                children: [],
              },
              {
                id: 419018,
                name: "梨林镇",
                shortName: "梨林镇",
                children: [],
              },
              {
                id: 419019,
                name: "轵城镇",
                shortName: "轵城镇",
                children: [],
              },
              {
                id: 419020,
                name: "承留镇",
                shortName: "承留镇",
                children: [],
              },
              {
                id: 419021,
                name: "坡头镇",
                shortName: "坡头镇",
                children: [],
              },
              {
                id: 419022,
                name: "大峪镇",
                shortName: "大峪镇",
                children: [],
              },
              {
                id: 419023,
                name: "邵原镇",
                shortName: "邵原镇",
                children: [],
              },
              {
                id: 419024,
                name: "思礼镇",
                shortName: "思礼镇",
                children: [],
              },
              {
                id: 419025,
                name: "王屋镇",
                shortName: "王屋镇",
                children: [],
              },
              {
                id: 419026,
                name: "下冶镇",
                shortName: "下冶镇",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 420000,
        name: "湖北省",
        shortName: "湖北",
        children: [
          {
            id: 420100,
            name: "武汉市",
            shortName: "武汉",
            children: [
              {
                id: 420102,
                name: "江岸区",
                shortName: "江岸",
                children: [],
              },
              {
                id: 420103,
                name: "江汉区",
                shortName: "江汉",
                children: [],
              },
              {
                id: 420104,
                name: "硚口区",
                shortName: "硚口",
                children: [],
              },
              {
                id: 420105,
                name: "汉阳区",
                shortName: "汉阳",
                children: [],
              },
              {
                id: 420106,
                name: "武昌区",
                shortName: "武昌",
                children: [],
              },
              {
                id: 420107,
                name: "青山区",
                shortName: "青山",
                children: [],
              },
              {
                id: 420111,
                name: "洪山区",
                shortName: "洪山",
                children: [],
              },
              {
                id: 420112,
                name: "东西湖区",
                shortName: "东西湖",
                children: [],
              },
              {
                id: 420113,
                name: "汉南区",
                shortName: "汉南",
                children: [],
              },
              {
                id: 420114,
                name: "蔡甸区",
                shortName: "蔡甸",
                children: [],
              },
              {
                id: 420115,
                name: "江夏区",
                shortName: "江夏",
                children: [],
              },
              {
                id: 420116,
                name: "黄陂区",
                shortName: "黄陂",
                children: [],
              },
              {
                id: 420117,
                name: "新洲区",
                shortName: "新洲",
                children: [],
              },
              {
                id: 420118,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 420200,
            name: "黄石市",
            shortName: "黄石",
            children: [
              {
                id: 420202,
                name: "黄石港区",
                shortName: "黄石港",
                children: [],
              },
              {
                id: 420203,
                name: "西塞山区",
                shortName: "西塞山",
                children: [],
              },
              {
                id: 420204,
                name: "下陆区",
                shortName: "下陆",
                children: [],
              },
              {
                id: 420205,
                name: "铁山区",
                shortName: "铁山",
                children: [],
              },
              {
                id: 420222,
                name: "阳新县",
                shortName: "阳新",
                children: [],
              },
              {
                id: 420281,
                name: "大冶市",
                shortName: "大冶",
                children: [],
              },
              {
                id: 420282,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 420300,
            name: "十堰市",
            shortName: "十堰",
            children: [
              {
                id: 420302,
                name: "茅箭区",
                shortName: "茅箭",
                children: [],
              },
              {
                id: 420303,
                name: "张湾区",
                shortName: "张湾",
                children: [],
              },
              {
                id: 420304,
                name: "郧阳区",
                shortName: "郧阳",
                children: [],
              },
              {
                id: 420322,
                name: "郧西县",
                shortName: "郧西",
                children: [],
              },
              {
                id: 420323,
                name: "竹山县",
                shortName: "竹山",
                children: [],
              },
              {
                id: 420324,
                name: "竹溪县",
                shortName: "竹溪",
                children: [],
              },
              {
                id: 420325,
                name: "房县",
                shortName: "房县",
                children: [],
              },
              {
                id: 420381,
                name: "丹江口市",
                shortName: "丹江口",
                children: [],
              },
            ],
          },
          {
            id: 420500,
            name: "宜昌市",
            shortName: "宜昌",
            children: [
              {
                id: 420502,
                name: "西陵区",
                shortName: "西陵",
                children: [],
              },
              {
                id: 420503,
                name: "伍家岗区",
                shortName: "伍家岗",
                children: [],
              },
              {
                id: 420504,
                name: "点军区",
                shortName: "点军",
                children: [],
              },
              {
                id: 420505,
                name: "猇亭区",
                shortName: "猇亭",
                children: [],
              },
              {
                id: 420506,
                name: "夷陵区",
                shortName: "夷陵",
                children: [],
              },
              {
                id: 420525,
                name: "远安县",
                shortName: "远安",
                children: [],
              },
              {
                id: 420526,
                name: "兴山县",
                shortName: "兴山",
                children: [],
              },
              {
                id: 420527,
                name: "秭归县",
                shortName: "秭归",
                children: [],
              },
              {
                id: 420528,
                name: "长阳土家族自治县",
                shortName: "长阳",
                children: [],
              },
              {
                id: 420529,
                name: "五峰土家族自治县",
                shortName: "五峰",
                children: [],
              },
              {
                id: 420581,
                name: "宜都市",
                shortName: "宜都",
                children: [],
              },
              {
                id: 420582,
                name: "当阳市",
                shortName: "当阳",
                children: [],
              },
              {
                id: 420583,
                name: "枝江市",
                shortName: "枝江",
                children: [],
              },
              {
                id: 420584,
                name: "宜昌新区",
                shortName: "宜昌新区",
                children: [],
              },
            ],
          },
          {
            id: 420600,
            name: "襄阳市",
            shortName: "襄阳",
            children: [
              {
                id: 420602,
                name: "襄城区",
                shortName: "襄城",
                children: [],
              },
              {
                id: 420606,
                name: "樊城区",
                shortName: "樊城",
                children: [],
              },
              {
                id: 420607,
                name: "襄州区",
                shortName: "襄州",
                children: [],
              },
              {
                id: 420624,
                name: "南漳县",
                shortName: "南漳",
                children: [],
              },
              {
                id: 420625,
                name: "谷城县",
                shortName: "谷城",
                children: [],
              },
              {
                id: 420626,
                name: "保康县",
                shortName: "保康",
                children: [],
              },
              {
                id: 420682,
                name: "老河口市",
                shortName: "老河口",
                children: [],
              },
              {
                id: 420683,
                name: "枣阳市",
                shortName: "枣阳",
                children: [],
              },
              {
                id: 420684,
                name: "宜城市",
                shortName: "宜城",
                children: [],
              },
              {
                id: 420685,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 420686,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 420700,
            name: "鄂州市",
            shortName: "鄂州",
            children: [
              {
                id: 420702,
                name: "梁子湖区",
                shortName: "梁子湖",
                children: [],
              },
              {
                id: 420703,
                name: "华容区",
                shortName: "华容",
                children: [],
              },
              {
                id: 420704,
                name: "鄂城区",
                shortName: "鄂城",
                children: [],
              },
            ],
          },
          {
            id: 420800,
            name: "荆门市",
            shortName: "荆门",
            children: [
              {
                id: 420802,
                name: "东宝区",
                shortName: "东宝",
                children: [],
              },
              {
                id: 420804,
                name: "掇刀区",
                shortName: "掇刀",
                children: [],
              },
              {
                id: 420821,
                name: "京山县",
                shortName: "京山",
                children: [],
              },
              {
                id: 420822,
                name: "沙洋县",
                shortName: "沙洋",
                children: [],
              },
              {
                id: 420881,
                name: "钟祥市",
                shortName: "钟祥",
                children: [],
              },
            ],
          },
          {
            id: 420900,
            name: "孝感市",
            shortName: "孝感",
            children: [
              {
                id: 420902,
                name: "孝南区",
                shortName: "孝南",
                children: [],
              },
              {
                id: 420921,
                name: "孝昌县",
                shortName: "孝昌",
                children: [],
              },
              {
                id: 420922,
                name: "大悟县",
                shortName: "大悟",
                children: [],
              },
              {
                id: 420923,
                name: "云梦县",
                shortName: "云梦",
                children: [],
              },
              {
                id: 420981,
                name: "应城市",
                shortName: "应城",
                children: [],
              },
              {
                id: 420982,
                name: "安陆市",
                shortName: "安陆",
                children: [],
              },
              {
                id: 420984,
                name: "汉川市",
                shortName: "汉川",
                children: [],
              },
            ],
          },
          {
            id: 421000,
            name: "荆州市",
            shortName: "荆州",
            children: [
              {
                id: 421002,
                name: "沙市区",
                shortName: "沙市",
                children: [],
              },
              {
                id: 421003,
                name: "荆州区",
                shortName: "荆州",
                children: [],
              },
              {
                id: 421022,
                name: "公安县",
                shortName: "公安",
                children: [],
              },
              {
                id: 421023,
                name: "监利县",
                shortName: "监利",
                children: [],
              },
              {
                id: 421024,
                name: "江陵县",
                shortName: "江陵",
                children: [],
              },
              {
                id: 421081,
                name: "石首市",
                shortName: "石首",
                children: [],
              },
              {
                id: 421083,
                name: "洪湖市",
                shortName: "洪湖",
                children: [],
              },
              {
                id: 421087,
                name: "松滋市",
                shortName: "松滋",
                children: [],
              },
            ],
          },
          {
            id: 421100,
            name: "黄冈市",
            shortName: "黄冈",
            children: [
              {
                id: 421102,
                name: "黄州区",
                shortName: "黄州",
                children: [],
              },
              {
                id: 421121,
                name: "团风县",
                shortName: "团风",
                children: [],
              },
              {
                id: 421122,
                name: "红安县",
                shortName: "红安",
                children: [],
              },
              {
                id: 421123,
                name: "罗田县",
                shortName: "罗田",
                children: [],
              },
              {
                id: 421124,
                name: "英山县",
                shortName: "英山",
                children: [],
              },
              {
                id: 421125,
                name: "浠水县",
                shortName: "浠水",
                children: [],
              },
              {
                id: 421126,
                name: "蕲春县",
                shortName: "蕲春",
                children: [],
              },
              {
                id: 421127,
                name: "黄梅县",
                shortName: "黄梅",
                children: [],
              },
              {
                id: 421181,
                name: "麻城市",
                shortName: "麻城",
                children: [],
              },
              {
                id: 421182,
                name: "武穴市",
                shortName: "武穴",
                children: [],
              },
            ],
          },
          {
            id: 421200,
            name: "咸宁市",
            shortName: "咸宁",
            children: [
              {
                id: 421202,
                name: "咸安区",
                shortName: "咸安",
                children: [],
              },
              {
                id: 421221,
                name: "嘉鱼县",
                shortName: "嘉鱼",
                children: [],
              },
              {
                id: 421222,
                name: "通城县",
                shortName: "通城",
                children: [],
              },
              {
                id: 421223,
                name: "崇阳县",
                shortName: "崇阳",
                children: [],
              },
              {
                id: 421224,
                name: "通山县",
                shortName: "通山",
                children: [],
              },
              {
                id: 421281,
                name: "赤壁市",
                shortName: "赤壁",
                children: [],
              },
            ],
          },
          {
            id: 421300,
            name: "随州市",
            shortName: "随州",
            children: [
              {
                id: 421303,
                name: "曾都区",
                shortName: "曾都",
                children: [],
              },
              {
                id: 421321,
                name: "随县",
                shortName: "随县",
                children: [],
              },
              {
                id: 421381,
                name: "广水市",
                shortName: "广水",
                children: [],
              },
            ],
          },
          {
            id: 422800,
            name: "恩施土家族苗族自治州",
            shortName: "恩施",
            children: [
              {
                id: 422801,
                name: "恩施市",
                shortName: "恩施",
                children: [],
              },
              {
                id: 422802,
                name: "利川市",
                shortName: "利川",
                children: [],
              },
              {
                id: 422822,
                name: "建始县",
                shortName: "建始",
                children: [],
              },
              {
                id: 422823,
                name: "巴东县",
                shortName: "巴东",
                children: [],
              },
              {
                id: 422825,
                name: "宣恩县",
                shortName: "宣恩",
                children: [],
              },
              {
                id: 422826,
                name: "咸丰县",
                shortName: "咸丰",
                children: [],
              },
              {
                id: 422827,
                name: "来凤县",
                shortName: "来凤",
                children: [],
              },
              {
                id: 422828,
                name: "鹤峰县",
                shortName: "鹤峰",
                children: [],
              },
            ],
          },
          {
            id: 429004,
            name: "仙桃市",
            shortName: "仙桃",
            children: [
              {
                id: 429401,
                name: "沙嘴街道",
                shortName: "沙嘴街道",
                children: [],
              },
              {
                id: 429402,
                name: "干河街道",
                shortName: "干河街道",
                children: [],
              },
              {
                id: 429403,
                name: "龙华山街道",
                shortName: "龙华山街道",
                children: [],
              },
              {
                id: 429404,
                name: "郑场镇",
                shortName: "郑场镇",
                children: [],
              },
              {
                id: 429405,
                name: "毛嘴镇",
                shortName: "毛嘴镇",
                children: [],
              },
              {
                id: 429406,
                name: "豆河镇",
                shortName: "豆河镇",
                children: [],
              },
              {
                id: 429407,
                name: "三伏潭镇",
                shortName: "三伏潭镇",
                children: [],
              },
              {
                id: 429408,
                name: "胡场镇",
                shortName: "胡场镇",
                children: [],
              },
              {
                id: 429409,
                name: "长埫口镇",
                shortName: "长埫口镇",
                children: [],
              },
              {
                id: 429410,
                name: "西流河镇",
                shortName: "西流河镇",
                children: [],
              },
              {
                id: 429411,
                name: "沙湖镇",
                shortName: "沙湖镇",
                children: [],
              },
              {
                id: 429412,
                name: "杨林尾镇",
                shortName: "杨林尾镇",
                children: [],
              },
              {
                id: 429413,
                name: "彭场镇",
                shortName: "彭场镇",
                children: [],
              },
              {
                id: 429414,
                name: "张沟镇",
                shortName: "张沟镇",
                children: [],
              },
              {
                id: 429415,
                name: "郭河镇",
                shortName: "郭河镇",
                children: [],
              },
              {
                id: 429416,
                name: "沔城镇",
                shortName: "沔城镇",
                children: [],
              },
              {
                id: 429417,
                name: "通海口镇",
                shortName: "通海口镇",
                children: [],
              },
              {
                id: 429418,
                name: "陈场镇",
                shortName: "陈场镇",
                children: [],
              },
              {
                id: 429419,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 429420,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
              {
                id: 429421,
                name: "工业园区",
                shortName: "工业园区",
                children: [],
              },
              {
                id: 429422,
                name: "九合垸原种场",
                shortName: "九合垸原种场",
                children: [],
              },
              {
                id: 429423,
                name: "沙湖原种场",
                shortName: "沙湖原种场",
                children: [],
              },
              {
                id: 429424,
                name: "排湖渔场",
                shortName: "排湖渔场",
                children: [],
              },
              {
                id: 429425,
                name: "五湖渔场",
                shortName: "五湖渔场",
                children: [],
              },
              {
                id: 429426,
                name: "赵西垸林场",
                shortName: "赵西垸林场",
                children: [],
              },
              {
                id: 429427,
                name: "刘家垸林场",
                shortName: "刘家垸林场",
                children: [],
              },
              {
                id: 429428,
                name: "畜禽良种场",
                shortName: "畜禽良种场",
                children: [],
              },
            ],
          },
          {
            id: 429005,
            name: "潜江市",
            shortName: "潜江",
            children: [
              {
                id: 429501,
                name: "园林",
                shortName: "园林",
                children: [],
              },
              {
                id: 429502,
                name: "广华",
                shortName: "广华",
                children: [],
              },
              {
                id: 429503,
                name: "杨市",
                shortName: "杨市",
                children: [],
              },
              {
                id: 429504,
                name: "周矶",
                shortName: "周矶",
                children: [],
              },
              {
                id: 429505,
                name: "泽口",
                shortName: "泽口",
                children: [],
              },
              {
                id: 429506,
                name: "泰丰",
                shortName: "泰丰",
                children: [],
              },
              {
                id: 429507,
                name: "高场",
                shortName: "高场",
                children: [],
              },
              {
                id: 429508,
                name: "熊口镇",
                shortName: "熊口镇",
                children: [],
              },
              {
                id: 429509,
                name: "竹根滩镇",
                shortName: "竹根滩镇",
                children: [],
              },
              {
                id: 429510,
                name: "高石碑镇",
                shortName: "高石碑镇",
                children: [],
              },
              {
                id: 429511,
                name: "老新镇",
                shortName: "老新镇",
                children: [],
              },
              {
                id: 429512,
                name: "王场镇",
                shortName: "王场镇",
                children: [],
              },
              {
                id: 429513,
                name: "渔洋镇",
                shortName: "渔洋镇",
                children: [],
              },
              {
                id: 429514,
                name: "龙湾镇",
                shortName: "龙湾镇",
                children: [],
              },
              {
                id: 429515,
                name: "浩口镇",
                shortName: "浩口镇",
                children: [],
              },
              {
                id: 429516,
                name: "积玉口镇",
                shortName: "积玉口镇",
                children: [],
              },
              {
                id: 429517,
                name: "张金镇",
                shortName: "张金镇",
                children: [],
              },
              {
                id: 429518,
                name: "白鹭湖管理区",
                shortName: "白鹭湖管理区",
                children: [],
              },
              {
                id: 429519,
                name: "总口管理区",
                shortName: "总口管理区",
                children: [],
              },
              {
                id: 429520,
                name: "熊口农场管理区",
                shortName: "熊口农场管理区",
                children: [],
              },
              {
                id: 429521,
                name: "运粮湖管理区",
                shortName: "运粮湖管理区",
                children: [],
              },
              {
                id: 429522,
                name: "后湖管理区",
                shortName: "后湖管理区",
                children: [],
              },
              {
                id: 429523,
                name: "周矶管理区",
                shortName: "周矶管理区",
                children: [],
              },
              {
                id: 429524,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 429006,
            name: "天门市",
            shortName: "天门",
            children: [
              {
                id: 429601,
                name: "竟陵街道",
                shortName: "竟陵街道",
                children: [],
              },
              {
                id: 429602,
                name: "杨林街道",
                shortName: "杨林街道",
                children: [],
              },
              {
                id: 429603,
                name: "佛子山镇",
                shortName: "佛子山镇",
                children: [],
              },
              {
                id: 429604,
                name: "多宝镇",
                shortName: "多宝镇",
                children: [],
              },
              {
                id: 429605,
                name: "拖市镇",
                shortName: "拖市镇",
                children: [],
              },
              {
                id: 429606,
                name: "张港镇",
                shortName: "张港镇",
                children: [],
              },
              {
                id: 429607,
                name: "蒋场镇",
                shortName: "蒋场镇",
                children: [],
              },
              {
                id: 429608,
                name: "汪场镇",
                shortName: "汪场镇",
                children: [],
              },
              {
                id: 429609,
                name: "渔薪镇",
                shortName: "渔薪镇",
                children: [],
              },
              {
                id: 429610,
                name: "黄潭镇",
                shortName: "黄潭镇",
                children: [],
              },
              {
                id: 429611,
                name: "岳口镇",
                shortName: "岳口镇",
                children: [],
              },
              {
                id: 429612,
                name: "横林镇",
                shortName: "横林镇",
                children: [],
              },
              {
                id: 429613,
                name: "彭市镇",
                shortName: "彭市镇",
                children: [],
              },
              {
                id: 429614,
                name: "麻洋镇",
                shortName: "麻洋镇",
                children: [],
              },
              {
                id: 429615,
                name: "多祥镇",
                shortName: "多祥镇",
                children: [],
              },
              {
                id: 429616,
                name: "干驿镇",
                shortName: "干驿镇",
                children: [],
              },
              {
                id: 429617,
                name: "马湾镇",
                shortName: "马湾镇",
                children: [],
              },
              {
                id: 429618,
                name: "卢市镇",
                shortName: "卢市镇",
                children: [],
              },
              {
                id: 429619,
                name: "小板镇",
                shortName: "小板镇",
                children: [],
              },
              {
                id: 429620,
                name: "九真镇",
                shortName: "九真镇",
                children: [],
              },
              {
                id: 429621,
                name: "皂市镇",
                shortName: "皂市镇",
                children: [],
              },
              {
                id: 429622,
                name: "胡市镇",
                shortName: "胡市镇",
                children: [],
              },
              {
                id: 429623,
                name: "石河镇",
                shortName: "石河镇",
                children: [],
              },
              {
                id: 429624,
                name: "净潭乡",
                shortName: "净潭乡",
                children: [],
              },
              {
                id: 429625,
                name: "蒋湖农场",
                shortName: "蒋湖农场",
                children: [],
              },
              {
                id: 429626,
                name: "白茅湖农场",
                shortName: "白茅湖农场",
                children: [],
              },
              {
                id: 429627,
                name: "沉湖林业科技示范区",
                shortName: "沉湖林业科技示范区",
                children: [],
              },
              {
                id: 429628,
                name: "天门工业园",
                shortName: "天门工业园",
                children: [],
              },
              {
                id: 429629,
                name: "侨乡街道开发区",
                shortName: "侨乡街道开发区",
                children: [],
              },
            ],
          },
          {
            id: 429021,
            name: "神农架林区",
            shortName: "神农架",
            children: [
              {
                id: 429022,
                name: "松柏镇",
                shortName: "松柏镇",
                children: [],
              },
              {
                id: 429023,
                name: "阳日镇",
                shortName: "阳日镇",
                children: [],
              },
              {
                id: 429024,
                name: "木鱼镇",
                shortName: "木鱼镇",
                children: [],
              },
              {
                id: 429025,
                name: "红坪镇",
                shortName: "红坪镇",
                children: [],
              },
              {
                id: 429026,
                name: "新华镇",
                shortName: "新华镇",
                children: [],
              },
              {
                id: 429027,
                name: "大九湖",
                shortName: "大九湖",
                children: [],
              },
              {
                id: 429028,
                name: "宋洛",
                shortName: "宋洛",
                children: [],
              },
              {
                id: 429029,
                name: "下谷坪乡",
                shortName: "下谷坪乡",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 430000,
        name: "湖南省",
        shortName: "湖南",
        children: [
          {
            id: 430100,
            name: "长沙市",
            shortName: "长沙",
            children: [
              {
                id: 430102,
                name: "芙蓉区",
                shortName: "芙蓉",
                children: [],
              },
              {
                id: 430103,
                name: "天心区",
                shortName: "天心",
                children: [],
              },
              {
                id: 430104,
                name: "岳麓区",
                shortName: "岳麓",
                children: [],
              },
              {
                id: 430105,
                name: "开福区",
                shortName: "开福",
                children: [],
              },
              {
                id: 430111,
                name: "雨花区",
                shortName: "雨花",
                children: [],
              },
              {
                id: 430112,
                name: "望城区",
                shortName: "望城",
                children: [],
              },
              {
                id: 430121,
                name: "长沙县",
                shortName: "长沙",
                children: [],
              },
              {
                id: 430124,
                name: "宁乡市",
                shortName: "宁乡",
                children: [],
              },
              {
                id: 430181,
                name: "浏阳市",
                shortName: "浏阳",
                children: [],
              },
              {
                id: 430182,
                name: "湘江新区",
                shortName: "湘江新区",
                children: [],
              },
            ],
          },
          {
            id: 430200,
            name: "株洲市",
            shortName: "株洲",
            children: [
              {
                id: 430202,
                name: "荷塘区",
                shortName: "荷塘",
                children: [],
              },
              {
                id: 430203,
                name: "芦淞区",
                shortName: "芦淞",
                children: [],
              },
              {
                id: 430204,
                name: "石峰区",
                shortName: "石峰",
                children: [],
              },
              {
                id: 430211,
                name: "天元区",
                shortName: "天元",
                children: [],
              },
              {
                id: 430221,
                name: "株洲县",
                shortName: "株洲",
                children: [],
              },
              {
                id: 430223,
                name: "攸县",
                shortName: "攸县",
                children: [],
              },
              {
                id: 430224,
                name: "茶陵县",
                shortName: "茶陵",
                children: [],
              },
              {
                id: 430225,
                name: "炎陵县",
                shortName: "炎陵",
                children: [],
              },
              {
                id: 430281,
                name: "醴陵市",
                shortName: "醴陵",
                children: [],
              },
            ],
          },
          {
            id: 430300,
            name: "湘潭市",
            shortName: "湘潭",
            children: [
              {
                id: 430302,
                name: "雨湖区",
                shortName: "雨湖",
                children: [],
              },
              {
                id: 430304,
                name: "岳塘区",
                shortName: "岳塘",
                children: [],
              },
              {
                id: 430321,
                name: "湘潭县",
                shortName: "湘潭",
                children: [],
              },
              {
                id: 430381,
                name: "湘乡市",
                shortName: "湘乡",
                children: [],
              },
              {
                id: 430382,
                name: "韶山市",
                shortName: "韶山",
                children: [],
              },
              {
                id: 430383,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 430400,
            name: "衡阳市",
            shortName: "衡阳",
            children: [
              {
                id: 430405,
                name: "珠晖区",
                shortName: "珠晖",
                children: [],
              },
              {
                id: 430406,
                name: "雁峰区",
                shortName: "雁峰",
                children: [],
              },
              {
                id: 430407,
                name: "石鼓区",
                shortName: "石鼓",
                children: [],
              },
              {
                id: 430408,
                name: "蒸湘区",
                shortName: "蒸湘",
                children: [],
              },
              {
                id: 430412,
                name: "南岳区",
                shortName: "南岳",
                children: [],
              },
              {
                id: 430421,
                name: "衡阳县",
                shortName: "衡阳",
                children: [],
              },
              {
                id: 430422,
                name: "衡南县",
                shortName: "衡南",
                children: [],
              },
              {
                id: 430423,
                name: "衡山县",
                shortName: "衡山",
                children: [],
              },
              {
                id: 430424,
                name: "衡东县",
                shortName: "衡东",
                children: [],
              },
              {
                id: 430426,
                name: "祁东县",
                shortName: "祁东",
                children: [],
              },
              {
                id: 430481,
                name: "耒阳市",
                shortName: "耒阳",
                children: [],
              },
              {
                id: 430482,
                name: "常宁市",
                shortName: "常宁",
                children: [],
              },
              {
                id: 430483,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 430500,
            name: "邵阳市",
            shortName: "邵阳",
            children: [
              {
                id: 430502,
                name: "双清区",
                shortName: "双清",
                children: [],
              },
              {
                id: 430503,
                name: "大祥区",
                shortName: "大祥",
                children: [],
              },
              {
                id: 430511,
                name: "北塔区",
                shortName: "北塔",
                children: [],
              },
              {
                id: 430521,
                name: "邵东县",
                shortName: "邵东",
                children: [],
              },
              {
                id: 430522,
                name: "新邵县",
                shortName: "新邵",
                children: [],
              },
              {
                id: 430523,
                name: "邵阳县",
                shortName: "邵阳",
                children: [],
              },
              {
                id: 430524,
                name: "隆回县",
                shortName: "隆回",
                children: [],
              },
              {
                id: 430525,
                name: "洞口县",
                shortName: "洞口",
                children: [],
              },
              {
                id: 430527,
                name: "绥宁县",
                shortName: "绥宁",
                children: [],
              },
              {
                id: 430528,
                name: "新宁县",
                shortName: "新宁",
                children: [],
              },
              {
                id: 430529,
                name: "城步苗族自治县",
                shortName: "城步",
                children: [],
              },
              {
                id: 430581,
                name: "武冈市",
                shortName: "武冈",
                children: [],
              },
            ],
          },
          {
            id: 430600,
            name: "岳阳市",
            shortName: "岳阳",
            children: [
              {
                id: 430602,
                name: "岳阳楼区",
                shortName: "岳阳楼",
                children: [],
              },
              {
                id: 430603,
                name: "云溪区",
                shortName: "云溪",
                children: [],
              },
              {
                id: 430611,
                name: "君山区",
                shortName: "君山",
                children: [],
              },
              {
                id: 430621,
                name: "岳阳县",
                shortName: "岳阳",
                children: [],
              },
              {
                id: 430623,
                name: "华容县",
                shortName: "华容",
                children: [],
              },
              {
                id: 430624,
                name: "湘阴县",
                shortName: "湘阴",
                children: [],
              },
              {
                id: 430626,
                name: "平江县",
                shortName: "平江",
                children: [],
              },
              {
                id: 430681,
                name: "汨罗市",
                shortName: "汨罗",
                children: [],
              },
              {
                id: 430682,
                name: "临湘市",
                shortName: "临湘",
                children: [],
              },
            ],
          },
          {
            id: 430700,
            name: "常德市",
            shortName: "常德",
            children: [
              {
                id: 430702,
                name: "武陵区",
                shortName: "武陵",
                children: [],
              },
              {
                id: 430703,
                name: "鼎城区",
                shortName: "鼎城",
                children: [],
              },
              {
                id: 430721,
                name: "安乡县",
                shortName: "安乡",
                children: [],
              },
              {
                id: 430722,
                name: "汉寿县",
                shortName: "汉寿",
                children: [],
              },
              {
                id: 430723,
                name: "澧县",
                shortName: "澧县",
                children: [],
              },
              {
                id: 430724,
                name: "临澧县",
                shortName: "临澧",
                children: [],
              },
              {
                id: 430725,
                name: "桃源县",
                shortName: "桃源",
                children: [],
              },
              {
                id: 430726,
                name: "石门县",
                shortName: "石门",
                children: [],
              },
              {
                id: 430781,
                name: "津市市",
                shortName: "津市",
                children: [],
              },
            ],
          },
          {
            id: 430800,
            name: "张家界市",
            shortName: "张家界",
            children: [
              {
                id: 430802,
                name: "永定区",
                shortName: "永定",
                children: [],
              },
              {
                id: 430811,
                name: "武陵源区",
                shortName: "武陵源",
                children: [],
              },
              {
                id: 430821,
                name: "慈利县",
                shortName: "慈利",
                children: [],
              },
              {
                id: 430822,
                name: "桑植县",
                shortName: "桑植",
                children: [],
              },
            ],
          },
          {
            id: 430900,
            name: "益阳市",
            shortName: "益阳",
            children: [
              {
                id: 430902,
                name: "资阳区",
                shortName: "资阳",
                children: [],
              },
              {
                id: 430903,
                name: "赫山区",
                shortName: "赫山",
                children: [],
              },
              {
                id: 430921,
                name: "南县",
                shortName: "南县",
                children: [],
              },
              {
                id: 430922,
                name: "桃江县",
                shortName: "桃江",
                children: [],
              },
              {
                id: 430923,
                name: "安化县",
                shortName: "安化",
                children: [],
              },
              {
                id: 430981,
                name: "沅江市",
                shortName: "沅江",
                children: [],
              },
            ],
          },
          {
            id: 431000,
            name: "郴州市",
            shortName: "郴州",
            children: [
              {
                id: 431002,
                name: "北湖区",
                shortName: "北湖",
                children: [],
              },
              {
                id: 431003,
                name: "苏仙区",
                shortName: "苏仙",
                children: [],
              },
              {
                id: 431021,
                name: "桂阳县",
                shortName: "桂阳",
                children: [],
              },
              {
                id: 431022,
                name: "宜章县",
                shortName: "宜章",
                children: [],
              },
              {
                id: 431023,
                name: "永兴县",
                shortName: "永兴",
                children: [],
              },
              {
                id: 431024,
                name: "嘉禾县",
                shortName: "嘉禾",
                children: [],
              },
              {
                id: 431025,
                name: "临武县",
                shortName: "临武",
                children: [],
              },
              {
                id: 431026,
                name: "汝城县",
                shortName: "汝城",
                children: [],
              },
              {
                id: 431027,
                name: "桂东县",
                shortName: "桂东",
                children: [],
              },
              {
                id: 431028,
                name: "安仁县",
                shortName: "安仁",
                children: [],
              },
              {
                id: 431081,
                name: "资兴市",
                shortName: "资兴",
                children: [],
              },
            ],
          },
          {
            id: 431100,
            name: "永州市",
            shortName: "永州",
            children: [
              {
                id: 431102,
                name: "零陵区",
                shortName: "零陵",
                children: [],
              },
              {
                id: 431103,
                name: "冷水滩区",
                shortName: "冷水滩",
                children: [],
              },
              {
                id: 431121,
                name: "祁阳县",
                shortName: "祁阳",
                children: [],
              },
              {
                id: 431122,
                name: "东安县",
                shortName: "东安",
                children: [],
              },
              {
                id: 431123,
                name: "双牌县",
                shortName: "双牌",
                children: [],
              },
              {
                id: 431124,
                name: "道县",
                shortName: "道县",
                children: [],
              },
              {
                id: 431125,
                name: "江永县",
                shortName: "江永",
                children: [],
              },
              {
                id: 431126,
                name: "宁远县",
                shortName: "宁远",
                children: [],
              },
              {
                id: 431127,
                name: "蓝山县",
                shortName: "蓝山",
                children: [],
              },
              {
                id: 431128,
                name: "新田县",
                shortName: "新田",
                children: [],
              },
              {
                id: 431129,
                name: "江华瑶族自治县",
                shortName: "江华",
                children: [],
              },
            ],
          },
          {
            id: 431200,
            name: "怀化市",
            shortName: "怀化",
            children: [
              {
                id: 431202,
                name: "鹤城区",
                shortName: "鹤城",
                children: [],
              },
              {
                id: 431221,
                name: "中方县",
                shortName: "中方",
                children: [],
              },
              {
                id: 431222,
                name: "沅陵县",
                shortName: "沅陵",
                children: [],
              },
              {
                id: 431223,
                name: "辰溪县",
                shortName: "辰溪",
                children: [],
              },
              {
                id: 431224,
                name: "溆浦县",
                shortName: "溆浦",
                children: [],
              },
              {
                id: 431225,
                name: "会同县",
                shortName: "会同",
                children: [],
              },
              {
                id: 431226,
                name: "麻阳苗族自治县",
                shortName: "麻阳",
                children: [],
              },
              {
                id: 431227,
                name: "新晃侗族自治县",
                shortName: "新晃",
                children: [],
              },
              {
                id: 431228,
                name: "芷江侗族自治县",
                shortName: "芷江",
                children: [],
              },
              {
                id: 431229,
                name: "靖州苗族侗族自治县",
                shortName: "靖州",
                children: [],
              },
              {
                id: 431230,
                name: "通道侗族自治县",
                shortName: "通道",
                children: [],
              },
              {
                id: 431281,
                name: "洪江市",
                shortName: "洪江",
                children: [],
              },
            ],
          },
          {
            id: 431300,
            name: "娄底市",
            shortName: "娄底",
            children: [
              {
                id: 431302,
                name: "娄星区",
                shortName: "娄星",
                children: [],
              },
              {
                id: 431321,
                name: "双峰县",
                shortName: "双峰",
                children: [],
              },
              {
                id: 431322,
                name: "新化县",
                shortName: "新化",
                children: [],
              },
              {
                id: 431381,
                name: "冷水江市",
                shortName: "冷水江",
                children: [],
              },
              {
                id: 431382,
                name: "涟源市",
                shortName: "涟源",
                children: [],
              },
            ],
          },
          {
            id: 433100,
            name: "湘西土家族苗族自治州",
            shortName: "湘西",
            children: [
              {
                id: 433101,
                name: "吉首市",
                shortName: "吉首",
                children: [],
              },
              {
                id: 433122,
                name: "泸溪县",
                shortName: "泸溪",
                children: [],
              },
              {
                id: 433123,
                name: "凤凰县",
                shortName: "凤凰",
                children: [],
              },
              {
                id: 433124,
                name: "花垣县",
                shortName: "花垣",
                children: [],
              },
              {
                id: 433125,
                name: "保靖县",
                shortName: "保靖",
                children: [],
              },
              {
                id: 433126,
                name: "古丈县",
                shortName: "古丈",
                children: [],
              },
              {
                id: 433127,
                name: "永顺县",
                shortName: "永顺",
                children: [],
              },
              {
                id: 433130,
                name: "龙山县",
                shortName: "龙山",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 440000,
        name: "广东省",
        shortName: "广东",
        children: [
          {
            id: 440100,
            name: "广州市",
            shortName: "广州",
            children: [
              {
                id: 440103,
                name: "荔湾区",
                shortName: "荔湾",
                children: [],
              },
              {
                id: 440104,
                name: "越秀区",
                shortName: "越秀",
                children: [],
              },
              {
                id: 440105,
                name: "海珠区",
                shortName: "海珠",
                children: [],
              },
              {
                id: 440106,
                name: "天河区",
                shortName: "天河",
                children: [],
              },
              {
                id: 440111,
                name: "白云区",
                shortName: "白云",
                children: [],
              },
              {
                id: 440112,
                name: "黄埔区",
                shortName: "黄埔",
                children: [],
              },
              {
                id: 440113,
                name: "番禺区",
                shortName: "番禺",
                children: [],
              },
              {
                id: 440114,
                name: "花都区",
                shortName: "花都",
                children: [],
              },
              {
                id: 440115,
                name: "南沙区",
                shortName: "南沙",
                children: [],
              },
              {
                id: 440117,
                name: "从化区",
                shortName: "从化",
                children: [],
              },
              {
                id: 440118,
                name: "增城区",
                shortName: "增城",
                children: [],
              },
            ],
          },
          {
            id: 440200,
            name: "韶关市",
            shortName: "韶关",
            children: [
              {
                id: 440203,
                name: "武江区",
                shortName: "武江",
                children: [],
              },
              {
                id: 440204,
                name: "浈江区",
                shortName: "浈江",
                children: [],
              },
              {
                id: 440205,
                name: "曲江区",
                shortName: "曲江",
                children: [],
              },
              {
                id: 440222,
                name: "始兴县",
                shortName: "始兴",
                children: [],
              },
              {
                id: 440224,
                name: "仁化县",
                shortName: "仁化",
                children: [],
              },
              {
                id: 440229,
                name: "翁源县",
                shortName: "翁源",
                children: [],
              },
              {
                id: 440232,
                name: "乳源瑶族自治县",
                shortName: "乳源",
                children: [],
              },
              {
                id: 440233,
                name: "新丰县",
                shortName: "新丰",
                children: [],
              },
              {
                id: 440281,
                name: "乐昌市",
                shortName: "乐昌",
                children: [],
              },
              {
                id: 440282,
                name: "南雄市",
                shortName: "南雄",
                children: [],
              },
            ],
          },
          {
            id: 440300,
            name: "深圳市",
            shortName: "深圳",
            children: [
              {
                id: 440303,
                name: "罗湖区",
                shortName: "罗湖",
                children: [],
              },
              {
                id: 440304,
                name: "福田区",
                shortName: "福田",
                children: [],
              },
              {
                id: 440305,
                name: "南山区",
                shortName: "南山",
                children: [],
              },
              {
                id: 440306,
                name: "宝安区",
                shortName: "宝安",
                children: [],
              },
              {
                id: 440307,
                name: "龙岗区",
                shortName: "龙岗",
                children: [],
              },
              {
                id: 440308,
                name: "盐田区",
                shortName: "盐田",
                children: [],
              },
              {
                id: 440309,
                name: "龙华区",
                shortName: "龙华",
                children: [],
              },
              {
                id: 440310,
                name: "坪山区",
                shortName: "坪山",
                children: [],
              },
              {
                id: 440311,
                name: "光明新区",
                shortName: "光明新区",
                children: [],
              },
              {
                id: 440312,
                name: "大鹏新区",
                shortName: "大鹏新区",
                children: [],
              },
            ],
          },
          {
            id: 440400,
            name: "珠海市",
            shortName: "珠海",
            children: [
              {
                id: 440402,
                name: "香洲区",
                shortName: "香洲",
                children: [],
              },
              {
                id: 440403,
                name: "斗门区",
                shortName: "斗门",
                children: [],
              },
              {
                id: 440404,
                name: "金湾区",
                shortName: "金湾",
                children: [],
              },
              {
                id: 440405,
                name: "横琴新区",
                shortName: "横琴新区",
                children: [],
              },
              {
                id: 440406,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 440500,
            name: "汕头市",
            shortName: "汕头",
            children: [
              {
                id: 440507,
                name: "龙湖区",
                shortName: "龙湖",
                children: [],
              },
              {
                id: 440511,
                name: "金平区",
                shortName: "金平",
                children: [],
              },
              {
                id: 440512,
                name: "濠江区",
                shortName: "濠江",
                children: [],
              },
              {
                id: 440513,
                name: "潮阳区",
                shortName: "潮阳",
                children: [],
              },
              {
                id: 440514,
                name: "潮南区",
                shortName: "潮南",
                children: [],
              },
              {
                id: 440515,
                name: "澄海区",
                shortName: "澄海",
                children: [],
              },
              {
                id: 440523,
                name: "南澳县",
                shortName: "南澳",
                children: [],
              },
            ],
          },
          {
            id: 440600,
            name: "佛山市",
            shortName: "佛山",
            children: [
              {
                id: 440604,
                name: "禅城区",
                shortName: "禅城",
                children: [],
              },
              {
                id: 440605,
                name: "南海区",
                shortName: "南海",
                children: [],
              },
              {
                id: 440606,
                name: "顺德区",
                shortName: "顺德",
                children: [],
              },
              {
                id: 440607,
                name: "三水区",
                shortName: "三水",
                children: [],
              },
              {
                id: 440608,
                name: "高明区",
                shortName: "高明",
                children: [],
              },
            ],
          },
          {
            id: 440700,
            name: "江门市",
            shortName: "江门",
            children: [
              {
                id: 440703,
                name: "蓬江区",
                shortName: "蓬江",
                children: [],
              },
              {
                id: 440704,
                name: "江海区",
                shortName: "江海",
                children: [],
              },
              {
                id: 440705,
                name: "新会区",
                shortName: "新会",
                children: [],
              },
              {
                id: 440781,
                name: "台山市",
                shortName: "台山",
                children: [],
              },
              {
                id: 440783,
                name: "开平市",
                shortName: "开平",
                children: [],
              },
              {
                id: 440784,
                name: "鹤山市",
                shortName: "鹤山",
                children: [],
              },
              {
                id: 440785,
                name: "恩平市",
                shortName: "恩平",
                children: [],
              },
            ],
          },
          {
            id: 440800,
            name: "湛江市",
            shortName: "湛江",
            children: [
              {
                id: 440802,
                name: "赤坎区",
                shortName: "赤坎",
                children: [],
              },
              {
                id: 440803,
                name: "霞山区",
                shortName: "霞山",
                children: [],
              },
              {
                id: 440804,
                name: "坡头区",
                shortName: "坡头",
                children: [],
              },
              {
                id: 440811,
                name: "麻章区",
                shortName: "麻章",
                children: [],
              },
              {
                id: 440823,
                name: "遂溪县",
                shortName: "遂溪",
                children: [],
              },
              {
                id: 440825,
                name: "徐闻县",
                shortName: "徐闻",
                children: [],
              },
              {
                id: 440881,
                name: "廉江市",
                shortName: "廉江",
                children: [],
              },
              {
                id: 440882,
                name: "雷州市",
                shortName: "雷州",
                children: [],
              },
              {
                id: 440883,
                name: "吴川市",
                shortName: "吴川",
                children: [],
              },
              {
                id: 440884,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 440900,
            name: "茂名市",
            shortName: "茂名",
            children: [
              {
                id: 440902,
                name: "茂南区",
                shortName: "茂南",
                children: [],
              },
              {
                id: 440904,
                name: "电白区",
                shortName: "电白",
                children: [],
              },
              {
                id: 440981,
                name: "高州市",
                shortName: "高州",
                children: [],
              },
              {
                id: 440982,
                name: "化州市",
                shortName: "化州",
                children: [],
              },
              {
                id: 440983,
                name: "信宜市",
                shortName: "信宜",
                children: [],
              },
            ],
          },
          {
            id: 441200,
            name: "肇庆市",
            shortName: "肇庆",
            children: [
              {
                id: 441202,
                name: "端州区",
                shortName: "端州",
                children: [],
              },
              {
                id: 441203,
                name: "鼎湖区",
                shortName: "鼎湖",
                children: [],
              },
              {
                id: 441204,
                name: "高要区",
                shortName: "高要",
                children: [],
              },
              {
                id: 441223,
                name: "广宁县",
                shortName: "广宁",
                children: [],
              },
              {
                id: 441224,
                name: "怀集县",
                shortName: "怀集",
                children: [],
              },
              {
                id: 441225,
                name: "封开县",
                shortName: "封开",
                children: [],
              },
              {
                id: 441226,
                name: "德庆县",
                shortName: "德庆",
                children: [],
              },
              {
                id: 441284,
                name: "四会市",
                shortName: "四会",
                children: [],
              },
            ],
          },
          {
            id: 441300,
            name: "惠州市",
            shortName: "惠州",
            children: [
              {
                id: 441302,
                name: "惠城区",
                shortName: "惠城",
                children: [],
              },
              {
                id: 441303,
                name: "惠阳区",
                shortName: "惠阳",
                children: [],
              },
              {
                id: 441322,
                name: "博罗县",
                shortName: "博罗",
                children: [],
              },
              {
                id: 441323,
                name: "惠东县",
                shortName: "惠东",
                children: [],
              },
              {
                id: 441324,
                name: "龙门县",
                shortName: "龙门",
                children: [],
              },
              {
                id: 441325,
                name: "大亚湾区",
                shortName: "大亚湾",
                children: [],
              },
            ],
          },
          {
            id: 441400,
            name: "梅州市",
            shortName: "梅州",
            children: [
              {
                id: 441402,
                name: "梅江区",
                shortName: "梅江",
                children: [],
              },
              {
                id: 441403,
                name: "梅县区",
                shortName: "梅县",
                children: [],
              },
              {
                id: 441422,
                name: "大埔县",
                shortName: "大埔",
                children: [],
              },
              {
                id: 441423,
                name: "丰顺县",
                shortName: "丰顺",
                children: [],
              },
              {
                id: 441424,
                name: "五华县",
                shortName: "五华",
                children: [],
              },
              {
                id: 441426,
                name: "平远县",
                shortName: "平远",
                children: [],
              },
              {
                id: 441427,
                name: "蕉岭县",
                shortName: "蕉岭",
                children: [],
              },
              {
                id: 441481,
                name: "兴宁市",
                shortName: "兴宁",
                children: [],
              },
            ],
          },
          {
            id: 441500,
            name: "汕尾市",
            shortName: "汕尾",
            children: [
              {
                id: 441502,
                name: "城区",
                shortName: "城区",
                children: [],
              },
              {
                id: 441521,
                name: "海丰县",
                shortName: "海丰",
                children: [],
              },
              {
                id: 441523,
                name: "陆河县",
                shortName: "陆河",
                children: [],
              },
              {
                id: 441581,
                name: "陆丰市",
                shortName: "陆丰",
                children: [],
              },
            ],
          },
          {
            id: 441600,
            name: "河源市",
            shortName: "河源",
            children: [
              {
                id: 441602,
                name: "源城区",
                shortName: "源城",
                children: [],
              },
              {
                id: 441621,
                name: "紫金县",
                shortName: "紫金",
                children: [],
              },
              {
                id: 441622,
                name: "龙川县",
                shortName: "龙川",
                children: [],
              },
              {
                id: 441623,
                name: "连平县",
                shortName: "连平",
                children: [],
              },
              {
                id: 441624,
                name: "和平县",
                shortName: "和平",
                children: [],
              },
              {
                id: 441625,
                name: "东源县",
                shortName: "东源",
                children: [],
              },
            ],
          },
          {
            id: 441700,
            name: "阳江市",
            shortName: "阳江",
            children: [
              {
                id: 441702,
                name: "江城区",
                shortName: "江城",
                children: [],
              },
              {
                id: 441704,
                name: "阳东区",
                shortName: "阳东",
                children: [],
              },
              {
                id: 441721,
                name: "阳西县",
                shortName: "阳西",
                children: [],
              },
              {
                id: 441781,
                name: "阳春市",
                shortName: "阳春",
                children: [],
              },
            ],
          },
          {
            id: 441800,
            name: "清远市",
            shortName: "清远",
            children: [
              {
                id: 441802,
                name: "清城区",
                shortName: "清城",
                children: [],
              },
              {
                id: 441803,
                name: "清新区",
                shortName: "清新",
                children: [],
              },
              {
                id: 441821,
                name: "佛冈县",
                shortName: "佛冈",
                children: [],
              },
              {
                id: 441823,
                name: "阳山县",
                shortName: "阳山",
                children: [],
              },
              {
                id: 441825,
                name: "连山壮族瑶族自治县",
                shortName: "连山",
                children: [],
              },
              {
                id: 441826,
                name: "连南瑶族自治县",
                shortName: "连南",
                children: [],
              },
              {
                id: 441881,
                name: "英德市",
                shortName: "英德",
                children: [],
              },
              {
                id: 441882,
                name: "连州市",
                shortName: "连州",
                children: [],
              },
            ],
          },
          {
            id: 441900,
            name: "东莞市",
            shortName: "东莞",
            children: [
              {
                id: 441901,
                name: "莞城区",
                shortName: "莞城",
                children: [],
              },
              {
                id: 441902,
                name: "南城区",
                shortName: "南城",
                children: [],
              },
              {
                id: 441903,
                name: "东城区",
                shortName: "东城",
                children: [],
              },
              {
                id: 441904,
                name: "万江区",
                shortName: "万江",
                children: [],
              },
              {
                id: 441905,
                name: "石碣镇",
                shortName: "石碣",
                children: [],
              },
              {
                id: 441906,
                name: "石龙镇",
                shortName: "石龙",
                children: [],
              },
              {
                id: 441907,
                name: "茶山镇",
                shortName: "茶山",
                children: [],
              },
              {
                id: 441908,
                name: "石排镇",
                shortName: "石排",
                children: [],
              },
              {
                id: 441909,
                name: "企石镇",
                shortName: "企石",
                children: [],
              },
              {
                id: 441910,
                name: "横沥镇",
                shortName: "横沥",
                children: [],
              },
              {
                id: 441911,
                name: "桥头镇",
                shortName: "桥头",
                children: [],
              },
              {
                id: 441912,
                name: "谢岗镇",
                shortName: "谢岗",
                children: [],
              },
              {
                id: 441913,
                name: "东坑镇",
                shortName: "东坑",
                children: [],
              },
              {
                id: 441914,
                name: "常平镇",
                shortName: "常平",
                children: [],
              },
              {
                id: 441915,
                name: "寮步镇",
                shortName: "寮步",
                children: [],
              },
              {
                id: 441916,
                name: "大朗镇",
                shortName: "大朗",
                children: [],
              },
              {
                id: 441917,
                name: "麻涌镇",
                shortName: "麻涌",
                children: [],
              },
              {
                id: 441918,
                name: "中堂镇",
                shortName: "中堂",
                children: [],
              },
              {
                id: 441919,
                name: "高埗镇",
                shortName: "高埗",
                children: [],
              },
              {
                id: 441920,
                name: "樟木头镇",
                shortName: "樟木头",
                children: [],
              },
              {
                id: 441921,
                name: "大岭山镇",
                shortName: "大岭山",
                children: [],
              },
              {
                id: 441922,
                name: "望牛墩镇",
                shortName: "望牛墩",
                children: [],
              },
              {
                id: 441923,
                name: "黄江镇",
                shortName: "黄江",
                children: [],
              },
              {
                id: 441924,
                name: "洪梅镇",
                shortName: "洪梅",
                children: [],
              },
              {
                id: 441925,
                name: "清溪镇",
                shortName: "清溪",
                children: [],
              },
              {
                id: 441926,
                name: "沙田镇",
                shortName: "沙田",
                children: [],
              },
              {
                id: 441927,
                name: "道滘镇",
                shortName: "道滘",
                children: [],
              },
              {
                id: 441928,
                name: "塘厦镇",
                shortName: "塘厦",
                children: [],
              },
              {
                id: 441929,
                name: "虎门镇",
                shortName: "虎门",
                children: [],
              },
              {
                id: 441930,
                name: "厚街镇",
                shortName: "厚街",
                children: [],
              },
              {
                id: 441931,
                name: "凤岗镇",
                shortName: "凤岗",
                children: [],
              },
              {
                id: 441932,
                name: "长安镇",
                shortName: "长安",
                children: [],
              },
              {
                id: 441933,
                name: "松山湖高新区",
                shortName: "松山湖高新区",
                children: [],
              },
            ],
          },
          {
            id: 442000,
            name: "中山市",
            shortName: "中山",
            children: [
              {
                id: 442001,
                name: "石岐区",
                shortName: "石岐",
                children: [],
              },
              {
                id: 442002,
                name: "东区",
                shortName: "东区",
                children: [],
              },
              {
                id: 442003,
                name: "西区",
                shortName: "东区",
                children: [],
              },
              {
                id: 442004,
                name: "南区",
                shortName: "南区",
                children: [],
              },
              {
                id: 442005,
                name: "五桂山区",
                shortName: "五桂山",
                children: [],
              },
              {
                id: 442006,
                name: "火炬开发区",
                shortName: "火炬",
                children: [],
              },
              {
                id: 442007,
                name: "黄圃镇",
                shortName: "黄圃",
                children: [],
              },
              {
                id: 442008,
                name: "南头镇",
                shortName: "南头",
                children: [],
              },
              {
                id: 442009,
                name: "东凤镇",
                shortName: "东凤",
                children: [],
              },
              {
                id: 442010,
                name: "阜沙镇",
                shortName: "阜沙",
                children: [],
              },
              {
                id: 442011,
                name: "小榄镇",
                shortName: "小榄",
                children: [],
              },
              {
                id: 442012,
                name: "东升镇",
                shortName: "东升",
                children: [],
              },
              {
                id: 442013,
                name: "古镇镇",
                shortName: "古镇",
                children: [],
              },
              {
                id: 442014,
                name: "横栏镇",
                shortName: "横栏",
                children: [],
              },
              {
                id: 442015,
                name: "三角镇",
                shortName: "三角",
                children: [],
              },
              {
                id: 442016,
                name: "民众镇",
                shortName: "民众",
                children: [],
              },
              {
                id: 442017,
                name: "南朗镇",
                shortName: "南朗",
                children: [],
              },
              {
                id: 442018,
                name: "港口镇",
                shortName: "港口",
                children: [],
              },
              {
                id: 442019,
                name: "大涌镇",
                shortName: "大涌",
                children: [],
              },
              {
                id: 442020,
                name: "沙溪镇",
                shortName: "沙溪",
                children: [],
              },
              {
                id: 442021,
                name: "三乡镇",
                shortName: "三乡",
                children: [],
              },
              {
                id: 442022,
                name: "板芙镇",
                shortName: "板芙",
                children: [],
              },
              {
                id: 442023,
                name: "神湾镇",
                shortName: "神湾",
                children: [],
              },
              {
                id: 442024,
                name: "坦洲镇",
                shortName: "坦洲",
                children: [],
              },
            ],
          },
          {
            id: 445100,
            name: "潮州市",
            shortName: "潮州",
            children: [
              {
                id: 445102,
                name: "湘桥区",
                shortName: "湘桥",
                children: [],
              },
              {
                id: 445103,
                name: "潮安区",
                shortName: "潮安",
                children: [],
              },
              {
                id: 445122,
                name: "饶平县",
                shortName: "饶平",
                children: [],
              },
            ],
          },
          {
            id: 445200,
            name: "揭阳市",
            shortName: "揭阳",
            children: [
              {
                id: 445202,
                name: "榕城区",
                shortName: "榕城",
                children: [],
              },
              {
                id: 445203,
                name: "揭东区",
                shortName: "揭东",
                children: [],
              },
              {
                id: 445222,
                name: "揭西县",
                shortName: "揭西",
                children: [],
              },
              {
                id: 445224,
                name: "惠来县",
                shortName: "惠来",
                children: [],
              },
              {
                id: 445281,
                name: "普宁市",
                shortName: "普宁",
                children: [],
              },
            ],
          },
          {
            id: 445300,
            name: "云浮市",
            shortName: "云浮",
            children: [
              {
                id: 445302,
                name: "云城区",
                shortName: "云城",
                children: [],
              },
              {
                id: 445303,
                name: "云安区",
                shortName: "云安",
                children: [],
              },
              {
                id: 445321,
                name: "新兴县",
                shortName: "新兴",
                children: [],
              },
              {
                id: 445322,
                name: "郁南县",
                shortName: "郁南",
                children: [],
              },
              {
                id: 445381,
                name: "罗定市",
                shortName: "罗定",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 450000,
        name: "广西壮族自治区",
        shortName: "广西",
        children: [
          {
            id: 450100,
            name: "南宁市",
            shortName: "南宁",
            children: [
              {
                id: 450102,
                name: "兴宁区",
                shortName: "兴宁",
                children: [],
              },
              {
                id: 450103,
                name: "青秀区",
                shortName: "青秀",
                children: [],
              },
              {
                id: 450105,
                name: "江南区",
                shortName: "江南",
                children: [],
              },
              {
                id: 450107,
                name: "西乡塘区",
                shortName: "西乡塘",
                children: [],
              },
              {
                id: 450108,
                name: "良庆区",
                shortName: "良庆",
                children: [],
              },
              {
                id: 450109,
                name: "邕宁区",
                shortName: "邕宁",
                children: [],
              },
              {
                id: 450110,
                name: "武鸣区",
                shortName: "武鸣",
                children: [],
              },
              {
                id: 450123,
                name: "隆安县",
                shortName: "隆安",
                children: [],
              },
              {
                id: 450124,
                name: "马山县",
                shortName: "马山",
                children: [],
              },
              {
                id: 450125,
                name: "上林县",
                shortName: "上林",
                children: [],
              },
              {
                id: 450126,
                name: "宾阳县",
                shortName: "宾阳",
                children: [],
              },
              {
                id: 450127,
                name: "横县",
                shortName: "横县",
                children: [],
              },
              {
                id: 450128,
                name: "埌东新区",
                shortName: "埌东",
                children: [],
              },
            ],
          },
          {
            id: 450200,
            name: "柳州市",
            shortName: "柳州",
            children: [
              {
                id: 450202,
                name: "城中区",
                shortName: "城中",
                children: [],
              },
              {
                id: 450203,
                name: "鱼峰区",
                shortName: "鱼峰",
                children: [],
              },
              {
                id: 450204,
                name: "柳南区",
                shortName: "柳南",
                children: [],
              },
              {
                id: 450205,
                name: "柳北区",
                shortName: "柳北",
                children: [],
              },
              {
                id: 450206,
                name: "柳江区",
                shortName: "柳江",
                children: [],
              },
              {
                id: 450222,
                name: "柳城县",
                shortName: "柳城",
                children: [],
              },
              {
                id: 450223,
                name: "鹿寨县",
                shortName: "鹿寨",
                children: [],
              },
              {
                id: 450224,
                name: "融安县",
                shortName: "融安",
                children: [],
              },
              {
                id: 450225,
                name: "融水苗族自治县",
                shortName: "融水",
                children: [],
              },
              {
                id: 450226,
                name: "三江侗族自治县",
                shortName: "三江",
                children: [],
              },
              {
                id: 450227,
                name: "柳东新区",
                shortName: "柳东",
                children: [],
              },
            ],
          },
          {
            id: 450300,
            name: "桂林市",
            shortName: "桂林",
            children: [
              {
                id: 450302,
                name: "秀峰区",
                shortName: "秀峰",
                children: [],
              },
              {
                id: 450303,
                name: "叠彩区",
                shortName: "叠彩",
                children: [],
              },
              {
                id: 450304,
                name: "象山区",
                shortName: "象山",
                children: [],
              },
              {
                id: 450305,
                name: "七星区",
                shortName: "七星",
                children: [],
              },
              {
                id: 450311,
                name: "雁山区",
                shortName: "雁山",
                children: [],
              },
              {
                id: 450312,
                name: "临桂区",
                shortName: "临桂",
                children: [],
              },
              {
                id: 450321,
                name: "阳朔县",
                shortName: "阳朔",
                children: [],
              },
              {
                id: 450323,
                name: "灵川县",
                shortName: "灵川",
                children: [],
              },
              {
                id: 450324,
                name: "全州县",
                shortName: "全州",
                children: [],
              },
              {
                id: 450325,
                name: "兴安县",
                shortName: "兴安",
                children: [],
              },
              {
                id: 450326,
                name: "永福县",
                shortName: "永福",
                children: [],
              },
              {
                id: 450327,
                name: "灌阳县",
                shortName: "灌阳",
                children: [],
              },
              {
                id: 450328,
                name: "龙胜各族自治县",
                shortName: "龙胜",
                children: [],
              },
              {
                id: 450329,
                name: "资源县",
                shortName: "资源",
                children: [],
              },
              {
                id: 450330,
                name: "平乐县",
                shortName: "平乐",
                children: [],
              },
              {
                id: 450331,
                name: "荔浦县",
                shortName: "荔浦",
                children: [],
              },
              {
                id: 450332,
                name: "恭城瑶族自治县",
                shortName: "恭城",
                children: [],
              },
            ],
          },
          {
            id: 450400,
            name: "梧州市",
            shortName: "梧州",
            children: [
              {
                id: 450403,
                name: "万秀区",
                shortName: "万秀",
                children: [],
              },
              {
                id: 450405,
                name: "长洲区",
                shortName: "长洲",
                children: [],
              },
              {
                id: 450406,
                name: "龙圩区",
                shortName: "龙圩",
                children: [],
              },
              {
                id: 450421,
                name: "苍梧县",
                shortName: "苍梧",
                children: [],
              },
              {
                id: 450422,
                name: "藤县",
                shortName: "藤县",
                children: [],
              },
              {
                id: 450423,
                name: "蒙山县",
                shortName: "蒙山",
                children: [],
              },
              {
                id: 450481,
                name: "岑溪市",
                shortName: "岑溪",
                children: [],
              },
            ],
          },
          {
            id: 450500,
            name: "北海市",
            shortName: "北海",
            children: [
              {
                id: 450502,
                name: "海城区",
                shortName: "海城",
                children: [],
              },
              {
                id: 450503,
                name: "银海区",
                shortName: "银海",
                children: [],
              },
              {
                id: 450512,
                name: "铁山港区",
                shortName: "铁山港",
                children: [],
              },
              {
                id: 450521,
                name: "合浦县",
                shortName: "合浦",
                children: [],
              },
            ],
          },
          {
            id: 450600,
            name: "防城港市",
            shortName: "防城港",
            children: [
              {
                id: 450602,
                name: "港口区",
                shortName: "港口",
                children: [],
              },
              {
                id: 450603,
                name: "防城区",
                shortName: "防城",
                children: [],
              },
              {
                id: 450621,
                name: "上思县",
                shortName: "上思",
                children: [],
              },
              {
                id: 450681,
                name: "东兴市",
                shortName: "东兴",
                children: [],
              },
            ],
          },
          {
            id: 450700,
            name: "钦州市",
            shortName: "钦州",
            children: [
              {
                id: 450702,
                name: "钦南区",
                shortName: "钦南",
                children: [],
              },
              {
                id: 450703,
                name: "钦北区",
                shortName: "钦北",
                children: [],
              },
              {
                id: 450721,
                name: "灵山县",
                shortName: "灵山",
                children: [],
              },
              {
                id: 450722,
                name: "浦北县",
                shortName: "浦北",
                children: [],
              },
            ],
          },
          {
            id: 450800,
            name: "贵港市",
            shortName: "贵港",
            children: [
              {
                id: 450802,
                name: "港北区",
                shortName: "港北",
                children: [],
              },
              {
                id: 450803,
                name: "港南区",
                shortName: "港南",
                children: [],
              },
              {
                id: 450804,
                name: "覃塘区",
                shortName: "覃塘",
                children: [],
              },
              {
                id: 450821,
                name: "平南县",
                shortName: "平南",
                children: [],
              },
              {
                id: 450881,
                name: "桂平市",
                shortName: "桂平",
                children: [],
              },
            ],
          },
          {
            id: 450900,
            name: "玉林市",
            shortName: "玉林",
            children: [
              {
                id: 450902,
                name: "玉州区",
                shortName: "玉州",
                children: [],
              },
              {
                id: 450903,
                name: "福绵区",
                shortName: "福绵",
                children: [],
              },
              {
                id: 450921,
                name: "容县",
                shortName: "容县",
                children: [],
              },
              {
                id: 450922,
                name: "陆川县",
                shortName: "陆川",
                children: [],
              },
              {
                id: 450923,
                name: "博白县",
                shortName: "博白",
                children: [],
              },
              {
                id: 450924,
                name: "兴业县",
                shortName: "兴业",
                children: [],
              },
              {
                id: 450981,
                name: "北流市",
                shortName: "北流",
                children: [],
              },
              {
                id: 450982,
                name: "玉东新区",
                shortName: "玉东",
                children: [],
              },
              {
                id: 450983,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 451000,
            name: "百色市",
            shortName: "百色",
            children: [
              {
                id: 451002,
                name: "右江区",
                shortName: "右江",
                children: [],
              },
              {
                id: 451021,
                name: "田阳县",
                shortName: "田阳",
                children: [],
              },
              {
                id: 451022,
                name: "田东县",
                shortName: "田东",
                children: [],
              },
              {
                id: 451023,
                name: "平果县",
                shortName: "平果",
                children: [],
              },
              {
                id: 451024,
                name: "德保县",
                shortName: "德保",
                children: [],
              },
              {
                id: 451026,
                name: "那坡县",
                shortName: "那坡",
                children: [],
              },
              {
                id: 451027,
                name: "凌云县",
                shortName: "凌云",
                children: [],
              },
              {
                id: 451028,
                name: "乐业县",
                shortName: "乐业",
                children: [],
              },
              {
                id: 451029,
                name: "田林县",
                shortName: "田林",
                children: [],
              },
              {
                id: 451030,
                name: "西林县",
                shortName: "西林",
                children: [],
              },
              {
                id: 451031,
                name: "隆林各族自治县",
                shortName: "隆林",
                children: [],
              },
              {
                id: 451081,
                name: "靖西市",
                shortName: "靖西",
                children: [],
              },
            ],
          },
          {
            id: 451100,
            name: "贺州市",
            shortName: "贺州",
            children: [
              {
                id: 451102,
                name: "八步区",
                shortName: "八步",
                children: [],
              },
              {
                id: 451103,
                name: "平桂区",
                shortName: "平桂",
                children: [],
              },
              {
                id: 451121,
                name: "昭平县",
                shortName: "昭平",
                children: [],
              },
              {
                id: 451122,
                name: "钟山县",
                shortName: "钟山",
                children: [],
              },
              {
                id: 451123,
                name: "富川瑶族自治县",
                shortName: "富川",
                children: [],
              },
            ],
          },
          {
            id: 451200,
            name: "河池市",
            shortName: "河池",
            children: [
              {
                id: 451202,
                name: "金城江区",
                shortName: "金城江",
                children: [],
              },
              {
                id: 451203,
                name: "宜州区",
                shortName: "宜州",
                children: [],
              },
              {
                id: 451221,
                name: "南丹县",
                shortName: "南丹",
                children: [],
              },
              {
                id: 451222,
                name: "天峨县",
                shortName: "天峨",
                children: [],
              },
              {
                id: 451223,
                name: "凤山县",
                shortName: "凤山",
                children: [],
              },
              {
                id: 451224,
                name: "东兰县",
                shortName: "东兰",
                children: [],
              },
              {
                id: 451225,
                name: "罗城仫佬族自治县",
                shortName: "罗城",
                children: [],
              },
              {
                id: 451226,
                name: "环江毛南族自治县",
                shortName: "环江",
                children: [],
              },
              {
                id: 451227,
                name: "巴马瑶族自治县",
                shortName: "巴马",
                children: [],
              },
              {
                id: 451228,
                name: "都安瑶族自治县",
                shortName: "都安",
                children: [],
              },
              {
                id: 451229,
                name: "大化瑶族自治县",
                shortName: "大化",
                children: [],
              },
            ],
          },
          {
            id: 451300,
            name: "来宾市",
            shortName: "来宾",
            children: [
              {
                id: 451302,
                name: "兴宾区",
                shortName: "兴宾",
                children: [],
              },
              {
                id: 451321,
                name: "忻城县",
                shortName: "忻城",
                children: [],
              },
              {
                id: 451322,
                name: "象州县",
                shortName: "象州",
                children: [],
              },
              {
                id: 451323,
                name: "武宣县",
                shortName: "武宣",
                children: [],
              },
              {
                id: 451324,
                name: "金秀瑶族自治县",
                shortName: "金秀",
                children: [],
              },
              {
                id: 451381,
                name: "合山市",
                shortName: "合山",
                children: [],
              },
            ],
          },
          {
            id: 451400,
            name: "崇左市",
            shortName: "崇左",
            children: [
              {
                id: 451402,
                name: "江州区",
                shortName: "江州",
                children: [],
              },
              {
                id: 451421,
                name: "扶绥县",
                shortName: "扶绥",
                children: [],
              },
              {
                id: 451422,
                name: "宁明县",
                shortName: "宁明",
                children: [],
              },
              {
                id: 451423,
                name: "龙州县",
                shortName: "龙州",
                children: [],
              },
              {
                id: 451424,
                name: "大新县",
                shortName: "大新",
                children: [],
              },
              {
                id: 451425,
                name: "天等县",
                shortName: "天等",
                children: [],
              },
              {
                id: 451481,
                name: "凭祥市",
                shortName: "凭祥",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 460000,
        name: "海南省",
        shortName: "海南",
        children: [
          {
            id: 460100,
            name: "海口市",
            shortName: "海口",
            children: [
              {
                id: 460105,
                name: "秀英区",
                shortName: "秀英",
                children: [],
              },
              {
                id: 460106,
                name: "龙华区",
                shortName: "龙华",
                children: [],
              },
              {
                id: 460107,
                name: "琼山区",
                shortName: "琼山",
                children: [],
              },
              {
                id: 460108,
                name: "美兰区",
                shortName: "美兰",
                children: [],
              },
            ],
          },
          {
            id: 460200,
            name: "三亚市",
            shortName: "三亚",
            children: [
              {
                id: 460202,
                name: "海棠区",
                shortName: "海棠",
                children: [],
              },
              {
                id: 460203,
                name: "吉阳区",
                shortName: "吉阳",
                children: [],
              },
              {
                id: 460204,
                name: "天涯区",
                shortName: "天涯",
                children: [],
              },
              {
                id: 460205,
                name: "崖州区",
                shortName: "崖州",
                children: [],
              },
            ],
          },
          {
            id: 460300,
            name: "三沙市",
            shortName: "三沙",
            children: [
              {
                id: 460321,
                name: "西沙群岛",
                shortName: "西沙",
                children: [],
              },
              {
                id: 460322,
                name: "南沙群岛",
                shortName: "南沙",
                children: [],
              },
              {
                id: 460323,
                name: "中沙群岛",
                shortName: "中沙",
                children: [],
              },
            ],
          },
          {
            id: 460400,
            name: "儋州市",
            shortName: "儋州",
            children: [
              {
                id: 460401,
                name: "洋浦经济开发区",
                shortName: "洋浦",
                children: [],
              },
              {
                id: 460402,
                name: "那大镇",
                shortName: "那大",
                children: [],
              },
              {
                id: 460403,
                name: "南丰镇",
                shortName: "南丰",
                children: [],
              },
              {
                id: 460404,
                name: "雅星镇",
                shortName: "雅星",
                children: [],
              },
              {
                id: 460405,
                name: "和庆镇",
                shortName: "和庆",
                children: [],
              },
              {
                id: 460406,
                name: "大成镇",
                shortName: "大成",
                children: [],
              },
              {
                id: 460407,
                name: "新州镇",
                shortName: "新州",
                children: [],
              },
              {
                id: 460408,
                name: "光村镇",
                shortName: "光村",
                children: [],
              },
              {
                id: 460409,
                name: "东成镇",
                shortName: "东成",
                children: [],
              },
              {
                id: 460410,
                name: "中和镇",
                shortName: "中和",
                children: [],
              },
              {
                id: 460411,
                name: "峨蔓镇",
                shortName: "峨蔓",
                children: [],
              },
              {
                id: 460412,
                name: "兰洋镇",
                shortName: "兰洋",
                children: [],
              },
              {
                id: 460413,
                name: "王五镇",
                shortName: "王五",
                children: [],
              },
              {
                id: 460414,
                name: "排浦镇",
                shortName: "排浦",
                children: [],
              },
              {
                id: 460415,
                name: "海头镇",
                shortName: "海头",
                children: [],
              },
              {
                id: 460416,
                name: "木棠镇",
                shortName: "木棠",
                children: [],
              },
              {
                id: 460417,
                name: "白马井镇",
                shortName: "白马井",
                children: [],
              },
              {
                id: 460418,
                name: "三都镇",
                shortName: "三都",
                children: [],
              },
              {
                id: 460419,
                name: "西培农场",
                shortName: "西培农场",
                children: [],
              },
              {
                id: 460420,
                name: "西联农场",
                shortName: "西联农场",
                children: [],
              },
              {
                id: 460421,
                name: "蓝洋农场",
                shortName: "蓝洋农场",
                children: [],
              },
              {
                id: 460422,
                name: "八一农场",
                shortName: "八一农场",
                children: [],
              },
              {
                id: 460423,
                name: "西华农场",
                shortName: "西华农场",
                children: [],
              },
              {
                id: 460424,
                name: "西庆农场",
                shortName: "西庆农场",
                children: [],
              },
              {
                id: 460425,
                name: "西流农场",
                shortName: "西流农场",
                children: [],
              },
              {
                id: 460426,
                name: "新盈农场",
                shortName: "新盈农场",
                children: [],
              },
              {
                id: 460427,
                name: "龙山农场",
                shortName: "龙山农场",
                children: [],
              },
              {
                id: 460428,
                name: "红岭农场",
                shortName: "红岭农场",
                children: [],
              },
            ],
          },
          {
            id: 469001,
            name: "五指山市",
            shortName: "五指山",
            children: [
              {
                id: 469101,
                name: "通什镇",
                shortName: "通什镇",
                children: [],
              },
              {
                id: 469102,
                name: "南圣镇",
                shortName: "南圣镇",
                children: [],
              },
              {
                id: 469103,
                name: "毛阳镇",
                shortName: "毛阳镇",
                children: [],
              },
              {
                id: 469104,
                name: "番阳镇",
                shortName: "番阳镇",
                children: [],
              },
              {
                id: 469105,
                name: "畅好乡",
                shortName: "畅好乡",
                children: [],
              },
              {
                id: 469106,
                name: "毛道乡",
                shortName: "毛道乡",
                children: [],
              },
              {
                id: 469107,
                name: "水满乡",
                shortName: "水满乡",
                children: [],
              },
            ],
          },
          {
            id: 469002,
            name: "琼海市",
            shortName: "琼海",
            children: [
              {
                id: 469201,
                name: "嘉积镇",
                shortName: "嘉积镇",
                children: [],
              },
              {
                id: 469202,
                name: "万泉镇",
                shortName: "万泉镇",
                children: [],
              },
              {
                id: 469203,
                name: "石壁镇",
                shortName: "石壁镇",
                children: [],
              },
              {
                id: 469204,
                name: "中原镇",
                shortName: "中原镇",
                children: [],
              },
              {
                id: 469205,
                name: "博鳌镇",
                shortName: "博鳌镇",
                children: [],
              },
              {
                id: 469206,
                name: "阳江镇",
                shortName: "阳江镇",
                children: [],
              },
              {
                id: 469207,
                name: "龙江镇",
                shortName: "龙江镇",
                children: [],
              },
              {
                id: 469208,
                name: "潭门镇",
                shortName: "潭门镇",
                children: [],
              },
              {
                id: 469209,
                name: "塔洋镇",
                shortName: "塔洋镇",
                children: [],
              },
              {
                id: 469210,
                name: "长坡镇",
                shortName: "长坡镇",
                children: [],
              },
              {
                id: 469211,
                name: "大路镇",
                shortName: "大路镇",
                children: [],
              },
              {
                id: 469212,
                name: "会山镇",
                shortName: "会山镇",
                children: [],
              },
              {
                id: 469213,
                name: "东太农场",
                shortName: "东太农场",
                children: [],
              },
              {
                id: 469214,
                name: "东红农场",
                shortName: "东红农场",
                children: [],
              },
              {
                id: 469215,
                name: "东升农场",
                shortName: "东升农场",
                children: [],
              },
              {
                id: 469216,
                name: "南俸农场",
                shortName: "南俸农场",
                children: [],
              },
              {
                id: 469217,
                name: "彬村山华侨农场",
                shortName: "彬村山华侨农场",
                children: [],
              },
            ],
          },
          {
            id: 469005,
            name: "文昌市",
            shortName: "文昌",
            children: [
              {
                id: 469501,
                name: "文城镇",
                shortName: "文城镇",
                children: [],
              },
              {
                id: 469502,
                name: "重兴镇",
                shortName: "重兴镇",
                children: [],
              },
              {
                id: 469503,
                name: "蓬莱镇",
                shortName: "蓬莱镇",
                children: [],
              },
              {
                id: 469504,
                name: "会文镇",
                shortName: "会文镇",
                children: [],
              },
              {
                id: 469505,
                name: "东路镇",
                shortName: "东路镇",
                children: [],
              },
              {
                id: 469506,
                name: "潭牛镇",
                shortName: "潭牛镇",
                children: [],
              },
              {
                id: 469507,
                name: "东阁镇",
                shortName: "东阁镇",
                children: [],
              },
              {
                id: 469508,
                name: "文教镇",
                shortName: "文教镇",
                children: [],
              },
              {
                id: 469509,
                name: "东郊镇",
                shortName: "东郊镇",
                children: [],
              },
              {
                id: 469510,
                name: "龙楼镇",
                shortName: "龙楼镇",
                children: [],
              },
              {
                id: 469511,
                name: "昌洒镇",
                shortName: "昌洒镇",
                children: [],
              },
              {
                id: 469512,
                name: "翁田镇",
                shortName: "翁田镇",
                children: [],
              },
              {
                id: 469513,
                name: "抱罗镇",
                shortName: "抱罗镇",
                children: [],
              },
              {
                id: 469514,
                name: "冯坡镇",
                shortName: "冯坡镇",
                children: [],
              },
              {
                id: 469515,
                name: "锦山镇",
                shortName: "锦山镇",
                children: [],
              },
              {
                id: 469516,
                name: "铺前镇",
                shortName: "铺前镇",
                children: [],
              },
              {
                id: 469517,
                name: "公坡镇",
                shortName: "公坡镇",
                children: [],
              },
              {
                id: 469518,
                name: "迈号镇",
                shortName: "迈号镇",
                children: [],
              },
              {
                id: 469519,
                name: "清谰镇",
                shortName: "清谰镇",
                children: [],
              },
              {
                id: 469520,
                name: "南阳镇",
                shortName: "南阳镇",
                children: [],
              },
              {
                id: 469521,
                name: "新桥镇",
                shortName: "新桥镇",
                children: [],
              },
              {
                id: 469522,
                name: "头苑镇",
                shortName: "头苑镇",
                children: [],
              },
              {
                id: 469523,
                name: "宝芳乡",
                shortName: "宝芳乡",
                children: [],
              },
              {
                id: 469524,
                name: "龙马乡",
                shortName: "龙马乡",
                children: [],
              },
              {
                id: 469525,
                name: "湖山乡",
                shortName: "湖山乡",
                children: [],
              },
              {
                id: 469526,
                name: "东路农场",
                shortName: "东路农场",
                children: [],
              },
              {
                id: 469527,
                name: "南阳农场",
                shortName: "南阳农场",
                children: [],
              },
              {
                id: 469528,
                name: "罗豆农场",
                shortName: "罗豆农场",
                children: [],
              },
            ],
          },
          {
            id: 469006,
            name: "万宁市",
            shortName: "万宁",
            children: [
              {
                id: 469601,
                name: "万城镇",
                shortName: "万城镇",
                children: [],
              },
              {
                id: 469602,
                name: "龙滚镇",
                shortName: "龙滚镇",
                children: [],
              },
              {
                id: 469603,
                name: "和乐镇",
                shortName: "和乐镇",
                children: [],
              },
              {
                id: 469604,
                name: "后安镇",
                shortName: "后安镇",
                children: [],
              },
              {
                id: 469605,
                name: "大茂镇",
                shortName: "大茂镇",
                children: [],
              },
              {
                id: 469606,
                name: "东澳镇",
                shortName: "东澳镇",
                children: [],
              },
              {
                id: 469607,
                name: "礼纪镇",
                shortName: "礼纪镇",
                children: [],
              },
              {
                id: 469608,
                name: "长丰镇",
                shortName: "长丰镇",
                children: [],
              },
              {
                id: 469609,
                name: "山根镇",
                shortName: "山根镇",
                children: [],
              },
              {
                id: 469610,
                name: "北大镇",
                shortName: "北大镇",
                children: [],
              },
              {
                id: 469611,
                name: "南桥镇",
                shortName: "南桥镇",
                children: [],
              },
              {
                id: 469612,
                name: "三更罗镇",
                shortName: "三更罗镇",
                children: [],
              },
              {
                id: 469613,
                name: "东岭农场",
                shortName: "东岭农场",
                children: [],
              },
              {
                id: 469614,
                name: "南林农场",
                shortName: "南林农场",
                children: [],
              },
              {
                id: 469615,
                name: "东兴农场",
                shortName: "东兴农场",
                children: [],
              },
              {
                id: 469616,
                name: "东和农场",
                shortName: "东和农场",
                children: [],
              },
              {
                id: 469617,
                name: "新中农场",
                shortName: "新中农场",
                children: [],
              },
              {
                id: 469618,
                name: "兴隆华侨农场",
                shortName: "兴隆华侨农场",
                children: [],
              },
            ],
          },
          {
            id: 469007,
            name: "东方市",
            shortName: "东方",
            children: [
              {
                id: 469701,
                name: "八所镇",
                shortName: "八所镇",
                children: [],
              },
              {
                id: 469702,
                name: "东河镇",
                shortName: "东河镇",
                children: [],
              },
              {
                id: 469703,
                name: "大田镇",
                shortName: "大田镇",
                children: [],
              },
              {
                id: 469704,
                name: "感城镇",
                shortName: "感城镇",
                children: [],
              },
              {
                id: 469705,
                name: "板桥镇",
                shortName: "板桥镇",
                children: [],
              },
              {
                id: 469706,
                name: "三家镇",
                shortName: "三家镇",
                children: [],
              },
              {
                id: 469707,
                name: "四更镇",
                shortName: "四更镇",
                children: [],
              },
              {
                id: 469708,
                name: "新龙镇",
                shortName: "新龙镇",
                children: [],
              },
              {
                id: 469709,
                name: "天安乡",
                shortName: "天安乡",
                children: [],
              },
              {
                id: 469710,
                name: "江边乡",
                shortName: "江边乡",
                children: [],
              },
              {
                id: 469711,
                name: "广坝农场",
                shortName: "广坝农场",
                children: [],
              },
              {
                id: 469712,
                name: "东方华侨农场",
                shortName: "东方华侨农场",
                children: [],
              },
            ],
          },
          {
            id: 469021,
            name: "定安县",
            shortName: "定安",
            children: [
              {
                id: 469801,
                name: "定城镇",
                shortName: "定城镇",
                children: [],
              },
              {
                id: 469802,
                name: "新竹镇",
                shortName: "新竹镇",
                children: [],
              },
              {
                id: 469803,
                name: "龙湖镇",
                shortName: "龙湖镇",
                children: [],
              },
              {
                id: 469804,
                name: "雷鸣镇",
                shortName: "雷鸣镇",
                children: [],
              },
              {
                id: 469805,
                name: "龙门镇",
                shortName: "龙门镇",
                children: [],
              },
              {
                id: 469806,
                name: "龙河镇",
                shortName: "龙河镇",
                children: [],
              },
              {
                id: 469807,
                name: "岭口镇",
                shortName: "岭口镇",
                children: [],
              },
              {
                id: 469808,
                name: "翰林镇",
                shortName: "翰林镇",
                children: [],
              },
              {
                id: 469809,
                name: "富文镇",
                shortName: "富文镇",
                children: [],
              },
              {
                id: 469810,
                name: "黄竹镇",
                shortName: "黄竹镇",
                children: [],
              },
              {
                id: 469811,
                name: "金鸡岭农场",
                shortName: "金鸡岭农场",
                children: [],
              },
              {
                id: 469812,
                name: "中瑞农场",
                shortName: "中瑞农场",
                children: [],
              },
              {
                id: 469813,
                name: "南海农场",
                shortName: "南海农场",
                children: [],
              },
              {
                id: 469814,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
          {
            id: 469022,
            name: "屯昌县",
            shortName: "屯昌",
            children: [
              {
                id: 469821,
                name: "屯城镇",
                shortName: "屯城镇",
                children: [],
              },
              {
                id: 469822,
                name: "新兴镇",
                shortName: "新兴镇",
                children: [],
              },
              {
                id: 469823,
                name: "枫木镇",
                shortName: "枫木镇",
                children: [],
              },
              {
                id: 469824,
                name: "乌坡镇",
                shortName: "乌坡镇",
                children: [],
              },
              {
                id: 469825,
                name: "南吕镇",
                shortName: "南吕镇",
                children: [],
              },
              {
                id: 469826,
                name: "南坤镇",
                shortName: "南坤镇",
                children: [],
              },
              {
                id: 469827,
                name: "坡心镇",
                shortName: "坡心镇",
                children: [],
              },
              {
                id: 469828,
                name: "西昌镇",
                shortName: "西昌镇",
                children: [],
              },
              {
                id: 469829,
                name: "中建农场",
                shortName: "中建农场",
                children: [],
              },
              {
                id: 469830,
                name: "中坤农场",
                shortName: "中坤农场",
                children: [],
              },
              {
                id: 469831,
                name: "县城内",
                shortName: "县城内",
                children: [],
              },
            ],
          },
          {
            id: 469023,
            name: "澄迈县",
            shortName: "澄迈",
            children: [
              {
                id: 469841,
                name: "金江镇",
                shortName: "金江镇",
                children: [],
              },
              {
                id: 469842,
                name: "老城镇",
                shortName: "老城镇",
                children: [],
              },
              {
                id: 469843,
                name: "瑞溪镇",
                shortName: "瑞溪镇",
                children: [],
              },
              {
                id: 469844,
                name: "永发镇",
                shortName: "永发镇",
                children: [],
              },
              {
                id: 469845,
                name: "加乐镇",
                shortName: "加乐镇",
                children: [],
              },
              {
                id: 469846,
                name: "文儒镇",
                shortName: "文儒镇",
                children: [],
              },
              {
                id: 469847,
                name: "中兴镇",
                shortName: "中兴镇",
                children: [],
              },
              {
                id: 469848,
                name: "仁兴镇",
                shortName: "仁兴镇",
                children: [],
              },
              {
                id: 469849,
                name: "福山镇",
                shortName: "福山镇",
                children: [],
              },
              {
                id: 469850,
                name: "桥头镇",
                shortName: "桥头镇",
                children: [],
              },
              {
                id: 469851,
                name: "大丰镇",
                shortName: "大丰镇",
                children: [],
              },
              {
                id: 469852,
                name: "红光农场",
                shortName: "红光农场",
                children: [],
              },
              {
                id: 469853,
                name: "西达农场",
                shortName: "西达农场",
                children: [],
              },
              {
                id: 469854,
                name: "金安农场",
                shortName: "金安农场",
                children: [],
              },
              {
                id: 469855,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
          {
            id: 469024,
            name: "临高县",
            shortName: "临高",
            children: [
              {
                id: 469861,
                name: "临城镇",
                shortName: "临城镇",
                children: [],
              },
              {
                id: 469862,
                name: "波莲镇",
                shortName: "波莲镇",
                children: [],
              },
              {
                id: 469863,
                name: "东英镇",
                shortName: "东英镇",
                children: [],
              },
              {
                id: 469864,
                name: "博厚镇",
                shortName: "博厚镇",
                children: [],
              },
              {
                id: 469865,
                name: "皇桐镇",
                shortName: "皇桐镇",
                children: [],
              },
              {
                id: 469866,
                name: "多文镇",
                shortName: "多文镇",
                children: [],
              },
              {
                id: 469867,
                name: "和舍镇",
                shortName: "和舍镇",
                children: [],
              },
              {
                id: 469868,
                name: "南宝镇",
                shortName: "南宝镇",
                children: [],
              },
              {
                id: 469869,
                name: "新盈镇",
                shortName: "新盈镇",
                children: [],
              },
              {
                id: 469870,
                name: "调楼镇",
                shortName: "调楼镇",
                children: [],
              },
              {
                id: 469871,
                name: "加来镇",
                shortName: "加来镇",
                children: [],
              },
              {
                id: 469872,
                name: "红华农场",
                shortName: "红华农场",
                children: [],
              },
              {
                id: 469873,
                name: "加来农场",
                shortName: "加来农场",
                children: [],
              },
              {
                id: 469874,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
          {
            id: 469025,
            name: "白沙黎族自治县",
            shortName: "白沙",
            children: [
              {
                id: 469881,
                name: "牙叉镇",
                shortName: "牙叉镇",
                children: [],
              },
              {
                id: 469882,
                name: "七坊镇",
                shortName: "七坊镇",
                children: [],
              },
              {
                id: 469883,
                name: "邦溪镇",
                shortName: "邦溪镇",
                children: [],
              },
              {
                id: 469884,
                name: "打安镇",
                shortName: "打安镇",
                children: [],
              },
              {
                id: 469885,
                name: "细水乡",
                shortName: "细水乡",
                children: [],
              },
              {
                id: 469886,
                name: "元门乡",
                shortName: "元门乡",
                children: [],
              },
              {
                id: 469887,
                name: "南开乡",
                shortName: "南开乡",
                children: [],
              },
              {
                id: 469888,
                name: "阜龙乡",
                shortName: "阜龙乡",
                children: [],
              },
              {
                id: 469889,
                name: "青松乡",
                shortName: "青松乡",
                children: [],
              },
              {
                id: 469890,
                name: "金波乡",
                shortName: "金波乡",
                children: [],
              },
              {
                id: 469891,
                name: "荣邦乡",
                shortName: "荣邦乡",
                children: [],
              },
              {
                id: 469892,
                name: "白沙农场",
                shortName: "白沙农场",
                children: [],
              },
              {
                id: 469893,
                name: "龙江农场",
                shortName: "龙江农场",
                children: [],
              },
              {
                id: 469894,
                name: "邦溪农场",
                shortName: "邦溪农场",
                children: [],
              },
              {
                id: 469895,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
          {
            id: 469026,
            name: "昌江黎族自治县",
            shortName: "昌江",
            children: [
              {
                id: 469901,
                name: "石碌镇",
                shortName: "石碌镇",
                children: [],
              },
              {
                id: 469902,
                name: "叉河镇",
                shortName: "叉河镇",
                children: [],
              },
              {
                id: 469903,
                name: "十月田镇",
                shortName: "十月田镇",
                children: [],
              },
              {
                id: 469904,
                name: "乌烈镇",
                shortName: "乌烈镇",
                children: [],
              },
              {
                id: 469905,
                name: "海尾镇",
                shortName: "海尾镇",
                children: [],
              },
              {
                id: 469906,
                name: "南罗镇",
                shortName: "南罗镇",
                children: [],
              },
              {
                id: 469907,
                name: "太坡镇",
                shortName: "太坡镇",
                children: [],
              },
              {
                id: 469908,
                name: "昌化镇",
                shortName: "昌化镇",
                children: [],
              },
              {
                id: 469909,
                name: "七叉镇",
                shortName: "七叉镇",
                children: [],
              },
              {
                id: 469910,
                name: "保平乡",
                shortName: "保平乡",
                children: [],
              },
              {
                id: 469911,
                name: "昌城乡",
                shortName: "昌城乡",
                children: [],
              },
              {
                id: 469912,
                name: "王下乡",
                shortName: "王下乡",
                children: [],
              },
              {
                id: 469913,
                name: "霸王岭林场",
                shortName: "霸王岭林场",
                children: [],
              },
              {
                id: 469914,
                name: "红林农场",
                shortName: "红林农场",
                children: [],
              },
              {
                id: 469915,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
          {
            id: 469027,
            name: "乐东黎族自治县",
            shortName: "乐东",
            children: [
              {
                id: 469920,
                name: "抱由镇",
                shortName: "抱由镇",
                children: [],
              },
              {
                id: 469921,
                name: "万冲镇",
                shortName: "万冲镇",
                children: [],
              },
              {
                id: 469922,
                name: "大安镇",
                shortName: "大安镇",
                children: [],
              },
              {
                id: 469923,
                name: "志仲镇",
                shortName: "志仲镇",
                children: [],
              },
              {
                id: 469924,
                name: "千家镇",
                shortName: "千家镇",
                children: [],
              },
              {
                id: 469925,
                name: "九所镇",
                shortName: "九所镇",
                children: [],
              },
              {
                id: 469926,
                name: "利国镇",
                shortName: "利国镇",
                children: [],
              },
              {
                id: 469927,
                name: "黄流镇",
                shortName: "黄流镇",
                children: [],
              },
              {
                id: 469928,
                name: "佛罗镇",
                shortName: "佛罗镇",
                children: [],
              },
              {
                id: 469929,
                name: "尖峰镇",
                shortName: "尖峰镇",
                children: [],
              },
              {
                id: 469930,
                name: "莺歌海镇",
                shortName: "莺歌海镇",
                children: [],
              },
              {
                id: 469931,
                name: "乐中农场",
                shortName: "乐中农场",
                children: [],
              },
              {
                id: 469932,
                name: "山荣农场",
                shortName: "山荣农场",
                children: [],
              },
              {
                id: 469933,
                name: "乐光农场",
                shortName: "乐光农场",
                children: [],
              },
              {
                id: 469934,
                name: "报伦农场",
                shortName: "报伦农场",
                children: [],
              },
              {
                id: 469935,
                name: "福报农场",
                shortName: "福报农场",
                children: [],
              },
              {
                id: 469936,
                name: "保国农场",
                shortName: "保国农场",
                children: [],
              },
              {
                id: 469937,
                name: "保显农场",
                shortName: "保显农场",
                children: [],
              },
              {
                id: 469938,
                name: "尖峰岭林业",
                shortName: "尖峰岭林业",
                children: [],
              },
              {
                id: 469939,
                name: "莺歌海盐场",
                shortName: "莺歌海盐场",
                children: [],
              },
              {
                id: 469940,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
          {
            id: 469028,
            name: "陵水黎族自治县",
            shortName: "陵水",
            children: [
              {
                id: 469941,
                name: "椰林镇",
                shortName: "椰林镇",
                children: [],
              },
              {
                id: 469942,
                name: "光坡镇",
                shortName: "光坡镇",
                children: [],
              },
              {
                id: 469943,
                name: "三才镇",
                shortName: "三才镇",
                children: [],
              },
              {
                id: 469944,
                name: "英州镇",
                shortName: "英州镇",
                children: [],
              },
              {
                id: 469945,
                name: "隆广镇",
                shortName: "隆广镇",
                children: [],
              },
              {
                id: 469946,
                name: "文罗镇",
                shortName: "文罗镇",
                children: [],
              },
              {
                id: 469947,
                name: "本号镇",
                shortName: "本号镇",
                children: [],
              },
              {
                id: 469948,
                name: "新村镇",
                shortName: "新村镇",
                children: [],
              },
              {
                id: 469949,
                name: "黎安镇",
                shortName: "黎安镇",
                children: [],
              },
              {
                id: 469950,
                name: "提蒙乡",
                shortName: "提蒙乡",
                children: [],
              },
              {
                id: 469951,
                name: "群英乡",
                shortName: "群英乡",
                children: [],
              },
              {
                id: 469952,
                name: "岭门农场",
                shortName: "岭门农场",
                children: [],
              },
              {
                id: 469953,
                name: "南平农场",
                shortName: "南平农场",
                children: [],
              },
              {
                id: 469954,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
          {
            id: 469029,
            name: "保亭黎族苗族自治县",
            shortName: "保亭",
            children: [
              {
                id: 469961,
                name: "保城镇",
                shortName: "保城镇",
                children: [],
              },
              {
                id: 469962,
                name: "什玲镇",
                shortName: "什玲镇",
                children: [],
              },
              {
                id: 469963,
                name: "加茂镇",
                shortName: "加茂镇",
                children: [],
              },
              {
                id: 469964,
                name: "响水镇",
                shortName: "响水镇",
                children: [],
              },
              {
                id: 469965,
                name: "新政镇",
                shortName: "新政镇",
                children: [],
              },
              {
                id: 469966,
                name: "三道镇",
                shortName: "三道镇",
                children: [],
              },
              {
                id: 469967,
                name: "六弓乡",
                shortName: "六弓乡",
                children: [],
              },
              {
                id: 469968,
                name: "南林乡",
                shortName: "南林乡",
                children: [],
              },
              {
                id: 469969,
                name: "毛感乡",
                shortName: "毛感乡",
                children: [],
              },
              {
                id: 469970,
                name: "新星农场",
                shortName: "新星农场",
                children: [],
              },
              {
                id: 469971,
                name: "金江农场",
                shortName: "金江农场",
                children: [],
              },
              {
                id: 469972,
                name: "三道农场",
                shortName: "三道农场",
                children: [],
              },
            ],
          },
          {
            id: 469030,
            name: "琼中黎族苗族自治县",
            shortName: "琼中",
            children: [
              {
                id: 469981,
                name: "营根镇",
                shortName: "营根镇",
                children: [],
              },
              {
                id: 469982,
                name: "湾岭镇",
                shortName: "湾岭镇",
                children: [],
              },
              {
                id: 469983,
                name: "黎母山镇",
                shortName: "黎母山镇",
                children: [],
              },
              {
                id: 469984,
                name: "和平镇",
                shortName: "和平镇",
                children: [],
              },
              {
                id: 469985,
                name: "长征镇",
                shortName: "长征镇",
                children: [],
              },
              {
                id: 469986,
                name: "红毛镇",
                shortName: "红毛镇",
                children: [],
              },
              {
                id: 469987,
                name: "中平镇",
                shortName: "中平镇",
                children: [],
              },
              {
                id: 469988,
                name: "上安乡",
                shortName: "上安乡",
                children: [],
              },
              {
                id: 469989,
                name: "什运乡",
                shortName: "什运乡",
                children: [],
              },
              {
                id: 469990,
                name: "吊罗山乡",
                shortName: "吊罗山乡",
                children: [],
              },
              {
                id: 469991,
                name: "阳江农场",
                shortName: "阳江农场",
                children: [],
              },
              {
                id: 469992,
                name: "乌石农场",
                shortName: "乌石农场",
                children: [],
              },
              {
                id: 469993,
                name: "加钗农场",
                shortName: "加钗农场",
                children: [],
              },
              {
                id: 469994,
                name: "长征农场",
                shortName: "长征农场",
                children: [],
              },
              {
                id: 469995,
                name: "城区",
                shortName: "城区",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 500000,
        name: "重庆",
        shortName: "重庆",
        children: [
          {
            id: 500100,
            name: "重庆市",
            shortName: "重庆",
            children: [
              {
                id: 500101,
                name: "万州区",
                shortName: "万州",
                children: [],
              },
              {
                id: 500102,
                name: "涪陵区",
                shortName: "涪陵",
                children: [],
              },
              {
                id: 500103,
                name: "渝中区",
                shortName: "渝中",
                children: [],
              },
              {
                id: 500104,
                name: "大渡口区",
                shortName: "大渡口",
                children: [],
              },
              {
                id: 500105,
                name: "江北区",
                shortName: "江北",
                children: [],
              },
              {
                id: 500106,
                name: "沙坪坝区",
                shortName: "沙坪坝",
                children: [],
              },
              {
                id: 500107,
                name: "九龙坡区",
                shortName: "九龙坡",
                children: [],
              },
              {
                id: 500108,
                name: "南岸区",
                shortName: "南岸",
                children: [],
              },
              {
                id: 500109,
                name: "北碚区",
                shortName: "北碚",
                children: [],
              },
              {
                id: 500110,
                name: "綦江区",
                shortName: "綦江",
                children: [],
              },
              {
                id: 500111,
                name: "大足区",
                shortName: "大足",
                children: [],
              },
              {
                id: 500112,
                name: "渝北区",
                shortName: "渝北",
                children: [],
              },
              {
                id: 500113,
                name: "巴南区",
                shortName: "巴南",
                children: [],
              },
              {
                id: 500114,
                name: "黔江区",
                shortName: "黔江",
                children: [],
              },
              {
                id: 500115,
                name: "长寿区",
                shortName: "长寿",
                children: [],
              },
              {
                id: 500116,
                name: "江津区",
                shortName: "江津",
                children: [],
              },
              {
                id: 500117,
                name: "合川区",
                shortName: "合川",
                children: [],
              },
              {
                id: 500118,
                name: "永川区",
                shortName: "永川",
                children: [],
              },
              {
                id: 500119,
                name: "南川区",
                shortName: "南川",
                children: [],
              },
              {
                id: 500120,
                name: "璧山区",
                shortName: "璧山",
                children: [],
              },
              {
                id: 500151,
                name: "铜梁区",
                shortName: "铜梁",
                children: [],
              },
              {
                id: 500152,
                name: "潼南区",
                shortName: "潼南",
                children: [],
              },
              {
                id: 500153,
                name: "荣昌区",
                shortName: "荣昌",
                children: [],
              },
              {
                id: 500154,
                name: "开州区",
                shortName: "开州",
                children: [],
              },
              {
                id: 500155,
                name: "梁平区",
                shortName: "梁平",
                children: [],
              },
              {
                id: 500156,
                name: "武隆区",
                shortName: "武隆",
                children: [],
              },
              {
                id: 500229,
                name: "城口县",
                shortName: "城口",
                children: [],
              },
              {
                id: 500230,
                name: "丰都县",
                shortName: "丰都",
                children: [],
              },
              {
                id: 500231,
                name: "垫江县",
                shortName: "垫江",
                children: [],
              },
              {
                id: 500233,
                name: "忠县",
                shortName: "忠县",
                children: [],
              },
              {
                id: 500235,
                name: "云阳县",
                shortName: "云阳",
                children: [],
              },
              {
                id: 500236,
                name: "奉节县",
                shortName: "奉节",
                children: [],
              },
              {
                id: 500237,
                name: "巫山县",
                shortName: "巫山",
                children: [],
              },
              {
                id: 500238,
                name: "巫溪县",
                shortName: "巫溪",
                children: [],
              },
              {
                id: 500240,
                name: "石柱土家族自治县",
                shortName: "石柱",
                children: [],
              },
              {
                id: 500241,
                name: "秀山土家族苗族自治县",
                shortName: "秀山",
                children: [],
              },
              {
                id: 500242,
                name: "酉阳土家族苗族自治县",
                shortName: "酉阳",
                children: [],
              },
              {
                id: 500243,
                name: "彭水苗族土家族自治县",
                shortName: "彭水",
                children: [],
              },
              {
                id: 500300,
                name: "两江新区",
                shortName: "两江新区",
                children: [],
              },
              {
                id: 500301,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 500302,
                name: "璧山高新区",
                shortName: "璧山高新区",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 510000,
        name: "四川省",
        shortName: "四川",
        children: [
          {
            id: 510100,
            name: "成都市",
            shortName: "成都",
            children: [
              {
                id: 510104,
                name: "锦江区",
                shortName: "锦江",
                children: [],
              },
              {
                id: 510105,
                name: "青羊区",
                shortName: "青羊",
                children: [],
              },
              {
                id: 510106,
                name: "金牛区",
                shortName: "金牛",
                children: [],
              },
              {
                id: 510107,
                name: "武侯区",
                shortName: "武侯",
                children: [],
              },
              {
                id: 510108,
                name: "成华区",
                shortName: "成华",
                children: [],
              },
              {
                id: 510112,
                name: "龙泉驿区",
                shortName: "龙泉驿",
                children: [],
              },
              {
                id: 510113,
                name: "青白江区",
                shortName: "青白江",
                children: [],
              },
              {
                id: 510114,
                name: "新都区",
                shortName: "新都",
                children: [],
              },
              {
                id: 510115,
                name: "温江区",
                shortName: "温江",
                children: [],
              },
              {
                id: 510116,
                name: "双流区",
                shortName: "双流",
                children: [],
              },
              {
                id: 510117,
                name: "郫都区",
                shortName: "郫都",
                children: [],
              },
              {
                id: 510121,
                name: "金堂县",
                shortName: "金堂",
                children: [],
              },
              {
                id: 510129,
                name: "大邑县",
                shortName: "大邑",
                children: [],
              },
              {
                id: 510131,
                name: "蒲江县",
                shortName: "蒲江",
                children: [],
              },
              {
                id: 510132,
                name: "新津县",
                shortName: "新津",
                children: [],
              },
              {
                id: 510181,
                name: "都江堰市",
                shortName: "都江堰",
                children: [],
              },
              {
                id: 510182,
                name: "彭州市",
                shortName: "彭州",
                children: [],
              },
              {
                id: 510183,
                name: "邛崃市",
                shortName: "邛崃",
                children: [],
              },
              {
                id: 510184,
                name: "崇州市",
                shortName: "崇州",
                children: [],
              },
              {
                id: 510185,
                name: "简阳市",
                shortName: "简阳",
                children: [],
              },
              {
                id: 510186,
                name: "天府新区",
                shortName: "天府新区",
                children: [],
              },
              {
                id: 510187,
                name: "高新南区",
                shortName: "高新南区",
                children: [],
              },
              {
                id: 510188,
                name: "高新西区",
                shortName: "高新西区",
                children: [],
              },
            ],
          },
          {
            id: 510300,
            name: "自贡市",
            shortName: "自贡",
            children: [
              {
                id: 510302,
                name: "自流井区",
                shortName: "自流井",
                children: [],
              },
              {
                id: 510303,
                name: "贡井区",
                shortName: "贡井",
                children: [],
              },
              {
                id: 510304,
                name: "大安区",
                shortName: "大安",
                children: [],
              },
              {
                id: 510311,
                name: "沿滩区",
                shortName: "沿滩",
                children: [],
              },
              {
                id: 510321,
                name: "荣县",
                shortName: "荣县",
                children: [],
              },
              {
                id: 510322,
                name: "富顺县",
                shortName: "富顺",
                children: [],
              },
              {
                id: 510323,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 510400,
            name: "攀枝花市",
            shortName: "攀枝花",
            children: [
              {
                id: 510402,
                name: "东区",
                shortName: "东区",
                children: [],
              },
              {
                id: 510403,
                name: "西区",
                shortName: "西区",
                children: [],
              },
              {
                id: 510411,
                name: "仁和区",
                shortName: "仁和",
                children: [],
              },
              {
                id: 510421,
                name: "米易县",
                shortName: "米易",
                children: [],
              },
              {
                id: 510422,
                name: "盐边县",
                shortName: "盐边",
                children: [],
              },
            ],
          },
          {
            id: 510500,
            name: "泸州市",
            shortName: "泸州",
            children: [
              {
                id: 510502,
                name: "江阳区",
                shortName: "江阳",
                children: [],
              },
              {
                id: 510503,
                name: "纳溪区",
                shortName: "纳溪",
                children: [],
              },
              {
                id: 510504,
                name: "龙马潭区",
                shortName: "龙马潭",
                children: [],
              },
              {
                id: 510521,
                name: "泸县",
                shortName: "泸县",
                children: [],
              },
              {
                id: 510522,
                name: "合江县",
                shortName: "合江",
                children: [],
              },
              {
                id: 510524,
                name: "叙永县",
                shortName: "叙永",
                children: [],
              },
              {
                id: 510525,
                name: "古蔺县",
                shortName: "古蔺",
                children: [],
              },
            ],
          },
          {
            id: 510600,
            name: "德阳市",
            shortName: "德阳",
            children: [
              {
                id: 510603,
                name: "旌阳区",
                shortName: "旌阳",
                children: [],
              },
              {
                id: 510623,
                name: "中江县",
                shortName: "中江",
                children: [],
              },
              {
                id: 510626,
                name: "罗江县",
                shortName: "罗江",
                children: [],
              },
              {
                id: 510681,
                name: "广汉市",
                shortName: "广汉",
                children: [],
              },
              {
                id: 510682,
                name: "什邡市",
                shortName: "什邡",
                children: [],
              },
              {
                id: 510683,
                name: "绵竹市",
                shortName: "绵竹",
                children: [],
              },
            ],
          },
          {
            id: 510700,
            name: "绵阳市",
            shortName: "绵阳",
            children: [
              {
                id: 510703,
                name: "涪城区",
                shortName: "涪城",
                children: [],
              },
              {
                id: 510704,
                name: "游仙区",
                shortName: "游仙",
                children: [],
              },
              {
                id: 510705,
                name: "安州区",
                shortName: "安州",
                children: [],
              },
              {
                id: 510722,
                name: "三台县",
                shortName: "三台",
                children: [],
              },
              {
                id: 510723,
                name: "盐亭县",
                shortName: "盐亭",
                children: [],
              },
              {
                id: 510725,
                name: "梓潼县",
                shortName: "梓潼",
                children: [],
              },
              {
                id: 510726,
                name: "北川羌族自治县",
                shortName: "北川",
                children: [],
              },
              {
                id: 510727,
                name: "平武县",
                shortName: "平武",
                children: [],
              },
              {
                id: 510781,
                name: "江油市",
                shortName: "江油",
                children: [],
              },
              {
                id: 510782,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 510783,
                name: "经开区",
                shortName: "经开区",
                children: [],
              },
            ],
          },
          {
            id: 510800,
            name: "广元市",
            shortName: "广元",
            children: [
              {
                id: 510802,
                name: "利州区",
                shortName: "利州",
                children: [],
              },
              {
                id: 510811,
                name: "昭化区",
                shortName: "昭化",
                children: [],
              },
              {
                id: 510812,
                name: "朝天区",
                shortName: "朝天",
                children: [],
              },
              {
                id: 510821,
                name: "旺苍县",
                shortName: "旺苍",
                children: [],
              },
              {
                id: 510822,
                name: "青川县",
                shortName: "青川",
                children: [],
              },
              {
                id: 510823,
                name: "剑阁县",
                shortName: "剑阁",
                children: [],
              },
              {
                id: 510824,
                name: "苍溪县",
                shortName: "苍溪",
                children: [],
              },
            ],
          },
          {
            id: 510900,
            name: "遂宁市",
            shortName: "遂宁",
            children: [
              {
                id: 510903,
                name: "船山区",
                shortName: "船山",
                children: [],
              },
              {
                id: 510904,
                name: "安居区",
                shortName: "安居",
                children: [],
              },
              {
                id: 510921,
                name: "蓬溪县",
                shortName: "蓬溪",
                children: [],
              },
              {
                id: 510922,
                name: "射洪县",
                shortName: "射洪",
                children: [],
              },
              {
                id: 510923,
                name: "大英县",
                shortName: "大英",
                children: [],
              },
              {
                id: 510924,
                name: "经济技术开发区",
                shortName: "经济技术开发区",
                children: [],
              },
            ],
          },
          {
            id: 511000,
            name: "内江市",
            shortName: "内江",
            children: [
              {
                id: 511002,
                name: "市中区",
                shortName: "市中",
                children: [],
              },
              {
                id: 511011,
                name: "东兴区",
                shortName: "东兴",
                children: [],
              },
              {
                id: 511024,
                name: "威远县",
                shortName: "威远",
                children: [],
              },
              {
                id: 511025,
                name: "资中县",
                shortName: "资中",
                children: [],
              },
              {
                id: 511083,
                name: "隆昌市",
                shortName: "隆昌",
                children: [],
              },
            ],
          },
          {
            id: 511100,
            name: "乐山市",
            shortName: "乐山",
            children: [
              {
                id: 511102,
                name: "市中区",
                shortName: "市中",
                children: [],
              },
              {
                id: 511111,
                name: "沙湾区",
                shortName: "沙湾",
                children: [],
              },
              {
                id: 511112,
                name: "五通桥区",
                shortName: "五通桥",
                children: [],
              },
              {
                id: 511113,
                name: "金口河区",
                shortName: "金口河",
                children: [],
              },
              {
                id: 511123,
                name: "犍为县",
                shortName: "犍为",
                children: [],
              },
              {
                id: 511124,
                name: "井研县",
                shortName: "井研",
                children: [],
              },
              {
                id: 511126,
                name: "夹江县",
                shortName: "夹江",
                children: [],
              },
              {
                id: 511129,
                name: "沐川县",
                shortName: "沐川",
                children: [],
              },
              {
                id: 511132,
                name: "峨边彝族自治县",
                shortName: "峨边",
                children: [],
              },
              {
                id: 511133,
                name: "马边彝族自治县",
                shortName: "马边",
                children: [],
              },
              {
                id: 511181,
                name: "峨眉山市",
                shortName: "峨眉山",
                children: [],
              },
            ],
          },
          {
            id: 511300,
            name: "南充市",
            shortName: "南充",
            children: [
              {
                id: 511302,
                name: "顺庆区",
                shortName: "顺庆",
                children: [],
              },
              {
                id: 511303,
                name: "高坪区",
                shortName: "高坪",
                children: [],
              },
              {
                id: 511304,
                name: "嘉陵区",
                shortName: "嘉陵",
                children: [],
              },
              {
                id: 511321,
                name: "南部县",
                shortName: "南部",
                children: [],
              },
              {
                id: 511322,
                name: "营山县",
                shortName: "营山",
                children: [],
              },
              {
                id: 511323,
                name: "蓬安县",
                shortName: "蓬安",
                children: [],
              },
              {
                id: 511324,
                name: "仪陇县",
                shortName: "仪陇",
                children: [],
              },
              {
                id: 511325,
                name: "西充县",
                shortName: "西充",
                children: [],
              },
              {
                id: 511381,
                name: "阆中市",
                shortName: "阆中",
                children: [],
              },
            ],
          },
          {
            id: 511400,
            name: "眉山市",
            shortName: "眉山",
            children: [
              {
                id: 511402,
                name: "东坡区",
                shortName: "东坡",
                children: [],
              },
              {
                id: 511403,
                name: "彭山区",
                shortName: "彭山",
                children: [],
              },
              {
                id: 511421,
                name: "仁寿县",
                shortName: "仁寿",
                children: [],
              },
              {
                id: 511423,
                name: "洪雅县",
                shortName: "洪雅",
                children: [],
              },
              {
                id: 511424,
                name: "丹棱县",
                shortName: "丹棱",
                children: [],
              },
              {
                id: 511425,
                name: "青神县",
                shortName: "青神",
                children: [],
              },
            ],
          },
          {
            id: 511500,
            name: "宜宾市",
            shortName: "宜宾",
            children: [
              {
                id: 511502,
                name: "翠屏区",
                shortName: "翠屏",
                children: [],
              },
              {
                id: 511503,
                name: "南溪区",
                shortName: "南溪",
                children: [],
              },
              {
                id: 511521,
                name: "宜宾县",
                shortName: "宜宾",
                children: [],
              },
              {
                id: 511523,
                name: "江安县",
                shortName: "江安",
                children: [],
              },
              {
                id: 511524,
                name: "长宁县",
                shortName: "长宁",
                children: [],
              },
              {
                id: 511525,
                name: "高县",
                shortName: "高县",
                children: [],
              },
              {
                id: 511526,
                name: "珙县",
                shortName: "珙县",
                children: [],
              },
              {
                id: 511527,
                name: "筠连县",
                shortName: "筠连",
                children: [],
              },
              {
                id: 511528,
                name: "兴文县",
                shortName: "兴文",
                children: [],
              },
              {
                id: 511529,
                name: "屏山县",
                shortName: "屏山",
                children: [],
              },
            ],
          },
          {
            id: 511600,
            name: "广安市",
            shortName: "广安",
            children: [
              {
                id: 511602,
                name: "广安区",
                shortName: "广安",
                children: [],
              },
              {
                id: 511603,
                name: "前锋区",
                shortName: "前锋",
                children: [],
              },
              {
                id: 511621,
                name: "岳池县",
                shortName: "岳池",
                children: [],
              },
              {
                id: 511622,
                name: "武胜县",
                shortName: "武胜",
                children: [],
              },
              {
                id: 511623,
                name: "邻水县",
                shortName: "邻水",
                children: [],
              },
              {
                id: 511681,
                name: "华蓥市",
                shortName: "华蓥",
                children: [],
              },
            ],
          },
          {
            id: 511700,
            name: "达州市",
            shortName: "达州",
            children: [
              {
                id: 511702,
                name: "通川区",
                shortName: "通川",
                children: [],
              },
              {
                id: 511703,
                name: "达川区",
                shortName: "达川",
                children: [],
              },
              {
                id: 511722,
                name: "宣汉县",
                shortName: "宣汉",
                children: [],
              },
              {
                id: 511723,
                name: "开江县",
                shortName: "开江",
                children: [],
              },
              {
                id: 511724,
                name: "大竹县",
                shortName: "大竹",
                children: [],
              },
              {
                id: 511725,
                name: "渠县",
                shortName: "渠县",
                children: [],
              },
              {
                id: 511781,
                name: "万源市",
                shortName: "万源",
                children: [],
              },
            ],
          },
          {
            id: 511800,
            name: "雅安市",
            shortName: "雅安",
            children: [
              {
                id: 511802,
                name: "雨城区",
                shortName: "雨城",
                children: [],
              },
              {
                id: 511803,
                name: "名山区",
                shortName: "名山",
                children: [],
              },
              {
                id: 511822,
                name: "荥经县",
                shortName: "荥经",
                children: [],
              },
              {
                id: 511823,
                name: "汉源县",
                shortName: "汉源",
                children: [],
              },
              {
                id: 511824,
                name: "石棉县",
                shortName: "石棉",
                children: [],
              },
              {
                id: 511825,
                name: "天全县",
                shortName: "天全",
                children: [],
              },
              {
                id: 511826,
                name: "芦山县",
                shortName: "芦山",
                children: [],
              },
              {
                id: 511827,
                name: "宝兴县",
                shortName: "宝兴",
                children: [],
              },
            ],
          },
          {
            id: 511900,
            name: "巴中市",
            shortName: "巴中",
            children: [
              {
                id: 511902,
                name: "巴州区",
                shortName: "巴州",
                children: [],
              },
              {
                id: 511903,
                name: "恩阳区",
                shortName: "恩阳",
                children: [],
              },
              {
                id: 511921,
                name: "通江县",
                shortName: "通江",
                children: [],
              },
              {
                id: 511922,
                name: "南江县",
                shortName: "南江",
                children: [],
              },
              {
                id: 511923,
                name: "平昌县",
                shortName: "平昌",
                children: [],
              },
            ],
          },
          {
            id: 512000,
            name: "资阳市",
            shortName: "资阳",
            children: [
              {
                id: 512002,
                name: "雁江区",
                shortName: "雁江",
                children: [],
              },
              {
                id: 512021,
                name: "安岳县",
                shortName: "安岳",
                children: [],
              },
              {
                id: 512022,
                name: "乐至县",
                shortName: "乐至",
                children: [],
              },
            ],
          },
          {
            id: 513200,
            name: "阿坝藏族羌族自治州",
            shortName: "阿坝",
            children: [
              {
                id: 513201,
                name: "马尔康市",
                shortName: "马尔康",
                children: [],
              },
              {
                id: 513221,
                name: "汶川县",
                shortName: "汶川",
                children: [],
              },
              {
                id: 513222,
                name: "理县",
                shortName: "理县",
                children: [],
              },
              {
                id: 513223,
                name: "茂县",
                shortName: "茂县",
                children: [],
              },
              {
                id: 513224,
                name: "松潘县",
                shortName: "松潘",
                children: [],
              },
              {
                id: 513225,
                name: "九寨沟县",
                shortName: "九寨沟",
                children: [],
              },
              {
                id: 513226,
                name: "金川县",
                shortName: "金川",
                children: [],
              },
              {
                id: 513227,
                name: "小金县",
                shortName: "小金",
                children: [],
              },
              {
                id: 513228,
                name: "黑水县",
                shortName: "黑水",
                children: [],
              },
              {
                id: 513230,
                name: "壤塘县",
                shortName: "壤塘",
                children: [],
              },
              {
                id: 513231,
                name: "阿坝县",
                shortName: "阿坝",
                children: [],
              },
              {
                id: 513232,
                name: "若尔盖县",
                shortName: "若尔盖",
                children: [],
              },
              {
                id: 513233,
                name: "红原县",
                shortName: "红原",
                children: [],
              },
            ],
          },
          {
            id: 513300,
            name: "甘孜藏族自治州",
            shortName: "甘孜",
            children: [
              {
                id: 513301,
                name: "康定市",
                shortName: "康定",
                children: [],
              },
              {
                id: 513322,
                name: "泸定县",
                shortName: "泸定",
                children: [],
              },
              {
                id: 513323,
                name: "丹巴县",
                shortName: "丹巴",
                children: [],
              },
              {
                id: 513324,
                name: "九龙县",
                shortName: "九龙",
                children: [],
              },
              {
                id: 513325,
                name: "雅江县",
                shortName: "雅江",
                children: [],
              },
              {
                id: 513326,
                name: "道孚县",
                shortName: "道孚",
                children: [],
              },
              {
                id: 513327,
                name: "炉霍县",
                shortName: "炉霍",
                children: [],
              },
              {
                id: 513328,
                name: "甘孜县",
                shortName: "甘孜",
                children: [],
              },
              {
                id: 513329,
                name: "新龙县",
                shortName: "新龙",
                children: [],
              },
              {
                id: 513330,
                name: "德格县",
                shortName: "德格",
                children: [],
              },
              {
                id: 513331,
                name: "白玉县",
                shortName: "白玉",
                children: [],
              },
              {
                id: 513332,
                name: "石渠县",
                shortName: "石渠",
                children: [],
              },
              {
                id: 513333,
                name: "色达县",
                shortName: "色达",
                children: [],
              },
              {
                id: 513334,
                name: "理塘县",
                shortName: "理塘",
                children: [],
              },
              {
                id: 513335,
                name: "巴塘县",
                shortName: "巴塘",
                children: [],
              },
              {
                id: 513336,
                name: "乡城县",
                shortName: "乡城",
                children: [],
              },
              {
                id: 513337,
                name: "稻城县",
                shortName: "稻城",
                children: [],
              },
              {
                id: 513338,
                name: "得荣县",
                shortName: "得荣",
                children: [],
              },
            ],
          },
          {
            id: 513400,
            name: "凉山彝族自治州",
            shortName: "凉山",
            children: [
              {
                id: 513401,
                name: "西昌市",
                shortName: "西昌",
                children: [],
              },
              {
                id: 513422,
                name: "木里藏族自治县",
                shortName: "木里",
                children: [],
              },
              {
                id: 513423,
                name: "盐源县",
                shortName: "盐源",
                children: [],
              },
              {
                id: 513424,
                name: "德昌县",
                shortName: "德昌",
                children: [],
              },
              {
                id: 513425,
                name: "会理县",
                shortName: "会理",
                children: [],
              },
              {
                id: 513426,
                name: "会东县",
                shortName: "会东",
                children: [],
              },
              {
                id: 513427,
                name: "宁南县",
                shortName: "宁南",
                children: [],
              },
              {
                id: 513428,
                name: "普格县",
                shortName: "普格",
                children: [],
              },
              {
                id: 513429,
                name: "布拖县",
                shortName: "布拖",
                children: [],
              },
              {
                id: 513430,
                name: "金阳县",
                shortName: "金阳",
                children: [],
              },
              {
                id: 513431,
                name: "昭觉县",
                shortName: "昭觉",
                children: [],
              },
              {
                id: 513432,
                name: "喜德县",
                shortName: "喜德",
                children: [],
              },
              {
                id: 513433,
                name: "冕宁县",
                shortName: "冕宁",
                children: [],
              },
              {
                id: 513434,
                name: "越西县",
                shortName: "越西",
                children: [],
              },
              {
                id: 513435,
                name: "甘洛县",
                shortName: "甘洛",
                children: [],
              },
              {
                id: 513436,
                name: "美姑县",
                shortName: "美姑",
                children: [],
              },
              {
                id: 513437,
                name: "雷波县",
                shortName: "雷波",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 520000,
        name: "贵州省",
        shortName: "贵州",
        children: [
          {
            id: 520100,
            name: "贵阳市",
            shortName: "贵阳",
            children: [
              {
                id: 520102,
                name: "南明区",
                shortName: "南明",
                children: [],
              },
              {
                id: 520103,
                name: "云岩区",
                shortName: "云岩",
                children: [],
              },
              {
                id: 520111,
                name: "花溪区",
                shortName: "花溪",
                children: [],
              },
              {
                id: 520112,
                name: "乌当区",
                shortName: "乌当",
                children: [],
              },
              {
                id: 520113,
                name: "白云区",
                shortName: "白云",
                children: [],
              },
              {
                id: 520115,
                name: "观山湖区",
                shortName: "观山湖",
                children: [],
              },
              {
                id: 520121,
                name: "开阳县",
                shortName: "开阳",
                children: [],
              },
              {
                id: 520122,
                name: "息烽县",
                shortName: "息烽",
                children: [],
              },
              {
                id: 520123,
                name: "修文县",
                shortName: "修文",
                children: [],
              },
              {
                id: 520181,
                name: "清镇市",
                shortName: "清镇",
                children: [],
              },
              {
                id: 520182,
                name: "贵安新区",
                shortName: "贵安新区",
                children: [],
              },
              {
                id: 520183,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 520200,
            name: "六盘水市",
            shortName: "六盘水",
            children: [
              {
                id: 520201,
                name: "钟山区",
                shortName: "钟山",
                children: [],
              },
              {
                id: 520203,
                name: "六枝特区",
                shortName: "六枝",
                children: [],
              },
              {
                id: 520221,
                name: "水城县",
                shortName: "水城",
                children: [],
              },
              {
                id: 520281,
                name: "盘州市",
                shortName: "盘州",
                children: [],
              },
            ],
          },
          {
            id: 520300,
            name: "遵义市",
            shortName: "遵义",
            children: [
              {
                id: 520302,
                name: "红花岗区",
                shortName: "红花岗",
                children: [],
              },
              {
                id: 520303,
                name: "汇川区",
                shortName: "汇川",
                children: [],
              },
              {
                id: 520304,
                name: "播州区",
                shortName: "播州",
                children: [],
              },
              {
                id: 520322,
                name: "桐梓县",
                shortName: "桐梓",
                children: [],
              },
              {
                id: 520323,
                name: "绥阳县",
                shortName: "绥阳",
                children: [],
              },
              {
                id: 520324,
                name: "正安县",
                shortName: "正安",
                children: [],
              },
              {
                id: 520325,
                name: "道真仡佬族苗族自治县",
                shortName: "道真",
                children: [],
              },
              {
                id: 520326,
                name: "务川仡佬族苗族自治县",
                shortName: "务川",
                children: [],
              },
              {
                id: 520327,
                name: "凤冈县",
                shortName: "凤冈",
                children: [],
              },
              {
                id: 520328,
                name: "湄潭县",
                shortName: "湄潭",
                children: [],
              },
              {
                id: 520329,
                name: "余庆县",
                shortName: "余庆",
                children: [],
              },
              {
                id: 520330,
                name: "习水县",
                shortName: "习水",
                children: [],
              },
              {
                id: 520381,
                name: "赤水市",
                shortName: "赤水",
                children: [],
              },
              {
                id: 520382,
                name: "仁怀市",
                shortName: "仁怀",
                children: [],
              },
            ],
          },
          {
            id: 520400,
            name: "安顺市",
            shortName: "安顺",
            children: [
              {
                id: 520402,
                name: "西秀区",
                shortName: "西秀",
                children: [],
              },
              {
                id: 520403,
                name: "平坝区",
                shortName: "平坝",
                children: [],
              },
              {
                id: 520422,
                name: "普定县",
                shortName: "普定",
                children: [],
              },
              {
                id: 520423,
                name: "镇宁布依族苗族自治县",
                shortName: "镇宁",
                children: [],
              },
              {
                id: 520424,
                name: "关岭布依族苗族自治县",
                shortName: "关岭",
                children: [],
              },
              {
                id: 520425,
                name: "紫云苗族布依族自治县",
                shortName: "紫云",
                children: [],
              },
            ],
          },
          {
            id: 520500,
            name: "毕节市",
            shortName: "毕节",
            children: [
              {
                id: 520502,
                name: "七星关区",
                shortName: "七星关",
                children: [],
              },
              {
                id: 520521,
                name: "大方县",
                shortName: "大方",
                children: [],
              },
              {
                id: 520522,
                name: "黔西县",
                shortName: "黔西",
                children: [],
              },
              {
                id: 520523,
                name: "金沙县",
                shortName: "金沙",
                children: [],
              },
              {
                id: 520524,
                name: "织金县",
                shortName: "织金",
                children: [],
              },
              {
                id: 520525,
                name: "纳雍县",
                shortName: "纳雍",
                children: [],
              },
              {
                id: 520526,
                name: "威宁彝族回族苗族自治县",
                shortName: "威宁",
                children: [],
              },
              {
                id: 520527,
                name: "赫章县",
                shortName: "赫章",
                children: [],
              },
            ],
          },
          {
            id: 520600,
            name: "铜仁市",
            shortName: "铜仁",
            children: [
              {
                id: 520602,
                name: "碧江区",
                shortName: "碧江",
                children: [],
              },
              {
                id: 520603,
                name: "万山区",
                shortName: "万山",
                children: [],
              },
              {
                id: 520621,
                name: "江口县",
                shortName: "江口",
                children: [],
              },
              {
                id: 520622,
                name: "玉屏侗族自治县",
                shortName: "玉屏",
                children: [],
              },
              {
                id: 520623,
                name: "石阡县",
                shortName: "石阡",
                children: [],
              },
              {
                id: 520624,
                name: "思南县",
                shortName: "思南",
                children: [],
              },
              {
                id: 520625,
                name: "印江土家族苗族自治县",
                shortName: "印江",
                children: [],
              },
              {
                id: 520626,
                name: "德江县",
                shortName: "德江",
                children: [],
              },
              {
                id: 520627,
                name: "沿河土家族自治县",
                shortName: "沿河",
                children: [],
              },
              {
                id: 520628,
                name: "松桃苗族自治县",
                shortName: "松桃",
                children: [],
              },
            ],
          },
          {
            id: 522300,
            name: "黔西南布依族苗族自治州",
            shortName: "黔西南",
            children: [
              {
                id: 522301,
                name: "兴义市",
                shortName: "兴义",
                children: [],
              },
              {
                id: 522322,
                name: "兴仁县",
                shortName: "兴仁",
                children: [],
              },
              {
                id: 522323,
                name: "普安县",
                shortName: "普安",
                children: [],
              },
              {
                id: 522324,
                name: "晴隆县",
                shortName: "晴隆",
                children: [],
              },
              {
                id: 522325,
                name: "贞丰县",
                shortName: "贞丰",
                children: [],
              },
              {
                id: 522326,
                name: "望谟县",
                shortName: "望谟",
                children: [],
              },
              {
                id: 522327,
                name: "册亨县",
                shortName: "册亨",
                children: [],
              },
              {
                id: 522328,
                name: "安龙县",
                shortName: "安龙",
                children: [],
              },
            ],
          },
          {
            id: 522600,
            name: "黔东南苗族侗族自治州",
            shortName: "黔东南",
            children: [
              {
                id: 522601,
                name: "凯里市",
                shortName: "凯里",
                children: [],
              },
              {
                id: 522622,
                name: "黄平县",
                shortName: "黄平",
                children: [],
              },
              {
                id: 522623,
                name: "施秉县",
                shortName: "施秉",
                children: [],
              },
              {
                id: 522624,
                name: "三穗县",
                shortName: "三穗",
                children: [],
              },
              {
                id: 522625,
                name: "镇远县",
                shortName: "镇远",
                children: [],
              },
              {
                id: 522626,
                name: "岑巩县",
                shortName: "岑巩",
                children: [],
              },
              {
                id: 522627,
                name: "天柱县",
                shortName: "天柱",
                children: [],
              },
              {
                id: 522628,
                name: "锦屏县",
                shortName: "锦屏",
                children: [],
              },
              {
                id: 522629,
                name: "剑河县",
                shortName: "剑河",
                children: [],
              },
              {
                id: 522630,
                name: "台江县",
                shortName: "台江",
                children: [],
              },
              {
                id: 522631,
                name: "黎平县",
                shortName: "黎平",
                children: [],
              },
              {
                id: 522632,
                name: "榕江县",
                shortName: "榕江",
                children: [],
              },
              {
                id: 522633,
                name: "从江县",
                shortName: "从江",
                children: [],
              },
              {
                id: 522634,
                name: "雷山县",
                shortName: "雷山",
                children: [],
              },
              {
                id: 522635,
                name: "麻江县",
                shortName: "麻江",
                children: [],
              },
              {
                id: 522636,
                name: "丹寨县",
                shortName: "丹寨",
                children: [],
              },
            ],
          },
          {
            id: 522700,
            name: "黔南布依族苗族自治州",
            shortName: "黔南",
            children: [
              {
                id: 522701,
                name: "都匀市",
                shortName: "都匀",
                children: [],
              },
              {
                id: 522702,
                name: "福泉市",
                shortName: "福泉",
                children: [],
              },
              {
                id: 522722,
                name: "荔波县",
                shortName: "荔波",
                children: [],
              },
              {
                id: 522723,
                name: "贵定县",
                shortName: "贵定",
                children: [],
              },
              {
                id: 522725,
                name: "瓮安县",
                shortName: "瓮安",
                children: [],
              },
              {
                id: 522726,
                name: "独山县",
                shortName: "独山",
                children: [],
              },
              {
                id: 522727,
                name: "平塘县",
                shortName: "平塘",
                children: [],
              },
              {
                id: 522728,
                name: "罗甸县",
                shortName: "罗甸",
                children: [],
              },
              {
                id: 522729,
                name: "长顺县",
                shortName: "长顺",
                children: [],
              },
              {
                id: 522730,
                name: "龙里县",
                shortName: "龙里",
                children: [],
              },
              {
                id: 522731,
                name: "惠水县",
                shortName: "惠水",
                children: [],
              },
              {
                id: 522732,
                name: "三都水族自治县",
                shortName: "三都",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 530000,
        name: "云南省",
        shortName: "云南",
        children: [
          {
            id: 530100,
            name: "昆明市",
            shortName: "昆明",
            children: [
              {
                id: 530102,
                name: "五华区",
                shortName: "五华",
                children: [],
              },
              {
                id: 530103,
                name: "盘龙区",
                shortName: "盘龙",
                children: [],
              },
              {
                id: 530111,
                name: "官渡区",
                shortName: "官渡",
                children: [],
              },
              {
                id: 530112,
                name: "西山区",
                shortName: "西山",
                children: [],
              },
              {
                id: 530113,
                name: "东川区",
                shortName: "东川",
                children: [],
              },
              {
                id: 530114,
                name: "呈贡区",
                shortName: "呈贡",
                children: [],
              },
              {
                id: 530115,
                name: "晋宁区",
                shortName: "晋宁",
                children: [],
              },
              {
                id: 530124,
                name: "富民县",
                shortName: "富民",
                children: [],
              },
              {
                id: 530125,
                name: "宜良县",
                shortName: "宜良",
                children: [],
              },
              {
                id: 530126,
                name: "石林彝族自治县",
                shortName: "石林",
                children: [],
              },
              {
                id: 530127,
                name: "嵩明县",
                shortName: "嵩明",
                children: [],
              },
              {
                id: 530128,
                name: "禄劝彝族苗族自治县",
                shortName: "禄劝",
                children: [],
              },
              {
                id: 530129,
                name: "寻甸回族彝族自治县",
                shortName: "寻甸",
                children: [],
              },
              {
                id: 530181,
                name: "安宁市",
                shortName: "安宁",
                children: [],
              },
              {
                id: 530182,
                name: "滇中新区",
                shortName: "滇中新区",
                children: [],
              },
              {
                id: 530183,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 530300,
            name: "曲靖市",
            shortName: "曲靖",
            children: [
              {
                id: 530302,
                name: "麒麟区",
                shortName: "麒麟",
                children: [],
              },
              {
                id: 530303,
                name: "沾益区",
                shortName: "沾益",
                children: [],
              },
              {
                id: 530321,
                name: "马龙县",
                shortName: "马龙",
                children: [],
              },
              {
                id: 530322,
                name: "陆良县",
                shortName: "陆良",
                children: [],
              },
              {
                id: 530323,
                name: "师宗县",
                shortName: "师宗",
                children: [],
              },
              {
                id: 530324,
                name: "罗平县",
                shortName: "罗平",
                children: [],
              },
              {
                id: 530325,
                name: "富源县",
                shortName: "富源",
                children: [],
              },
              {
                id: 530326,
                name: "会泽县",
                shortName: "会泽",
                children: [],
              },
              {
                id: 530381,
                name: "宣威市",
                shortName: "宣威",
                children: [],
              },
            ],
          },
          {
            id: 530400,
            name: "玉溪市",
            shortName: "玉溪",
            children: [
              {
                id: 530402,
                name: "红塔区",
                shortName: "红塔",
                children: [],
              },
              {
                id: 530403,
                name: "江川区",
                shortName: "江川",
                children: [],
              },
              {
                id: 530422,
                name: "澄江县",
                shortName: "澄江",
                children: [],
              },
              {
                id: 530423,
                name: "通海县",
                shortName: "通海",
                children: [],
              },
              {
                id: 530424,
                name: "华宁县",
                shortName: "华宁",
                children: [],
              },
              {
                id: 530425,
                name: "易门县",
                shortName: "易门",
                children: [],
              },
              {
                id: 530426,
                name: "峨山彝族自治县",
                shortName: "峨山",
                children: [],
              },
              {
                id: 530427,
                name: "新平彝族傣族自治县",
                shortName: "新平",
                children: [],
              },
              {
                id: 530428,
                name: "元江哈尼族彝族傣族自治县",
                shortName: "元江",
                children: [],
              },
            ],
          },
          {
            id: 530500,
            name: "保山市",
            shortName: "保山",
            children: [
              {
                id: 530502,
                name: "隆阳区",
                shortName: "隆阳",
                children: [],
              },
              {
                id: 530521,
                name: "施甸县",
                shortName: "施甸",
                children: [],
              },
              {
                id: 530523,
                name: "龙陵县",
                shortName: "龙陵",
                children: [],
              },
              {
                id: 530524,
                name: "昌宁县",
                shortName: "昌宁",
                children: [],
              },
              {
                id: 530581,
                name: "腾冲市",
                shortName: "腾冲",
                children: [],
              },
            ],
          },
          {
            id: 530600,
            name: "昭通市",
            shortName: "昭通",
            children: [
              {
                id: 530602,
                name: "昭阳区",
                shortName: "昭阳",
                children: [],
              },
              {
                id: 530621,
                name: "鲁甸县",
                shortName: "鲁甸",
                children: [],
              },
              {
                id: 530622,
                name: "巧家县",
                shortName: "巧家",
                children: [],
              },
              {
                id: 530623,
                name: "盐津县",
                shortName: "盐津",
                children: [],
              },
              {
                id: 530624,
                name: "大关县",
                shortName: "大关",
                children: [],
              },
              {
                id: 530625,
                name: "永善县",
                shortName: "永善",
                children: [],
              },
              {
                id: 530626,
                name: "绥江县",
                shortName: "绥江",
                children: [],
              },
              {
                id: 530627,
                name: "镇雄县",
                shortName: "镇雄",
                children: [],
              },
              {
                id: 530628,
                name: "彝良县",
                shortName: "彝良",
                children: [],
              },
              {
                id: 530629,
                name: "威信县",
                shortName: "威信",
                children: [],
              },
              {
                id: 530630,
                name: "水富县",
                shortName: "水富",
                children: [],
              },
            ],
          },
          {
            id: 530700,
            name: "丽江市",
            shortName: "丽江",
            children: [
              {
                id: 530702,
                name: "古城区",
                shortName: "古城",
                children: [],
              },
              {
                id: 530721,
                name: "玉龙纳西族自治县",
                shortName: "玉龙",
                children: [],
              },
              {
                id: 530722,
                name: "永胜县",
                shortName: "永胜",
                children: [],
              },
              {
                id: 530723,
                name: "华坪县",
                shortName: "华坪",
                children: [],
              },
              {
                id: 530724,
                name: "宁蒗彝族自治县",
                shortName: "宁蒗",
                children: [],
              },
            ],
          },
          {
            id: 530800,
            name: "普洱市",
            shortName: "普洱",
            children: [
              {
                id: 530802,
                name: "思茅区",
                shortName: "思茅",
                children: [],
              },
              {
                id: 530821,
                name: "宁洱哈尼族彝族自治县",
                shortName: "宁洱",
                children: [],
              },
              {
                id: 530822,
                name: "墨江哈尼族自治县",
                shortName: "墨江",
                children: [],
              },
              {
                id: 530823,
                name: "景东彝族自治县",
                shortName: "景东",
                children: [],
              },
              {
                id: 530824,
                name: "景谷傣族彝族自治县",
                shortName: "景谷",
                children: [],
              },
              {
                id: 530825,
                name: "镇沅彝族哈尼族拉祜族自治县",
                shortName: "镇沅",
                children: [],
              },
              {
                id: 530826,
                name: "江城哈尼族彝族自治县",
                shortName: "江城",
                children: [],
              },
              {
                id: 530827,
                name: "孟连傣族拉祜族佤族自治县",
                shortName: "孟连",
                children: [],
              },
              {
                id: 530828,
                name: "澜沧拉祜族自治县",
                shortName: "澜沧",
                children: [],
              },
              {
                id: 530829,
                name: "西盟佤族自治县",
                shortName: "西盟",
                children: [],
              },
            ],
          },
          {
            id: 530900,
            name: "临沧市",
            shortName: "临沧",
            children: [
              {
                id: 530902,
                name: "临翔区",
                shortName: "临翔",
                children: [],
              },
              {
                id: 530921,
                name: "凤庆县",
                shortName: "凤庆",
                children: [],
              },
              {
                id: 530922,
                name: "云县",
                shortName: "云县",
                children: [],
              },
              {
                id: 530923,
                name: "永德县",
                shortName: "永德",
                children: [],
              },
              {
                id: 530924,
                name: "镇康县",
                shortName: "镇康",
                children: [],
              },
              {
                id: 530925,
                name: "双江拉祜族佤族布朗族傣族自治县",
                shortName: "双江",
                children: [],
              },
              {
                id: 530926,
                name: "耿马傣族佤族自治县",
                shortName: "耿马",
                children: [],
              },
              {
                id: 530927,
                name: "沧源佤族自治县",
                shortName: "沧源",
                children: [],
              },
            ],
          },
          {
            id: 532300,
            name: "楚雄彝族自治州",
            shortName: "楚雄",
            children: [
              {
                id: 532301,
                name: "楚雄市",
                shortName: "楚雄",
                children: [],
              },
              {
                id: 532322,
                name: "双柏县",
                shortName: "双柏",
                children: [],
              },
              {
                id: 532323,
                name: "牟定县",
                shortName: "牟定",
                children: [],
              },
              {
                id: 532324,
                name: "南华县",
                shortName: "南华",
                children: [],
              },
              {
                id: 532325,
                name: "姚安县",
                shortName: "姚安",
                children: [],
              },
              {
                id: 532326,
                name: "大姚县",
                shortName: "大姚",
                children: [],
              },
              {
                id: 532327,
                name: "永仁县",
                shortName: "永仁",
                children: [],
              },
              {
                id: 532328,
                name: "元谋县",
                shortName: "元谋",
                children: [],
              },
              {
                id: 532329,
                name: "武定县",
                shortName: "武定",
                children: [],
              },
              {
                id: 532331,
                name: "禄丰县",
                shortName: "禄丰",
                children: [],
              },
            ],
          },
          {
            id: 532500,
            name: "红河哈尼族彝族自治州",
            shortName: "红河",
            children: [
              {
                id: 532501,
                name: "个旧市",
                shortName: "个旧",
                children: [],
              },
              {
                id: 532502,
                name: "开远市",
                shortName: "开远",
                children: [],
              },
              {
                id: 532503,
                name: "蒙自市",
                shortName: "蒙自",
                children: [],
              },
              {
                id: 532504,
                name: "弥勒市",
                shortName: "弥勒",
                children: [],
              },
              {
                id: 532523,
                name: "屏边苗族自治县",
                shortName: "屏边",
                children: [],
              },
              {
                id: 532524,
                name: "建水县",
                shortName: "建水",
                children: [],
              },
              {
                id: 532525,
                name: "石屏县",
                shortName: "石屏",
                children: [],
              },
              {
                id: 532527,
                name: "泸西县",
                shortName: "泸西",
                children: [],
              },
              {
                id: 532528,
                name: "元阳县",
                shortName: "元阳",
                children: [],
              },
              {
                id: 532529,
                name: "红河县",
                shortName: "红河县",
                children: [],
              },
              {
                id: 532530,
                name: "金平苗族瑶族傣族自治县",
                shortName: "金平",
                children: [],
              },
              {
                id: 532531,
                name: "绿春县",
                shortName: "绿春",
                children: [],
              },
              {
                id: 532532,
                name: "河口瑶族自治县",
                shortName: "河口",
                children: [],
              },
            ],
          },
          {
            id: 532600,
            name: "文山壮族苗族自治州",
            shortName: "文山",
            children: [
              {
                id: 532601,
                name: "文山市",
                shortName: "文山",
                children: [],
              },
              {
                id: 532622,
                name: "砚山县",
                shortName: "砚山",
                children: [],
              },
              {
                id: 532623,
                name: "西畴县",
                shortName: "西畴",
                children: [],
              },
              {
                id: 532624,
                name: "麻栗坡县",
                shortName: "麻栗坡",
                children: [],
              },
              {
                id: 532625,
                name: "马关县",
                shortName: "马关",
                children: [],
              },
              {
                id: 532626,
                name: "丘北县",
                shortName: "丘北",
                children: [],
              },
              {
                id: 532627,
                name: "广南县",
                shortName: "广南",
                children: [],
              },
              {
                id: 532628,
                name: "富宁县",
                shortName: "富宁",
                children: [],
              },
            ],
          },
          {
            id: 532800,
            name: "西双版纳傣族自治州",
            shortName: "西双版纳",
            children: [
              {
                id: 532801,
                name: "景洪市",
                shortName: "景洪",
                children: [],
              },
              {
                id: 532822,
                name: "勐海县",
                shortName: "勐海",
                children: [],
              },
              {
                id: 532823,
                name: "勐腊县",
                shortName: "勐腊",
                children: [],
              },
            ],
          },
          {
            id: 532900,
            name: "大理白族自治州",
            shortName: "大理",
            children: [
              {
                id: 532901,
                name: "大理市",
                shortName: "大理",
                children: [],
              },
              {
                id: 532922,
                name: "漾濞彝族自治县",
                shortName: "漾濞",
                children: [],
              },
              {
                id: 532923,
                name: "祥云县",
                shortName: "祥云",
                children: [],
              },
              {
                id: 532924,
                name: "宾川县",
                shortName: "宾川",
                children: [],
              },
              {
                id: 532925,
                name: "弥渡县",
                shortName: "弥渡",
                children: [],
              },
              {
                id: 532926,
                name: "南涧彝族自治县",
                shortName: "南涧",
                children: [],
              },
              {
                id: 532927,
                name: "巍山彝族回族自治县",
                shortName: "巍山",
                children: [],
              },
              {
                id: 532928,
                name: "永平县",
                shortName: "永平",
                children: [],
              },
              {
                id: 532929,
                name: "云龙县",
                shortName: "云龙",
                children: [],
              },
              {
                id: 532930,
                name: "洱源县",
                shortName: "洱源",
                children: [],
              },
              {
                id: 532931,
                name: "剑川县",
                shortName: "剑川",
                children: [],
              },
              {
                id: 532932,
                name: "鹤庆县",
                shortName: "鹤庆",
                children: [],
              },
            ],
          },
          {
            id: 533100,
            name: "德宏傣族景颇族自治州",
            shortName: "德宏",
            children: [
              {
                id: 533102,
                name: "瑞丽市",
                shortName: "瑞丽",
                children: [],
              },
              {
                id: 533103,
                name: "芒市",
                shortName: "芒市",
                children: [],
              },
              {
                id: 533122,
                name: "梁河县",
                shortName: "梁河",
                children: [],
              },
              {
                id: 533123,
                name: "盈江县",
                shortName: "盈江",
                children: [],
              },
              {
                id: 533124,
                name: "陇川县",
                shortName: "陇川",
                children: [],
              },
            ],
          },
          {
            id: 533300,
            name: "怒江傈僳族自治州",
            shortName: "怒江",
            children: [
              {
                id: 533301,
                name: "泸水市",
                shortName: "泸水",
                children: [],
              },
              {
                id: 533323,
                name: "福贡县",
                shortName: "福贡",
                children: [],
              },
              {
                id: 533324,
                name: "贡山独龙族怒族自治县",
                shortName: "贡山",
                children: [],
              },
              {
                id: 533325,
                name: "兰坪白族普米族自治县",
                shortName: "兰坪",
                children: [],
              },
            ],
          },
          {
            id: 533400,
            name: "迪庆藏族自治州",
            shortName: "迪庆",
            children: [
              {
                id: 533401,
                name: "香格里拉市",
                shortName: "香格里拉",
                children: [],
              },
              {
                id: 533422,
                name: "德钦县",
                shortName: "德钦",
                children: [],
              },
              {
                id: 533423,
                name: "维西傈僳族自治县",
                shortName: "维西",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 540000,
        name: "西藏自治区",
        shortName: "西藏",
        children: [
          {
            id: 540100,
            name: "拉萨市",
            shortName: "拉萨",
            children: [
              {
                id: 540102,
                name: "城关区",
                shortName: "城关",
                children: [],
              },
              {
                id: 540103,
                name: "堆龙德庆区",
                shortName: "堆龙德庆",
                children: [],
              },
              {
                id: 540121,
                name: "林周县",
                shortName: "林周",
                children: [],
              },
              {
                id: 540122,
                name: "当雄县",
                shortName: "当雄",
                children: [],
              },
              {
                id: 540123,
                name: "尼木县",
                shortName: "尼木",
                children: [],
              },
              {
                id: 540124,
                name: "曲水县",
                shortName: "曲水",
                children: [],
              },
              {
                id: 540126,
                name: "达孜县",
                shortName: "达孜",
                children: [],
              },
              {
                id: 540127,
                name: "墨竹工卡县",
                shortName: "墨竹工卡",
                children: [],
              },
            ],
          },
          {
            id: 540200,
            name: "日喀则市",
            shortName: "日喀则",
            children: [
              {
                id: 540202,
                name: "桑珠孜区",
                shortName: "桑珠孜",
                children: [],
              },
              {
                id: 540221,
                name: "南木林县",
                shortName: "南木林",
                children: [],
              },
              {
                id: 540222,
                name: "江孜县",
                shortName: "江孜",
                children: [],
              },
              {
                id: 540223,
                name: "定日县",
                shortName: "定日",
                children: [],
              },
              {
                id: 540224,
                name: "萨迦县",
                shortName: "萨迦",
                children: [],
              },
              {
                id: 540225,
                name: "拉孜县",
                shortName: "拉孜",
                children: [],
              },
              {
                id: 540226,
                name: "昂仁县",
                shortName: "昂仁",
                children: [],
              },
              {
                id: 540227,
                name: "谢通门县",
                shortName: "谢通门",
                children: [],
              },
              {
                id: 540228,
                name: "白朗县",
                shortName: "白朗",
                children: [],
              },
              {
                id: 540229,
                name: "仁布县",
                shortName: "仁布",
                children: [],
              },
              {
                id: 540230,
                name: "康马县",
                shortName: "康马",
                children: [],
              },
              {
                id: 540231,
                name: "定结县",
                shortName: "定结",
                children: [],
              },
              {
                id: 540232,
                name: "仲巴县",
                shortName: "仲巴",
                children: [],
              },
              {
                id: 540233,
                name: "亚东县",
                shortName: "亚东",
                children: [],
              },
              {
                id: 540234,
                name: "吉隆县",
                shortName: "吉隆",
                children: [],
              },
              {
                id: 540235,
                name: "聂拉木县",
                shortName: "聂拉木",
                children: [],
              },
              {
                id: 540236,
                name: "萨嘎县",
                shortName: "萨嘎",
                children: [],
              },
              {
                id: 540237,
                name: "岗巴县",
                shortName: "岗巴",
                children: [],
              },
            ],
          },
          {
            id: 540300,
            name: "昌都市",
            shortName: "昌都",
            children: [
              {
                id: 540302,
                name: "卡若区",
                shortName: "卡若",
                children: [],
              },
              {
                id: 540321,
                name: "江达县",
                shortName: "江达",
                children: [],
              },
              {
                id: 540322,
                name: "贡觉县",
                shortName: "贡觉",
                children: [],
              },
              {
                id: 540323,
                name: "类乌齐县",
                shortName: "类乌齐",
                children: [],
              },
              {
                id: 540324,
                name: "丁青县",
                shortName: "丁青",
                children: [],
              },
              {
                id: 540325,
                name: "察雅县",
                shortName: "察雅",
                children: [],
              },
              {
                id: 540326,
                name: "八宿县",
                shortName: "八宿",
                children: [],
              },
              {
                id: 540327,
                name: "左贡县",
                shortName: "左贡",
                children: [],
              },
              {
                id: 540328,
                name: "芒康县",
                shortName: "芒康",
                children: [],
              },
              {
                id: 540329,
                name: "洛隆县",
                shortName: "洛隆",
                children: [],
              },
              {
                id: 540330,
                name: "边坝县",
                shortName: "边坝",
                children: [],
              },
            ],
          },
          {
            id: 540400,
            name: "林芝市",
            shortName: "林芝",
            children: [
              {
                id: 540402,
                name: "巴宜区",
                shortName: "巴宜",
                children: [],
              },
              {
                id: 540421,
                name: "工布江达县",
                shortName: "工布江达",
                children: [],
              },
              {
                id: 540422,
                name: "米林县",
                shortName: "米林",
                children: [],
              },
              {
                id: 540423,
                name: "墨脱县",
                shortName: "墨脱",
                children: [],
              },
              {
                id: 540424,
                name: "波密县",
                shortName: "波密",
                children: [],
              },
              {
                id: 540425,
                name: "察隅县",
                shortName: "察隅",
                children: [],
              },
              {
                id: 540426,
                name: "朗县",
                shortName: "朗县",
                children: [],
              },
            ],
          },
          {
            id: 540500,
            name: "山南市",
            shortName: "山南",
            children: [
              {
                id: 540502,
                name: "乃东区",
                shortName: "乃东",
                children: [],
              },
              {
                id: 540521,
                name: "扎囊县",
                shortName: "扎囊",
                children: [],
              },
              {
                id: 540522,
                name: "贡嘎县",
                shortName: "贡嘎",
                children: [],
              },
              {
                id: 540523,
                name: "桑日县",
                shortName: "桑日",
                children: [],
              },
              {
                id: 540524,
                name: "琼结县",
                shortName: "琼结",
                children: [],
              },
              {
                id: 540525,
                name: "曲松县",
                shortName: "曲松",
                children: [],
              },
              {
                id: 540526,
                name: "措美县",
                shortName: "措美",
                children: [],
              },
              {
                id: 540527,
                name: "洛扎县",
                shortName: "洛扎",
                children: [],
              },
              {
                id: 540528,
                name: "加查县",
                shortName: "加查",
                children: [],
              },
              {
                id: 540529,
                name: "隆子县",
                shortName: "隆子",
                children: [],
              },
              {
                id: 540530,
                name: "错那县",
                shortName: "错那",
                children: [],
              },
              {
                id: 540531,
                name: "浪卡子县",
                shortName: "浪卡子",
                children: [],
              },
            ],
          },
          {
            id: 542400,
            name: "那曲地区",
            shortName: "那曲",
            children: [
              {
                id: 542421,
                name: "那曲县",
                shortName: "那曲",
                children: [],
              },
              {
                id: 542422,
                name: "嘉黎县",
                shortName: "嘉黎",
                children: [],
              },
              {
                id: 542423,
                name: "比如县",
                shortName: "比如",
                children: [],
              },
              {
                id: 542424,
                name: "聂荣县",
                shortName: "聂荣",
                children: [],
              },
              {
                id: 542425,
                name: "安多县",
                shortName: "安多",
                children: [],
              },
              {
                id: 542426,
                name: "申扎县",
                shortName: "申扎",
                children: [],
              },
              {
                id: 542427,
                name: "索县",
                shortName: "索县",
                children: [],
              },
              {
                id: 542428,
                name: "班戈县",
                shortName: "班戈",
                children: [],
              },
              {
                id: 542429,
                name: "巴青县",
                shortName: "巴青",
                children: [],
              },
              {
                id: 542430,
                name: "尼玛县",
                shortName: "尼玛",
                children: [],
              },
              {
                id: 542431,
                name: "双湖县",
                shortName: "双湖",
                children: [],
              },
            ],
          },
          {
            id: 542500,
            name: "阿里地区",
            shortName: "阿里",
            children: [
              {
                id: 542521,
                name: "普兰县",
                shortName: "普兰",
                children: [],
              },
              {
                id: 542522,
                name: "札达县",
                shortName: "札达",
                children: [],
              },
              {
                id: 542523,
                name: "噶尔县",
                shortName: "噶尔",
                children: [],
              },
              {
                id: 542524,
                name: "日土县",
                shortName: "日土",
                children: [],
              },
              {
                id: 542525,
                name: "革吉县",
                shortName: "革吉",
                children: [],
              },
              {
                id: 542526,
                name: "改则县",
                shortName: "改则",
                children: [],
              },
              {
                id: 542527,
                name: "措勤县",
                shortName: "措勤",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 610000,
        name: "陕西省",
        shortName: "陕西",
        children: [
          {
            id: 610100,
            name: "西安市",
            shortName: "西安",
            children: [
              {
                id: 610102,
                name: "新城区",
                shortName: "新城",
                children: [],
              },
              {
                id: 610103,
                name: "碑林区",
                shortName: "碑林",
                children: [],
              },
              {
                id: 610104,
                name: "莲湖区",
                shortName: "莲湖",
                children: [],
              },
              {
                id: 610111,
                name: "灞桥区",
                shortName: "灞桥",
                children: [],
              },
              {
                id: 610112,
                name: "未央区",
                shortName: "未央",
                children: [],
              },
              {
                id: 610113,
                name: "雁塔区",
                shortName: "雁塔",
                children: [],
              },
              {
                id: 610114,
                name: "阎良区",
                shortName: "阎良",
                children: [],
              },
              {
                id: 610115,
                name: "临潼区",
                shortName: "临潼",
                children: [],
              },
              {
                id: 610116,
                name: "长安区",
                shortName: "长安",
                children: [],
              },
              {
                id: 610117,
                name: "高陵区",
                shortName: "高陵",
                children: [],
              },
              {
                id: 610118,
                name: "鄠邑区",
                shortName: "鄠邑",
                children: [],
              },
              {
                id: 610122,
                name: "蓝田县",
                shortName: "蓝田",
                children: [],
              },
              {
                id: 610124,
                name: "周至县",
                shortName: "周至",
                children: [],
              },
              {
                id: 610125,
                name: "西咸新区",
                shortName: "西咸新区",
                children: [],
              },
              {
                id: 610127,
                name: "曲江新区",
                shortName: "曲江新区",
                children: [],
              },
              {
                id: 610128,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 610200,
            name: "铜川市",
            shortName: "铜川",
            children: [
              {
                id: 610202,
                name: "王益区",
                shortName: "王益",
                children: [],
              },
              {
                id: 610203,
                name: "印台区",
                shortName: "印台",
                children: [],
              },
              {
                id: 610204,
                name: "耀州区",
                shortName: "耀州",
                children: [],
              },
              {
                id: 610222,
                name: "宜君县",
                shortName: "宜君",
                children: [],
              },
            ],
          },
          {
            id: 610300,
            name: "宝鸡市",
            shortName: "宝鸡",
            children: [
              {
                id: 610302,
                name: "渭滨区",
                shortName: "渭滨",
                children: [],
              },
              {
                id: 610303,
                name: "金台区",
                shortName: "金台",
                children: [],
              },
              {
                id: 610304,
                name: "陈仓区",
                shortName: "陈仓",
                children: [],
              },
              {
                id: 610322,
                name: "凤翔县",
                shortName: "凤翔",
                children: [],
              },
              {
                id: 610323,
                name: "岐山县",
                shortName: "岐山",
                children: [],
              },
              {
                id: 610324,
                name: "扶风县",
                shortName: "扶风",
                children: [],
              },
              {
                id: 610326,
                name: "眉县",
                shortName: "眉县",
                children: [],
              },
              {
                id: 610327,
                name: "陇县",
                shortName: "陇县",
                children: [],
              },
              {
                id: 610328,
                name: "千阳县",
                shortName: "千阳",
                children: [],
              },
              {
                id: 610329,
                name: "麟游县",
                shortName: "麟游",
                children: [],
              },
              {
                id: 610330,
                name: "凤县",
                shortName: "凤县",
                children: [],
              },
              {
                id: 610331,
                name: "太白县",
                shortName: "太白",
                children: [],
              },
              {
                id: 610332,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 610400,
            name: "咸阳市",
            shortName: "咸阳",
            children: [
              {
                id: 610402,
                name: "秦都区",
                shortName: "秦都",
                children: [],
              },
              {
                id: 610403,
                name: "杨陵区",
                shortName: "杨陵",
                children: [],
              },
              {
                id: 610404,
                name: "渭城区",
                shortName: "渭城",
                children: [],
              },
              {
                id: 610422,
                name: "三原县",
                shortName: "三原",
                children: [],
              },
              {
                id: 610423,
                name: "泾阳县",
                shortName: "泾阳",
                children: [],
              },
              {
                id: 610424,
                name: "乾县",
                shortName: "乾县",
                children: [],
              },
              {
                id: 610425,
                name: "礼泉县",
                shortName: "礼泉",
                children: [],
              },
              {
                id: 610426,
                name: "永寿县",
                shortName: "永寿",
                children: [],
              },
              {
                id: 610427,
                name: "彬县",
                shortName: "彬县",
                children: [],
              },
              {
                id: 610428,
                name: "长武县",
                shortName: "长武",
                children: [],
              },
              {
                id: 610429,
                name: "旬邑县",
                shortName: "旬邑",
                children: [],
              },
              {
                id: 610430,
                name: "淳化县",
                shortName: "淳化",
                children: [],
              },
              {
                id: 610431,
                name: "武功县",
                shortName: "武功",
                children: [],
              },
              {
                id: 610481,
                name: "兴平市",
                shortName: "兴平",
                children: [],
              },
              {
                id: 610482,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
            ],
          },
          {
            id: 610500,
            name: "渭南市",
            shortName: "渭南",
            children: [
              {
                id: 610502,
                name: "临渭区",
                shortName: "临渭",
                children: [],
              },
              {
                id: 610503,
                name: "华州区",
                shortName: "华州",
                children: [],
              },
              {
                id: 610522,
                name: "潼关县",
                shortName: "潼关",
                children: [],
              },
              {
                id: 610523,
                name: "大荔县",
                shortName: "大荔",
                children: [],
              },
              {
                id: 610524,
                name: "合阳县",
                shortName: "合阳",
                children: [],
              },
              {
                id: 610525,
                name: "澄城县",
                shortName: "澄城",
                children: [],
              },
              {
                id: 610526,
                name: "蒲城县",
                shortName: "蒲城",
                children: [],
              },
              {
                id: 610527,
                name: "白水县",
                shortName: "白水",
                children: [],
              },
              {
                id: 610528,
                name: "富平县",
                shortName: "富平",
                children: [],
              },
              {
                id: 610581,
                name: "韩城市",
                shortName: "韩城",
                children: [],
              },
              {
                id: 610582,
                name: "华阴市",
                shortName: "华阴",
                children: [],
              },
            ],
          },
          {
            id: 610600,
            name: "延安市",
            shortName: "延安",
            children: [
              {
                id: 610602,
                name: "宝塔区",
                shortName: "宝塔",
                children: [],
              },
              {
                id: 610603,
                name: "安塞区",
                shortName: "安塞",
                children: [],
              },
              {
                id: 610621,
                name: "延长县",
                shortName: "延长",
                children: [],
              },
              {
                id: 610622,
                name: "延川县",
                shortName: "延川",
                children: [],
              },
              {
                id: 610623,
                name: "子长县",
                shortName: "子长",
                children: [],
              },
              {
                id: 610625,
                name: "志丹县",
                shortName: "志丹",
                children: [],
              },
              {
                id: 610626,
                name: "吴起县",
                shortName: "吴起",
                children: [],
              },
              {
                id: 610627,
                name: "甘泉县",
                shortName: "甘泉",
                children: [],
              },
              {
                id: 610628,
                name: "富县",
                shortName: "富县",
                children: [],
              },
              {
                id: 610629,
                name: "洛川县",
                shortName: "洛川",
                children: [],
              },
              {
                id: 610630,
                name: "宜川县",
                shortName: "宜川",
                children: [],
              },
              {
                id: 610631,
                name: "黄龙县",
                shortName: "黄龙",
                children: [],
              },
              {
                id: 610632,
                name: "黄陵县",
                shortName: "黄陵",
                children: [],
              },
            ],
          },
          {
            id: 610700,
            name: "汉中市",
            shortName: "汉中",
            children: [
              {
                id: 610702,
                name: "汉台区",
                shortName: "汉台",
                children: [],
              },
              {
                id: 610721,
                name: "南郑县",
                shortName: "南郑",
                children: [],
              },
              {
                id: 610722,
                name: "城固县",
                shortName: "城固",
                children: [],
              },
              {
                id: 610723,
                name: "洋县",
                shortName: "洋县",
                children: [],
              },
              {
                id: 610724,
                name: "西乡县",
                shortName: "西乡",
                children: [],
              },
              {
                id: 610725,
                name: "勉县",
                shortName: "勉县",
                children: [],
              },
              {
                id: 610726,
                name: "宁强县",
                shortName: "宁强",
                children: [],
              },
              {
                id: 610727,
                name: "略阳县",
                shortName: "略阳",
                children: [],
              },
              {
                id: 610728,
                name: "镇巴县",
                shortName: "镇巴",
                children: [],
              },
              {
                id: 610729,
                name: "留坝县",
                shortName: "留坝",
                children: [],
              },
              {
                id: 610730,
                name: "佛坪县",
                shortName: "佛坪",
                children: [],
              },
            ],
          },
          {
            id: 610800,
            name: "榆林市",
            shortName: "榆林",
            children: [
              {
                id: 610802,
                name: "榆阳区",
                shortName: "榆阳",
                children: [],
              },
              {
                id: 610803,
                name: "横山区",
                shortName: "横山",
                children: [],
              },
              {
                id: 610822,
                name: "府谷县",
                shortName: "府谷",
                children: [],
              },
              {
                id: 610824,
                name: "靖边县",
                shortName: "靖边",
                children: [],
              },
              {
                id: 610825,
                name: "定边县",
                shortName: "定边",
                children: [],
              },
              {
                id: 610826,
                name: "绥德县",
                shortName: "绥德",
                children: [],
              },
              {
                id: 610827,
                name: "米脂县",
                shortName: "米脂",
                children: [],
              },
              {
                id: 610828,
                name: "佳县",
                shortName: "佳县",
                children: [],
              },
              {
                id: 610829,
                name: "吴堡县",
                shortName: "吴堡",
                children: [],
              },
              {
                id: 610830,
                name: "清涧县",
                shortName: "清涧",
                children: [],
              },
              {
                id: 610831,
                name: "子洲县",
                shortName: "子洲",
                children: [],
              },
              {
                id: 610881,
                name: "神木市",
                shortName: "神木",
                children: [],
              },
            ],
          },
          {
            id: 610900,
            name: "安康市",
            shortName: "安康",
            children: [
              {
                id: 610902,
                name: "汉滨区",
                shortName: "汉滨",
                children: [],
              },
              {
                id: 610921,
                name: "汉阴县",
                shortName: "汉阴",
                children: [],
              },
              {
                id: 610922,
                name: "石泉县",
                shortName: "石泉",
                children: [],
              },
              {
                id: 610923,
                name: "宁陕县",
                shortName: "宁陕",
                children: [],
              },
              {
                id: 610924,
                name: "紫阳县",
                shortName: "紫阳",
                children: [],
              },
              {
                id: 610925,
                name: "岚皋县",
                shortName: "岚皋",
                children: [],
              },
              {
                id: 610926,
                name: "平利县",
                shortName: "平利",
                children: [],
              },
              {
                id: 610927,
                name: "镇坪县",
                shortName: "镇坪",
                children: [],
              },
              {
                id: 610928,
                name: "旬阳县",
                shortName: "旬阳",
                children: [],
              },
              {
                id: 610929,
                name: "白河县",
                shortName: "白河",
                children: [],
              },
            ],
          },
          {
            id: 611000,
            name: "商洛市",
            shortName: "商洛",
            children: [
              {
                id: 611002,
                name: "商州区",
                shortName: "商州",
                children: [],
              },
              {
                id: 611021,
                name: "洛南县",
                shortName: "洛南",
                children: [],
              },
              {
                id: 611022,
                name: "丹凤县",
                shortName: "丹凤",
                children: [],
              },
              {
                id: 611023,
                name: "商南县",
                shortName: "商南",
                children: [],
              },
              {
                id: 611024,
                name: "山阳县",
                shortName: "山阳",
                children: [],
              },
              {
                id: 611025,
                name: "镇安县",
                shortName: "镇安",
                children: [],
              },
              {
                id: 611026,
                name: "柞水县",
                shortName: "柞水",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 620000,
        name: "甘肃省",
        shortName: "甘肃",
        children: [
          {
            id: 620100,
            name: "兰州市",
            shortName: "兰州",
            children: [
              {
                id: 620102,
                name: "城关区",
                shortName: "城关",
                children: [],
              },
              {
                id: 620103,
                name: "七里河区",
                shortName: "七里河",
                children: [],
              },
              {
                id: 620104,
                name: "西固区",
                shortName: "西固",
                children: [],
              },
              {
                id: 620105,
                name: "安宁区",
                shortName: "安宁",
                children: [],
              },
              {
                id: 620111,
                name: "红古区",
                shortName: "红古",
                children: [],
              },
              {
                id: 620121,
                name: "永登县",
                shortName: "永登",
                children: [],
              },
              {
                id: 620122,
                name: "皋兰县",
                shortName: "皋兰",
                children: [],
              },
              {
                id: 620123,
                name: "榆中县",
                shortName: "榆中",
                children: [],
              },
              {
                id: 620124,
                name: "兰州新区",
                shortName: "兰州新区",
                children: [],
              },
              {
                id: 620125,
                name: "高新区",
                shortName: "高新区",
                children: [],
              },
              {
                id: 620126,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 620200,
            name: "嘉峪关市",
            shortName: "嘉峪关",
            children: [
              {
                id: 620201,
                name: "雄关区",
                shortName: "雄关",
                children: [],
              },
              {
                id: 620202,
                name: "长城区",
                shortName: "长城",
                children: [],
              },
              {
                id: 620203,
                name: "镜铁区",
                shortName: "镜铁",
                children: [],
              },
            ],
          },
          {
            id: 620300,
            name: "金昌市",
            shortName: "金昌",
            children: [
              {
                id: 620302,
                name: "金川区",
                shortName: "金川",
                children: [],
              },
              {
                id: 620321,
                name: "永昌县",
                shortName: "永昌",
                children: [],
              },
            ],
          },
          {
            id: 620400,
            name: "白银市",
            shortName: "白银",
            children: [
              {
                id: 620402,
                name: "白银区",
                shortName: "白银",
                children: [],
              },
              {
                id: 620403,
                name: "平川区",
                shortName: "平川",
                children: [],
              },
              {
                id: 620421,
                name: "靖远县",
                shortName: "靖远",
                children: [],
              },
              {
                id: 620422,
                name: "会宁县",
                shortName: "会宁",
                children: [],
              },
              {
                id: 620423,
                name: "景泰县",
                shortName: "景泰",
                children: [],
              },
            ],
          },
          {
            id: 620500,
            name: "天水市",
            shortName: "天水",
            children: [
              {
                id: 620502,
                name: "秦州区",
                shortName: "秦州",
                children: [],
              },
              {
                id: 620503,
                name: "麦积区",
                shortName: "麦积",
                children: [],
              },
              {
                id: 620521,
                name: "清水县",
                shortName: "清水",
                children: [],
              },
              {
                id: 620522,
                name: "秦安县",
                shortName: "秦安",
                children: [],
              },
              {
                id: 620523,
                name: "甘谷县",
                shortName: "甘谷",
                children: [],
              },
              {
                id: 620524,
                name: "武山县",
                shortName: "武山",
                children: [],
              },
              {
                id: 620525,
                name: "张家川回族自治县",
                shortName: "张家川",
                children: [],
              },
            ],
          },
          {
            id: 620600,
            name: "武威市",
            shortName: "武威",
            children: [
              {
                id: 620602,
                name: "凉州区",
                shortName: "凉州",
                children: [],
              },
              {
                id: 620621,
                name: "民勤县",
                shortName: "民勤",
                children: [],
              },
              {
                id: 620622,
                name: "古浪县",
                shortName: "古浪",
                children: [],
              },
              {
                id: 620623,
                name: "天祝藏族自治县",
                shortName: "天祝",
                children: [],
              },
            ],
          },
          {
            id: 620700,
            name: "张掖市",
            shortName: "张掖",
            children: [
              {
                id: 620702,
                name: "甘州区",
                shortName: "甘州",
                children: [],
              },
              {
                id: 620721,
                name: "肃南裕固族自治县",
                shortName: "肃南",
                children: [],
              },
              {
                id: 620722,
                name: "民乐县",
                shortName: "民乐",
                children: [],
              },
              {
                id: 620723,
                name: "临泽县",
                shortName: "临泽",
                children: [],
              },
              {
                id: 620724,
                name: "高台县",
                shortName: "高台",
                children: [],
              },
              {
                id: 620725,
                name: "山丹县",
                shortName: "山丹",
                children: [],
              },
            ],
          },
          {
            id: 620800,
            name: "平凉市",
            shortName: "平凉",
            children: [
              {
                id: 620802,
                name: "崆峒区",
                shortName: "崆峒",
                children: [],
              },
              {
                id: 620821,
                name: "泾川县",
                shortName: "泾川",
                children: [],
              },
              {
                id: 620822,
                name: "灵台县",
                shortName: "灵台",
                children: [],
              },
              {
                id: 620823,
                name: "崇信县",
                shortName: "崇信",
                children: [],
              },
              {
                id: 620824,
                name: "华亭县",
                shortName: "华亭",
                children: [],
              },
              {
                id: 620825,
                name: "庄浪县",
                shortName: "庄浪",
                children: [],
              },
              {
                id: 620826,
                name: "静宁县",
                shortName: "静宁",
                children: [],
              },
            ],
          },
          {
            id: 620900,
            name: "酒泉市",
            shortName: "酒泉",
            children: [
              {
                id: 620902,
                name: "肃州区",
                shortName: "肃州",
                children: [],
              },
              {
                id: 620921,
                name: "金塔县",
                shortName: "金塔",
                children: [],
              },
              {
                id: 620922,
                name: "瓜州县",
                shortName: "瓜州",
                children: [],
              },
              {
                id: 620923,
                name: "肃北蒙古族自治县",
                shortName: "肃北",
                children: [],
              },
              {
                id: 620924,
                name: "阿克塞哈萨克族自治县",
                shortName: "阿克塞",
                children: [],
              },
              {
                id: 620981,
                name: "玉门市",
                shortName: "玉门",
                children: [],
              },
              {
                id: 620982,
                name: "敦煌市",
                shortName: "敦煌",
                children: [],
              },
            ],
          },
          {
            id: 621000,
            name: "庆阳市",
            shortName: "庆阳",
            children: [
              {
                id: 621002,
                name: "西峰区",
                shortName: "西峰",
                children: [],
              },
              {
                id: 621021,
                name: "庆城县",
                shortName: "庆城",
                children: [],
              },
              {
                id: 621022,
                name: "环县",
                shortName: "环县",
                children: [],
              },
              {
                id: 621023,
                name: "华池县",
                shortName: "华池",
                children: [],
              },
              {
                id: 621024,
                name: "合水县",
                shortName: "合水",
                children: [],
              },
              {
                id: 621025,
                name: "正宁县",
                shortName: "正宁",
                children: [],
              },
              {
                id: 621026,
                name: "宁县",
                shortName: "宁县",
                children: [],
              },
              {
                id: 621027,
                name: "镇原县",
                shortName: "镇原",
                children: [],
              },
            ],
          },
          {
            id: 621100,
            name: "定西市",
            shortName: "定西",
            children: [
              {
                id: 621102,
                name: "安定区",
                shortName: "安定",
                children: [],
              },
              {
                id: 621121,
                name: "通渭县",
                shortName: "通渭",
                children: [],
              },
              {
                id: 621122,
                name: "陇西县",
                shortName: "陇西",
                children: [],
              },
              {
                id: 621123,
                name: "渭源县",
                shortName: "渭源",
                children: [],
              },
              {
                id: 621124,
                name: "临洮县",
                shortName: "临洮",
                children: [],
              },
              {
                id: 621125,
                name: "漳县",
                shortName: "漳县",
                children: [],
              },
              {
                id: 621126,
                name: "岷县",
                shortName: "岷县",
                children: [],
              },
            ],
          },
          {
            id: 621200,
            name: "陇南市",
            shortName: "陇南",
            children: [
              {
                id: 621202,
                name: "武都区",
                shortName: "武都",
                children: [],
              },
              {
                id: 621221,
                name: "成县",
                shortName: "成县",
                children: [],
              },
              {
                id: 621222,
                name: "文县",
                shortName: "文县",
                children: [],
              },
              {
                id: 621223,
                name: "宕昌县",
                shortName: "宕昌",
                children: [],
              },
              {
                id: 621224,
                name: "康县",
                shortName: "康县",
                children: [],
              },
              {
                id: 621225,
                name: "西和县",
                shortName: "西和",
                children: [],
              },
              {
                id: 621226,
                name: "礼县",
                shortName: "礼县",
                children: [],
              },
              {
                id: 621227,
                name: "徽县",
                shortName: "徽县",
                children: [],
              },
              {
                id: 621228,
                name: "两当县",
                shortName: "两当",
                children: [],
              },
            ],
          },
          {
            id: 622900,
            name: "临夏回族自治州",
            shortName: "临夏",
            children: [
              {
                id: 622901,
                name: "临夏市",
                shortName: "临夏",
                children: [],
              },
              {
                id: 622921,
                name: "临夏县",
                shortName: "临夏",
                children: [],
              },
              {
                id: 622922,
                name: "康乐县",
                shortName: "康乐",
                children: [],
              },
              {
                id: 622923,
                name: "永靖县",
                shortName: "永靖",
                children: [],
              },
              {
                id: 622924,
                name: "广河县",
                shortName: "广河",
                children: [],
              },
              {
                id: 622925,
                name: "和政县",
                shortName: "和政",
                children: [],
              },
              {
                id: 622926,
                name: "东乡族自治县",
                shortName: "东乡族",
                children: [],
              },
              {
                id: 622927,
                name: "积石山保安族东乡族撒拉族自治县",
                shortName: "积石山",
                children: [],
              },
            ],
          },
          {
            id: 623000,
            name: "甘南藏族自治州",
            shortName: "甘南",
            children: [
              {
                id: 623001,
                name: "合作市",
                shortName: "合作",
                children: [],
              },
              {
                id: 623021,
                name: "临潭县",
                shortName: "临潭",
                children: [],
              },
              {
                id: 623022,
                name: "卓尼县",
                shortName: "卓尼",
                children: [],
              },
              {
                id: 623023,
                name: "舟曲县",
                shortName: "舟曲",
                children: [],
              },
              {
                id: 623024,
                name: "迭部县",
                shortName: "迭部",
                children: [],
              },
              {
                id: 623025,
                name: "玛曲县",
                shortName: "玛曲",
                children: [],
              },
              {
                id: 623026,
                name: "碌曲县",
                shortName: "碌曲",
                children: [],
              },
              {
                id: 623027,
                name: "夏河县",
                shortName: "夏河",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 630000,
        name: "青海省",
        shortName: "青海",
        children: [
          {
            id: 630100,
            name: "西宁市",
            shortName: "西宁",
            children: [
              {
                id: 630102,
                name: "城东区",
                shortName: "城东",
                children: [],
              },
              {
                id: 630103,
                name: "城中区",
                shortName: "城中",
                children: [],
              },
              {
                id: 630104,
                name: "城西区",
                shortName: "城西",
                children: [],
              },
              {
                id: 630105,
                name: "城北区",
                shortName: "城北",
                children: [],
              },
              {
                id: 630121,
                name: "大通回族土族自治县",
                shortName: "大通",
                children: [],
              },
              {
                id: 630122,
                name: "湟中县",
                shortName: "湟中",
                children: [],
              },
              {
                id: 630123,
                name: "湟源县",
                shortName: "湟源",
                children: [],
              },
            ],
          },
          {
            id: 630200,
            name: "海东市",
            shortName: "海东",
            children: [
              {
                id: 630202,
                name: "乐都区",
                shortName: "乐都",
                children: [],
              },
              {
                id: 630203,
                name: "平安区",
                shortName: "平安",
                children: [],
              },
              {
                id: 630222,
                name: "民和回族土族自治县",
                shortName: "民和",
                children: [],
              },
              {
                id: 630223,
                name: "互助土族自治县",
                shortName: "互助",
                children: [],
              },
              {
                id: 630224,
                name: "化隆回族自治县",
                shortName: "化隆",
                children: [],
              },
              {
                id: 630225,
                name: "循化撒拉族自治县",
                shortName: "循化",
                children: [],
              },
            ],
          },
          {
            id: 632200,
            name: "海北藏族自治州",
            shortName: "海北",
            children: [
              {
                id: 632221,
                name: "门源回族自治县",
                shortName: "门源",
                children: [],
              },
              {
                id: 632222,
                name: "祁连县",
                shortName: "祁连",
                children: [],
              },
              {
                id: 632223,
                name: "海晏县",
                shortName: "海晏",
                children: [],
              },
              {
                id: 632224,
                name: "刚察县",
                shortName: "刚察",
                children: [],
              },
            ],
          },
          {
            id: 632300,
            name: "黄南藏族自治州",
            shortName: "黄南",
            children: [
              {
                id: 632321,
                name: "同仁县",
                shortName: "同仁",
                children: [],
              },
              {
                id: 632322,
                name: "尖扎县",
                shortName: "尖扎",
                children: [],
              },
              {
                id: 632323,
                name: "泽库县",
                shortName: "泽库",
                children: [],
              },
              {
                id: 632324,
                name: "河南蒙古族自治县",
                shortName: "河南",
                children: [],
              },
            ],
          },
          {
            id: 632500,
            name: "海南藏族自治州",
            shortName: "海南",
            children: [
              {
                id: 632521,
                name: "共和县",
                shortName: "共和",
                children: [],
              },
              {
                id: 632522,
                name: "同德县",
                shortName: "同德",
                children: [],
              },
              {
                id: 632523,
                name: "贵德县",
                shortName: "贵德",
                children: [],
              },
              {
                id: 632524,
                name: "兴海县",
                shortName: "兴海",
                children: [],
              },
              {
                id: 632525,
                name: "贵南县",
                shortName: "贵南",
                children: [],
              },
            ],
          },
          {
            id: 632600,
            name: "果洛藏族自治州",
            shortName: "果洛",
            children: [
              {
                id: 632621,
                name: "玛沁县",
                shortName: "玛沁",
                children: [],
              },
              {
                id: 632622,
                name: "班玛县",
                shortName: "班玛",
                children: [],
              },
              {
                id: 632623,
                name: "甘德县",
                shortName: "甘德",
                children: [],
              },
              {
                id: 632624,
                name: "达日县",
                shortName: "达日",
                children: [],
              },
              {
                id: 632625,
                name: "久治县",
                shortName: "久治",
                children: [],
              },
              {
                id: 632626,
                name: "玛多县",
                shortName: "玛多",
                children: [],
              },
            ],
          },
          {
            id: 632700,
            name: "玉树藏族自治州",
            shortName: "玉树",
            children: [
              {
                id: 632701,
                name: "玉树市",
                shortName: "玉树",
                children: [],
              },
              {
                id: 632722,
                name: "杂多县",
                shortName: "杂多",
                children: [],
              },
              {
                id: 632723,
                name: "称多县",
                shortName: "称多",
                children: [],
              },
              {
                id: 632724,
                name: "治多县",
                shortName: "治多",
                children: [],
              },
              {
                id: 632725,
                name: "囊谦县",
                shortName: "囊谦",
                children: [],
              },
              {
                id: 632726,
                name: "曲麻莱县",
                shortName: "曲麻莱",
                children: [],
              },
            ],
          },
          {
            id: 632800,
            name: "海西蒙古族藏族自治州",
            shortName: "海西",
            children: [
              {
                id: 632801,
                name: "格尔木市",
                shortName: "格尔木",
                children: [],
              },
              {
                id: 632802,
                name: "德令哈市",
                shortName: "德令哈",
                children: [],
              },
              {
                id: 632821,
                name: "乌兰县",
                shortName: "乌兰",
                children: [],
              },
              {
                id: 632822,
                name: "都兰县",
                shortName: "都兰",
                children: [],
              },
              {
                id: 632823,
                name: "天峻县",
                shortName: "天峻",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 640000,
        name: "宁夏回族自治区",
        shortName: "宁夏",
        children: [
          {
            id: 640100,
            name: "银川市",
            shortName: "银川",
            children: [
              {
                id: 640104,
                name: "兴庆区",
                shortName: "兴庆",
                children: [],
              },
              {
                id: 640105,
                name: "西夏区",
                shortName: "西夏",
                children: [],
              },
              {
                id: 640106,
                name: "金凤区",
                shortName: "金凤",
                children: [],
              },
              {
                id: 640121,
                name: "永宁县",
                shortName: "永宁",
                children: [],
              },
              {
                id: 640122,
                name: "贺兰县",
                shortName: "贺兰",
                children: [],
              },
              {
                id: 640181,
                name: "灵武市",
                shortName: "灵武",
                children: [],
              },
              {
                id: 640182,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 640200,
            name: "石嘴山市",
            shortName: "石嘴山",
            children: [
              {
                id: 640202,
                name: "大武口区",
                shortName: "大武口",
                children: [],
              },
              {
                id: 640205,
                name: "惠农区",
                shortName: "惠农",
                children: [],
              },
              {
                id: 640221,
                name: "平罗县",
                shortName: "平罗",
                children: [],
              },
              {
                id: 640222,
                name: "经济开发区",
                shortName: "经济开发区",
                children: [],
              },
            ],
          },
          {
            id: 640300,
            name: "吴忠市",
            shortName: "吴忠",
            children: [
              {
                id: 640302,
                name: "利通区",
                shortName: "利通",
                children: [],
              },
              {
                id: 640303,
                name: "红寺堡区",
                shortName: "红寺堡",
                children: [],
              },
              {
                id: 640323,
                name: "盐池县",
                shortName: "盐池",
                children: [],
              },
              {
                id: 640324,
                name: "同心县",
                shortName: "同心",
                children: [],
              },
              {
                id: 640381,
                name: "青铜峡市",
                shortName: "青铜峡",
                children: [],
              },
            ],
          },
          {
            id: 640400,
            name: "固原市",
            shortName: "固原",
            children: [
              {
                id: 640402,
                name: "原州区",
                shortName: "原州",
                children: [],
              },
              {
                id: 640422,
                name: "西吉县",
                shortName: "西吉",
                children: [],
              },
              {
                id: 640423,
                name: "隆德县",
                shortName: "隆德",
                children: [],
              },
              {
                id: 640424,
                name: "泾源县",
                shortName: "泾源",
                children: [],
              },
              {
                id: 640425,
                name: "彭阳县",
                shortName: "彭阳",
                children: [],
              },
            ],
          },
          {
            id: 640500,
            name: "中卫市",
            shortName: "中卫",
            children: [
              {
                id: 640502,
                name: "沙坡头区",
                shortName: "沙坡头",
                children: [],
              },
              {
                id: 640521,
                name: "中宁县",
                shortName: "中宁",
                children: [],
              },
              {
                id: 640522,
                name: "海原县",
                shortName: "海原",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 650000,
        name: "新疆维吾尔自治区",
        shortName: "新疆",
        children: [
          {
            id: 650100,
            name: "乌鲁木齐市",
            shortName: "乌鲁木齐",
            children: [
              {
                id: 650102,
                name: "天山区",
                shortName: "天山",
                children: [],
              },
              {
                id: 650103,
                name: "沙依巴克区",
                shortName: "沙依巴克",
                children: [],
              },
              {
                id: 650104,
                name: "新市区",
                shortName: "新市",
                children: [],
              },
              {
                id: 650105,
                name: "水磨沟区",
                shortName: "水磨沟",
                children: [],
              },
              {
                id: 650106,
                name: "头屯河区",
                shortName: "头屯河",
                children: [],
              },
              {
                id: 650107,
                name: "达坂城区",
                shortName: "达坂城",
                children: [],
              },
              {
                id: 650109,
                name: "米东区",
                shortName: "米东",
                children: [],
              },
              {
                id: 650121,
                name: "乌鲁木齐县",
                shortName: "乌鲁木齐",
                children: [],
              },
            ],
          },
          {
            id: 650200,
            name: "克拉玛依市",
            shortName: "克拉玛依",
            children: [
              {
                id: 650202,
                name: "独山子区",
                shortName: "独山子",
                children: [],
              },
              {
                id: 650203,
                name: "克拉玛依区",
                shortName: "克拉玛依",
                children: [],
              },
              {
                id: 650204,
                name: "白碱滩区",
                shortName: "白碱滩",
                children: [],
              },
              {
                id: 650205,
                name: "乌尔禾区",
                shortName: "乌尔禾",
                children: [],
              },
            ],
          },
          {
            id: 650400,
            name: "吐鲁番市",
            shortName: "吐鲁番",
            children: [
              {
                id: 650402,
                name: "高昌区",
                shortName: "高昌",
                children: [],
              },
              {
                id: 650421,
                name: "鄯善县",
                shortName: "鄯善",
                children: [],
              },
              {
                id: 650422,
                name: "托克逊县",
                shortName: "托克逊",
                children: [],
              },
            ],
          },
          {
            id: 650500,
            name: "哈密市",
            shortName: "哈密",
            children: [
              {
                id: 650502,
                name: "伊州区",
                shortName: "哈密",
                children: [],
              },
              {
                id: 650521,
                name: "巴里坤哈萨克自治县",
                shortName: "巴里坤",
                children: [],
              },
              {
                id: 650522,
                name: "伊吾县",
                shortName: "伊吾",
                children: [],
              },
            ],
          },
          {
            id: 652300,
            name: "昌吉回族自治州",
            shortName: "昌吉",
            children: [
              {
                id: 652301,
                name: "昌吉市",
                shortName: "昌吉",
                children: [],
              },
              {
                id: 652302,
                name: "阜康市",
                shortName: "阜康",
                children: [],
              },
              {
                id: 652323,
                name: "呼图壁县",
                shortName: "呼图壁",
                children: [],
              },
              {
                id: 652324,
                name: "玛纳斯县",
                shortName: "玛纳斯",
                children: [],
              },
              {
                id: 652325,
                name: "奇台县",
                shortName: "奇台",
                children: [],
              },
              {
                id: 652327,
                name: "吉木萨尔县",
                shortName: "吉木萨尔",
                children: [],
              },
              {
                id: 652328,
                name: "木垒哈萨克自治县",
                shortName: "木垒",
                children: [],
              },
            ],
          },
          {
            id: 652700,
            name: "博尔塔拉蒙古自治州",
            shortName: "博尔塔拉",
            children: [
              {
                id: 652701,
                name: "博乐市",
                shortName: "博乐",
                children: [],
              },
              {
                id: 652702,
                name: "阿拉山口市",
                shortName: "阿拉山口",
                children: [],
              },
              {
                id: 652722,
                name: "精河县",
                shortName: "精河",
                children: [],
              },
              {
                id: 652723,
                name: "温泉县",
                shortName: "温泉",
                children: [],
              },
            ],
          },
          {
            id: 652800,
            name: "巴音郭楞蒙古自治州",
            shortName: "巴音郭楞",
            children: [
              {
                id: 652801,
                name: "库尔勒市",
                shortName: "库尔勒",
                children: [],
              },
              {
                id: 652822,
                name: "轮台县",
                shortName: "轮台",
                children: [],
              },
              {
                id: 652823,
                name: "尉犁县",
                shortName: "尉犁",
                children: [],
              },
              {
                id: 652824,
                name: "若羌县",
                shortName: "若羌",
                children: [],
              },
              {
                id: 652825,
                name: "且末县",
                shortName: "且末",
                children: [],
              },
              {
                id: 652826,
                name: "焉耆回族自治县",
                shortName: "焉耆",
                children: [],
              },
              {
                id: 652827,
                name: "和静县",
                shortName: "和静",
                children: [],
              },
              {
                id: 652828,
                name: "和硕县",
                shortName: "和硕",
                children: [],
              },
              {
                id: 652829,
                name: "博湖县",
                shortName: "博湖",
                children: [],
              },
            ],
          },
          {
            id: 652900,
            name: "阿克苏地区",
            shortName: "阿克苏",
            children: [
              {
                id: 652901,
                name: "阿克苏市",
                shortName: "阿克苏",
                children: [],
              },
              {
                id: 652922,
                name: "温宿县",
                shortName: "温宿",
                children: [],
              },
              {
                id: 652923,
                name: "库车县",
                shortName: "库车",
                children: [],
              },
              {
                id: 652924,
                name: "沙雅县",
                shortName: "沙雅",
                children: [],
              },
              {
                id: 652925,
                name: "新和县",
                shortName: "新和",
                children: [],
              },
              {
                id: 652926,
                name: "拜城县",
                shortName: "拜城",
                children: [],
              },
              {
                id: 652927,
                name: "乌什县",
                shortName: "乌什",
                children: [],
              },
              {
                id: 652928,
                name: "阿瓦提县",
                shortName: "阿瓦提",
                children: [],
              },
              {
                id: 652929,
                name: "柯坪县",
                shortName: "柯坪",
                children: [],
              },
            ],
          },
          {
            id: 653000,
            name: "克孜勒苏柯尔克孜自治州",
            shortName: "克孜勒苏",
            children: [
              {
                id: 653001,
                name: "阿图什市",
                shortName: "阿图什",
                children: [],
              },
              {
                id: 653022,
                name: "阿克陶县",
                shortName: "阿克陶",
                children: [],
              },
              {
                id: 653023,
                name: "阿合奇县",
                shortName: "阿合奇",
                children: [],
              },
              {
                id: 653024,
                name: "乌恰县",
                shortName: "乌恰",
                children: [],
              },
            ],
          },
          {
            id: 653100,
            name: "喀什地区",
            shortName: "喀什",
            children: [
              {
                id: 653101,
                name: "喀什市",
                shortName: "喀什",
                children: [],
              },
              {
                id: 653121,
                name: "疏附县",
                shortName: "疏附",
                children: [],
              },
              {
                id: 653122,
                name: "疏勒县",
                shortName: "疏勒",
                children: [],
              },
              {
                id: 653123,
                name: "英吉沙县",
                shortName: "英吉沙",
                children: [],
              },
              {
                id: 653124,
                name: "泽普县",
                shortName: "泽普",
                children: [],
              },
              {
                id: 653125,
                name: "莎车县",
                shortName: "莎车",
                children: [],
              },
              {
                id: 653126,
                name: "叶城县",
                shortName: "叶城",
                children: [],
              },
              {
                id: 653127,
                name: "麦盖提县",
                shortName: "麦盖提",
                children: [],
              },
              {
                id: 653128,
                name: "岳普湖县",
                shortName: "岳普湖",
                children: [],
              },
              {
                id: 653129,
                name: "伽师县",
                shortName: "伽师",
                children: [],
              },
              {
                id: 653130,
                name: "巴楚县",
                shortName: "巴楚",
                children: [],
              },
              {
                id: 653131,
                name: "塔什库尔干塔吉克自治县",
                shortName: "塔什库尔干塔吉克",
                children: [],
              },
            ],
          },
          {
            id: 653200,
            name: "和田地区",
            shortName: "和田",
            children: [
              {
                id: 653201,
                name: "和田市",
                shortName: "和田市",
                children: [],
              },
              {
                id: 653221,
                name: "和田县",
                shortName: "和田县",
                children: [],
              },
              {
                id: 653222,
                name: "墨玉县",
                shortName: "墨玉",
                children: [],
              },
              {
                id: 653223,
                name: "皮山县",
                shortName: "皮山",
                children: [],
              },
              {
                id: 653224,
                name: "洛浦县",
                shortName: "洛浦",
                children: [],
              },
              {
                id: 653225,
                name: "策勒县",
                shortName: "策勒",
                children: [],
              },
              {
                id: 653226,
                name: "于田县",
                shortName: "于田",
                children: [],
              },
              {
                id: 653227,
                name: "民丰县",
                shortName: "民丰",
                children: [],
              },
            ],
          },
          {
            id: 654000,
            name: "伊犁哈萨克自治州",
            shortName: "伊犁",
            children: [
              {
                id: 654002,
                name: "伊宁市",
                shortName: "伊宁",
                children: [],
              },
              {
                id: 654003,
                name: "奎屯市",
                shortName: "奎屯",
                children: [],
              },
              {
                id: 654004,
                name: "霍尔果斯市",
                shortName: "霍尔果斯",
                children: [],
              },
              {
                id: 654021,
                name: "伊宁县",
                shortName: "伊宁",
                children: [],
              },
              {
                id: 654022,
                name: "察布查尔锡伯自治县",
                shortName: "察布查尔锡伯",
                children: [],
              },
              {
                id: 654023,
                name: "霍城县",
                shortName: "霍城",
                children: [],
              },
              {
                id: 654024,
                name: "巩留县",
                shortName: "巩留",
                children: [],
              },
              {
                id: 654025,
                name: "新源县",
                shortName: "新源",
                children: [],
              },
              {
                id: 654026,
                name: "昭苏县",
                shortName: "昭苏",
                children: [],
              },
              {
                id: 654027,
                name: "特克斯县",
                shortName: "特克斯",
                children: [],
              },
              {
                id: 654028,
                name: "尼勒克县",
                shortName: "尼勒克",
                children: [],
              },
            ],
          },
          {
            id: 654200,
            name: "塔城地区",
            shortName: "塔城",
            children: [
              {
                id: 654201,
                name: "塔城市",
                shortName: "塔城",
                children: [],
              },
              {
                id: 654202,
                name: "乌苏市",
                shortName: "乌苏",
                children: [],
              },
              {
                id: 654221,
                name: "额敏县",
                shortName: "额敏",
                children: [],
              },
              {
                id: 654223,
                name: "沙湾县",
                shortName: "沙湾",
                children: [],
              },
              {
                id: 654224,
                name: "托里县",
                shortName: "托里",
                children: [],
              },
              {
                id: 654225,
                name: "裕民县",
                shortName: "裕民",
                children: [],
              },
              {
                id: 654226,
                name: "和布克赛尔蒙古自治县",
                shortName: "和布克赛尔",
                children: [],
              },
            ],
          },
          {
            id: 654300,
            name: "阿勒泰地区",
            shortName: "阿勒泰",
            children: [
              {
                id: 654301,
                name: "阿勒泰市",
                shortName: "阿勒泰",
                children: [],
              },
              {
                id: 654321,
                name: "布尔津县",
                shortName: "布尔津",
                children: [],
              },
              {
                id: 654322,
                name: "富蕴县",
                shortName: "富蕴",
                children: [],
              },
              {
                id: 654323,
                name: "福海县",
                shortName: "福海",
                children: [],
              },
              {
                id: 654324,
                name: "哈巴河县",
                shortName: "哈巴河",
                children: [],
              },
              {
                id: 654325,
                name: "青河县",
                shortName: "青河",
                children: [],
              },
              {
                id: 654326,
                name: "吉木乃县",
                shortName: "吉木乃",
                children: [],
              },
            ],
          },
          {
            id: 659001,
            name: "石河子市",
            shortName: "石河子",
            children: [
              {
                id: 659101,
                name: "新城街道",
                shortName: "新城街道",
                children: [],
              },
              {
                id: 659102,
                name: "向阳街道",
                shortName: "向阳街道",
                children: [],
              },
              {
                id: 659103,
                name: "红山街道",
                shortName: "红山街道",
                children: [],
              },
              {
                id: 659104,
                name: "老街街道",
                shortName: "老街街道",
                children: [],
              },
              {
                id: 659105,
                name: "东城街道",
                shortName: "东城街道",
                children: [],
              },
              {
                id: 659106,
                name: "北泉镇",
                shortName: "北泉镇",
                children: [],
              },
              {
                id: 659107,
                name: "石河子乡",
                shortName: "石河子乡",
                children: [],
              },
              {
                id: 659108,
                name: "一五二团",
                shortName: "一五二团",
                children: [],
              },
            ],
          },
          {
            id: 659002,
            name: "阿拉尔市",
            shortName: "阿拉尔",
            children: [
              {
                id: 659201,
                name: "幸福路街道",
                shortName: "阿拉尔",
                children: [],
              },
              {
                id: 659202,
                name: "金银川路街道",
                shortName: "金银川路街道",
                children: [],
              },
              {
                id: 659203,
                name: "青松路街道",
                shortName: "青松路街道",
                children: [],
              },
              {
                id: 659204,
                name: "南口街道",
                shortName: "南口街道",
                children: [],
              },
              {
                id: 659205,
                name: "托喀依乡",
                shortName: "托喀依乡",
                children: [],
              },
              {
                id: 659206,
                name: "金银川镇",
                shortName: "阿拉尔",
                children: [],
              },
            ],
          },
          {
            id: 659003,
            name: "图木舒克市",
            shortName: "图木舒克",
            children: [
              {
                id: 659301,
                name: "图木舒克市区",
                shortName: "图木舒克市区",
                children: [],
              },
              {
                id: 659302,
                name: "兵团四十四团",
                shortName: "兵团四十四团",
                children: [],
              },
              {
                id: 659303,
                name: "兵团四十九团",
                shortName: "兵团四十九团",
                children: [],
              },
              {
                id: 659304,
                name: "兵团五十团",
                shortName: "兵团五十团",
                children: [],
              },
              {
                id: 659305,
                name: "兵团五十一团",
                shortName: "兵团五十一团",
                children: [],
              },
              {
                id: 659306,
                name: "兵团五十二团",
                shortName: "兵团五十二团",
                children: [],
              },
              {
                id: 659307,
                name: "兵团五十三团",
                shortName: "兵团五十三团",
                children: [],
              },
              {
                id: 659308,
                name: "喀拉拜勒镇",
                shortName: "喀拉拜勒",
                children: [],
              },
              {
                id: 659309,
                name: "永安坝",
                shortName: "永安坝",
                children: [],
              },
            ],
          },
          {
            id: 659004,
            name: "五家渠市",
            shortName: "五家渠",
            children: [
              {
                id: 659401,
                name: "城区",
                shortName: "城区",
                children: [],
              },
              {
                id: 659402,
                name: "一零一团",
                shortName: "一零一团",
                children: [],
              },
              {
                id: 659403,
                name: "一零二团",
                shortName: "一零二团",
                children: [],
              },
              {
                id: 659404,
                name: "一零三团",
                shortName: "一零三团",
                children: [],
              },
            ],
          },
          {
            id: 659005,
            name: "北屯市",
            shortName: "北屯",
            children: [
              {
                id: 659501,
                name: "新城区",
                shortName: "新城",
                children: [],
              },
              {
                id: 659502,
                name: "老城区",
                shortName: "老城",
                children: [],
              },
              {
                id: 659503,
                name: "工业园区",
                shortName: "工业园",
                children: [],
              },
              {
                id: 659504,
                name: "海川镇",
                shortName: "海川",
                children: [],
              },
              {
                id: 659505,
                name: "丰庆镇",
                shortName: "丰庆",
                children: [],
              },
              {
                id: 659506,
                name: "锡伯渡镇",
                shortName: "锡伯渡",
                children: [],
              },
            ],
          },
          {
            id: 659006,
            name: "铁门关市",
            shortName: "铁门关",
            children: [
              {
                id: 659601,
                name: "二十九团场",
                shortName: "二十九团场",
                children: [],
              },
              {
                id: 659602,
                name: "库西经济工业园",
                shortName: "库西经济工业园",
                children: [],
              },
              {
                id: 659603,
                name: "博古其镇",
                shortName: "博古其镇",
                children: [],
              },
              {
                id: 659604,
                name: "双丰镇",
                shortName: "双丰镇",
                children: [],
              },
            ],
          },
          {
            id: 659007,
            name: "双河市",
            shortName: "双河",
            children: [
              {
                id: 659701,
                name: "八十一团",
                shortName: "八十一团",
                children: [],
              },
              {
                id: 659702,
                name: "八十四团",
                shortName: "八十四团",
                children: [],
              },
              {
                id: 659703,
                name: "八十五团",
                shortName: "八十五团",
                children: [],
              },
              {
                id: 659704,
                name: "八十六团",
                shortName: "八十六团",
                children: [],
              },
              {
                id: 659705,
                name: "八十九团",
                shortName: "八十九团",
                children: [],
              },
              {
                id: 659706,
                name: "九十团",
                shortName: "九十团",
                children: [],
              },
            ],
          },
          {
            id: 659008,
            name: "可克达拉市",
            shortName: "可克达拉",
            children: [
              {
                id: 659801,
                name: "63团",
                shortName: "63团",
                children: [],
              },
              {
                id: 659802,
                name: "64团",
                shortName: "64团",
                children: [],
              },
              {
                id: 659803,
                name: "66团",
                shortName: "66团",
                children: [],
              },
              {
                id: 659804,
                name: "67团",
                shortName: "67团",
                children: [],
              },
              {
                id: 659805,
                name: "68团",
                shortName: "68团",
                children: [],
              },
            ],
          },
          {
            id: 659009,
            name: "昆玉市",
            shortName: "昆玉",
            children: [
              {
                id: 659901,
                name: "皮山农场",
                shortName: "皮山农场",
                children: [],
              },
              {
                id: 659902,
                name: "二二四团",
                shortName: "二二四团",
                children: [],
              },
              {
                id: 659903,
                name: "四十七团",
                shortName: "四十七团",
                children: [],
              },
              {
                id: 659904,
                name: "一牧场",
                shortName: "一牧场",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 710000,
        name: "台湾",
        shortName: "台湾",
        children: [
          {
            id: 710100,
            name: "台北市",
            shortName: "台北",
            children: [
              {
                id: 710101,
                name: "松山区",
                shortName: "松山",
                children: [],
              },
              {
                id: 710102,
                name: "信义区",
                shortName: "信义",
                children: [],
              },
              {
                id: 710103,
                name: "大安区",
                shortName: "大安",
                children: [],
              },
              {
                id: 710104,
                name: "中山区",
                shortName: "中山",
                children: [],
              },
              {
                id: 710105,
                name: "中正区",
                shortName: "中正",
                children: [],
              },
              {
                id: 710106,
                name: "大同区",
                shortName: "大同",
                children: [],
              },
              {
                id: 710107,
                name: "万华区",
                shortName: "万华",
                children: [],
              },
              {
                id: 710108,
                name: "文山区",
                shortName: "文山",
                children: [],
              },
              {
                id: 710109,
                name: "南港区",
                shortName: "南港",
                children: [],
              },
              {
                id: 710110,
                name: "内湖区",
                shortName: "内湖",
                children: [],
              },
              {
                id: 710111,
                name: "士林区",
                shortName: "士林",
                children: [],
              },
              {
                id: 710112,
                name: "北投区",
                shortName: "北投",
                children: [],
              },
            ],
          },
          {
            id: 710200,
            name: "高雄市",
            shortName: "高雄",
            children: [
              {
                id: 710201,
                name: "盐埕区",
                shortName: "盐埕",
                children: [],
              },
              {
                id: 710202,
                name: "鼓山区",
                shortName: "鼓山",
                children: [],
              },
              {
                id: 710203,
                name: "左营区",
                shortName: "左营",
                children: [],
              },
              {
                id: 710204,
                name: "楠梓区",
                shortName: "楠梓",
                children: [],
              },
              {
                id: 710205,
                name: "三民区",
                shortName: "三民",
                children: [],
              },
              {
                id: 710206,
                name: "新兴区",
                shortName: "新兴",
                children: [],
              },
              {
                id: 710207,
                name: "前金区",
                shortName: "前金",
                children: [],
              },
              {
                id: 710208,
                name: "苓雅区",
                shortName: "苓雅",
                children: [],
              },
              {
                id: 710209,
                name: "前镇区",
                shortName: "前镇",
                children: [],
              },
              {
                id: 710210,
                name: "旗津区",
                shortName: "旗津",
                children: [],
              },
              {
                id: 710211,
                name: "小港区",
                shortName: "小港",
                children: [],
              },
              {
                id: 710212,
                name: "凤山区",
                shortName: "凤山",
                children: [],
              },
              {
                id: 710213,
                name: "林园区",
                shortName: "林园",
                children: [],
              },
              {
                id: 710214,
                name: "大寮区",
                shortName: "大寮",
                children: [],
              },
              {
                id: 710215,
                name: "大树区",
                shortName: "大树",
                children: [],
              },
              {
                id: 710216,
                name: "大社区",
                shortName: "大社",
                children: [],
              },
              {
                id: 710217,
                name: "仁武区",
                shortName: "仁武",
                children: [],
              },
              {
                id: 710218,
                name: "鸟松区",
                shortName: "鸟松",
                children: [],
              },
              {
                id: 710219,
                name: "冈山区",
                shortName: "冈山",
                children: [],
              },
              {
                id: 710220,
                name: "桥头区",
                shortName: "桥头",
                children: [],
              },
              {
                id: 710221,
                name: "燕巢区",
                shortName: "燕巢",
                children: [],
              },
              {
                id: 710222,
                name: "田寮区",
                shortName: "田寮",
                children: [],
              },
              {
                id: 710223,
                name: "阿莲区",
                shortName: "阿莲",
                children: [],
              },
              {
                id: 710224,
                name: "路竹区",
                shortName: "路竹",
                children: [],
              },
              {
                id: 710225,
                name: "湖内区",
                shortName: "湖内",
                children: [],
              },
              {
                id: 710226,
                name: "茄萣区",
                shortName: "茄萣",
                children: [],
              },
              {
                id: 710227,
                name: "永安区",
                shortName: "永安",
                children: [],
              },
              {
                id: 710228,
                name: "弥陀区",
                shortName: "弥陀",
                children: [],
              },
              {
                id: 710229,
                name: "梓官区",
                shortName: "梓官",
                children: [],
              },
              {
                id: 710230,
                name: "旗山区",
                shortName: "旗山",
                children: [],
              },
              {
                id: 710231,
                name: "美浓区",
                shortName: "美浓",
                children: [],
              },
              {
                id: 710232,
                name: "六龟区",
                shortName: "六龟",
                children: [],
              },
              {
                id: 710233,
                name: "甲仙区",
                shortName: "甲仙",
                children: [],
              },
              {
                id: 710234,
                name: "杉林区",
                shortName: "杉林",
                children: [],
              },
              {
                id: 710235,
                name: "内门区",
                shortName: "内门",
                children: [],
              },
              {
                id: 710236,
                name: "茂林区",
                shortName: "茂林",
                children: [],
              },
              {
                id: 710237,
                name: "桃源区",
                shortName: "桃源",
                children: [],
              },
              {
                id: 710238,
                name: "那玛夏区",
                shortName: "那玛夏",
                children: [],
              },
            ],
          },
          {
            id: 710300,
            name: "基隆市",
            shortName: "基隆",
            children: [
              {
                id: 710301,
                name: "中正区",
                shortName: "中正",
                children: [],
              },
              {
                id: 710302,
                name: "七堵区",
                shortName: "七堵",
                children: [],
              },
              {
                id: 710303,
                name: "暖暖区",
                shortName: "暖暖",
                children: [],
              },
              {
                id: 710304,
                name: "仁爱区",
                shortName: "仁爱",
                children: [],
              },
              {
                id: 710305,
                name: "中山区",
                shortName: "中山",
                children: [],
              },
              {
                id: 710306,
                name: "安乐区",
                shortName: "安乐",
                children: [],
              },
              {
                id: 710307,
                name: "信义区",
                shortName: "信义",
                children: [],
              },
            ],
          },
          {
            id: 710400,
            name: "台中市",
            shortName: "台中",
            children: [
              {
                id: 710401,
                name: "中区",
                shortName: "中区",
                children: [],
              },
              {
                id: 710402,
                name: "东区",
                shortName: "东区",
                children: [],
              },
              {
                id: 710403,
                name: "南区",
                shortName: "南区",
                children: [],
              },
              {
                id: 710404,
                name: "西区",
                shortName: "西区",
                children: [],
              },
              {
                id: 710405,
                name: "北区",
                shortName: "北区",
                children: [],
              },
              {
                id: 710406,
                name: "西屯区",
                shortName: "西屯",
                children: [],
              },
              {
                id: 710407,
                name: "南屯区",
                shortName: "南屯",
                children: [],
              },
              {
                id: 710408,
                name: "北屯区",
                shortName: "北屯",
                children: [],
              },
              {
                id: 710409,
                name: "丰原区",
                shortName: "丰原",
                children: [],
              },
              {
                id: 710410,
                name: "东势区",
                shortName: "东势",
                children: [],
              },
              {
                id: 710411,
                name: "大甲区",
                shortName: "大甲",
                children: [],
              },
              {
                id: 710412,
                name: "清水区",
                shortName: "清水",
                children: [],
              },
              {
                id: 710413,
                name: "沙鹿区",
                shortName: "沙鹿",
                children: [],
              },
              {
                id: 710414,
                name: "梧栖区",
                shortName: "梧栖",
                children: [],
              },
              {
                id: 710415,
                name: "后里区",
                shortName: "后里",
                children: [],
              },
              {
                id: 710416,
                name: "神冈区",
                shortName: "神冈",
                children: [],
              },
              {
                id: 710417,
                name: "潭子区",
                shortName: "潭子",
                children: [],
              },
              {
                id: 710418,
                name: "大雅区",
                shortName: "大雅",
                children: [],
              },
              {
                id: 710419,
                name: "新社区",
                shortName: "新社",
                children: [],
              },
              {
                id: 710420,
                name: "石冈区",
                shortName: "石冈",
                children: [],
              },
              {
                id: 710421,
                name: "外埔区",
                shortName: "外埔",
                children: [],
              },
              {
                id: 710422,
                name: "大安区",
                shortName: "大安",
                children: [],
              },
              {
                id: 710423,
                name: "乌日区",
                shortName: "乌日",
                children: [],
              },
              {
                id: 710424,
                name: "大肚区",
                shortName: "大肚",
                children: [],
              },
              {
                id: 710425,
                name: "龙井区",
                shortName: "龙井",
                children: [],
              },
              {
                id: 710426,
                name: "雾峰区",
                shortName: "雾峰",
                children: [],
              },
              {
                id: 710427,
                name: "太平区",
                shortName: "太平",
                children: [],
              },
              {
                id: 710428,
                name: "大里区",
                shortName: "大里",
                children: [],
              },
              {
                id: 710429,
                name: "和平区",
                shortName: "和平",
                children: [],
              },
            ],
          },
          {
            id: 710500,
            name: "台南市",
            shortName: "台南",
            children: [
              {
                id: 710501,
                name: "东区",
                shortName: "东区",
                children: [],
              },
              {
                id: 710502,
                name: "南区",
                shortName: "南区",
                children: [],
              },
              {
                id: 710504,
                name: "北区",
                shortName: "北区",
                children: [],
              },
              {
                id: 710506,
                name: "安南区",
                shortName: "安南",
                children: [],
              },
              {
                id: 710507,
                name: "安平区",
                shortName: "安平",
                children: [],
              },
              {
                id: 710508,
                name: "中西区",
                shortName: "中西",
                children: [],
              },
              {
                id: 710509,
                name: "新营区",
                shortName: "新营",
                children: [],
              },
              {
                id: 710510,
                name: "盐水区",
                shortName: "盐水",
                children: [],
              },
              {
                id: 710511,
                name: "白河区",
                shortName: "白河",
                children: [],
              },
              {
                id: 710512,
                name: "柳营区",
                shortName: "柳营",
                children: [],
              },
              {
                id: 710513,
                name: "后壁区",
                shortName: "后壁",
                children: [],
              },
              {
                id: 710514,
                name: "东山区",
                shortName: "东山",
                children: [],
              },
              {
                id: 710515,
                name: "麻豆区",
                shortName: "麻豆",
                children: [],
              },
              {
                id: 710516,
                name: "下营区",
                shortName: "下营",
                children: [],
              },
              {
                id: 710517,
                name: "六甲区",
                shortName: "六甲",
                children: [],
              },
              {
                id: 710518,
                name: "官田区",
                shortName: "官田",
                children: [],
              },
              {
                id: 710519,
                name: "大内区",
                shortName: "大内",
                children: [],
              },
              {
                id: 710520,
                name: "佳里区",
                shortName: "佳里",
                children: [],
              },
              {
                id: 710521,
                name: "学甲区",
                shortName: "学甲",
                children: [],
              },
              {
                id: 710522,
                name: "西港区",
                shortName: "西港",
                children: [],
              },
              {
                id: 710523,
                name: "七股区",
                shortName: "七股",
                children: [],
              },
              {
                id: 710524,
                name: "将军区",
                shortName: "将军",
                children: [],
              },
              {
                id: 710525,
                name: "北门区",
                shortName: "北门",
                children: [],
              },
              {
                id: 710526,
                name: "新化区",
                shortName: "新化",
                children: [],
              },
              {
                id: 710527,
                name: "善化区",
                shortName: "善化",
                children: [],
              },
              {
                id: 710528,
                name: "新市区",
                shortName: "新市",
                children: [],
              },
              {
                id: 710529,
                name: "安定区",
                shortName: "安定",
                children: [],
              },
              {
                id: 710530,
                name: "山上区",
                shortName: "山上",
                children: [],
              },
              {
                id: 710531,
                name: "玉井区",
                shortName: "玉井",
                children: [],
              },
              {
                id: 710532,
                name: "楠西区",
                shortName: "楠西",
                children: [],
              },
              {
                id: 710533,
                name: "南化区",
                shortName: "南化",
                children: [],
              },
              {
                id: 710534,
                name: "左镇区",
                shortName: "左镇",
                children: [],
              },
              {
                id: 710535,
                name: "仁德区",
                shortName: "仁德",
                children: [],
              },
              {
                id: 710536,
                name: "归仁区",
                shortName: "归仁",
                children: [],
              },
              {
                id: 710537,
                name: "关庙区",
                shortName: "关庙",
                children: [],
              },
              {
                id: 710538,
                name: "龙崎区",
                shortName: "龙崎",
                children: [],
              },
              {
                id: 710539,
                name: "永康区",
                shortName: "永康",
                children: [],
              },
            ],
          },
          {
            id: 710600,
            name: "新竹市",
            shortName: "新竹",
            children: [
              {
                id: 710601,
                name: "东区",
                shortName: "东区",
                children: [],
              },
              {
                id: 710602,
                name: "北区",
                shortName: "北区",
                children: [],
              },
              {
                id: 710603,
                name: "香山区",
                shortName: "香山",
                children: [],
              },
            ],
          },
          {
            id: 710700,
            name: "嘉义市",
            shortName: "嘉义",
            children: [
              {
                id: 710701,
                name: "东区",
                shortName: "东区",
                children: [],
              },
              {
                id: 710702,
                name: "西区",
                shortName: "西区",
                children: [],
              },
            ],
          },
          {
            id: 710800,
            name: "新北市",
            shortName: "新北",
            children: [
              {
                id: 710801,
                name: "板桥区",
                shortName: "板桥",
                children: [],
              },
              {
                id: 710802,
                name: "三重区",
                shortName: "三重",
                children: [],
              },
              {
                id: 710803,
                name: "中和区",
                shortName: "中和",
                children: [],
              },
              {
                id: 710804,
                name: "永和区",
                shortName: "永和",
                children: [],
              },
              {
                id: 710805,
                name: "新庄区",
                shortName: "新庄",
                children: [],
              },
              {
                id: 710806,
                name: "新店区",
                shortName: "新店",
                children: [],
              },
              {
                id: 710807,
                name: "树林区",
                shortName: "树林",
                children: [],
              },
              {
                id: 710808,
                name: "莺歌区",
                shortName: "莺歌",
                children: [],
              },
              {
                id: 710809,
                name: "三峡区",
                shortName: "三峡",
                children: [],
              },
              {
                id: 710810,
                name: "淡水区",
                shortName: "淡水",
                children: [],
              },
              {
                id: 710811,
                name: "汐止区",
                shortName: "汐止",
                children: [],
              },
              {
                id: 710812,
                name: "瑞芳区",
                shortName: "瑞芳",
                children: [],
              },
              {
                id: 710813,
                name: "土城区",
                shortName: "土城",
                children: [],
              },
              {
                id: 710814,
                name: "芦洲区",
                shortName: "芦洲",
                children: [],
              },
              {
                id: 710815,
                name: "五股区",
                shortName: "五股",
                children: [],
              },
              {
                id: 710816,
                name: "泰山区",
                shortName: "泰山",
                children: [],
              },
              {
                id: 710817,
                name: "林口区",
                shortName: "林口",
                children: [],
              },
              {
                id: 710818,
                name: "深坑区",
                shortName: "深坑",
                children: [],
              },
              {
                id: 710819,
                name: "石碇区",
                shortName: "石碇",
                children: [],
              },
              {
                id: 710820,
                name: "坪林区",
                shortName: "坪林",
                children: [],
              },
              {
                id: 710821,
                name: "三芝区",
                shortName: "三芝",
                children: [],
              },
              {
                id: 710822,
                name: "石门区",
                shortName: "石门",
                children: [],
              },
              {
                id: 710823,
                name: "八里区",
                shortName: "八里",
                children: [],
              },
              {
                id: 710824,
                name: "平溪区",
                shortName: "平溪",
                children: [],
              },
              {
                id: 710825,
                name: "双溪区",
                shortName: "双溪",
                children: [],
              },
              {
                id: 710826,
                name: "贡寮区",
                shortName: "贡寮",
                children: [],
              },
              {
                id: 710827,
                name: "金山区",
                shortName: "金山",
                children: [],
              },
              {
                id: 710828,
                name: "万里区",
                shortName: "万里",
                children: [],
              },
              {
                id: 710829,
                name: "乌来区",
                shortName: "乌来",
                children: [],
              },
            ],
          },
          {
            id: 712200,
            name: "宜兰县",
            shortName: "宜兰",
            children: [
              {
                id: 712201,
                name: "宜兰市",
                shortName: "宜兰",
                children: [],
              },
              {
                id: 712221,
                name: "罗东镇",
                shortName: "罗东",
                children: [],
              },
              {
                id: 712222,
                name: "苏澳镇",
                shortName: "苏澳",
                children: [],
              },
              {
                id: 712223,
                name: "头城镇",
                shortName: "头城",
                children: [],
              },
              {
                id: 712224,
                name: "礁溪乡",
                shortName: "礁溪",
                children: [],
              },
              {
                id: 712225,
                name: "壮围乡",
                shortName: "壮围",
                children: [],
              },
              {
                id: 712226,
                name: "员山乡",
                shortName: "员山",
                children: [],
              },
              {
                id: 712227,
                name: "冬山乡",
                shortName: "冬山",
                children: [],
              },
              {
                id: 712228,
                name: "五结乡",
                shortName: "五结",
                children: [],
              },
              {
                id: 712229,
                name: "三星乡",
                shortName: "三星",
                children: [],
              },
              {
                id: 712230,
                name: "大同乡",
                shortName: "大同",
                children: [],
              },
              {
                id: 712231,
                name: "南澳乡",
                shortName: "南澳",
                children: [],
              },
            ],
          },
          {
            id: 712300,
            name: "桃园市",
            shortName: "桃园",
            children: [
              {
                id: 712301,
                name: "桃园市",
                shortName: "桃园",
                children: [],
              },
              {
                id: 712302,
                name: "中坜市",
                shortName: "中坜",
                children: [],
              },
              {
                id: 712303,
                name: "平镇市",
                shortName: "平镇",
                children: [],
              },
              {
                id: 712304,
                name: "八德市",
                shortName: "八德",
                children: [],
              },
              {
                id: 712305,
                name: "杨梅市",
                shortName: "杨梅",
                children: [],
              },
              {
                id: 712306,
                name: "芦竹市",
                shortName: "芦竹",
                children: [],
              },
              {
                id: 712321,
                name: "大溪镇",
                shortName: "大溪",
                children: [],
              },
              {
                id: 712324,
                name: "大园乡",
                shortName: "大园",
                children: [],
              },
              {
                id: 712325,
                name: "龟山乡",
                shortName: "龟山",
                children: [],
              },
              {
                id: 712327,
                name: "龙潭乡",
                shortName: "龙潭",
                children: [],
              },
              {
                id: 712329,
                name: "新屋乡",
                shortName: "新屋",
                children: [],
              },
              {
                id: 712330,
                name: "观音乡",
                shortName: "观音",
                children: [],
              },
              {
                id: 712331,
                name: "复兴乡",
                shortName: "复兴",
                children: [],
              },
            ],
          },
          {
            id: 712400,
            name: "新竹县",
            shortName: "新竹",
            children: [
              {
                id: 712401,
                name: "竹北市",
                shortName: "竹北",
                children: [],
              },
              {
                id: 712421,
                name: "竹东镇",
                shortName: "竹东",
                children: [],
              },
              {
                id: 712422,
                name: "新埔镇",
                shortName: "新埔",
                children: [],
              },
              {
                id: 712423,
                name: "关西镇",
                shortName: "关西",
                children: [],
              },
              {
                id: 712424,
                name: "湖口乡",
                shortName: "湖口",
                children: [],
              },
              {
                id: 712425,
                name: "新丰乡",
                shortName: "新丰",
                children: [],
              },
              {
                id: 712426,
                name: "芎林乡",
                shortName: "芎林",
                children: [],
              },
              {
                id: 712427,
                name: "横山乡",
                shortName: "横山",
                children: [],
              },
              {
                id: 712428,
                name: "北埔乡",
                shortName: "北埔",
                children: [],
              },
              {
                id: 712429,
                name: "宝山乡",
                shortName: "宝山",
                children: [],
              },
              {
                id: 712430,
                name: "峨眉乡",
                shortName: "峨眉",
                children: [],
              },
              {
                id: 712431,
                name: "尖石乡",
                shortName: "尖石",
                children: [],
              },
              {
                id: 712432,
                name: "五峰乡",
                shortName: "五峰",
                children: [],
              },
            ],
          },
          {
            id: 712500,
            name: "苗栗县",
            shortName: "苗栗",
            children: [
              {
                id: 712501,
                name: "苗栗市",
                shortName: "苗栗",
                children: [],
              },
              {
                id: 712521,
                name: "苑里镇",
                shortName: "苑里",
                children: [],
              },
              {
                id: 712522,
                name: "通霄镇",
                shortName: "通霄",
                children: [],
              },
              {
                id: 712523,
                name: "竹南镇",
                shortName: "竹南",
                children: [],
              },
              {
                id: 712524,
                name: "头份市",
                shortName: "头份",
                children: [],
              },
              {
                id: 712525,
                name: "后龙镇",
                shortName: "后龙",
                children: [],
              },
              {
                id: 712526,
                name: "卓兰镇",
                shortName: "卓兰",
                children: [],
              },
              {
                id: 712527,
                name: "大湖乡",
                shortName: "大湖",
                children: [],
              },
              {
                id: 712528,
                name: "公馆乡",
                shortName: "公馆",
                children: [],
              },
              {
                id: 712529,
                name: "铜锣乡",
                shortName: "铜锣",
                children: [],
              },
              {
                id: 712530,
                name: "南庄乡",
                shortName: "南庄",
                children: [],
              },
              {
                id: 712531,
                name: "头屋乡",
                shortName: "头屋",
                children: [],
              },
              {
                id: 712532,
                name: "三义乡",
                shortName: "三义",
                children: [],
              },
              {
                id: 712533,
                name: "西湖乡",
                shortName: "西湖",
                children: [],
              },
              {
                id: 712534,
                name: "造桥乡",
                shortName: "造桥",
                children: [],
              },
              {
                id: 712535,
                name: "三湾乡",
                shortName: "三湾",
                children: [],
              },
              {
                id: 712536,
                name: "狮潭乡",
                shortName: "狮潭",
                children: [],
              },
              {
                id: 712537,
                name: "泰安乡",
                shortName: "泰安",
                children: [],
              },
            ],
          },
          {
            id: 712700,
            name: "彰化县",
            shortName: "彰化",
            children: [
              {
                id: 712701,
                name: "彰化市",
                shortName: "彰化市",
                children: [],
              },
              {
                id: 712721,
                name: "鹿港镇",
                shortName: "鹿港",
                children: [],
              },
              {
                id: 712722,
                name: "和美镇",
                shortName: "和美",
                children: [],
              },
              {
                id: 712723,
                name: "线西乡",
                shortName: "线西",
                children: [],
              },
              {
                id: 712724,
                name: "伸港乡",
                shortName: "伸港",
                children: [],
              },
              {
                id: 712725,
                name: "福兴乡",
                shortName: "福兴",
                children: [],
              },
              {
                id: 712726,
                name: "秀水乡",
                shortName: "秀水",
                children: [],
              },
              {
                id: 712727,
                name: "花坛乡",
                shortName: "花坛",
                children: [],
              },
              {
                id: 712728,
                name: "芬园乡",
                shortName: "芬园",
                children: [],
              },
              {
                id: 712729,
                name: "员林市",
                shortName: "员林",
                children: [],
              },
              {
                id: 712730,
                name: "溪湖镇",
                shortName: "溪湖",
                children: [],
              },
              {
                id: 712731,
                name: "田中镇",
                shortName: "田中",
                children: [],
              },
              {
                id: 712732,
                name: "大村乡",
                shortName: "大村",
                children: [],
              },
              {
                id: 712733,
                name: "埔盐乡",
                shortName: "埔盐",
                children: [],
              },
              {
                id: 712734,
                name: "埔心乡",
                shortName: "埔心",
                children: [],
              },
              {
                id: 712735,
                name: "永靖乡",
                shortName: "永靖",
                children: [],
              },
              {
                id: 712736,
                name: "社头乡",
                shortName: "社头",
                children: [],
              },
              {
                id: 712737,
                name: "二水乡",
                shortName: "二水",
                children: [],
              },
              {
                id: 712738,
                name: "北斗镇",
                shortName: "北斗",
                children: [],
              },
              {
                id: 712739,
                name: "二林镇",
                shortName: "二林",
                children: [],
              },
              {
                id: 712740,
                name: "田尾乡",
                shortName: "田尾",
                children: [],
              },
              {
                id: 712741,
                name: "埤头乡",
                shortName: "埤头",
                children: [],
              },
              {
                id: 712742,
                name: "芳苑乡",
                shortName: "芳苑",
                children: [],
              },
              {
                id: 712743,
                name: "大城乡",
                shortName: "大城",
                children: [],
              },
              {
                id: 712744,
                name: "竹塘乡",
                shortName: "竹塘",
                children: [],
              },
              {
                id: 712745,
                name: "溪州乡",
                shortName: "溪州",
                children: [],
              },
            ],
          },
          {
            id: 712800,
            name: "南投县",
            shortName: "南投",
            children: [
              {
                id: 712801,
                name: "南投市",
                shortName: "南投市",
                children: [],
              },
              {
                id: 712821,
                name: "埔里镇",
                shortName: "埔里",
                children: [],
              },
              {
                id: 712822,
                name: "草屯镇",
                shortName: "草屯",
                children: [],
              },
              {
                id: 712823,
                name: "竹山镇",
                shortName: "竹山",
                children: [],
              },
              {
                id: 712824,
                name: "集集镇",
                shortName: "集集",
                children: [],
              },
              {
                id: 712825,
                name: "名间乡",
                shortName: "名间",
                children: [],
              },
              {
                id: 712826,
                name: "鹿谷乡",
                shortName: "鹿谷",
                children: [],
              },
              {
                id: 712827,
                name: "中寮乡",
                shortName: "中寮",
                children: [],
              },
              {
                id: 712828,
                name: "鱼池乡",
                shortName: "鱼池",
                children: [],
              },
              {
                id: 712829,
                name: "国姓乡",
                shortName: "国姓",
                children: [],
              },
              {
                id: 712830,
                name: "水里乡",
                shortName: "水里",
                children: [],
              },
              {
                id: 712831,
                name: "信义乡",
                shortName: "信义",
                children: [],
              },
              {
                id: 712832,
                name: "仁爱乡",
                shortName: "仁爱",
                children: [],
              },
            ],
          },
          {
            id: 712900,
            name: "云林县",
            shortName: "云林",
            children: [
              {
                id: 712901,
                name: "斗六市",
                shortName: "斗六",
                children: [],
              },
              {
                id: 712921,
                name: "斗南镇",
                shortName: "斗南",
                children: [],
              },
              {
                id: 712922,
                name: "虎尾镇",
                shortName: "虎尾",
                children: [],
              },
              {
                id: 712923,
                name: "西螺镇",
                shortName: "西螺",
                children: [],
              },
              {
                id: 712924,
                name: "土库镇",
                shortName: "土库",
                children: [],
              },
              {
                id: 712925,
                name: "北港镇",
                shortName: "北港",
                children: [],
              },
              {
                id: 712926,
                name: "古坑乡",
                shortName: "古坑",
                children: [],
              },
              {
                id: 712927,
                name: "大埤乡",
                shortName: "大埤",
                children: [],
              },
              {
                id: 712928,
                name: "莿桐乡",
                shortName: "莿桐",
                children: [],
              },
              {
                id: 712929,
                name: "林内乡",
                shortName: "林内",
                children: [],
              },
              {
                id: 712930,
                name: "二仑乡",
                shortName: "二仑",
                children: [],
              },
              {
                id: 712931,
                name: "仑背乡",
                shortName: "仑背",
                children: [],
              },
              {
                id: 712932,
                name: "麦寮乡",
                shortName: "麦寮",
                children: [],
              },
              {
                id: 712933,
                name: "东势乡",
                shortName: "东势",
                children: [],
              },
              {
                id: 712934,
                name: "褒忠乡",
                shortName: "褒忠",
                children: [],
              },
              {
                id: 712935,
                name: "台西乡",
                shortName: "台西",
                children: [],
              },
              {
                id: 712936,
                name: "元长乡",
                shortName: "元长",
                children: [],
              },
              {
                id: 712937,
                name: "四湖乡",
                shortName: "四湖",
                children: [],
              },
              {
                id: 712938,
                name: "口湖乡",
                shortName: "口湖",
                children: [],
              },
              {
                id: 712939,
                name: "水林乡",
                shortName: "水林",
                children: [],
              },
            ],
          },
          {
            id: 713000,
            name: "嘉义县",
            shortName: "嘉义",
            children: [
              {
                id: 713001,
                name: "太保市",
                shortName: "太保",
                children: [],
              },
              {
                id: 713002,
                name: "朴子市",
                shortName: "朴子",
                children: [],
              },
              {
                id: 713023,
                name: "布袋镇",
                shortName: "布袋",
                children: [],
              },
              {
                id: 713024,
                name: "大林镇",
                shortName: "大林",
                children: [],
              },
              {
                id: 713025,
                name: "民雄乡",
                shortName: "民雄",
                children: [],
              },
              {
                id: 713026,
                name: "溪口乡",
                shortName: "溪口",
                children: [],
              },
              {
                id: 713027,
                name: "新港乡",
                shortName: "新港",
                children: [],
              },
              {
                id: 713028,
                name: "六脚乡",
                shortName: "六脚",
                children: [],
              },
              {
                id: 713029,
                name: "东石乡",
                shortName: "东石",
                children: [],
              },
              {
                id: 713030,
                name: "义竹乡",
                shortName: "义竹",
                children: [],
              },
              {
                id: 713031,
                name: "鹿草乡",
                shortName: "鹿草",
                children: [],
              },
              {
                id: 713032,
                name: "水上乡",
                shortName: "水上",
                children: [],
              },
              {
                id: 713033,
                name: "中埔乡",
                shortName: "中埔",
                children: [],
              },
              {
                id: 713034,
                name: "竹崎乡",
                shortName: "竹崎",
                children: [],
              },
              {
                id: 713035,
                name: "梅山乡",
                shortName: "梅山",
                children: [],
              },
              {
                id: 713036,
                name: "番路乡",
                shortName: "番路",
                children: [],
              },
              {
                id: 713037,
                name: "大埔乡",
                shortName: "大埔",
                children: [],
              },
              {
                id: 713038,
                name: "阿里山乡",
                shortName: "阿里山",
                children: [],
              },
            ],
          },
          {
            id: 713300,
            name: "屏东县",
            shortName: "屏东",
            children: [
              {
                id: 713301,
                name: "屏东市",
                shortName: "屏东",
                children: [],
              },
              {
                id: 713321,
                name: "潮州镇",
                shortName: "潮州",
                children: [],
              },
              {
                id: 713322,
                name: "东港镇",
                shortName: "东港",
                children: [],
              },
              {
                id: 713323,
                name: "恒春镇",
                shortName: "恒春",
                children: [],
              },
              {
                id: 713324,
                name: "万丹乡",
                shortName: "万丹",
                children: [],
              },
              {
                id: 713325,
                name: "长治乡",
                shortName: "长治",
                children: [],
              },
              {
                id: 713326,
                name: "麟洛乡",
                shortName: "麟洛",
                children: [],
              },
              {
                id: 713327,
                name: "九如乡",
                shortName: "九如",
                children: [],
              },
              {
                id: 713328,
                name: "里港乡",
                shortName: "里港",
                children: [],
              },
              {
                id: 713329,
                name: "盐埔乡",
                shortName: "盐埔",
                children: [],
              },
              {
                id: 713330,
                name: "高树乡",
                shortName: "高树",
                children: [],
              },
              {
                id: 713331,
                name: "万峦乡",
                shortName: "万峦",
                children: [],
              },
              {
                id: 713332,
                name: "内埔乡",
                shortName: "内埔",
                children: [],
              },
              {
                id: 713333,
                name: "竹田乡",
                shortName: "竹田",
                children: [],
              },
              {
                id: 713334,
                name: "新埤乡",
                shortName: "新埤",
                children: [],
              },
              {
                id: 713335,
                name: "枋寮乡",
                shortName: "枋寮",
                children: [],
              },
              {
                id: 713336,
                name: "新园乡",
                shortName: "新园",
                children: [],
              },
              {
                id: 713337,
                name: "崁顶乡",
                shortName: "崁顶",
                children: [],
              },
              {
                id: 713338,
                name: "林边乡",
                shortName: "林边",
                children: [],
              },
              {
                id: 713339,
                name: "南州乡",
                shortName: "南州",
                children: [],
              },
              {
                id: 713340,
                name: "佳冬乡",
                shortName: "佳冬",
                children: [],
              },
              {
                id: 713341,
                name: "琉球乡",
                shortName: "琉球",
                children: [],
              },
              {
                id: 713342,
                name: "车城乡",
                shortName: "车城",
                children: [],
              },
              {
                id: 713343,
                name: "满州乡",
                shortName: "满州",
                children: [],
              },
              {
                id: 713344,
                name: "枋山乡",
                shortName: "枋山",
                children: [],
              },
              {
                id: 713345,
                name: "三地门乡",
                shortName: "三地门",
                children: [],
              },
              {
                id: 713346,
                name: "雾台乡",
                shortName: "雾台",
                children: [],
              },
              {
                id: 713347,
                name: "玛家乡",
                shortName: "玛家",
                children: [],
              },
              {
                id: 713348,
                name: "泰武乡",
                shortName: "泰武",
                children: [],
              },
              {
                id: 713349,
                name: "来义乡",
                shortName: "来义",
                children: [],
              },
              {
                id: 713350,
                name: "春日乡",
                shortName: "春日",
                children: [],
              },
              {
                id: 713351,
                name: "狮子乡",
                shortName: "狮子",
                children: [],
              },
              {
                id: 713352,
                name: "牡丹乡",
                shortName: "牡丹",
                children: [],
              },
            ],
          },
          {
            id: 713400,
            name: "台东县",
            shortName: "台东",
            children: [
              {
                id: 713401,
                name: "台东市",
                shortName: "台东",
                children: [],
              },
              {
                id: 713421,
                name: "成功镇",
                shortName: "成功",
                children: [],
              },
              {
                id: 713422,
                name: "关山镇",
                shortName: "关山",
                children: [],
              },
              {
                id: 713423,
                name: "卑南乡",
                shortName: "卑南",
                children: [],
              },
              {
                id: 713424,
                name: "鹿野乡",
                shortName: "鹿野",
                children: [],
              },
              {
                id: 713425,
                name: "池上乡",
                shortName: "池上",
                children: [],
              },
              {
                id: 713426,
                name: "东河乡",
                shortName: "东河",
                children: [],
              },
              {
                id: 713427,
                name: "长滨乡",
                shortName: "长滨",
                children: [],
              },
              {
                id: 713428,
                name: "太麻里乡",
                shortName: "太麻里",
                children: [],
              },
              {
                id: 713429,
                name: "大武乡",
                shortName: "大武",
                children: [],
              },
              {
                id: 713430,
                name: "绿岛乡",
                shortName: "绿岛",
                children: [],
              },
              {
                id: 713431,
                name: "海端乡",
                shortName: "海端",
                children: [],
              },
              {
                id: 713432,
                name: "延平乡",
                shortName: "延平",
                children: [],
              },
              {
                id: 713433,
                name: "金峰乡",
                shortName: "金峰",
                children: [],
              },
              {
                id: 713434,
                name: "达仁乡",
                shortName: "达仁",
                children: [],
              },
              {
                id: 713435,
                name: "兰屿乡",
                shortName: "兰屿",
                children: [],
              },
            ],
          },
          {
            id: 713500,
            name: "花莲县",
            shortName: "花莲",
            children: [
              {
                id: 713501,
                name: "花莲市",
                shortName: "花莲",
                children: [],
              },
              {
                id: 713521,
                name: "凤林镇",
                shortName: "凤林",
                children: [],
              },
              {
                id: 713522,
                name: "玉里镇",
                shortName: "玉里",
                children: [],
              },
              {
                id: 713523,
                name: "新城乡",
                shortName: "新城",
                children: [],
              },
              {
                id: 713524,
                name: "吉安乡",
                shortName: "吉安",
                children: [],
              },
              {
                id: 713525,
                name: "寿丰乡",
                shortName: "寿丰",
                children: [],
              },
              {
                id: 713526,
                name: "光复乡",
                shortName: "光复",
                children: [],
              },
              {
                id: 713527,
                name: "丰滨乡",
                shortName: "丰滨",
                children: [],
              },
              {
                id: 713528,
                name: "瑞穗乡",
                shortName: "瑞穗",
                children: [],
              },
              {
                id: 713529,
                name: "富里乡",
                shortName: "富里",
                children: [],
              },
              {
                id: 713530,
                name: "秀林乡",
                shortName: "秀林",
                children: [],
              },
              {
                id: 713531,
                name: "万荣乡",
                shortName: "万荣",
                children: [],
              },
              {
                id: 713532,
                name: "卓溪乡",
                shortName: "卓溪",
                children: [],
              },
            ],
          },
          {
            id: 713600,
            name: "澎湖县",
            shortName: "澎湖",
            children: [
              {
                id: 713601,
                name: "马公市",
                shortName: "马公",
                children: [],
              },
              {
                id: 713621,
                name: "湖西乡",
                shortName: "湖西",
                children: [],
              },
              {
                id: 713622,
                name: "白沙乡",
                shortName: "白沙",
                children: [],
              },
              {
                id: 713623,
                name: "西屿乡",
                shortName: "西屿",
                children: [],
              },
              {
                id: 713624,
                name: "望安乡",
                shortName: "望安",
                children: [],
              },
              {
                id: 713625,
                name: "七美乡",
                shortName: "七美",
                children: [],
              },
            ],
          },
          {
            id: 713700,
            name: "金门县",
            shortName: "金门",
            children: [
              {
                id: 713701,
                name: "金城镇",
                shortName: "金城",
                children: [],
              },
              {
                id: 713702,
                name: "金湖镇",
                shortName: "金湖",
                children: [],
              },
              {
                id: 713703,
                name: "金沙镇",
                shortName: "金沙",
                children: [],
              },
              {
                id: 713704,
                name: "金宁乡",
                shortName: "金宁",
                children: [],
              },
              {
                id: 713705,
                name: "烈屿乡",
                shortName: "烈屿",
                children: [],
              },
              {
                id: 713706,
                name: "乌丘乡",
                shortName: "乌丘",
                children: [],
              },
            ],
          },
          {
            id: 713800,
            name: "连江县",
            shortName: "连江",
            children: [
              {
                id: 713801,
                name: "南竿乡",
                shortName: "南竿",
                children: [],
              },
              {
                id: 713802,
                name: "北竿乡",
                shortName: "北竿",
                children: [],
              },
              {
                id: 713803,
                name: "莒光乡",
                shortName: "莒光",
                children: [],
              },
              {
                id: 713804,
                name: "东引乡",
                shortName: "东引",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 810000,
        name: "香港特别行政区",
        shortName: "香港",
        children: [
          {
            id: 810100,
            name: "香港岛",
            shortName: "香港岛",
            children: [
              {
                id: 810101,
                name: "中西区",
                shortName: "中西区",
                children: [],
              },
              {
                id: 810102,
                name: "湾仔区",
                shortName: "湾仔区",
                children: [],
              },
              {
                id: 810103,
                name: "东区",
                shortName: "东区",
                children: [],
              },
              {
                id: 810104,
                name: "南区",
                shortName: "南区",
                children: [],
              },
            ],
          },
          {
            id: 810200,
            name: "九龙",
            shortName: "九龙",
            children: [
              {
                id: 810201,
                name: "油尖旺区",
                shortName: "油尖旺",
                children: [],
              },
              {
                id: 810202,
                name: "深水埗区",
                shortName: "深水埗",
                children: [],
              },
              {
                id: 810203,
                name: "九龙城区",
                shortName: "九龙城",
                children: [],
              },
              {
                id: 810204,
                name: "黄大仙区",
                shortName: "黄大仙",
                children: [],
              },
              {
                id: 810205,
                name: "观塘区",
                shortName: "观塘",
                children: [],
              },
            ],
          },
          {
            id: 810300,
            name: "新界",
            shortName: "新界",
            children: [
              {
                id: 810301,
                name: "荃湾区",
                shortName: "荃湾",
                children: [],
              },
              {
                id: 810302,
                name: "屯门区",
                shortName: "屯门",
                children: [],
              },
              {
                id: 810303,
                name: "元朗区",
                shortName: "元朗",
                children: [],
              },
              {
                id: 810304,
                name: "北区",
                shortName: "北区",
                children: [],
              },
              {
                id: 810305,
                name: "大埔区",
                shortName: "大埔",
                children: [],
              },
              {
                id: 810306,
                name: "西贡区",
                shortName: "西贡",
                children: [],
              },
              {
                id: 810307,
                name: "沙田区",
                shortName: "沙田",
                children: [],
              },
              {
                id: 810308,
                name: "葵青区",
                shortName: "葵青",
                children: [],
              },
              {
                id: 810309,
                name: "离岛区",
                shortName: "离岛",
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 820000,
        name: "澳门特别行政区",
        shortName: "澳门",
        children: [
          {
            id: 820100,
            name: "澳门半岛",
            shortName: "澳门半岛",
            children: [
              {
                id: 820101,
                name: "花地玛堂区",
                shortName: "花地玛堂区",
                children: [],
              },
              {
                id: 820102,
                name: "圣安多尼堂区",
                shortName: "圣安多尼堂区",
                children: [],
              },
              {
                id: 820103,
                name: "大堂区",
                shortName: "大堂",
                children: [],
              },
              {
                id: 820104,
                name: "望德堂区",
                shortName: "望德堂区",
                children: [],
              },
              {
                id: 820105,
                name: "风顺堂区",
                shortName: "风顺堂区",
                children: [],
              },
            ],
          },
          {
            id: 820200,
            name: "氹仔岛",
            shortName: "氹仔岛",
            children: [
              {
                id: 820201,
                name: "嘉模堂区",
                shortName: "嘉模堂区",
                children: [],
              },
            ],
          },
          {
            id: 820300,
            name: "路环岛",
            shortName: "路环岛",
            children: [
              {
                id: 820301,
                name: "圣方济各堂区",
                shortName: "圣方济各堂区",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  getters: {
    regions(state) {
      if (state.regions.length > 0) {
        return state.regions;
      } else {
        return JSON.parse(localStorage.getItem("regions"));
      }
    },
  },
  mutations: {
    regions(state, data) {
      state.regions = data;
      localStorage.setItem("regions", JSON.stringify(data));
    },
  },
  actions: {
    async regions(store, config = {}) {
      let regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        return;
      }
      const { commit } = store;
      const { code, data } = await http.get(
        process.env.VUE_APP_URL + "/config/regions",
        config
      );
      if (code == 200) {
        commit("regions", data);
      }
    },
  },
};
export default Region;
