import http from "@/api/Http";
const WarnType = {
  state: {
    warnTypeList: JSON.parse(localStorage.getItem("warnTypeList") || "[]"),
  },
  getters: {
    warnTypeList(state) {
      return state.warnTypeList;
    },
  },
  mutations: {
    updateWarnType(state, data) {
      state.warnTypeList = data;
      localStorage.setItem("warnTypeList", JSON.stringify(data));
    },
  },
  actions: {
    async SaveWarnTypeList(context, data) {
      if (context.warnTypeList == undefined) {
        http
          .get("/warnType")
          .then((res) => {
            if (res.code == 1) {
              context.commit("updateWarnType", res.data);
            }
          })
          .catch(() => {});
      }
    },
  },
};
export default WarnType;
