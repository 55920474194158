import http from "@/api/Http";
const Device = {
  state: {
    timeList: JSON.parse(localStorage.getItem("timeList") || "[]"),
    typeList: JSON.parse(localStorage.getItem("typeList") || "[]"),
    unitList: JSON.parse(localStorage.getItem("unitList") || "[]"),
    checkList: JSON.parse(localStorage.getItem("checkList") || "[]"),
    classificationList: JSON.parse(
      localStorage.getItem("classificationList") || "[]"
    ),
  },
  getters: {
    timeList(state) {
      return state.timeList;
    },
    typeList(state) {
      return state.typeList;
    },
    unitList(state) {
      return state.unitList;
    },
    checkList(state) {
      return state.checkList;
    },
    classificationList(state) {
      return state.classificationList;
    },
  },
  mutations: {
    addTodayTime(state, data) {
      state.timeList = data;
      localStorage.setItem("timeList", JSON.stringify(data));
    },
    addType(state, data) {
      state.typeList = data;
      localStorage.setItem("typeList", JSON.stringify(data));
    },
    addUnit(state, data) {
      state.unitList = data;
      localStorage.setItem("unitList", JSON.stringify(data));
    },
    addCheck(state, data) {
      state.checkList = data;
      localStorage.setItem("checkList", JSON.stringify(data));
    },
    addClassification(state, data) {
      state.classificationList = data;
      localStorage.setItem("classificationList", JSON.stringify(data));
    },
  },
  actions: {
    //获取服务器当天时间
    SaveTodayTime(context, data) {
      http
        .get("/config/timeCheck")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addTodayTime", res.msg);
          }
        })
        .catch(() => {});
    },
    // 获取用水单元
    SaveUnit(context, data) {
      http
        .get("/waterUnit")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addUnit", res.data);
          }
        })
        .catch(() => {});
    },
    // 获取设备类型
    SaveType(context, data) {
      http
        .get("/deviceType")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addType", res.data);
          }
        })
        .catch(() => {});
    },
    // 获取检测类型
    SaveCheck(context, data) {
      context.commit("addCheck", data);
    },
    //获取水资源分类
    SaveClassification(context, data) {
      http
        .get("/classificationWater")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addClassification", res.data);
          }
        })
        .catch(() => {});
    },
  },
};
export default Device;
