import http from "@/api/Http";
const User = {
  state: {
    User: null,  
    Authorization: localStorage.getItem("Authorization") ? JSON.parse(localStorage.getItem("Authorization")) : false, //只初始化的时候调用localStorage（store.state.User调用的一直是最后一次更新的数值）
  },
  //计算属性
  getters: {
    Authorization(state) {
      if (!state.Authorization) {
        return false;
      } 
      else {
        if (state.Authorization.expire_in - 60000 < new Date().getTime()) {
          return false;
        } else {
          return state.Authorization;
        }
      }
    },
    TokenExpire(state) {
      if (state.Authorization) {
        return state.Authorization.expire_in;
      } else {
        return false;
      }
    },
    User(state) {
      if (state.User != null) {
        return state.User;
      } else if (localStorage.getItem("user") != null) {
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return null;
      }
    },
    userId(state) {
      if (state.User != null) {
        return state.User.id;
      } else if (localStorage.getItem("user") != null) {
        return JSON.parse(localStorage.getItem("user")).id;
      } else {
        return 0;
      }
    },
    Company(state) {
      if (state.User != null) {
        return state.User.company;
      } else if (localStorage.getItem("user") != null) {
        let user = JSON.parse(localStorage.getItem("user"));
        return user.company;
      } else {
        return null;
      }
    },
    CompanyId(state) {
      if (state.User != null) {
        return state.User.company.id;
      } else if (localStorage.getItem("user") != null) {
        let user = JSON.parse(localStorage.getItem("user"));
        return user.company.id;
      } else {
        return null;
      }
    },
  },
  //更改store中的状态
  mutations: {
    Authorization(state, data) {
      state.Authorization = data;
      localStorage.setItem("Authorization", JSON.stringify(data));
    },
    LOGIN(state, data) {
      state.User = data;
      localStorage.setItem("user", JSON.stringify(data));
    },
    LOGOUT(state) {
      state.User = null;
      localStorage.clear();
    },
  },
  actions: {
    //刷新Token
    async refreshToken(store, config = {}) {
      const { commit, state } = store;
      const { code, data } = await http.get(
        process.env.VUE_APP_URL + "/oauth/refreshToken",
        config
      );
      if (code == 1) {
        commit("Authorization", data);
      }
    },
    async Login(store, config = {}) {
      const { commit, state } = store;
      commit("Authorization", config);
      const { code, data } = await http.get(
        process.env.VUE_APP_URL + "/oauth/userinfo",
        config
      );
      if (code == 1) {
        commit("LOGIN", data);
      }
    },
    Logout(context) {
      context.commit("LOGOUT");
    },
    async getMark(store, config = {}) {
      const { commit, state } = store;
      const { code, msg } = await http.get(
        process.env.VUE_APP_URL + "/config/timeCheck",
        config
        );
      if (code == 1) {
        var currentDay = msg;
        var markDay = (parseInt(currentDay.substring(0, 4)) - 1).toString() + currentDay.substring(4);
        http
          .get("/device/equipmentList", {
            params: {
              keyword: "",
              isFictitious: 0,
            },
          })
          .then((res) => {
            if (res.code == 1) {
              var msgArr = res.data.filter((item) => new Date(item.createdAt.substring(0, 10)).getTime() < new Date(markDay).getTime());
                if(msgArr.length == 0){
                  sessionStorage.setItem('markIndex', true);
                }
                else{
                  sessionStorage.setItem('markIndex', false);
                }
              }
            })
            .catch(() => {});
          }
    },
  },
};
export default User;
