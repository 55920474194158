import Vue from "vue";
import App from "./App.vue";
import axios from "./api/Http";
import * as api from "./api/Api";
// import mqtt from "./api/Mqtt";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "./assets/less/app.less";
import IconFont from "./utils/iconfont";
import ECharts from "vue-echarts";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import JsonExcel from "vue-json-excel";
import "./utils/index";
import scroll from "vue-seamless-scroll";
import Router from "vue-router";
import "animate.css";
import echarts from "echarts";
import proportion from "vue-proportion-directive";
import "./assets/fonts/iconfont.css";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
Vue.prototype.$echarts = echarts;
Vue.use(proportion);
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
Vue.use(scroll);
Vue.prototype.$moment = moment; //赋值使用
moment.locale("zh-cn"); //需要汉化
Vue.config.productionTip = false;
Vue.api = Vue.prototype.$api = api;
Vue.http = Vue.prototype.$http = axios;
Vue.prototype.$zhCN = zhCN;
// Vue.mqtt = Vue.prototype.$mqtt = mqtt;
Vue.use(Antd);
Vue.component("icon-font", IconFont);
Vue.component("downloadExcel", JsonExcel);
Vue.component("v-chart", ECharts);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
Vue.use(VXETable);
// 给 vue 实例挂载内部对象，例如：
Vue.prototype.$XModal = VXETable.modal;
Vue.prototype.$XPrint = VXETable.print;
Vue.prototype.$XSaveFile = VXETable.saveFile;
Vue.prototype.$XReadFile = VXETable.readFile;
