var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"antd-layout-container"},[_c('div',{staticClass:"login-panel"},[_c('div',[_c('h1',{staticClass:"title"},[_vm._v("登录")]),_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.login}},[_c('p',{staticClass:"des"},[_vm._v("账号/邮箱")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'userName',
              {
                rules: [{ required: true, message: '请输入账号或邮箱地址!' }],
              } ]),expression:"[\n              'userName',\n              {\n                rules: [{ required: true, message: '请输入账号或邮箱地址!' }],\n              },\n            ]"}],staticStyle:{"width":"500px"},attrs:{"size":"large","placeholder":"请输入账号或邮箱地址"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('p',{staticClass:"passWord"},[_vm._v("密码")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              { rules: [{ required: true, message: '请输入您的密码!' }] } ]),expression:"[\n              'password',\n              { rules: [{ required: true, message: '请输入您的密码!' }] },\n            ]"}],attrs:{"size":"large","type":"password","placeholder":"请输入您的密码","autocomplete":""}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remember',
              {
                valuePropName: 'checked',
                initialValue: true,
              } ]),expression:"[\n              'remember',\n              {\n                valuePropName: 'checked',\n                initialValue: true,\n              },\n            ]"}]},[_vm._v("记住我")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","size":"large","loading":_vm.loading}},[_vm._v("登录")])],1)],1)],1)]),_c('div',{staticClass:"bg"}),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("建议使用"),_c('img',{staticClass:"googleIcon",attrs:{"src":require("@assets/images/google.png"),"alt":""}}),_vm._v("Chrome(谷歌)浏览器访问本系统")]),_c('p',[_vm._v("Copyright ©2019 OnePower Corporation, All Rights Reserved")]),_c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("豫ICP备2021025725-1号")])])])}]

export { render, staticRenderFns }